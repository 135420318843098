import isNumber from 'lodash/isNumber'

export const phone = (num) =>
  typeof num === 'number'
    ? phone(num.toString())
    : num && num.length === 10
    ? `(${num.substr(0, 3)}) ${num.substr(3, 3)}-${num.substr(6, 4)}`
    : num

export const name = (user, lastFirst) => {
  if (!user) return ''

  const firstExists = user.firstName && user.firstName.length
  const lastExists = user.lastName && user.lastName.length

  if (!(firstExists || lastExists)) return '---'

  if (
    lastFirst === undefined ||
    lastFirst === true ||
    lastFirst.lastFirst === true
  )
    if (lastExists && firstExists) return `${user.lastName}, ${user.firstName}`
    else if (lastExists) return user.lastName
    else return user.firstName
  else if (firstExists && lastExists)
    return `${user.firstName} ${user.lastName}`
  else if (firstExists) return user.firstName
  else return user.lastName
}

export const dollars = (cents) => {
  if (!isNumber(cents)) return null
  if (cents < 0) return '-$' + (-cents / 100).toFixed(2)
  else return '$' + cents.toFixed(2)
}

const doubleDigits = (num) => (num < 10 ? '0' + num : num.toString())

export const dateTime = (utc) => {
  const date = utc.getTime ? utc : new Date(utc * 1000)
  return (
    doubleDigits(date.getMonth() + 1) +
    '/' +
    doubleDigits(date.getDate()) +
    '/' +
    doubleDigits(date.getFullYear() - 2000) +
    ' ' +
    doubleDigits(date.getHours()) +
    ':' +
    doubleDigits(date.getMinutes())
  )
}
export const dateTimeWithSeconds = (utc) => {
  const date = utc.getTime ? utc : new Date(utc * 1000)
  return (
    doubleDigits(date.getMonth() + 1) +
    '/' +
    doubleDigits(date.getDate()) +
    '/' +
    doubleDigits(date.getFullYear() - 2000) +
    ' ' +
    doubleDigits(date.getHours()) +
    ':' +
    doubleDigits(date.getMinutes()) +
    ':' +
    doubleDigits(date.getSeconds())
  )
}
export const date = (utc) => {
  const date = utc.getTime ? utc : new Date(utc * 1000)
  return (
    doubleDigits(date.getMonth() + 1) +
    '/' +
    doubleDigits(date.getDate()) +
    '/' +
    doubleDigits(date.getFullYear() - 2000)
  )
}

export const feet = (value) => {
  if (!isNumber(value)) return null
  else if (value < 0) return `-${feet(-1 * value)}`
  else {
    const units = Math.round(value * 120)
    const inchesOut = (units % 120) / 10
    const feetOut = Math.round(value - inchesOut / 12)
    return `${feetOut} ft ${inchesOut.toFixed(1)} in`
  }
}

export const timer = (seconds) => {
  const roundedSeconds = seconds > 0 ? seconds >> 0 : 0
  const minutes = Math.floor(seconds / 60)
  return `${minutes}:${doubleDigits(roundedSeconds - minutes * 60)}`
}
