export const resizeContainer = ({top, left, width, height}) => ({
  type: 'CONTAINER_UPDATE',
  payload: {top, left, width, height}
})

export const changeOffset = (params) => ({
  type: 'CONTAINER_CHANGE_OFFSET',
  payload: params
})

export const updateOffset = (params) => ({
  type: 'CONTAINER_UPDATE_OFFSET',
  payload: params
})

export const updateContainer = (params) => ({
  type: 'CONTAINER_UPDATE',
  payload: params
})

export const moveView = (move) => ({
  type: 'VIEW_MOVE',
  payload: move
})

export const zoom = (factor, center) => ({
  type: 'VIEW_ZOOM',
  payload: {factor, center}
})

export const setView = (params) => ({
  type: 'VIEW_SET',
  payload: params
})

export const updateView = (params) => ({
  type: 'VIEW_UPDATE',
  payload: params
})
