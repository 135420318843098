import React from 'react'
import {Link} from 'router'
import isDelayed from 'utils/isDelayed'
import TagCardDevicesScratch from './TagCardDevicesScratch'
import filterVisibleTags from 'utils/filterVisibleTags'
import Tooltip from 'Components/Tooltip'

import './DeviceCard.scss'

const _filterVisibleTags = (tags, defaultTag) =>
  tags
    .filter(t => t.tag === defaultTag)

const DeviceCard = ({device, isSuperUser}) => {
  if (!device) return null

  let tags

  // supports scratchTable and non-scratchTable
  if (device.defaultTag) {
    tags = _filterVisibleTags(device.tags, device.defaultTag)
  } else {
    tags = filterVisibleTags(device.tags)
  }

  const delayed = isDelayed(device)
  return (
    <div className={delayed ? 'device-card delayed' : 'device-card'}>
      <div className="box">
        <div className="title">
          {delayed && (
            <Tooltip iconClassName="fa fa-clock">
              This device hasn't reported data in the past 6 hours.
            </Tooltip>
          )}
          <span className="device-name">
            {device.name && device.name !== '' ? (
              device.name
            ) : (
              <span>
                [{device.id}] <em>New Device</em>
              </span>
            )}
          </span>
        </div>
        {tags.length > 0 ? (
          tags.map(t => (
            <TagCardDevicesScratch device={device} tag={t} shrink={tags.length > 1} key={t.tag} />
          ))
        ) : (
          <div className="no-tags">
            <i className="fa fa-ban" />
          </div>
        )}
        <div className="edit-device">
          <span className="left">ID: {device.id}</span>
          <span className="right">
            <Link path={`/devices/${device.id}`}>Device Info</Link>
          </span>
        </div>
      </div>
    </div>
  )
}

export default DeviceCard
