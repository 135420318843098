import React from 'react'
// import DeviceCard from 'Components/DeviceCard'
import {Query} from 'react-apollo'
import { gql } from 'graphql.macro'
import Loading from 'Components/Loading'

import * as compose from 'lodash/flowRight'

import './AllDevices.scss'
import Toolbar from './Toolbar2'
import List from './ListAll'
import {connect} from 'react-redux'
import {
  setDirectoryPath,
  setDirectoryCompany,
  toggleIncludeInactive,
  toggleIncludeOOS,
  setViewType,
  toggleIncludeVIA
} from 'store/actions'

export const GET_DEVICES_SCRATCH_ALL = gql`
  query GET_DEVICES_SCRATCH_ALL($includeInactive: Boolean, $includeOOS: Boolean, $query: String, $selectedCompanyID: Int, $limit: Int, $offset: Int) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesRe(includeInactive: $includeInactive, includeOOS: $includeOOS, query: $query, selectedCompanyID: $selectedCompanyID, limit: $limit, offset: $offset) {
      id
      name
      path
      userAssignedID
      batteryLevel
      displayUnits
      longitude
      latitude
      sensorSubtype1
      sensorSubtype2
      firmwareVersion
      modemType
      simNumber
      boardRev
      signalStrength
      defaultTag
      companyID
      company {
        name
      }
      latestReading
      lastTimeMessageReceived
      tags {
        tag
        alarms {
          flag
        }
      }
    }
  }
`

const AllDevices = ({includeInactive, includeOOS, includeVIA}) => (
  <Query query={GET_DEVICES_SCRATCH_ALL} variables={{includeInactive, includeOOS, includeVIA, query: 'all'}}>
    {({error, loading, data}) => {
      if (error) return 'error'
      if (loading) return <Loading />

      const devices = data.devicesRe

      return (
        <div className="directory-body">
            <Toolbar
              isSuperUser={data && data.me && data.me.isSuperUser}
              isManager={data && data.me && data.me.isManager}
            />
            <List devices={devices} isSuperUser={data && data.me && data.me.isSuperUser} />
        </div>
      )
    }}
  </Query>
)

const mapStateToProps = (state) => ({
    path: state.directory.path,
    companyID: state.directory.companyID,
    includeInactive: state.directory.includeInactive,
    includeOOS: state.directory.includeOOS,
    includeVIA: state.directory.includeVIA,
    viewType: state.directory.viewType
  })
  const mapDispatchToProps = (dispatch) => ({
    setDirectoryPath: (path) => dispatch(setDirectoryPath(path)),
    setDirectoryCompany: (id) => {
      dispatch(setDirectoryPath(''))
      dispatch(setDirectoryCompany(id))
    },
    toggleIncludeInactive: () => dispatch(toggleIncludeInactive()),
    toggleIncludeOOS: () => dispatch(toggleIncludeOOS()),
    toggleIncludeVIA: () => dispatch(toggleIncludeVIA()),
    setViewType: (type) => dispatch(setViewType(type))
  })
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps)
  )(AllDevices)