import React from 'react'
// import React, {useState} from 'react'
// import {ReactComponent as NoAlarms} from 'assets/noAlarms.svg'
// import {Query, Mutation} from 'react-apollo'
// import {GET_DEVICES_SCRATCH} from 'queries'
// import Loading from 'Components/Loading'
// import Tag from 'utils/Tag'
// import Modal from 'Components/Modal'
// import ago from 'utils/ago'
// import {gql} from 'graphql.macro'
// import {ButtonGroup, ButtonGroupOption} from 'Components/ButtonGroup'

import './Tasks.scss'

// const Tasks = ({allDevices}) => {
const Tasks = () => {
  // const [sortBy, setSortBy] = useState('name')
  // const [sortDirection, setSortDirection] = useState('asc')
  // const [modal, setModal] = useState({type: false})
  // const [activeTask, setActiveTask] = useState(null)
  // const [priority, setPriority] = useState(null)
  // const [notes, setNotes] = useState(null)
  // const [isComplete, setIsComplete] = useState(null)
  // const [view, setView] = useState('incomplete')

  // const UPDATE_TASK = gql`
  //   mutation UPDATE_TASK(
  //     $deviceID: Int!
  //     $priority: Int!
  //     $isComplete: Boolean
  //     $notes: String
  //     $type: String
  //   ) {
  //     updateTask(
  //       deviceID: $deviceID
  //       priority: $priority
  //       isComplete: $isComplete
  //       notes: $notes
  //       type: $type
  //     ) {
  //       device {
  //         tasks {
  //           id
  //           deviceID
  //           priority
  //           isComplete
  //           notes
  //           type
  //         }
  //       }
  //     }
  //   }
  // `
  return ( <div>in testing</div>
    // <Query query={GET_DEVICES_SCRATCH}>
    //   {({error, loading, data}) => {
    //     if (error) return 'error'
    //     if (loading) return <Loading />

    //     const typeTitle = {
    //       failure: 'Failure',
    //       tardy: 'Tardiness',
    //       slow: 'Slowdown',
    //       hibernate: 'Hibernation'
    //     }

    //     const sort = async (e, key) => {
    //       e.preventDefault()
    //       await setSortBy(key)
    //       await setSortDirection(
    //         sortBy === key ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    //       )
    //     }

    //     const sortFunction = () => {
    //       const reverse = sortDirection === 'asc'
    //       switch (sortBy) {
    //         case 'id':
    //           return (a, b) =>
    //             reverse ? a.deviceID - b.deviceID : b.deviceID - a.deviceID
    //         case 'companyName':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) *
    //             (a.companyName || 'zzzzz').localeCompare(
    //               b.companyName || 'zzzzz'
    //             )
    //         case 'boardRev':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) *
    //             (a.boardRev || 'zzzzz').localeCompare(b.boardRev || 'zzzzz')
    //         case 'notes':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) *
    //             (a.notes || 'zzzzz').localeCompare(b.notes || 'zzzzz')
    //         case 'alerttype':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) *
    //             (a.type || 'zzzzz').localeCompare(b.type || 'zzzzz')
    //         case 'name':
    //           return (a, b) =>
    //             (reverse ? -1 : 1) *
    //             (
    //               (a.devicePath || 'zzzzz') + (a.deviceName || 'zzzzz')
    //             ).localeCompare(
    //               (b.devicePath || 'zzzzz') + (b.deviceName || 'zzzzz')
    //             )
    //         case 'priority':
    //           return (a, b) => (reverse ? 1 : -1) * (a.priority - b.priority)
    //         case 'firmware':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) * (a.firmwareVersion - b.firmwareVersion)
    //         case 'reading':
    //           return (a, b) =>
    //             !a.LatestTag
    //               ? 1
    //               : !b.LatestTag
    //               ? -1
    //               : (reverse ? 1 : -1) * (a.LatestTag.value - b.LatestTag.value)
    //         case 'complete':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) * (a.isComplete - b.isComplete)
    //         case 'filter':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) * (a.filterEnabled - b.filterEnabled)
    //         case 'sensorType':
    //           return (a, b) =>
    //             !a.LatestTag
    //               ? 1
    //               : !b.LatestTag
    //               ? -1
    //               : (reverse ? 1 : -1) *
    //                 (a.LatestTag.tag || 'zzzzz').localeCompare(
    //                   b.LatestTag.tag || 'zzzzz'
    //                 )
    //         case 'battery':
    //           return (a, b) =>
    //             !a.battery
    //               ? 1
    //               : !b.battery
    //               ? -1
    //               : (reverse ? -1 : 1) * (a.battery - b.battery)
    //         case 'time':
    //           return (a, b) =>
    //             !a.LatestTag.time
    //               ? 1
    //               : !b.LatestTag.time
    //               ? -1
    //               : (reverse ? -1 : 1) * (a.LatestTag.time - b.LatestTag.time)
    //         default:
    //           return () => null
    //       }
    //     }

    //     const sortIndicator = (key) =>
    //       sortBy === key ? (
    //         sortDirection === 'asc' ? (
    //           <i className="fa fa-caret-up" />
    //         ) : (
    //           <i className="fa fa-caret-down" />
    //         )
    //       ) : null

    //     let tasks = (data.devicesScratch || [])
    //       .filter((d) => d.isActive)
    //       .filter((d) => d.inService)
    //       .filter((d) => d.tasks.length > 0)
    //       .map((d) =>
    //         d.tasks
    //           .filter((t) => {
    //             if (view === 'incomplete') {
    //               return !t.isComplete
    //             } else if (view === 'completed') {
    //               return t.isComplete
    //             } else {
    //               return true
    //             }
    //           })
    //           .map((t) => {
    //             return {
    //               taskID: t.id,
    //               deviceID: d.id,
    //               deviceName: d.name,
    //               devicePath: d.path,
    //               isAlarm: d.isAlarm,
    //               isLowVoltage: d.isLowVoltage,
    //               isDelayed: d.isDelayed,
    //               description: d.description,
    //               boardRev: d.boardRev,
    //               firmwareVersion: d.firmwareVersion,
    //               LatestTag: new Tag(
    //                 d.tags.reduce(
    //                   (latest, t) => (t.time > latest.time ? t : latest),
    //                   d.tags[0]
    //                 )
    //               ),
    //               battery: d.tags.reduce(
    //                 (batt, t) => batt || (t.tag === '_v' && t.value),
    //                 null
    //               ),
    //               ...t
    //             }
    //           })
    //       )
    //       .flat()

    //     tasks.sort(sortFunction())
    //     return (
    //       <div className=" device-card-container">
    //         <div className=" device-card-row">
    //           <div className="title">
    //             Tasks
    //             <div className="group-right">
    //               <ButtonGroup>
    //                 <ButtonGroupOption
    //                   onClick={() => setView('incomplete')}
    //                   selected={view === 'incomplete'}
    //                 >
    //                   Incomplete
    //                 </ButtonGroupOption>
    //                 <ButtonGroupOption
    //                   onClick={() => setView('completed')}
    //                   selected={view === 'completed'}
    //                 >
    //                   Completed
    //                 </ButtonGroupOption>
    //                 <ButtonGroupOption
    //                   onClick={() => setView('all')}
    //                   selected={view === 'all'}
    //                 >
    //                   All
    //                 </ButtonGroupOption>
    //               </ButtonGroup>
    //             </div>
    //           </div>
    //           <div className="list-container device-list">
    //             {tasks.length === 0 ? null : (
    //               <div className="tasks-row header">
    //                 <div
    //                   className="tasks-id clickable"
    //                   onClick={async (e) => await sort(e, 'id')}
    //                 >
    //                   Device ID {sortIndicator('id')}
    //                 </div>
    //                 <div
    //                   className="tasks-name clickable"
    //                   onClick={async (e) => await sort(e, 'name')}
    //                 >
    //                   Name {sortIndicator('name')}
    //                 </div>
    //                 <div
    //                   className="tasks-company clickable"
    //                   onClick={async (e) => await sort(e, 'companyName')}
    //                 >
    //                   Company {sortIndicator('companyName')}
    //                 </div>

    //                 <div
    //                   className="tasks-sensor-type clickable"
    //                   onClick={async (e) => await sort(e, 'sensorType')}
    //                 >
    //                   Sensor Type {sortIndicator('sensorType')}
    //                 </div>
    //                 <div
    //                   className="tasks-latest-reading clickable"
    //                   onClick={async (e) => await sort(e, 'reading')}
    //                 >
    //                   Latest Reading {sortIndicator('reading')}
    //                 </div>

    //                 <div
    //                   className="tasks-voltage clickable"
    //                   onClick={async (e) => await sort(e, 'battery')}
    //                 >
    //                   Battery {sortIndicator('battery')}
    //                 </div>
    //                 <div
    //                   className="tasks-last-message clickable"
    //                   onClick={async (e) => await sort(e, 'time')}
    //                 >
    //                   Last Message {sortIndicator('time')}
    //                 </div>
    //                 <div
    //                   className="tasks-boardrev clickable"
    //                   onClick={async (e) => await sort(e, 'boardRev')}
    //                 >
    //                   Board Rev {sortIndicator('boardRev')}
    //                 </div>
    //                 <div
    //                   className="tasks-firmware clickable"
    //                   onClick={async (e) => await sort(e, 'firmware')}
    //                 >
    //                   Firmware {sortIndicator('firmware')}
    //                 </div>
    //                 <div
    //                   className="tasks-filter-enabled clickable"
    //                   onClick={async (e) => await sort(e, 'filter')}
    //                 >
    //                   Filtered? {sortIndicator('filter')}
    //                 </div>
    //                 <div
    //                   className="tasks-alerttype clickable"
    //                   onClick={async (e) => await sort(e, 'alerttype')}
    //                 >
    //                   Alert Type {sortIndicator('alerttype')}
    //                 </div>
    //                 <div
    //                   className="tasks-priority clickable"
    //                   onClick={async (e) => await sort(e, 'priority')}
    //                 >
    //                   Priority {sortIndicator('priority')}
    //                 </div>
    //                 <div
    //                   className="tasks-complete clickable"
    //                   onClick={async (e) => await sort(e, 'complete')}
    //                 >
    //                   Completed? {sortIndicator('complete')}
    //                 </div>
    //                 <div
    //                   className="tasks-notes clickable"
    //                   onClick={async (e) => await sort(e, 'notes')}
    //                 >
    //                   Notes {sortIndicator('notes')}
    //                 </div>
    //               </div>
    //             )}

    //             <div className="scrollable-container">
    //               {tasks.length === 0 ? (
    //                 <div className="no-alarms" style={{marginTop: '15em'}}>
    //                   <NoAlarms />
    //                   <h1>No tasks</h1>
    //                 </div>
    //               ) : (
    //                 <table>
    //                   <tbody>
    //                     {tasks.map((t) => (
    //                       <tr
    //                         key={t.taskID}
    //                         className="tasks-row"
    //                         onClick={async () => {
    //                           await setActiveTask(t)
    //                           await setPriority(t.priority)
    //                           await setIsComplete(t.isComplete)
    //                           await setNotes(t.notes)
    //                           await setModal(true)
    //                         }}
    //                       >
    //                         <td className="tasks-id" style={{height: 'auto'}}>
    //                           <a href={`#/devices/${t.deviceID}`}>
    //                             {t.deviceID}
    //                           </a>
    //                         </td>
    //                         <td className="tasks-name" style={{height: 'auto'}}>
    //                           {t.devicePath && t.deviceName ? (
    //                             <a href={`#/devices/${t.deviceID}`}>
    //                               {t.devicePath} :{' '}
    //                               <strong>{t.deviceName}</strong>
    //                             </a>
    //                           ) : (
    //                             <em>None</em>
    //                           )}
    //                         </td>
    //                         <td
    //                           className="tasks-company"
    //                           style={{height: 'auto'}}
    //                         >
    //                           {t.companyName ? t.companyName : <em>None</em>}
    //                         </td>

    //                         <td
    //                           className="tasks-sensor-type"
    //                           style={{height: 'auto'}}
    //                         >
    //                           {t.LatestTag ? t.LatestTag.tag : <em>None</em>}
    //                         </td>
    //                         <td
    //                           className="tasks-latest-reading"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div className={t.isAlarm ? 'alarm' : 'normal'}>
    //                             {t.LatestTag.display.render(
    //                               t.LatestTag.value
    //                             ) ? (
    //                               t.LatestTag.display.render(t.LatestTag.value)
    //                             ) : (
    //                               <em>N/A</em>
    //                             )}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="tasks-voltage"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div
    //                             className={
    //                               t.isLowVoltage ? 'warning' : 'normal'
    //                             }
    //                           >
    //                             {t.battery ? (
    //                               `${t.battery.toFixed(2)} V`
    //                             ) : (
    //                               <em>N/A</em>
    //                             )}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="tasks-last-message"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div
    //                             className={t.isDelayed ? 'warning' : 'normal'}
    //                           >
    //                             {ago(t.LatestTag.time) ? (
    //                               ago(t.LatestTag.time)
    //                             ) : (
    //                               <em>N/A</em>
    //                             )}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="tasks-boardrev"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div className={'normal'}>
    //                             {t.boardRev ? t.boardRev : <em>N/A</em>}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="tasks-firmware"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div className={'normal'}>
    //                             {t.firmwareVersion ? (
    //                               t.firmwareVersion
    //                             ) : (
    //                               <em>N/A</em>
    //                             )}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="tasks-filter-enabled"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div className={'normal'}>
    //                             {t.LatestTag ? (
    //                               t.LatestTag.filterEnabled === false ? (
    //                                 'No'
    //                               ) : t.LatestTag.filterEnabled === true ? (
    //                                 'Yes'
    //                               ) : (
    //                                 <em>N/A</em>
    //                               )
    //                             ) : (
    //                               <em>N/A</em>
    //                             )}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="tasks-alerttype"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div className={'normal'}>
    //                             {t.type ? t.type : <em>N/A</em>}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="tasks-priority"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div className={'normal'}>
    //                             {t.priority ? t.priority : <em>None</em>}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="tasks-complete"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div className={'normal'}>
    //                             {t.isComplete ? 'Yes' : 'No'}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="tasks-notes"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div className={'normal'}>
    //                             {t.notes ? t.notes : <em>None</em>}
    //                           </div>
    //                         </td>
    //                       </tr>
    //                     ))}
    //                   </tbody>
    //                 </table>
    //               )}
    //             </div>
    //           </div>
    //         </div>
    //         {modal === true && (
    //           <Modal
    //             onClose={async () => {
    //               await setModal(false)
    //               await setIsComplete(null)
    //               await setPriority(null)
    //               await setNotes(null)
    //               await setActiveTask(null)
    //             }}
    //           >
    //             <Modal.Title>
    //               Task for {typeTitle[activeTask.type]} Alert
    //             </Modal.Title>
    //             <Modal.Body>
    //               <div className="col inputs">
    //                 <div className="flex-row device-id">
    //                   <div className="label">Device ID: </div>
    //                   <div className="input">
    //                     <input
    //                       type="number"
    //                       value={activeTask.deviceID}
    //                       disabled={true}
    //                     />
    //                   </div>
    //                 </div>
    //                 <br />
    //                 <div className="flex-row name">
    //                   <div className="label">Device Name: </div>
    //                   <div className="input">
    //                     <input value={activeTask.deviceName} disabled={true} />
    //                   </div>
    //                 </div>
    //                 <br />
    //                 <div className="flex-row directory">
    //                   <div className="label">Company Name: </div>
    //                   <div className="input">
    //                     <input value={activeTask.companyName} disabled={true} />
    //                   </div>
    //                 </div>
    //                 <br />
    //                 <div className="flex-row ">
    //                   <div className="label">Priority: </div>
    //                   <div className="input">
    //                     <input
    //                       value={priority}
    //                       type={'number'}
    //                       onChange={(e) => setPriority(e.target.value)}
    //                     />
    //                   </div>
    //                 </div>
    //                 <br />
    //                 <div className="flex-row ">
    //                   <div className="label">Notes: </div>
    //                   <div className="input">
    //                     <textarea
    //                       rows="10"
    //                       cols="40"
    //                       value={notes}
    //                       onChange={(e) => setNotes(e.target.value)}
    //                     />
    //                   </div>
    //                 </div>
    //                 <br />
    //                 <div className="flex-row ">
    //                   <div className="label">Completed: </div>
    //                   <div className="input">
    //                     <select
    //                       value={isComplete}
    //                       onChange={(e) =>
    //                         setIsComplete(
    //                           e.target.value === 'false' ? false : true
    //                         )
    //                       }
    //                     >
    //                       <option value={true}>Yes</option>
    //                       <option value={false}>No</option>
    //                     </select>
    //                   </div>
    //                 </div>
    //                 <br />
    //               </div>
    //             </Modal.Body>
    //             <Modal.Footer>
    //               <span
    //                 className="button secondary"
    //                 onClick={async () => {
    //                   await setModal(false)
    //                   await setIsComplete(null)
    //                   await setPriority(null)
    //                   await setNotes(null)
    //                   await setActiveTask(null)
    //                 }}
    //               >
    //                 Cancel
    //               </span>
    //               <Mutation mutation={UPDATE_TASK}>
    //                 {(updateTask) => {
    //                   return (
    //                     <span
    //                       className="button primary"
    //                       onClick={async () => {
    //                         await updateTask({
    //                           variables: {
    //                             deviceID: activeTask.deviceID,
    //                             priority: parseInt(priority),
    //                             notes: notes,
    //                             isComplete: isComplete
    //                           },
    //                           refetchQueries: [{query: GET_DEVICES_SCRATCH}]
    //                         })
    //                         await setModal(false)
    //                         await setIsComplete(null)
    //                         await setPriority(null)
    //                         await setNotes(null)
    //                         await setActiveTask(null)
    //                       }}
    //                     >
    //                       Save
    //                     </span>
    //                   )
    //                 }}
    //               </Mutation>
    //             </Modal.Footer>
    //           </Modal>
    //         )}
    //       </div>
    //     )
    //   }}
    // </Query>
  )
}

export default Tasks
