import React, {Component} from 'react'
import {connect} from 'react-redux'
import {LTTB} from 'downsample'
import interpolate from 'color-interpolate'
import memoize from 'memoize-one'
import './FourierDisplay.scss'
// let colorMap = require('colormap')
// let palette = colorMap({colormap: 'portland'})

class FourierDisplay extends Component {
  state = {
    colorPalette: null
  }
  componentDidMount() {
    this.initializeState()
  }
  initializeState = () => {
    const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)')
    if (prefersDarkScheme.matches) {
      this.setState({
        colorPalette: interpolate(['blue', 'purple', 'red'])
      })
    } else {
      this.setState({
        colorPalette: interpolate(['green', 'yellow', 'red'])
      })
    }
  }
  visibleBlocks = memoize((data, xStart, xEnd) => {
    let range = xEnd - xStart
    return data.filter((row) => {
      return row[0] >= xStart - 0.05 * range && row[0] <= xEnd + 0.05 * range
    })
  })
  downSampledBlocks = memoize((blockArray) => {
    return LTTB(blockArray, 500)
  })
  blockPixelHeight = memoize((graphHeight) => graphHeight / 8)

  defineGradients = memoize((blockArray, colorPalette) => {
    if (blockArray.length < 1 || !colorPalette) {
      return null
    }
    let start = this.props.xStart
    let end = this.props.xEnd
    let totalRange = end - start
    let gapValue = totalRange / 6
    let startOffset =
      (((blockArray[0][0] - start) / totalRange) * 100).toFixed(2) + '%'
    let endOffset =
      (
        ((blockArray[blockArray.length - 1][0] - start) / totalRange) *
        100
      ).toFixed(2) + '%'
    let opacity = 0.5
    let bin1Stops = [
      <stop
        key="bin1Start"
        offset={startOffset}
        stopColor="black"
        stopOpacity={0}
      />
    ]
    let bin2Stops = [
      <stop
        key="bin2Start"
        offset={startOffset}
        stopColor="black"
        stopOpacity={0}
      />
    ]
    let bin3Stops = [
      <stop
        key="bin3Start"
        offset={startOffset}
        stopColor="black"
        stopOpacity={0}
      />
    ]
    let bin4Stops = [
      <stop
        key="bin4Start"
        offset={startOffset}
        stopColor="black"
        stopOpacity={0}
      />
    ]
    let bin5Stops = [
      <stop
        key="bin5Start"
        offset={startOffset}
        stopColor="black"
        stopOpacity={0}
      />
    ]
    let bin6Stops = [
      <stop
        key="bin6Start"
        offset={startOffset}
        stopColor="black"
        stopOpacity={0}
      />
    ]
    let bin7Stops = [
      <stop
        key="bin7Start"
        offset={startOffset}
        stopColor="black"
        stopOpacity={0}
      />
    ]
    let bin8Stops = [
      <stop
        key="bin8Start"
        offset={startOffset}
        stopColor="black"
        stopOpacity={0}
      />
    ]
    blockArray.forEach((block, index) => {
      let offsetValue = ((block[0] - start) / totalRange) * 100
      let offsetString = offsetValue.toFixed(2) + '%'
      let prevOffset
      let prevOffsetString
      let prevBlock
      if (index > 0) {
        prevBlock = blockArray[index - 1]
        prevOffset = ((prevBlock[0] - start) / totalRange) * 100
        prevOffsetString = (prevOffset + 0.01).toFixed(2) + '%'
      }
      if (index > 0 && block[0] - prevBlock[0] > gapValue) {
        bin1Stops.push(
          <stop
            key={`bin1-extra-offset-start-${prevBlock[0]}`}
            offset={prevOffsetString}
            stopColor="black"
            stopOpacity={0}
          />,
          <stop
            key={`bin1-extra-offset-end-${block[0]}`}
            offset={(offsetValue - 0.01).toFixed(2) + '%'}
            stopColor="black"
            stopOpacity={0}
          />
        )
        bin2Stops.push(
          <stop
            key={`bin2-extra-offset-start-${prevBlock[0]}`}
            offset={prevOffsetString}
            stopColor="black"
            stopOpacity={0}
          />,
          <stop
            key={`bin2-extra-offset-end-${block[0]}`}
            offset={(offsetValue - 0.01).toFixed(2) + '%'}
            stopColor="black"
            stopOpacity={0}
          />
        )
        bin3Stops.push(
          <stop
            key={`bin3-extra-offset-start-${prevBlock[0]}`}
            offset={prevOffsetString}
            stopColor="black"
            stopOpacity={0}
          />,
          <stop
            key={`bin3-extra-offset-end-${block[0]}`}
            offset={(offsetValue - 0.01).toFixed(2) + '%'}
            stopColor="black"
            stopOpacity={0}
          />
        )
        bin4Stops.push(
          <stop
            key={`bin4-extra-offset-start-${prevBlock[0]}`}
            offset={prevOffsetString}
            stopColor="black"
            stopOpacity={0}
          />,
          <stop
            key={`bin4-extra-offset-end-${block[0]}`}
            offset={(offsetValue - 0.01).toFixed(2) + '%'}
            stopColor="black"
            stopOpacity={0}
          />
        )
        bin5Stops.push(
          <stop
            key={`bin5-extra-offset-start-${prevBlock[0]}`}
            offset={prevOffsetString}
            stopColor="black"
            stopOpacity={0}
          />,
          <stop
            key={`bin5-extra-offset-end-${block[0]}`}
            offset={(offsetValue - 0.01).toFixed(2) + '%'}
            stopColor="black"
            stopOpacity={0}
          />
        )
        bin6Stops.push(
          <stop
            key={`bin6-extra-offset-start-${prevBlock[0]}`}
            offset={prevOffsetString}
            stopColor="black"
            stopOpacity={0}
          />,
          <stop
            key={`bin6-extra-offset-end-${block[0]}`}
            offset={(offsetValue - 0.01).toFixed(2) + '%'}
            stopColor="black"
            stopOpacity={0}
          />
        )
        bin7Stops.push(
          <stop
            key={`bin7-extra-offset-start-${prevBlock[0]}`}
            offset={prevOffsetString}
            stopColor="black"
            stopOpacity={0}
          />,
          <stop
            key={`bin7-extra-offset-end-${block[0]}`}
            offset={(offsetValue - 0.01).toFixed(2) + '%'}
            stopColor="black"
            stopOpacity={0}
          />
        )
        bin8Stops.push(
          <stop
            key={`bin8-extra-offset-start-${prevBlock[0]}`}
            offset={prevOffsetString}
            stopColor="black"
            stopOpacity={0}
          />,
          <stop
            key={`bin8-extra-offset-end-${block[0]}`}
            offset={(offsetValue - 0.01).toFixed(2) + '%'}
            stopColor="black"
            stopOpacity={0}
          />
        )
      }
      bin1Stops.push(
        <stop
          key={`bin1-${block[0]}`}
          offset={offsetString}
          stopColor={colorPalette((block[4] / block[12]) || 0)}
          stopOpacity={opacity}
        />
      )
      bin2Stops.push(
        <stop
          key={`bin2-${block[0]}`}
          offset={offsetString}
          stopColor={colorPalette((block[5] / block[12]) || 0)}
          stopOpacity={opacity}
        />
      )
      bin3Stops.push(
        <stop
          key={`bin3-${block[0]}`}
          offset={offsetString}
          stopColor={colorPalette((block[6] / block[12]) || 0)}
          stopOpacity={opacity}
        />
      )
      bin4Stops.push(
        <stop
          key={`bin4-${block[0]}`}
          offset={offsetString}
          stopColor={colorPalette((block[7] / block[12]) || 0)}
          stopOpacity={opacity}
        />
      )
      bin5Stops.push(
        <stop
          key={`bin5-${block[0]}`}
          offset={offsetString}
          stopColor={colorPalette((block[8] / block[12]) || 0)}
          stopOpacity={opacity}
        />
      )
      bin6Stops.push(
        <stop
          key={`bin6-${block[0]}`}
          offset={offsetString}
          stopColor={colorPalette((block[9] / block[12]) || 0)}
          stopOpacity={opacity}
        />
      )
      bin7Stops.push(
        <stop
          key={`bin7-${block[0]}`}
          offset={offsetString}
          stopColor={colorPalette((block[10] / block[12]) || 0)}
          stopOpacity={opacity}
        />
      )
      bin8Stops.push(
        <stop
          key={`bin8-${block[0]}`}
          offset={offsetString}
          stopColor={colorPalette((block[11] / block[12]) || 0)}
          stopOpacity={opacity}
        />
      )
    })
    bin1Stops.push(
      <stop
        key="bin1End"
        offset={endOffset}
        stopColor="black"
        stopOpacity={0}
      />
    )
    bin2Stops.push(
      <stop
        key="bin2End"
        offset={endOffset}
        stopColor="black"
        stopOpacity={0}
      />
    )
    bin3Stops.push(
      <stop
        key="bin3End"
        offset={endOffset}
        stopColor="black"
        stopOpacity={0}
      />
    )
    bin4Stops.push(
      <stop
        key="bin4End"
        offset={endOffset}
        stopColor="black"
        stopOpacity={0}
      />
    )
    bin5Stops.push(
      <stop
        key="bin5End"
        offset={endOffset}
        stopColor="black"
        stopOpacity={0}
      />
    )
    bin6Stops.push(
      <stop
        key="bin6End"
        offset={endOffset}
        stopColor="black"
        stopOpacity={0}
      />
    )
    bin7Stops.push(
      <stop
        key="bin7End"
        offset={endOffset}
        stopColor="black"
        stopOpacity={0}
      />
    )
    bin8Stops.push(
      <stop
        key="bin8End"
        offset={endOffset}
        stopColor="black"
        stopOpacity={0}
      />
    )
    return (
      <defs>
        <linearGradient id="bin1">{bin1Stops}</linearGradient>
        <linearGradient id="bin2">{bin2Stops}</linearGradient>
        <linearGradient id="bin3">{bin3Stops}</linearGradient>
        <linearGradient id="bin4">{bin4Stops}</linearGradient>
        <linearGradient id="bin5">{bin5Stops}</linearGradient>
        <linearGradient id="bin6">{bin6Stops}</linearGradient>
        <linearGradient id="bin7">{bin7Stops}</linearGradient>
        <linearGradient id="bin8">{bin8Stops}</linearGradient>
      </defs>
    )
  })

  render() {
    if (!this.props.data || !this.props.data.length) return null
    const blockArray = this.visibleBlocks(
      this.props.data,
      this.props.xStart,
      this.props.xEnd
    )
    const downSampledBlockArray = this.downSampledBlocks(blockArray)
    const blockLength = downSampledBlockArray.length
    const bHeight = this.blockPixelHeight(this.props.graphHeight)
    if (blockLength > 0) {
      return (
        <g id="fourier-graphic">
          {this.defineGradients(downSampledBlockArray, this.state.colorPalette)}
          <rect
            key="rect1"
            width={this.props.graphWidth}
            height={bHeight}
            x={0}
            y={this.props.graphHeight - bHeight}
            fill="url(#bin1)"
          />
          <rect
            key="rect2"
            width={this.props.graphWidth}
            height={bHeight}
            x={0}
            y={this.props.graphHeight - bHeight * 2}
            fill="url(#bin2)"
          />
          <rect
            key="rect3"
            width={this.props.graphWidth}
            height={bHeight}
            x={0}
            y={this.props.graphHeight - bHeight * 3}
            fill="url(#bin3)"
          />
          <rect
            key="rect4"
            width={this.props.graphWidth}
            height={bHeight}
            x={0}
            y={this.props.graphHeight - bHeight * 4}
            fill="url(#bin4)"
          />
          <rect
            key="rect5"
            width={this.props.graphWidth}
            height={bHeight}
            x={0}
            y={this.props.graphHeight - bHeight * 5}
            fill="url(#bin5)"
          />
          <rect
            key="rect6"
            width={this.props.graphWidth}
            height={bHeight}
            x={0}
            y={this.props.graphHeight - bHeight * 6}
            fill="url(#bin6)"
          />
          <rect
            key="rect7"
            width={this.props.graphWidth}
            height={bHeight}
            x={0}
            y={this.props.graphHeight - bHeight * 7}
            fill="url(#bin7)"
          />
          <rect
            key="rect8"
            width={this.props.graphWidth}
            height={bHeight}
            x={0}
            y={this.props.graphHeight - bHeight * 8}
            fill="url(#bin8)"
          />
        </g>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => ({
  xStart: state.view.x.start,
  xEnd: state.view.x.end,
  xScale: state.view.x.scale,
  yStart: state.view.y.start,
  yScale: state.view.y.scale,
  graphHeight:
    state.container.height -
    state.container.topOffset -
    state.container.bottomOffset,
  graphWidth:
    state.container.width -
    state.container.leftOffset -
    state.container.rightOffset
})

export default connect(mapStateToProps)(FourierDisplay)
