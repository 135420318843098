import React from 'react'

import './ButtonGroup.scss'

export const ButtonGroup = ({children, className}) => (
  <div className={'button-group ' + (className || '')}>{children}</div>
)
export const ButtonGroupOption = ({children, onClick, selected, className}) => (
  <button
    className={
      'button-group-option ' + (selected ? 'selected ' : '') + (className || '')
    }
    onClick={onClick}
  >
    {children}
  </button>
)
