// roundDirection:
//  0: round to nearest (default)
//  1: round up
//  -1: round down

export default function findClosestPoint(x, data, roundDirection = 0) {
  if (!data.length) return null
  let low = 0,
    high = data.length,
    mid
  while (low < high) {
    mid = (low + high) >>> 1
    data[mid][0] < x ? (low = mid + 1) : (high = mid)
  }

  if (low >= data.length) return data.length - 1
  if (roundDirection === 0) {
    if (
      low > 0 &&
      Math.abs(x - data[low - 1][0]) < Math.abs(x - data[low][0])
    ) {
      return low - 1
    }
  } else {
    if (x !== data[low][0]) {
      if (roundDirection === 1) return low + 1
      else return low - 1
    }
  }
  return low
}
