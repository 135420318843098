import React, {Component} from 'react'
import Modal from 'Components/Modal'
import {Input, InputRow} from 'Components/Form'
import {dateTime as formatDateTime} from 'utils/format'
import {graphql} from 'react-apollo'
import {gql} from 'graphql.macro'

export const ADD_USER_DATA = gql`
  mutation ADD_USER_DATA(
    $deviceID: Int!
    $tag: String!
    $time: Int!
    $value: Float!
  ) {
    addUserData(deviceID: $deviceID, tag: $tag, time: $time, value: $value) {
      device {
        id
        tag(tag: $tag) {
          id
          userData {
            time
            value
          }
        }
      }
    }
  }
`

class AddUserData extends Component {
  state = {value: null}
  render() {
    return (
      <Modal onClose={this.props.close}>
        <Modal.Title>Add User Data</Modal.Title>
        <Modal.Body>
          <InputRow>
            <Input
              type="string"
              label="Time"
              static
              tooltip="The time the measurement was taken. Choose a different time by clicking on a different point on the graph."
              value={formatDateTime(this.props.time)}
              disabled
            />
            <Input
              name="value"
              type={this.props.Tag.display.input || 'float'}
              tooltip="Enter your measurement here."
              label="Reading"
              value={this.state.value}
              onChange={e => this.setState({value: e.target.value})}
            />
          </InputRow>
        </Modal.Body>
        <Modal.Footer>
          <span>
            <span className="button" onClick={this.props.close}>
              Cancel
            </span>
            <span
              className="button primary"
              onClick={() => {
                this.props.addUserData({
                  variables: {
                    deviceID: this.props.Tag.deviceID,
                    tag: this.props.Tag.tag,
                    time: this.props.time,
                    value: this.state.value
                  }
                })
                this.props.close()
              }}
            >
              Save
            </span>
          </span>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default graphql(ADD_USER_DATA, {name: 'addUserData'})(AddUserData)
