import React, {Component} from 'react'
import {gql} from 'graphql.macro'
import {Query} from 'react-apollo'

import './index.scss'

const GET_DEVICE_IDS = gql`
  query GET_DEVICE_IDS {
    deviceIDs {
      deviceID
      model
      po
      hasTurnedOn
      simNumber
    }
  }
`

class DeviceIDs extends Component {
  render() {
    return (
      <div className="view-device-ids">
        <table>
          <thead>
            <tr>
              <th>Device ID</th>
              <th>Model</th>
              <th>P.O.</th>
              <th>SIM Number</th>
              <th>Has Turned On</th>
            </tr>
          </thead>
          <tbody>
            <Query query={GET_DEVICE_IDS}>
              {({error, loading, data}) =>
                ((data && data.deviceIDs) || [])
                  .concat()
                  .sort((a, b) => b.deviceID - a.deviceID)
                  .map(({deviceID, model, po, simNumber, hasTurnedOn}) => (
                    <tr
                      key={deviceID}
                      className={
                        hasTurnedOn ? 'has-turned-on' : 'has-not-turned-on'
                      }
                    >
                      <td>{deviceID}</td>
                      <td>{model || <em>None</em>}</td>
                      <td>{po || <em>None</em>}</td>
                      <td>{simNumber || <em>None</em>}</td>
                      <td>{hasTurnedOn ? 'Yes' : 'No'}</td>
                    </tr>
                  ))
              }
            </Query>
          </tbody>
        </table>
      </div>
    )
  }
}

export default DeviceIDs
