import React, {Component} from 'react'
import GroupsList from './GroupsList'
import './ViewUser.scss'
import {name as formatName} from 'utils/format'
import {dateTime as formatDateTime} from 'utils/format'
import {route} from 'router'
import {Input, InputTable} from 'Components/Form'
import {gql} from 'graphql.macro'
import {graphql, Query} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import Loading from 'Components/Loading'
import Loadable from 'react-loadable'
import {GET_GROUPS_BY_COMPANY} from './queries'
import PermissionsList from './PermissionsList'
import {ButtonGroup, ButtonGroupOption} from 'Components/ButtonGroup'

const ActivityGraph = Loadable({
  loader: () => import('./ActivityGraph'),
  loading: Loading
})

const UPDATE_USER = gql`
  mutation UPDATE_USER(
    $userID: Int!
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $countryCode: String
    $permissions: [String]
    $isManager: Boolean
    $isSuperUser: Boolean
    $companyID: Int
    $canEdit: Boolean
    $isDemo: Boolean
    $activeGroups: [Int]
  ) {
    updateUser(
      userID: $userID
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      countryCode: $countryCode
      isManager: $isManager
      isSuperUser: $isSuperUser
      companyID: $companyID
      canEdit: $canEdit
      isDemo: $isDemo
      permissions: $permissions
      activeGroups: $activeGroups
    ) {
      user {
        id
        firstName
        lastName
        email
        phoneNumber
        countryCode
        isManager
        isSuperUser
        company {
          id
        }
        canEdit
        isDemo
        permissions
        groups {
          id
          name
        }
      }
    }
  }
`

const DELETE_USER = gql`
  mutation DELETE_USER($userID: Int!) {
    deleteUser(userID: $userID) {
      users {
        id
      }
    }
  }
`

const GET_COMPANIES = gql`
  query GET_COMPANIES {
    companies {
      id
      name
    }
  }
`

const roleNames = {
  demo: 'Demo',
  limited: 'Limited',
  full: 'Full',
  admin: 'Administrator',
  superuser: 'Super User'
}

class ViewUser extends Component {
  constructor(props) {
    super(props)
    this.state = this.getCleanState()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) this.setState(this.getCleanState())
  }
  getCleanState = () => {
    const user = this.props.user
    return {
      editing: false,
      confirmDeleteUser: false,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      countryCode: user.countryCode,
      permissions: user.permissions,
      canEdit: user.canEdit,
      isManager: user.isManager,
      isSuperUser: user.isSuperUser,
      activeGroups: user.groups.map((g) => g.id),
      companyID: user.company ? user.company.id : null,
      isDemo: false
    }
  }
  toggleGroup = (id) => {
    if (this.state.activeGroups.includes(id)) {
      this.setState({
        activeGroups: this.state.activeGroups.filter((gID) => gID !== id)
      })
    } else {
      this.setState({activeGroups: [...this.state.activeGroups, id]})
    }
  }
  getRole = () => {
    if (this.state.isSuperUser) return 'superuser'
    else if (this.state.isDemo) return 'demo'
    else if (this.state.isManager) return 'admin'
    else if (this.state.canEdit) return 'full'
    else return 'limited'
  }
  setRole = (role) => {
    if (role === 'superuser')
      this.setState({
        canEdit: false,
        isManager: false,
        isSuperUser: true,
        isDemo: false
      })
    else if (role === 'demo')
      this.setState({
        canEdit: false,
        isManager: false,
        isSuperUser: false,
        isDemo: true
      })
    else if (role === 'admin')
      this.setState({
        canEdit: false,
        isManager: true,
        isSuperUser: false,
        isDemo: false
      })
    else if (role === 'full')
      this.setState({
        canEdit: true,
        isManager: false,
        isSuperUser: false,
        isDemo: false
      })
    else
      this.setState({
        canEdit: false,
        isManager: false,
        isSuperUser: false,
        isDemo: false
      })
  }
  render = () => (
    <div className="view-user">
      <div className="row">
        <div className="left">
          <h1>{formatName(this.props.user, false)}</h1>
          <div className="last-active">
            Last Active:{' '}
            {this.props.user.latestActivity
              ? formatDateTime(this.props.user.latestActivity)
              : 'Never'}
          </div>
          <div className="divider" />
          {this.state.editing ? (
            <div className="buttons">
              <button onClick={() => this.setState(this.getCleanState())}>
                Cancel
              </button>
              {this.state.confirmDeleteUser ? (
                <button
                  className="red primary"
                  onClick={() => {
                    this.props.deleteUser({
                      variables: {
                        userID: this.props.user.id
                      },
                      refetchQueries: [
                        {
                          query: GET_GROUPS_BY_COMPANY,
                          variables: {companyID: this.state.companyID}
                        }
                      ]
                    })
                    route('/team')
                  }}
                >
                  Confirm Delete User
                </button>
              ) : (
                <button
                  className="red"
                  onClick={() => this.setState({confirmDeleteUser: true})}
                >
                  Delete User
                </button>
              )}
              <button
                className="primary"
                onClick={async () => {
                  await this.props.updateUser({
                    variables: {
                      userID: this.props.user.id,
                      firstName: this.state.firstName,
                      lastName: this.state.lastName,
                      email: this.state.email,
                      phoneNumber: this.state.phoneNumber,
                      countryCode: this.state.countryCode,
                      permissions: ['limited', 'full'].includes(this.getRole())
                        ? this.state.permissions
                        : [],
                      canEdit: this.state.canEdit,
                      isManager: this.state.isManager,
                      isSuperUser: this.state.isSuperUser,
                      companyID: this.state.isDemo ? 0 : this.state.companyID,
                      activeGroups: this.state.activeGroups,
                      isDemo: this.state.isDemo
                    }
                  })
                  this.setState({editing: false})
                  if (this.props.refetch) {
                    this.props.refetch()
                  } else {
                    window.location.reload(false)
                  }
                }}
              >
                Save Changes
              </button>
            </div>
          ) : (
            <div className="buttons">
              <button onClick={() => this.setState({editing: true})}>
                Edit User
              </button>
            </div>
          )}
        </div>
        <div className="right">
          <InputTable>
            <Input
              type="string"
              label="First Name"
              onChange={(e) => this.setState({firstName: e.target.value})}
              value={this.state.firstName}
              static={!this.state.editing}
            />
            <Input
              type="string"
              label="Last Name"
              onChange={(e) => this.setState({lastName: e.target.value})}
              value={this.state.lastName}
              static={!this.state.editing}
            />
            <Input
              type="phone"
              label="Phone Number"
              onChange={(e) => this.setState({phoneNumber: e.target.value})}
              value={this.state.phoneNumber}
              static={!this.state.editing}
            />
            <Input
              type="select"
              options={[
                {label: 'United States (+1)', value: '+1'},
                {label: 'United Kingdom (+44)', value: '+44'},
                {label: 'Sweden (+46)', value: '+46'},
                {label: 'Singapore (+65)', value: '+65'},
                {label: 'Thailand (+66)', value: '+66'},
              ]}
              label="Country Code"
              static={!this.state.editing}
              onChange={(e) =>
                this.setState({
                  countryCode: e.target.value
                })
              }
              value={this.state.countryCode}
            />
            <Input
              type="email"
              label="Email"
              onChange={(e) => this.setState({email: e.target.value})}
              value={this.state.email}
              static={!this.state.editing}
            />
            {this.props.isSuperUser && (
              <Query query={GET_COMPANIES}>
                {({loading, error, data}) => (
                  <Input
                    label="Company"
                    type="select"
                    value={this.state.companyID}
                    onChange={(e) => this.setState({companyID: e.target.value})}
                    options={((data && data.companies) || []).map((c) => ({
                      label: c.name,
                      value: c.id
                    }))}
                    static={!this.state.editing}
                  />
                )}
              </Query>
            )}
          </InputTable>
        </div>
      </div>
      <div className="row permissions">
        <div className="col">
          <h2>Permissions: {roleNames[this.getRole()]}</h2>
          {this.state.editing && (
            <ButtonGroup>
              <ButtonGroupOption
                selected={this.getRole() === 'limited'}
                onClick={() => this.setRole('limited')}
              >
                Limited
              </ButtonGroupOption>
              <ButtonGroupOption
                selected={this.getRole() === 'full'}
                onClick={() => this.setRole('full')}
              >
                <i className="fa fa-star-half" /> Full
              </ButtonGroupOption>
              <ButtonGroupOption
                selected={this.getRole() === 'admin'}
                onClick={() => this.setRole('admin')}
              >
                <i className="fa fa-star" /> Administrator
              </ButtonGroupOption>
              {this.props.isSuperUser && (
                <ButtonGroupOption
                  selected={this.getRole() === 'superuser'}
                  onClick={() => this.setRole('superuser')}
                >
                  <i className="fa fa-star yellow" /> Super User
                </ButtonGroupOption>
              )}
              {this.props.isSuperUser && (
                <ButtonGroupOption
                  selected={this.getRole() === 'demo'}
                  onClick={() => this.setRole('demo')}
                >
                  Demo
                </ButtonGroupOption>
              )}
            </ButtonGroup>
          )}
          <PermissionsList {...this.state} />
        </div>
        <div className="col">
          {!this.state.isDemo && !this.state.isSuperUser && (
            <GroupsList
              activeGroups={this.state.activeGroups}
              toggleGroup={this.toggleGroup}
              groups={this.props.user.groups}
              allGroups={this.props.allGroups}
              editing={this.state.editing}
            />
          )}
        </div>
      </div>
      {!!this.props.isSuperUser && (
        <div className="row">
          <ActivityGraph userID={this.props.user.id} />
        </div>
      )}
    </div>
  )
}

export default compose(
  graphql(UPDATE_USER, {name: 'updateUser'}),
  graphql(DELETE_USER, {name: 'deleteUser'})
)(ViewUser)
