export default (devices, fn) => {
  const tree = {devices: [], children: {}}
  devices
    .filter(d => d.path && d.path[0] === '/')
    .forEach(device => {
      let node = tree
      const path = device.path.substr(1)
      path.split('/').forEach(folder => {
        if (!node.children.hasOwnProperty('/' + folder))
          node.children['/' + folder] = {devices: [], children: {}}
        node = node.children['/' + folder]
      })
      node.devices = node.devices.concat(device)
    })
  return tree
}

export const searchNode = ({devices, children}, fn) =>
  devices.some(fn) ||
  Object.keys(children).some(k => searchNode(children[k], fn))
