const isSubpath = (parent, child) => {
  return (
    !parent ||
    parent.length === 0 ||
    (child &&
      child.startsWith(parent) &&
      (child[parent.length] === '/' || child[parent.length] === undefined))
  )
}
export default isSubpath
