import React from 'react'
import MaskedInput from 'react-text-mask'
import {phone as formatPhone} from 'utils/format'

const Phone = props =>
  props.static ? (
    props.value && props.value !== '' ? (
      formatPhone(props.value)
    ) : (
      <em>None</em>
    )
  ) : (
    <MaskedInput
      //prettier-ignore
      mask={[
        '(',/[1-9]/,/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/,/\d/
      ]}
      value={props.value}
      unmask="true"
      onChange={e => {
        props.onChange({
          target: {
            dataset: {
              key: props['data-key'],
              nullable: props['data-nullable']
            },
            value: e.target.value.replace(/\D/g, '')
          }
        })
      }}
      placeholder={props.placeholder}
      className={
        props.valid === true ? 'valid' : props.valid === false ? 'invalid' : ''
      }
      onKeyUp={props.onKeyUp}
      type="tel"
      pattern="[0-9]*"
      inputMode="numeric"
      guide={true}
    />
  )

export default Phone
