import React from 'react'

const Radio = props => {
  if (props.static)
    return (props.options.find(o => o.value === props.value) || {label: ''})
      .label
  //make a new onChange that doesn't cast or mess with the values
  const onChange = value => e =>
    props.onChange({target: {value: value, dataset: e.target.dataset}})
  return (
    <div className="input-radio-options-group">
      {props.options.map((o, i) => (
        <span key={i} className="input-radio-option">
          <span>
            <input
              type="radio"
              disabled={props.disabled}
              checked={props.value === o.value}
              value={o.value}
              data-key={props['data-key']}
              onChange={onChange(o.value)}
            />
          </span>
          <span className="input-radio-label">{o.label}</span>
        </span>
      ))}
    </div>
  )
}

export default Radio
