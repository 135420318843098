import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import clamp from 'lodash/clamp'
import downsample from '../downsample'

class Preview extends PureComponent {
  render() {
    if (!this.props.data || !this.props.data.length || !this.props.data[0][0])
      return null
    const domain = this.props.max - this.props.min
    const range = this.props.yEnd - this.props.yStart
    const xScale = this.props.width / domain
    const yScale = this.props.height / range
    const inds = downsample(
      this.props.data,
      this.props.width,
      this.props.data[0][0],
      Date.now() / 1000
    )
    const mappedData = inds
      .map(i => this.props.data[i])
      .map(d => [
        ((d[0] - this.props.min) * xScale) >> 0,
        (this.props.height - (d[1] - this.props.yStart) * yScale) >> 0
      ])
      .map(d => [d[0], clamp(d[1], 0, this.props.height)])

    let path = `M0 ${this.props.height}`

    //Use a max gap of either the one given or 5px. Needs a 5px minimum so the downsampled data doesn't result in gaps
    const maxGap = Math.max(this.props.maxGap, 5 / xScale)
    for (let i = 1; i < mappedData.length; i++) {
      if (
        inds[i] !== 0 &&
        this.props.maxGap &&
        this.props.data[inds[i]][0] - this.props.data[inds[i] - 1][0] > maxGap
      )
        path += `L${mappedData[i - 1][0]} ${this.props.height}L${
          mappedData[i][0]
        } ${this.props.height}`
      path += `L${mappedData[i][0]} ${mappedData[i][1]}`
    }
    path += `L${mappedData[mappedData.length - 1][0]} ${this.props.height}Z`
    return (
      <g>
        <path d={path} style={{fill: 'rgb(181, 193, 205)', stroke: 'none'}} />
      </g>
    )
  }
}

const mapStateToProps = state => ({
  yStart: state.view.y.start,
  yEnd: state.view.y.end
})

export default connect(mapStateToProps)(Preview)
