import React, {Component} from 'react'
import {connect} from 'react-redux'

class Line extends Component {
  lastX = null
  lastY = null
  dragging = false
  dragHandler = (e) => {
    if (!this.props.onDrag) return

    // deltaY is negative since the mouse y coordinates are in the opposite direction
    // of the data Y axis
    this.props.onDrag({
      clientX: e.clientX,
      clientY: e.clientY,
      deltaClientX: this.lastX !== null ? e.clientX - this.lastX : 0,
      deltaClientY: this.lastY !== null ? -(e.clientY - this.lastY) : 0,
      deltaX:
        this.lastX !== null ? (e.clientX - this.lastX) / this.props.xScale : 0,
      deltaY:
        this.lastY !== null ? -(e.clientY - this.lastY) / this.props.yScale : 0
    })
    this.lastX = e.clientX
    this.lastY = e.clientY
  }
  componentWillUnmount() {
    if (this.dragging) {
      window.removeEventListener('mousemove', this.dragHandler)
      window.removeEventListener('mouseup', this.endDrag)
    }
  }
  startDrag = () => {
    window.addEventListener('mousemove', this.dragHandler)
    window.addEventListener('mouseup', this.endDrag)
    this.dragging = true
  }
  endDrag = () => {
    window.removeEventListener('mousemove', this.dragHandler)
    window.removeEventListener('mouseup', this.endDrag)
    if (this.props.onDragEnd) this.props.onDragEnd()
  }
  render() {
    const {
      x1,
      x2,
      y1,
      y2,
      x,
      y,
      xStart,
      yStart,
      xScale,
      yScale,
      width = 1,
      color = '#f00',
      dashed,
      style = {},
      containerHeight,
      className,
      onDrag
    } = this.props

    const mapX = (x) => (x - xStart) * xScale
    const mapY = (y) => containerHeight - (y - yStart) * yScale
    return (
      <line
        x1={x1 !== undefined ? mapX(x1) : x !== undefined ? mapX(x) : '0%'}
        x2={x2 !== undefined ? mapX(x2) : x !== undefined ? mapX(x) : '100%'}
        y1={y1 !== undefined ? mapY(y1) : y !== undefined ? mapY(y) : '0%'}
        y2={y2 !== undefined ? mapY(y2) : y !== undefined ? mapY(y) : '100%'}
        style={{strokeWidth: width, stroke: color, ...style}}
        strokeDasharray={!!dashed ? dashed : null}
        className={className}
        onMouseDown={onDrag ? this.startDrag : null}
        onMouseOver={this.props.onMouseOver ? this.props.onMouseOver : null}
        onMouseOut={this.props.onMouseOut ? this.props.onMouseOut : null}
        shapeRendering="geometricPrecision"
      ></line>
    )
  }
}

const mapStateToProps = (state) => ({
  xStart: state.view.x.start,
  yStart: state.view.y.start,
  xScale: state.view.x.scale,
  yScale: state.view.y.scale,
  containerHeight:
    state.container.height -
    state.container.topOffset -
    state.container.bottomOffset
})

export default connect(mapStateToProps)(Line)
