import React from 'react'

import './AddButton.scss'

export default ({children, className, text, ...props}) => (
  <button className={'add-button-comp ' + (className || '')} {...props}>
    {children || (
      <>
        <i className="fa fa-plus" /> {text || 'Add'}
      </>
    )}
  </button>
)
