import isNewDevice from './isNewDevice'

const MAX_DELAY = 18 * 60 * 60 //18 hours

export default device => {
  if (!device || !device.tags) return false
  if (isNewDevice(device)) return false
  if (device.lastTimeMessageReceived) return !(Date.now() / 1000 - device.lastTimeMessageReceived < MAX_DELAY)
  return !device.tags.some(t => Date.now() / 1000 - t.time < MAX_DELAY)
}
