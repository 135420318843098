const initialState = {
  path: '',
  viewType: 'icons',
  companyID: null,
  includeInactive: false,
  includeOOS: false,
  includeVIA: false
}
function directory(state = initialState, action) {
  switch (action.type) {
    case 'SET_DIRECTORY_VIEW_TYPE':
      return {...state, viewType: action.viewType}
    case 'SET_DIRECTORY_PATH':
      return {...state, path: action.path}
    case 'SET_DIRECTORY_COMPANY':
      return {...state, companyID: action.id}
    case 'SET_DIRECTORY_GROUP':
      return {...state, groupID: action.id}
    case 'SET_REFETCH_GROUPS_BY_COMPANY':
      return {...state, refetchGroupsByCompany: action.refetch}
    case 'TOGGLE_DIRECTORY_INCLUDE_INACTIVE':
      return {...state, includeInactive: action.bool}
    case 'TOGGLE_DIRECTORY_INCLUDE_OOS':
      return {...state, includeOOS: action.bool}
    case 'TOGGLE_DIRECTORY_INCLUDE_VIA':
      return {...state, includeVIA: action.bool}
    default:
      return state
  }
}
export default directory
