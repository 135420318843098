import {ApolloClient} from 'apollo-client'
import {InMemoryCache} from 'apollo-cache-inmemory'
//import {BatchHttpLink} from 'apollo-link-batch-http'
import {onError} from 'apollo-link-error'
import {ApolloLink} from 'apollo-link'
import update from 'immutability-helper'
import {refreshToken} from 'store/actions'
import {RetryLink} from 'apollo-link-retry'
import {createHttpLink} from 'apollo-link-http'

// import {Hermes} from 'apollo-cache-hermes'

export default ({store, endpoint}) => {
  // custom fetch function that handles authorization headers and updates the
  // redux store accordingly
  const customFetch = store => async (uri, optionsIn) => {
    const state = store.getState()
    let options = optionsIn

    if (state.user.authorization)
      options = update(options, {
        headers: {authorization: {$set: 'Bearer ' + state.user.authorization}}
      })

    if (state.user.loginAsUserID) {
      options = update(options, {
        headers: {
          'access-control-request-headers': {$set: 'login-as'},
          'login-as': {$set: state.user.loginAsUserID}
        }
      })
    }
    const res = await fetch(uri, options)

    if (res.headers.has('refresh-token'))
      store.dispatch(refreshToken(res.headers.get('refresh-token')))

    return res
  }

  const link = ApolloLink.from([
    // error handler
    onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({message, locations, path}) => {
          const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
          console.log(errorMessage)
        })
      if (networkError) {
        const errorMessage = `[Network error]: ${networkError}`
        console.log(errorMessage)
      }
    }),
    // retry network requests that fail, if user of offline for instance
    new RetryLink({
      attempts: count => !navigator.onLine || count < 3,
      delay: count => (navigator.onLine ? 300 : 10000)
    }),
    // batching http link to combine multiple graphql requests together
    // new BatchHttpLink({
    createHttpLink({
      uri: endpoint,
      credentials: 'include',
      fetch: customFetch(store)
    })
  ])

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache({}),
    // defaultOptions: {
    //   watchQuery: {
    //     // Note: I had to disable this because the data we are fetching is way too large,
    //     // resulting in CPU issues / the main browser thread getting bogged down trying
    //     // to normalize and persist the data
    //     fetchPolicy: "no-cache"
    //   }
    // }
  })

  return client
}
