import React, {Component} from 'react'
import {Mutation} from 'react-apollo'
import {gql} from 'graphql.macro'
import {GET_COMPANIES} from 'queries'

import './AddCompany.scss'

const ADD_COMPANY = gql`
  mutation ADD_COMPANY($name: String!) {
    addCompany(name: $name) {
      company {
        id
        name
      }
    }
  }
`

class AddCompany extends Component {
  state = {name: ''}
  render() {
    return (
      <div className="add-company">
        <div className="container">
          <div className="col icon">
            <i className="fa fa-building" />
          </div>
          <div className="col inputs">
            <div className="flex-row first-name">
              <div className="label">Name: </div>
              <div className="input">
                <input
                  value={this.state.name}
                  onChange={e => this.setState({name: e.target.value})}
                />
              </div>
            </div>
            <br />
            <br />
            <Mutation mutation={ADD_COMPANY}>
              {addCompany => (
                <button
                  className="primary"
                  disabled={!this.state.name.length}
                  id="add-company-button"
                  onClick={() => {
                      addCompany({
                        variables: {name: this.state.name},
                        refetchQueries: [{query: GET_COMPANIES}]
                      })

                      window.location.reload()
                    }
                  }
                >
                  Add Company
                </button>
              )}
            </Mutation>
          </div>
        </div>
      </div>
    )
  }
}

export default AddCompany
