import React from 'react'
import {connect} from 'react-redux'
import clamp from 'lodash/clamp'

const Comment = ({
  point,
  xStart,
  yStart,
  xScale,
  yScale,
  height,
  width,
  color = '#00f',
  className,
  onClick,
  text = 'C',
  maxWidth = 100,
  maxHeight = 100
}) => {
  if (!point) return null
  const x = (point[0] - xStart) * xScale
  const y = clamp((point[1] - yStart) * yScale, 12, height - 30)

  if (x < -maxWidth / 2 || x > width + maxWidth / 2) return null

  return (
    <g transform={`translate(${x}, ${height - y})`} className={className || ''}>
      <polyline
        points="-7,-5 -5,-5 0,0 5,-5 7,-5"
        style={{stroke: '#000', strokeWidth: 1, fill: '#fff'}}
      />
      <foreignObject
        x={-maxWidth / 2}
        y={-maxHeight}
        width={maxWidth}
        height={maxHeight}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            pointerEvents: 'all'
          }}
        >
          <div
            style={{
              border: '1px solid #000',
              backgroundColor: '#fff',
              fontSize: '.8em',
              display: 'inline-block',
              padding: '2px 3px',
              borderRadius: '2px',
              marginBottom: '4px',
              fill: '#fff'
            }}
            onClick={onClick}
            onTouchStart={onClick}
          >
            {text}
          </div>
        </div>
      </foreignObject>
      <path d="M-7 -7L0 0L7 -7M-7 -7" style={{fill: '#fff', stroke: 'none'}} />
    </g>
  )
}

const mapStateToProps = state => ({
  xStart: state.view.x.start,
  yStart: state.view.y.start,
  xScale: state.view.x.scale,
  yScale: state.view.y.scale,
  height:
    state.container.height -
    state.container.topOffset -
    state.container.bottomOffset,
  width:
    state.container.width -
    state.container.leftOffset -
    state.container.rightOffset
})

export default connect(mapStateToProps)(Comment)
