import React from 'react'
import Select from './Select'
import {GET_USERS} from 'queries'
import {Query} from 'react-apollo'
import {name as formatName} from 'utils/format'

export default props => (
  <Query query={GET_USERS}>
    {({loading, error, data}) => (
      <Select
        {...props}
        options={((data && data.users) || [])
          .filter(u => !props.filter || props.filter(u))
          .map(u => ({
            label: formatName(u),
            value: u.id
          }))}
      />
    )}
  </Query>
)
