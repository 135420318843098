import React, {Component} from 'react'
import Modal from 'Components/Modal'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import {gql} from 'graphql.macro'
import {GET_TAG} from '../queries'
import axios from '../../../utils/getAxios'

const calibrationDefaults = {
  time: 0,
  offset: 0,
  fluid: 'water',
  tds: 24,
  api: 32,
  sg: 1,
  barrelsPerInch: 1.67,
  target: 0,
  kFactor: 1,
  productionOffset: 0,
  tank_name: null,
  unit: 'inches'
}

const UPDATE_CALIBRATION = gql`
  mutation UPDATE_CALIBRATION(
    $deviceID: Int!
    $tag: String!
    $time: Int!
    $fluid: String
    $api: Float
    $tds: Float
    $sg: Float
    $target: Float!
    $kFactor: Float!
    $tank_name: String
    $productionOffset: Float!
    $tankHeight: Float
    $tankVolume: Float
    $tankShape: String
    $unit: String
  ) {
    updateCalibration(
      deviceID: $deviceID
      tag: $tag
      time: $time
      fluid: $fluid
      api: $api
      tds: $tds
      sg: $sg
      target: $target
      kFactor: $kFactor
      tank_name: $tank_name
      productionOffset: $productionOffset
      tankHeight: $tankHeight
      tankVolume: $tankVolume
      tankShape: $tankShape
      unit: $unit
    ) {
      success
    }
  }
`

const DELETE_CALIBRATION = gql`
  mutation DELETE_CALIBRATION($deviceID: Int!, $tag: String!, $time: Int!) {
    deleteCalibration(deviceID: $deviceID, tag: $tag, time: $time) {
      success
    }
  }
`

var tank = []

class Calibration extends Component {
  state = {...calibrationDefaults, ...(this.props.calibration || {})}

  async componentDidMount(){
    try{
      const res = await axios.get('/api/chem_tank/names')
      tank = res.data
    } catch(err) {console.error(err)}
  }

  render() {
    const Tag = this.props.Tag

    if (!Tag.Calibration)
      return (
        <Modal onClose={this.props.close}>
          <Modal.Title>Calibration</Modal.Title>
          <Modal.Body>
            <p>This sensor does not require calibration.</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="primary" onClick={this.props.close}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )

    return (
      <Modal onClose={this.props.close}>
        <Modal.Title>Calibration</Modal.Title>
        <Modal.Body>
          <Tag.Calibration
            calibration={this.state}
            update={(u) => this.setState(u)}
            Tag={Tag}
            Tank={tank}
          />
        </Modal.Body>
        <Modal.Footer>
          <span>
            {!!this.props.calibration && (
              <button
                onClick={() => {
                  this.props.deleteCalibration({
                    variables: {
                      deviceID: this.props.Tag.deviceID,
                      tag: this.props.Tag.tag,
                      time: this.props.calibration.time
                    },
                    refetchQueries: [
                      {
                        query: GET_TAG,
                        variables: {
                          deviceID: this.props.Tag.deviceID,
                          tag: Tag.tag
                        }
                      }
                    ]
                  })
                  this.props.close()
                }}
              >
                Delete
              </button>
            )}
            <button onClick={this.props.close}>Cancel</button>
            <button
              className="primary"
              onClick={() => {
                this.props.updateCalibration({
                  variables: {
                    ...this.state,
                    deviceID: this.props.Tag.deviceID,
                    tag: this.props.Tag.tag,
                    time: this.props.calibration
                      ? this.props.calibration.time
                      : this.props.time
                  },
                  refetchQueries: [
                    {
                      query: GET_TAG,
                      variables: {
                        deviceID: this.props.Tag.deviceID,
                        tag: Tag.tag
                      }
                    }
                  ]
                })
                this.props.close()
              }}
            >
              Save Calibration
            </button>
          </span>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default compose(
  graphql(UPDATE_CALIBRATION, {name: 'updateCalibration'}),
  graphql(DELETE_CALIBRATION, {name: 'deleteCalibration'})
)(Calibration)
