import isNumber from 'lodash/isNumber'
import {feet as formatFeet} from './format'

const getDisplayOptions = (tag) => {
  switch (tag) {
    case 'psr':
      return [
        {
          label: 'PSI',
          value: 'psi',
          render: (v) => `${v.toFixed(1)} psi`,
          yAxisLabel: 'Pressure (psi)',
          ymin: 0,
          ymax: 260
        },
        {
          label: 'PSI (Full)',
          value: 'psi (full)',
          render: (v) => `${v.toFixed(3)} psi`,
          yAxisLabel: 'Pressure (psi)',
          ymin: 0,
          ymax: 260
        },
        {
          label: 'in Hg',
          value: 'inhg',
          render: (v) => `${v.toFixed(1)} inHg`,
          yAxisLabel: 'Pressure (in Hg)',
          decimals: 1,
          ymin: 0,
          ymax: 540
        },
        {
          label: 'Bar',
          value: 'bar',
          render: (v) => `${v.toFixed(1)} bar`,
          yAxisLabel: 'Pressure (bar)',
          decimals: 1,
          ymin: 0,
          ymax: 18
        },
        // {
        //   label: 'dP TEMP UNIT',
        //   value: 'psi (temp)',
        //   render: (v) => `${v.toFixed(3)} psi`,
        //   yAxisLabel: 'Pressure (psi)',
        //   ymin: 0,
        //   ymax: 260
        // },
      ]

    case 'spsr':
      return [
        {
          label: 'Feet',
          value: 'ft',
          render: formatFeet,
          yAxisLabel: 'Level (ft)',
          input: 'feetinches',
          ymin: 0,
          ymax: 20
        },
        {
          label: 'Inches',
          value: 'in',
          render: (v) => `${v.toFixed(1)} in`,
          yAxisLabel: 'Level (in)',
          ymin: 0,
          ymax: 240
        },
        {
          label: 'PSI',
          value: 'psi',
          render: (v) => `${v.toFixed(1)} psi`,
          yAxisLabel: 'Pressure (psi)',
          ymin: 0,
          ymax: 10
        },
        {
          label: 'PSI (Full)',
          value: 'psi (full)',
          render: (v) => `${v.toFixed(3)} psi`,
          yAxisLabel: 'Pressure (psi)',
          ymin: 0,
          ymax: 10
        },
        {
          label: 'Gallons (REQUIRES TANK STRAP CALIBRATION)',
          value: 'gln',
          render: (v) => `${v.toFixed(1)} gln`,
          yAxisLabel: 'Level (gln)',
          ymin: 0,
          ymax: 5000
        }
      ]
    case 'dspsr':
      return [
        {
          label: 'Feet',
          value: 'ft',
          render: formatFeet,
          yAxisLabel: 'Level (ft)',
          input: 'feetinches',
          ymin: 0,
          ymax: 20
        },
        {
          label: 'Inches',
          value: 'in',
          render: (v) => `${v.toFixed(1)} in`,
          yAxisLabel: 'Level (in)',
          ymin: 0,
          ymax: 240
        },
        {
          label: 'PSI',
          value: 'psi',
          render: (v) => `${v.toFixed(1)} psi`,
          yAxisLabel: 'Pressure (psi)',
          ymin: 0,
          ymax: 10
        },
        {
          label: 'PSI (Full)',
          value: 'psi (full)',
          render: (v) => `${v.toFixed(3)} psi`,
          yAxisLabel: 'Pressure (psi)',
          ymin: 0,
          ymax: 10
        }
      ]
    case 'rot':
      return [
        {
          label: 'Strokes Per Minute',
          value: 'spm',
          render: (v) => `${v.toFixed(3)} strokes/min`,
          yAxisLabel: 'Rotation Speed (strokes/min)',
          ymin: 0,
          ymax: 15
        },
        {
          label: 'Seconds per stroke',
          value: 'sps',
          render: (v) => `${v.toFixed(3)} sec/stroke`,
          yAxisLabel: 'Rotation Speed (sec/stroke)',
          ymin: 0,
          ymax: 15
        }
      ]
    case 'vib':
      return [
        {
          label: 'm/sec^2 RMS',
          value: 'm/sec^2 RMS',
          render: (v) => `${v.toFixed(3)}`,
          yAxisLabel: 'Vibration Amplitude (m/sec^2 RMS)',
          ymin: 0,
          ymax: 1
        },
        {
          label: 'm/sec^2 PEAK',
          value: 'm/sec^2 PEAK',
          render: (v) => `${v.toFixed(3)}`,
          yAxisLabel: 'Vibration Amplitude (m/sec^2 PEAK)',
          ymin: 0,
          ymax: 1
        },
        {
          label: 'mm/sec RMS',
          value: 'mm/sec RMS',
          render: (v) => `${v.toFixed(3)}`,
          yAxisLabel: 'Velocity (mm/sec RMS)',
          ymin: 0,
          ymax: 1
        },
        {
          label: 'mm/sec PEAK',
          value: 'mm/sec PEAK',
          render: (v) => `${v.toFixed(3)}`,
          yAxisLabel: 'Velocity (mm/sec PEAK)',
          ymin: 0,
          ymax: 1
        },
        {
          label: 'in/sec RMS',
          value: 'in/sec RMS',
          render: (v) => `${v.toFixed(3)}`,
          yAxisLabel: 'Velocity (in/sec RMS)',
          ymin: 0,
          ymax: 1
        },
        {
          label: 'in/sec PEAK',
          value: 'in/sec PEAK',
          render: (v) => `${v.toFixed(3)}`,
          yAxisLabel: 'Velocity (in/sec PEAK)',
          ymin: 0,
          ymax: 1
        }

      ]
    case 'puls':
      return [
        {
          label: 'Flow (bbls/day)',
          value: 'flow_barrels_per_day',
          yAxisLabel: 'Flow (bbls/day)',
          render: (v) => `${v.toFixed(1)} bbls/day`,
          ymin: 0,
          ymax: 3000
        }
        // {
        //   label: 'Flow (gal/min)',
        //   value: 'flow_gal',
        //   yAxisLabel: 'Flow (gal/min)',
        //   render: (v) => `${v.toFixed(2)} gal/min`,
        //   ymin: 0,
        //   ymax: 30
        // },
        // {
        //   label: 'Flow (bbl/min)',
        //   value: 'flow_bbl',
        //   yAxisLabel: 'Flow (bbl/min)',
        //   render: (v) => `${v.toFixed(2)} bbl/min`,
        //   ymin: 0,
        //   ymax: 1
        // },
        // {
        //   label: 'Volume (gal)',
        //   value: 'total_gal',
        //   yAxisLabel: 'Volume (gal)',
        //   render: (v) => `${v.toFixed(1)} gal`,
        //   ymin: 0,
        //   ymax: 30
        // },
        // {
        //   label: 'Volume (bbl)',
        //   value: 'total_bbl',
        //   yAxisLabel: 'Volume (bbl)',
        //   render: (v) => `${v.toFixed(1)} bbl`,
        //   ymin: 0,
        //   ymax: 30
        // },
        // {
        //   label: 'Pulses',
        //   value: 'pulse',
        //   yAxisLabel: 'Pulse Count',
        //   render: (v) => `${v}`,
        //   ymin: 0,
        //   ymax: 30
        // }
      ]
    case 'pult':
      return [
        {
          label: 'Flow (bbls/day)',
          value: 'flow_barrels_per_day',
          yAxisLabel: 'Flow (bbls/day)',
          render: (v) => `${v.toFixed(1)} bbls/day`,
          ymin: 0,
          ymax: 3000
        }
      ]
    case 'dist':
    case 'sonr':
      return [
        {
          label: 'Inches',
          value: 'in',
          yAxisLabel: 'Level (in)',
          render: (v) => {
            return `${v.toFixed(1)} in`
          },
          ymin: 0,
          ymax: 60
        },
        {
          label: 'Feet',
          value: 'ft',
          render: formatFeet,
          yAxisLabel: 'Level (ft)',
          input: 'feetinches',
          ymin: 0,
          ymax: 20
        }
      ]
    case 'hum':
      return [
        {
          label: 'Percentage',
          value: '%',
          yAxisLabel: 'Percent',
          render: (v) => `${v.toFixed(1)}%`,
          ymin: 0,
          ymax: 100
        }
      ]
    case '_v':
      return [
        // {
        //   label: 'Percentage',
        //   value: '%',
        //   render: v => `${Math.round(v)}%`,
        //   yAxisLabel: 'Percent',
        //   ymin: -10,
        //   ymax: 110
        // },
        {
          label: 'Voltage',
          value: 'v',
          render: (v) => `${v.toFixed(3)} V`,
          yAxisLabel: 'Voltage (V)',
          ymin: 3,
          ymax: 6
        }
      ]
    case '_csq':
      return [
        {
          label: 'Signal Quality',
          value: 'csq',
          render: (v) => `${v.toFixed(0)}`,
          yAxisLabel: 'Signal Strength (CSQ)',
          ymin: 0,
          ymax: 35
        }
      ]
    case '_t':
      return [
        {
          label: 'Farenheit',
          value: 'f',
          render: (v) => `${v.toFixed(1)}°F`,
          yAxisLabel: 'Temperature (°F)',
          ymin: -50,
          ymax: 150
        },
        {
          label: 'Celsius',
          value: 'c',
          render: (v) => `${v.toFixed(1)}°C`,
          yAxisLabel: 'Temperature (°C)',
          ymin: 0,
          ymax: 260
        }
      ]
    case 'temp':
      return [
        {
          label: 'Farenheit',
          value: 'f',
          render: (v) => `${v.toFixed(1)}°F`,
          yAxisLabel: 'Temperature (°F)',
          ymin: 0,
          ymax: 500
        },
        {
          label: 'Celsius',
          value: 'c',
          render: (v) => `${v.toFixed(1)}°C`,
          yAxisLabel: 'Temperature (°C)',
          ymin: 0,
          ymax: 260
        }
      ]
    case 'volt':
      return [
        {
          label: 'Millivolts',
          value: 'mV',
          render: (mV) => `${mV.toFixed(3)} mV`,
          yAxisLabel: 'Millivolts (mV)',
          ymin: 0,
          ymax: 2500
        },
        {
          label: 'Volts',
          value: 'v',
          render: (v) => `${v.toFixed(3)} V`,
          yAxisLabel: 'Volts (V)',
          ymin: 0,
          ymax: 100
        }
      ]
    case 'flash':
      return [
        {
          label: 'Flash Amplitude',
          value: 'flashes per second',
          render: (flashRate) => flashRate,
          yAxisLabel: 'Flash Amplitude',
          ymin: 0,
          ymax: 1000
        }
      ]
    case 'swt':
      return [
        {
          label: 'State',
          value: 'v',
          render: (v) => v,
          yAxisLabel: 'State',
          ymin: 0,
          ymax: 2
        }
      ]
    case 'flw':
      return [
        {
          label: 'PSI (dP)',
          value: 'psi (dP)',
          render: (v) => `${v.toFixed(3)} psi`,
          yAxisLabel: 'Pressure (psi)',
          ymin: 0,
          ymax: 260
        }
      ]
    default:
      return [
        {
          label: 'Default',
          value: null,
          render: (v) => (isNumber(v) ? v.toFixed(2) : null),
          yAxisLabel: 'Reading',
          ymin: 0,
          ymax: 30
        }
      ]
  }
}

export default getDisplayOptions
