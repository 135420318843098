import React from 'react'

import './GroupsList.scss'

const GroupsList = ({allGroups, activeGroups, toggleGroup, editing}) => {
  return (
    <div className="permissions-paths box">
      <div className="title">Groups: {activeGroups.length}</div>
      <div className="body">
        <GroupsMenu
          allGroups={allGroups}
          activeGroups={activeGroups}
          toggleGroup={toggleGroup}
          editing={editing}
        />
      </div>
    </div>
  )
}

const GroupsMenu = ({allGroups, activeGroups, toggleGroup, editing}) => {
  if (!allGroups) {
    return null
  }
  const compare = (a, b) => {
    if (a.name === null) {
      return 1
    }
    if (b.name === null) {
      return -1
    }
    if (a.name.toUpperCase() > b.name.toUpperCase()) {
      return 1
    } else if (a.name.toUpperCase() < b.name.toUpperCase()) {
      return -1
    } else {
      return 0
    }
  }
  return (
    <ul>
      {allGroups.sort(compare).map((g) => {
        const allowed = activeGroups.includes(g.id)
        return (
          <li
            key={g.id}
            className={'permissions-directory ' + (allowed ? 'allowed' : '')}
          >
            <input
              type="checkbox"
              data-path={g.id}
              checked={allowed}
              disabled={!editing}
              onChange={() => {
                toggleGroup(g.id)
              }}
            />
            {g.name}
          </li>
        )
      })}
    </ul>
  )
}

export default GroupsList
