import React from 'react'
import isNumber from 'lodash/isNumber'

const InputFloat = props =>
  props.static ? (
    isNumber(props.value) ? (
      props.render ? (
        props.render(props.value)
      ) : (
        props.value.toPrecision(4)
      )
    ) : (
      <em>None</em>
    )
  ) : (
    <input
      type="number"
      step={props.step || 0.01}
      onChange={e => {
        const value = parseFloat(e.target.value)
        props.onChange({
          ...e,
          target: {
            dataset: e.target.dataset,
            value: !isNaN(value) ? value : null
          }
        })
      }}
      value={isNumber(props.value) ? props.value : ''}
      data-key={props['data-key']}
      data-nullable={props['data-nullable']}
      className={
        props.valid === true ? 'valid' : props.valid === false ? 'invalid' : ''
      }
      placeholder={props.placeholder}
      onKeyUp={props.onKeyUp}
      disabled={props.disabled}
    />
  )

export default InputFloat
