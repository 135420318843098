import React from 'react'
import {Link} from 'router'
import './CompanyDeviceList.scss'

const CompanyDeviceList = ({devices}) => {
  if (!devices) {
    return null
  }
  return (
    <div className="permissions-paths box">
      <div className="title">Devices: {devices.length}</div>
      <div className="body">
        <DevicesMenu devices={devices} />
      </div>
    </div>
  )
}

const DevicesMenu = ({devices}) => {
  if (!devices) {
    return null
  }
  const compare = (a, b) => {
    if (a.name === null) {
      return 1
    }
    if (b.name === null) {
      return -1
    }
    if (a.name.toUpperCase() > b.name.toUpperCase()) {
      return 1
    } else if (a.name.toUpperCase() < b.name.toUpperCase()) {
      return -1
    } else {
      return 0
    }
  }
  return (
    <ul>
      {devices.sort(compare).map((d) => {
        return (
          <li
            key={d.id}
            className={'permissions-directory'}
            style={{backgroundColor: 'cornsilk'}}
          >
            <Link path={`/devices/${d.id}`}>
              <div style={{color: '#2c5f8e'}}>
                {d.id} - {d.name}
              </div>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default CompanyDeviceList
