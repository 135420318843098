import React from 'react'
import DefaultSignatures from './DefaultSignatures'
import SignatureMapping from './SignatureMapping'

export default () => {
  return <>
    <SignatureMapping />
    <DefaultSignatures />
  </>
}
