import base64js from 'base64-js'

export default (compressedString) => {
  if (!compressedString) return []

  const uint8View = base64js.toByteArray(compressedString)

  // if (uint8View.length % 8) return []
  if (uint8View.length % 12) return []

  const uintView = new Uint32Array(uint8View.buffer)
  const floatView = new Float32Array(uint8View.buffer)
  // const floatViewTemp = new Float32Array(uint8View.buffer)
  // const data = new Array(uint8View.length / 8)
  const data = new Array(uint8View.length / 12)

  for (let i = 0; i < data.length; i++)
    data[i] = [uintView[i * 3], floatView[i * 3 + 1], floatView[i * 3 + 2]]

  return data
}
