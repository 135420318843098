import findClosestPoint from 'utils/findClosestPoint'

//take a data array, target length, start value, and end value and returns an array of indicies
function downsample(data, targetLength, start, end) {
  if (data.length < targetLength || start >= end) {
    let out = []
    for (let i = 0; i < data.length; i++) out.push(i)
    return out
  }
  const len = data.length
  const timeInterval = (end - start) / (targetLength / 2)

  let out = []

  let ind = findClosestPoint(start, data)
  if (ind > 0) ind--

  //include the point before the inteval starts to get a continuous line
  out.push(ind)

  for (let time = start; ind < len && time < end; time += timeInterval) {
    //for each interval, find the min and max points and keep them in the downsampled signal
    let minInd = null,
      maxInd = null
    while (ind < len && data[ind][0] < time + timeInterval) {
      if (data[ind][1] !== null) {
        if (minInd === null || data[ind][1] < data[minInd][1]) minInd = ind
        if (maxInd === null || data[ind][1] > data[maxInd][1]) maxInd = ind
      }
      ind++
    }

    //if the min and max point are the same, just include one
    if (minInd === maxInd && minInd !== null) out.push(minInd)

    //make sure the min and max points are still in chronological order in the downsampled signal
    if (minInd < maxInd) out.push(minInd, maxInd)
    if (minInd > maxInd) out.push(maxInd, minInd)
  }

  //include the point after the inteval starts to get a continuous line
  if (ind < len) out.push(ind)

  return out
}

export default downsample
