import React from 'react'

import './Address.scss'

const Address = props => {
  const a = props.value || {}
  if (props.static) {
    if (!props.value) return <em>None</em>
    let output = ''

    if (a.contactName) output += a.contantName + '\n'
    if (a.streetAddress1) output += a.streetAddress1 + '\n'
    if (a.streetAddress2) output += a.streetAddress2 + '\n'
    output += `${a.city || ''}, ${a.state || ''} ${a.zip || ''}`
    return <pre>{output}</pre>
  }
  return (
    <div className="address-input">
      <div className="line">
        <input
          value={a.contactName || ''}
          onChange={e => {
            props.onChange({
              ...e,
              target: {
                value: {...a, contactName: e.target.value}
              }
            })
          }}
          className="contact-name"
          placeholder="Contact Name"
        />
      </div>
      <div className="line">
        <input
          value={a.street1 || ''}
          onChange={e => null}
          className="street1"
          placeholder="Street Address 1"
        />
      </div>
      <div className="line">
        <input
          value={a.street2 || ''}
          onChange={e => null}
          className="street2"
          placeholder="Street Address 2"
        />
      </div>
      <div className="line">
        <input
          value={a.city || ''}
          onChange={e => null}
          className="city"
          placeholder="City"
        />
        <input
          value={a.state || ''}
          onChange={e => null}
          className="state"
          placeholder="State"
        />
        <input
          value={a.zip || ''}
          onChange={e => null}
          className="zip"
          placeholder="ZIP"
        />
      </div>
    </div>
  )
}
export default Address
