import base64js from 'base64-js'

export default (compressedString) => {
  if (!compressedString) return []

  const uint8View = base64js.toByteArray(compressedString)

  if (uint8View.length % 52) return []

  const uintView = new Uint32Array(uint8View.buffer)
  const floatView = new Float32Array(uint8View.buffer)
  // const floatViewTemp = new Float32Array(uint8View.buffer)
  // const data = new Array(uint8View.length / 8)
  const data = new Array(uint8View.length / 52)

  for (let i = 0; i < data.length; i++)
    data[i] = [
      uintView[i * 13],
      floatView[i * 13 + 1],
      floatView[i * 13 + 2],
      floatView[i * 13 + 3],
      floatView[i * 13 + 4],
      floatView[i * 13 + 5],
      floatView[i * 13 + 6],
      floatView[i * 13 + 7],
      floatView[i * 13 + 8],
      floatView[i * 13 + 9],
      floatView[i * 13 + 10],
      floatView[i * 13 + 11],
      floatView[i * 13 + 12]
    ]

  return data
}
