import React from 'react'
import {Query} from 'react-apollo'
import {gql} from 'graphql.macro'
import Loading from 'Components/Loading'
import {route} from 'router'
import List from './ListBattery'

import './Alerts.scss'

const GET_DEVICES_BATTERY = gql`
  query GET_DEVICES_BATTERY {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesBattery {
      id
      name
      path
      percentSolar
      batteryDelta  
      batteryLevel
      defaultTag
      companyID
      modemType
      lastTimeMessageReceived
      firmwareVersion
      signalStrength
      company {
        name
      }
    }
  }
`

const BatteryView = () => {

  return (
    <Query query={GET_DEVICES_BATTERY}>
      {({error, loading, data}) => {
        if (error) return 'error'
        if (loading) return <Loading />
        if (!!data && !data.me.isSuperUser) {
          route('/alarms')
        } 
        const list = (data.devicesBattery || [])

          return (
            <>{!!data.me.isSuperUser && (
            <div className='directory-body'>
              <div className='directory-top-bar'>
                <p className='title2344'>BATTERY VIEW</p>
              </div>
            <List devices={list} isSuperUser={data && data.me && data.me.isSuperUser} />
          </div>)}
          </>
          )
      }}
    </Query>
  )
}

export default BatteryView
