import React from 'react'

const Handle = props => (
  <g
    onMouseDown={props.onMouseDown}
    style={{stroke: '#000', strokeWidth: 1, cursor: 'ew-resize'}}
  >
    <rect
      x={props.x - 5}
      y={props.height / 2 - 10}
      width="10"
      height="20"
      rx="3"
      ry="3"
      style={{fill: '#fff'}}
    />
    <line
      x1={props.x - 1}
      x2={props.x - 1}
      y1={props.height / 2 - 5}
      y2={props.height / 2 + 5}
    />
    <line
      x1={props.x + 1}
      x2={props.x + 1}
      y1={props.height / 2 - 5}
      y2={props.height / 2 + 5}
    />
  </g>
)

export default Handle
