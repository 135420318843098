import React, {Component} from 'react'
import {connect} from 'react-redux'
import {zoom, moveView, setView, updateView} from './store/actions'
import normalizeWheel from 'normalize-wheel'
import isNumber from 'lodash/isNumber'
import colors from 'appColors'
import './Graph.scss'

const DRAG_THRESHOLD = 5

const eventToCoords = (e) => ({x: e.clientX, y: e.clientY})

class Graph extends Component {
  state = {
    mouseStart: false,
    dragging: false,
    touches: null,
    colorPalette: null,
    darkMode: null,
    menu: {
      x: null,
      y: null,
      currentTime: null
    }
  }
  ignoreClick = false
  componentDidMount() {
    this.props.childRef && this.props.childRef(this)
    this.props.setView({
      x: {
        start: isNumber(this.props.xmin)
          ? this.props.xmin
          : Date.now() / 1000 - 14 * 24 * 60 * 60,
        end: isNumber(this.props.xmax) ? this.props.xmax : Date.now() / 1000
      },
      y: {
        start: isNumber(this.props.ymin) ? this.props.ymin : 0,
        end: isNumber(this.props.ymax) ? this.props.ymax : 10
      }
    })
    this.initializeState()
  }
  componentDidUpdate(prevProps) {
    let update = {x: {}, y: {}}
    if (prevProps.xmin !== this.props.xmin) update.x.start = this.props.xmin
    if (prevProps.xmax !== this.props.xmax) update.x.end = this.props.xmax
    if (prevProps.ymin !== this.props.ymin) update.y.start = this.props.ymin
    if (prevProps.ymax !== this.props.ymax) update.y.end = this.props.ymax
    if (update.x.start || update.x.end || update.y.start || update.y.end) {
      this.connectedUpdate(update)
    }
  }
  initializeState = () => {
    const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)')
    if (prefersDarkScheme.matches) {
      this.setState({
        colorPalette: ['blue', 'purple', 'red'],
        darkMode: true
      })
    } else {
      this.setState({colorPalette: ['green', 'yellow', 'red'], darkMode: false})
    }
  }

  remoteUpdate = (update) => {
    this.props.updateView(update)
  }
  connectedUpdate = (update, skip = false) => {
    if (this.props.connectedGraph !== null && skip === false) {
      this.props.connectedGraph.updateView(update, true)
    }
    this.props.updateView(update)
  }
  isMobile = () => {
    // credit to Timothy Huang for this regex test:
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true
    } else {
      return false
    }
  }
  addListeners = (ref) => {
    if (!ref) return
    this.ref = ref
    this.ref.addEventListener('mousedown', this.mouseDownHandler)
    this.ref.addEventListener('mousemove', this.mouseMoveHandler)
    this.ref.addEventListener('touchstart', this.touchStartHandler)
    this.ref.addEventListener('touchmove', this.touchMoveHandler)
    this.ref.addEventListener('touchend', this.touchEndHandler)
    this.ref.addEventListener('wheel', this.wheelHandler)
  }
  removeListeners = () => {
    if (!this.ref) return
    this.ref.removeEventListener('mousedown', this.mouseDownHandler)
    this.ref.removeEventListener('mousemove', this.mouseMoveHandler)
    this.ref.removeEventListener('touchstart', this.touchStartHandler)
    this.ref.removeEventListener('touchmove', this.touchMoveHandler)
    this.ref.removeEventListener('touchend', this.touchEndHandler)
    this.ref.removeEventListener('wheel', this.wheelHandler)
    this.ref = null
  }
  mouseDownHandler = (e, skip = false) => {
    if (this.props.connectedGraph !== null && skip === false) {
      this.props.connectedGraph.mouseDownHandler(e, true)
    }
    window.addEventListener('mousemove', this.dragHandler)
    window.addEventListener('mouseup', this.mouseUpHandler)
    window.addEventListener('mouseleave', this.mouseUpHandler)
    this.setState({mouseStart: eventToCoords(e)})
  }
  mouseUpHandler = (e, skip = false) => {
    if (this.props.connectedGraph !== null && skip === false) {
      this.props.connectedGraph.mouseUpHandler(e, true)
    }
    window.removeEventListener('mousemove', this.dragHandler)
    window.removeEventListener('mouseup', this.mouseUpHandler)
    window.removeEventListener('mouseleave', this.mouseUpHandler)
    //if ending a drag, ignore the subsequent onClick event
    if (this.state.dragging) this.ignoreClick = true
    this.setState({mouseStart: false, dragging: false})
  }
  dragHandler = (e, skip = false) => {
    e.preventDefault()
    e.stopPropagation()

    if (this.props.connectedGraph !== null && skip === false) {
      this.props.connectedGraph.dragHandler(e, true)
    }
    if (
      this.state.dragging ||
      (this.state.mouseStart &&
        (Math.abs(e.clientX - this.state.mouseStart.x) > DRAG_THRESHOLD ||
          Math.abs(e.clientY - this.state.mouseStart.y) > DRAG_THRESHOLD))
    ) {
      this.props.moveView({
        x: e.clientX - this.state.mouseStart.x
        //y: e.clientY - this.state.mouseStart.y
      })
      this.setState({
        dragging: true,
        mouseStart: eventToCoords(e)
      })
    }
  }
  wheelHandler = (e, skip = false) => {
    if (this.props.connectedGraph !== null && skip === false) {
      this.props.connectedGraph.wheelHandler(e, true)
    }
    const zoomFactor = 1 - normalizeWheel(e).spinY * 0.02
    const center = {x: e.clientX, y: e.clientY}
    this.props.zoom(zoomFactor, center)
  }
  touchStartHandler = (e, skip = false) => {
    const x = e.touches[0].clientX
    const y = e.touches[0].clientY
    this.setState({
      menu: {
        x,
        y,
        currentTime: (x - this.props.left) / this.props.xScale + this.props.xStart
      }
    })

    if (this.props.connectedGraph !== null && skip === false) {
      this.props.connectedGraph.touchStartHandler(e, true)
    }

    if (e.touches.length === 1) this.mouseDownHandler(e.touches[0])
    else {
      this.setState({
        touches: [eventToCoords(e.touches[0]), eventToCoords(e.touches[1])]
      })
    }
  }
  touchMoveHandler = (e, skip = false) => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
      menu: {
        x: null,
        y: null,
        currentTime: null
      }
    })

    if (this.props.connectedGraph !== null && skip === false) {
      this.props.connectedGraph.touchMoveHandler(e, true)
    }
    if (e.touches.length === 1) {
      this.props.moveView({
        x: e.touches[0].clientX - this.state.mouseStart.x
        //y: e.clientY - this.state.mouseStart.y
      })
      this.setState({
        dragging: true,
        mouseStart: eventToCoords(e.touches[0])
      })
    } else {
      if (!this.state.touches) return
      this.props.zoom(
        (e.touches[0].clientX - e.touches[1].clientX) /
          (this.state.touches[0].x - this.state.touches[1].x),
        {
          x: (e.touches[0].clientX + e.touches[1].clientX) / 2,
          y: (e.touches[0].clientY + e.touches[1].clientY) / 2
        }
      )
      this.setState({
        touches: [eventToCoords(e.touches[0]), eventToCoords(e.touches[1])]
      })
    }
  }
  touchEndHandler = (e, skip = false) => {
    const {x, y, currentTime} = this.state.menu

    e.preventDefault()
    e.stopPropagation()

    if (x && y && currentTime) {
      if (this.props.handlePopupMenu) {
        this.props.handlePopupMenu({x, y, currentTime})
      }
    }

    if (this.props.connectedGraph !== null && skip === false) {
      this.props.connectedGraph.touchEndHandler(e, true)
    }

    this.setState(
      e.touches.length === 1
        ? {
            touches: null,
            dragging: true,
            mouseStart: eventToCoords(e.touches[0])
          }
        : {touches: null, mouseDown: false, dragging: false}
    )
  }
  mouseMoveHandler = (e) =>
    !this.state.dragging &&
    this.props.onMouseMove &&
    this.props.onMouseMove({
      clientX: e.clientX,
      clientY: e.clientY,
      time:
        (e.clientX - this.props.left) / this.props.xScale + this.props.xStart,
      y: this.props.yEnd - (e.clientY - this.props.top) / this.props.yScale
    })
  clickHandler = (e) => {
    if (this.ignoreClick) this.ignoreClick = false
    else if (this.props.onClick) this.props.onClick(e)
  }
  setDomain = (xminIn, xmaxIn) => {
    const xmin = xminIn
    const xmax = xmaxIn || Date.now() / 1000
    this.props.updateView({x: {start: xmin, end: xmax}})
  }
  getPosition = () => {
    let pos =
      (this.props.currentTime - this.props.xStart) /
      (this.props.width / this.props.xScale)
    if (pos.toFixed(3) > 0 && pos.toFixed(3) < 1) {
      return pos.toFixed(3) * 100 + '%'
    } else {
      return null
    }
  }
  render() {
    if (this.props.width <= 0 || this.props.height <= 0) return null
    return (
      <svg
        width={this.props.width}
        height={this.props.height}
        x={this.props.x}
        y={this.props.y}
        style={{
          pointerEvents: 'all',
          cursor: this.state.dragging ? 'grabbing' : 'pointer'
        }}
        className={this.props.className}
        id="graph"
        onClick={this.clickHandler}
        onTouchStart={this.touchStartHandler}
        ref={this.addListeners}
      >
        {/* empty rect to catch pointer events*/}
        <rect x="0%" y="0%" height="100%" width="100%" style={{fill: 'none', cursor: 'pointer'}} />
        {this.props.children}
        {this.props.timeLine && this.getPosition() !== null && (
          <line
            x1={this.getPosition()}
            y1={'0%'}
            x2={this.getPosition()}
            y2={'100%'}
            stroke={colors.yellow}
            strokeWidth={1.5}
            opacity={'100%'}
          />
        )}
        {/* {this.props.fourierMax && (
          <svg x={'calc(100% - 35px)'} y="0%" height="100%" width="35px">
            <defs>
              <linearGradient
                id={'example-gradient'}
                x1="0"
                x2="0"
                y1="1"
                y2="0"
              >
                <stop
                  offset={'0%'}
                  stopColor={
                    this.state.colorPalette && this.state.colorPalette[0]
                  }
                  stopOpacity={0.5}
                />
                <stop
                  offset={'50%'}
                  stopColor={
                    this.state.colorPalette && this.state.colorPalette[1]
                  }
                  stopOpacity={0.5}
                />
                <stop
                  offset={'100%'}
                  stopColor={
                    this.state.colorPalette && this.state.colorPalette[2]
                  }
                  stopOpacity={0.5}
                />
              </linearGradient>
            </defs>
            <rect
              height="100%"
              width="100%"
              style={{
                fill: 'white',
                strokeWidth: '2px',
                stroke: colors.primary
              }}
            />
            <rect
              id="fourier-amplitude-bar"
              x="0%"
              y="0%"
              height="100%"
              width="15px"
              style={{
                fill: 'url(#example-gradient)',
                strokeWidth: '1px',
                stroke: this.state.darkMode
                  ? colors.primaryDark
                  : colors.primary
              }}
            />
            <text
              fontSize={this.isMobile() ? '11px' : '13px'}
              style={{transform: 'translate(30px,98%) rotate(-90deg)'}}
            >
              0
            </text>
            <text
              textAnchor="middle"
              fontSize={this.isMobile() ? '11px' : '13px'}
              style={{transform: 'translate(30px,50%) rotate(-90deg)'}}
            >
              Amplitude (Hz)
            </text>
            <text
              textAnchor="end"
              fontSize={this.isMobile() ? '11px' : '13px'}
              style={{transform: 'translate(30px, 2%) rotate(-90deg)'}}
            >
              {this.props.fourierMax}
            </text>
          </svg>
        )} */}
      </svg>
    )
  }
}

const mapStateToProps = (state) => ({
  x: state.container.leftOffset,
  y: state.container.topOffset,
  width:
    state.container.width -
    state.container.leftOffset -
    state.container.rightOffset,
  height:
    state.container.height -
    state.container.topOffset -
    state.container.bottomOffset,
  xScale: state.view.x.scale,
  yScale: state.view.y.scale,
  xStart: state.view.x.start,
  yStart: state.view.y.start,
  left: state.container.left + state.container.leftOffset
})
const mapDispatchToProps = (dispatch) => ({
  zoom: (zoomFactor, center) => dispatch(zoom(zoomFactor, center)),
  moveView: (move) => dispatch(moveView(move)),
  setView: (params) => dispatch(setView(params)),
  updateView: (params) => dispatch(updateView(params))
})
export default connect(mapStateToProps, mapDispatchToProps)(Graph)
