import React, {Component} from 'react'
import {Query, withApollo} from 'react-apollo'
import {gql} from 'graphql.macro'
import {Input} from 'Components/Form'

import './index.scss'

const GET_INVOICE = gql`
  query GET_INVOICE(
    $quarter: Int!
    $year: Int!
    $companyID: Int!
    $isTaxed: Boolean
    $invoiceNumber: String
    $accountingCodes: [String]
  ) {
    invoice(
      quarter: $quarter
      year: $year
      companyID: $companyID
      isTaxed: $isTaxed
      invoiceNumber: $invoiceNumber
      accountingCodes: $accountingCodes
    ) {
      html
    }
  }
`

const GET_DEVICES_COMPANIES = gql`
  query GET_USERS_DEVICES {
    companies {
      id
      name
      isBilled
      isTaxed
    }
    devices {
      id
      company {
        id
      }
      accountingCode
    }
  }
`

class Billing extends Component {
  state = {
    quarter: null,
    companyID: null,
    isTaxed: false,
    invoiceNumber: '',
    accountingCodes: [],
    year: new Date().getFullYear()
  }
  generateInvoice = async () => {
    const win = window.open('', 'win', 'width=800,height=600') // a window object
    const {data} = await this.props.client.query({
      query: GET_INVOICE,
      variables: {
        quarter: this.state.quarter,
        year: this.state.year,
        companyID: this.state.companyID,
        isTaxed: this.state.isTaxed,
        invoiceNumber: this.state.invoiceNumber,
        accountingCodes: this.state.accountingCodes
      },
      fetchPolicy: 'no-cache'
    })

    win.document.open('text/html', 'replace')
    win.document.write(data.invoice.html)
    win.document.close()
  }
  render() {
    const quarters = [
      {label: 'Q1 - Jan-Mar', value: 1},
      {label: 'Q2 - Apr-Jun', value: 2},
      {label: 'Q3 - Jul-Sep', value: 3},
      {label: 'Q4 - Oct-Dec', value: 4}
    ]
    let now = new Date()
    const years = []
    for (let i = 0; i < 5; i++) {
      let year = now.getFullYear()
      years.push(year)
      now.setFullYear(now.getFullYear() - 1)
    }

    return (
      <Query query={GET_DEVICES_COMPANIES}>
        {({loading, error, data}) => (
          <div className="admin-billing">
            <div className="window">
              <div className="half">
                <div className="title">Generate Invoice</div>
                <Input
                  type="select"
                  value={this.state.companyID}
                  label="Company"
                  tooltip="Only companies that are set up to be billed on the company page will appear in this list."
                  onChange={(e) =>
                    this.setState({
                      companyID: e.target.value,
                      accountingCodes: getCodes(
                        (data && data.devices) || [],
                        e.target.value
                      ),
                      isTaxed: (
                        data.companies.find((c) => c.id === e.target.value) ||
                        {}
                      ).isTaxed
                    })
                  }
                  options={[{label: '', value: null}].concat(
                    ((data && data.companies) || [])
                      .filter((c) => c.isBilled)
                      .map((c) => ({
                        label: c.name,
                        value: c.id
                      }))
                  )}
                />
                <Input
                  type="select"
                  value={this.state.quarter}
                  label="Quarter"
                  onChange={(e) => this.setState({quarter: e.target.value})}
                  options={quarters.map((q) => ({
                    label: q.label,
                    value: q.value
                  }))}
                />
                <Input
                  type="select"
                  value={this.state.year}
                  label="Year"
                  onChange={(e) => this.setState({year: e.target.value})}
                  options={years.map((y) => ({
                    label: y,
                    value: y
                  }))}
                />
                <Input
                  type="string"
                  value={this.state.invoiceNumber}
                  label="Invoice Number"
                  onChange={(e) =>
                    this.setState({invoiceNumber: e.target.value})
                  }
                />
                <Input
                  type="checkbox"
                  value={this.state.isTaxed}
                  label="Include Tax"
                  tooltip="Tax status can be changed on the company page."
                  disabled
                  options={[
                    {label: 'Yes', value: true},
                    {label: 'No', value: false}
                  ]}
                />
                <button className="primary" onClick={this.generateInvoice}>
                  Generate Invoice
                </button>
              </div>
              <div className="half">
                <div className="title">Accounting Codes</div>
                <div className="codes">
                  <div>
                    Select:{' '}
                    <span
                      className="clickable"
                      onClick={() =>
                        this.setState({
                          accountingCodes: getCodes(
                            (data && data.devices) || [],
                            this.state.companyID
                          )
                        })
                      }
                    >
                      All
                    </span>{' '}
                    <span
                      className="clickable"
                      onClick={() => this.setState({accountingCodes: []})}
                    >
                      None
                    </span>
                  </div>
                  {getCodes(
                    (data && data.devices) || [],
                    this.state.companyID
                  ).map((accountingCode) =>
                    this.state.accountingCodes.includes(accountingCode) ? (
                      <div
                        className="clickable"
                        key={accountingCode}
                        onClick={() =>
                          this.setState({
                            accountingCodes: this.state.accountingCodes.filter(
                              (code) => code !== accountingCode
                            )
                          })
                        }
                      >
                        <i className="fa fa-check allowed checked" />
                        {accountingCode}
                      </div>
                    ) : (
                      <div
                        className="clickable"
                        key={accountingCode}
                        onClick={() =>
                          this.setState({
                            accountingCodes:
                              this.state.accountingCodes.concat(accountingCode)
                          })
                        }
                      >
                        <i className="fa fa-check allowed unchecked" />
                        {accountingCode}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Query>
    )
  }
}

const getCodes = (devices, companyID) =>
  (devices || [])
    .filter((d) => d.company && d.company.id === companyID)
    .filter((d) => !!d.accountingCode)
    .map((d) => d.accountingCode)
    .sort((a, b) => a.localeCompare(b))
    .filter((code, i, arr) => i === 0 || arr[i - 1] !== code)

export default withApollo(Billing)
