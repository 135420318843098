// NOTE: I had to name this file with a prepending underscore, because I guess it was conflicting with the server file of the same name

const getGpsCoordinates = (userDefinedCoordinates = {}, deviceDefinedCoordinates = {}) => {
  if (!userDefinedCoordinates.latitude && !userDefinedCoordinates.longitude
      && !deviceDefinedCoordinates.latitude && !deviceDefinedCoordinates.longitude) {
    return {latitude: null, longitude: null}
  }

  if (deviceDefinedCoordinates.latitude && deviceDefinedCoordinates.latitude !== 0
    && deviceDefinedCoordinates.longitude && deviceDefinedCoordinates.longitude !== 0) {
    return deviceDefinedCoordinates
  }

  if (userDefinedCoordinates.latitude && userDefinedCoordinates.longitude) {
    return userDefinedCoordinates
  }

  return {latitude: null, longitude: null}
}

export default getGpsCoordinates
