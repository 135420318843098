import React, {Component} from 'react'

class Checkbox extends Component {
  componentDidMount() {
    if (!this.ref) return
    if (this.props.value === true || this.props.value === false)
      this.ref.indeterminate = false
    else this.ref.indeterminate = true
  }
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.ref)
      if (prevProps.value === true || prevProps.value === false)
        this.ref.indeterminate = false
      else this.ref.indeterminate = true
  }
  render() {
    const checked = this.props.value === true ? true : false
    return this.props.static ? (
      checked ? (
        'Yes'
      ) : (
        'No'
      )
    ) : (
      <input
        type="checkbox"
        ref={ref => (this.ref = ref)}
        checked={checked}
        data-key={this.props['data-key']}
        onClick={e => {
          if (this.props.onChange) this.props.onChange(e)
        }}
        onChange={() => {}}
        disabled={this.props.disabled || this.props.static}
      />
    )
  }
}

export default Checkbox
