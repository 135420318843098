import React from 'react'
import {connect} from 'react-redux'

const Wrapper = ({
  point,
  xStart,
  yStart,
  xScale,
  yScale,
  graphHeight,
  graphWidth,
  width = 50,
  height = 50,
  viewBox = '0 0 50 50',
  children,
  onClick
}) => {
  if (!point) return null
  const x = (point[0] - xStart) * xScale
  const y = (point[1] - yStart) * yScale
  if (
    x < -width / 2 ||
    x > graphWidth + width / 2 ||
    y < -height / 2 ||
    y > graphHeight + height / 2
  )
    return null
  return (
    <svg
      x={x - width / 2}
      y={graphHeight - (y + height + 15)}
      width={width}
      height={height}
      viewBox={viewBox}
      onClick={onClick}
    >
      {children}
    </svg>
  )
}

const mapStateToProps = (state) => ({
  xStart: state.view.x.start,
  yStart: state.view.y.start,
  xScale: state.view.x.scale,
  yScale: state.view.y.scale,
  graphHeight:
    state.container.height -
    state.container.topOffset -
    state.container.bottomOffset,
  graphWidth:
    state.container.width -
    state.container.leftOffset -
    state.container.rightOffset
})

export default connect(mapStateToProps)(Wrapper)
