import React from 'react'
import {connect} from 'react-redux'
import Handle from './Handle'

const SelectionBox = props => {
  const top = 1
  const bottom = props.height - 1
  const scaleTimeToPixels = props.width / (props.max - props.min)
  const start = (props.start - props.min) * scaleTimeToPixels
  const end = (props.end - props.min) * scaleTimeToPixels
  const startVisible = props.start > props.min + 60
  const endVisible = props.end < props.max - 60

  //inset the points by 1px so the 2px stroke doesn't get clipped by the outer svg box
  //draw the selection line only where its visible
  const pointString =
    (startVisible ? `1,${top} ${start + 1},${top} ` : ``) +
    `${start + 1},${bottom} ${end - 1},${bottom} ` +
    (endVisible ? `${end - 1},${top} ${props.width - 1},${top}` : ``)

  return (
    <g className="selector">
      <g style={{stroke: 'none', fill: '#fff', opacity: 0.7}}>
        <rect x="0" y="0" width={start > 0 ? start : 0} height={props.height} />
        <rect
          x={end}
          y="0"
          width={props.width - end > 0 ? props.width - end : 0}
          height={props.height}
        />
      </g>
      <rect
        onMouseDown={e => props.startDrag(e, 'middle')}
        x={start}
        y="0"
        width={end - start}
        height={props.height}
        style={{
          strokeWidth: 0,
          fill: 'none',
          pointerEvents: 'visible',
          cursor: props.dragging === 'middle' ? 'grabbing' : 'grab'
        }}
      />
      <polyline
        points={pointString}
        style={{fill: 'none', stroke: '#000', strokeWidth: 2}}
      />
      {startVisible && (
        <Handle
          height={props.height}
          x={start}
          onMouseDown={e => props.startDrag(e, 'start')}
        />
      )}
      {endVisible && (
        <Handle
          height={props.height}
          x={end}
          onMouseDown={e => props.startDrag(e, 'end')}
        />
      )}
    </g>
  )
}

const mapStateToProps = state => ({
  start: state.view.x.start,
  end: state.view.x.end
})

export default connect(mapStateToProps)(SelectionBox)
