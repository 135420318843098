import React from 'react'
import ago from 'utils/ago'

import './List.scss'

class List extends React.Component {
  state = {
    sortBy: 'time',
    sortDirection: 'asc',
    menu: {
      type: false
    }
  }
  sort = (key) => (e) => {
    e.preventDefault()
    this.setState((state) => ({
      sortBy: key,
      sortDirection:
        state.sortBy === key
          ? state.sortDirection === 'asc'
            ? 'desc'
            : 'asc'
          : 'asc'
    }))
  }

  renderTree = (tag, subtype1, subtype2) => {
    let tagString = tag
    let subString1 = subtype1
    let subString2 = subtype2
    switch (tag) {
      case 'spsr':
        tagString = 'Tank Level'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Alphawire White'
            break
          case 2:
            subString2 = 'Shenyuan 5.5mm'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'dspsr':
        tagString = 'Tank Level - Dual Sensor'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Alphawire White'
            break
          case 2:
            subString2 = 'Shenyuan 5.5mm'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'psr':
        tagString = 'Pressure'
        subString1 = subtype1
        switch (subtype2) {
          case 1:
            subString2 = 'Normal'
            break
          case 2:
            subString2 = 'SS Mesh Covering'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'puls':
        tagString = 'Turbine'
        subString1 = 'na'
        subString2 = 'na'
        break
      case 'rot':
        tagString = 'Beam Pump'
        switch (subtype1) {
          case 1:
            subString1 = 'Dongle - Internal'
            break
          case 2:
            subString1 = 'Dongle - Sensor'
            break
          default:
            subString1 = 'Dongle - Not Reported'
        }
        subString2 = 'na'
        break
      case 'temp':
        tagString = 'Temperature'
        switch (subtype1) {
          case 1:
            subString1 = 'Skin'
            break
          case 2:
            subString1 = 'Insert'
            break
          default:
            subString1 = 'Not Reported'
        }
        switch (subtype2) {
          case 1:
            subString2 = 'Bare Wire'
            break
          case 2:
            subString2 = 'SS Mesh Covering'
            break
          default:
            subString2 = 'Not Reported'
        }
        break
      case 'vib':
        tagString = 'Vibration'
        switch (subtype1) {
          case 1:
            subString1 = 'Dongle - Internal'
            break
          case 2:
            subString1 = 'Dongle - Sensor'
            break
          case 3:
            subString1 = 'External - Cable'
            break
          default:
            subString1 = 'Not Reported'
        }
        subString2 = 'na'
        break
      case 'dist':
        tagString = 'Radar'
        switch (subtype1) {
          case 1:
            subString1 = 'Internal'
            break
          case 2:
            subString1 = 'External - Cable'
            break
          default:
            subString1 = 'Not Reported'
        }
        subString2 = 'na'
        break
      case 'hum':
        tagString = 'Humidity'
        subString1 = 'na'
        subString2 = 'na'
        break
      case 'sonr':
        tagString = 'Sonar'
        subString1 = 'na'
        subString2 = 'na'
        break
      default:
        tagString = 'na'
        subString1 = 'na'
        subString2 = 'na'
        break
    }
    return {tagString, subString1, subString2}
  }
  sortIndicator = (key) =>
    this.state.sortBy === key ? (
      this.state.sortDirection === 'asc' ? (
        <i className="fa fa-caret-up" />
      ) : (
        <i className="fa fa-caret-down" />
      )
    ) : null
  get sortFunction() {
    const reverse = this.state.sortDirection === 'asc'
    switch (this.state.sortBy) {
      case 'id':
        return (a, b) => (reverse ? a.id - b.id : b.id - a.id)
      case 'uaid':
        return (a, b) => (reverse ? a.userAssignedID - b.userAssignedID : b.userAssignedID - a.userAssignedID)
      case 'name':
        return (a, b) =>
          (reverse ? 1 : -1) *
          ((a.path || 'zzzzz') + (a.name || 'zzzzz')).localeCompare(
            (b.path || 'zzzzz') + (b.name || 'zzzzz')
          )
      case 'companyName':
        return (a, b) =>
          (reverse ? 1 : -1) *
          (a.companyName || 'zzzzz').localeCompare((b.companyName || 'zzzzz'))
      case 'battery':
        return (a, b) =>
          !a.batteryLevel
            ? 1
            : !b.batteryLevel
            ? -1
            : (reverse ? 1 : -1) * (a.batteryLevel - b.batteryLevel)
      case 'time':
        return (a, b) =>
          !a.lastTimeMessageReceived
            ? 1
            : !b.lastTimeMessageReceived
            ? -1
            : (reverse ? -1 : 1) * (a.lastTimeMessageReceived - b.lastTimeMessageReceived)
      case 'defaultTagHumanReadable':
        return (a, b) =>
          (reverse ? 1 : -1) *
          (a.defaultTagHumanReadable || 'zzzzz').localeCompare(b.defaultTagHumanReadable || 'zzzzz')
      case 'signalStrength':
        return (a, b) => (reverse ? a.signalStrength - b.signalStrength : b.signalStrength - a.signalStrength)
      case 'boardRev':
        return (a, b) =>
          (reverse ? 1 : -1) *
          ((a.boardRev || 'zzzzz') + (a.boardRev || 'zzzzz')).localeCompare(
            (b.boardRev || 'zzzzz') + (b.boardRev || 'zzzzz')
          )
      case 'firmwareVersion':
        return (a, b) => (reverse ? a.firmwareVersion - b.firmwareVersion : b.firmwareVersion - a.firmwareVersion)
      case 'units':
        return (a, b) =>
        (reverse ? 1 : -1) *
        (a.displayUnits || 'zzzzz').localeCompare((b.displayUnits || 'zzzzz'))
      case 'coordslo':
        return (a, b) => (reverse ? a.longitude - b.longitude : b.longitude - a.longitude)
      case 'coordsla':
        return (a, b) => (reverse ? a.latitude - b.latitude : b.latitude - a.latitude)
      case 'reading':
        return (a, b) => (reverse ? a.latestReading - b.latestReading : b.latestReading - a.latestReading)
      default:
        return () => null
    }
  }
  render() {
    const devices = this.props.devices
      .map(
        ({
          id,
          userAssignedID,
          name,
          path,
          lastTimeMessageReceived,
          latestReading,
          batteryLevel,
          company,
          displayUnits,
          defaultTag,
          longitude,
          latitude,
          sensorSubtype1,
          sensorSubtype2,
          firmwareVersion,
          modemType,
          simNumber,
          boardRev,
          signalStrength,
        }) => {
          const tree = this.renderTree(
            defaultTag,
            sensorSubtype1,
            sensorSubtype2
          )
          
          const defaultTagHumanReadable = tree.tagString
          sensorSubtype1 = tree.subString1
          sensorSubtype2 = tree.subString2
          
          return {
            id,
            userAssignedID,
            name,
            companyName: company && company.name,
            path,
            lastTimeMessageReceived,
            latestReading,
            batteryLevel,
            displayUnits,
            defaultTag,
            longitude,
            latitude,
            defaultTagHumanReadable,
            sensorSubtype1,
            sensorSubtype2,
            firmwareVersion,
            modemType,
            simNumber,
            boardRev,
            signalStrength,
          }
        }
      )
      .sort(this.sortFunction)

    return (
      <div className="list-container device-list">
        <div className="row header">
          <div className="id clickable" onClick={this.sort('id')}>
            ID {this.sortIndicator('id')}
          </div>
          {!this.props.isSuperUser && (
          <div className="id clickable" onClick={this.sort('uaid')}>
            UA ID {this.sortIndicator('uaid')}
          </div>
          )}
          <div className="name clickable" onClick={this.sort('name')}>
            Name {this.sortIndicator('name')}
          </div>
          <div className="last-message clickable" onClick={this.sort('companyName')}>
            Company {this.sortIndicator('companyName')}
          </div>
          <div
            className="last-message clickable"
            onClick={this.sort('defaultTagHumanReadable')}
          >
            Sensor Type {this.sortIndicator('defaultTagHumanReadable')}
          </div>
          {!this.props.isSuperUser && (
          <div
            className="last-message clickable"
            onClick={this.sort('reading')}
          >
            Last Reading {this.sortIndicator('reading')}
          </div>
          )}
          {!this.props.isSuperUser && (
          <div
            className="last-message clickable"
            onClick={this.sort('units')}
          >
            Units {this.sortIndicator('units')}
          </div>
          )}
          {!this.props.isSuperUser && (
          <div
            className="last-message clickable"
            onClick={this.sort('coordsla')}
          >
            Coordinates {this.sortIndicator('coordsla')}
          </div>
          )}
          <div className="last-message clickable" onClick={this.sort('time')}>
            Last Message {this.sortIndicator('time')}
          </div>

          <div className="voltage clickable" onClick={this.sort('battery')}>
            Battery {this.sortIndicator('battery')}
          </div>

          {this.props.isSuperUser && (
            <div
              className="last-message clickable"
              onClick={this.sort('signalStrength')}
            >
              Signal Strength {this.sortIndicator('signalStrength')}
            </div>
          )}

          {this.props.isSuperUser && (
            <div
              className="last-message clickable"
              onClick={this.sort('boardRev')}
            >
              Board {this.sortIndicator('boardRev')}
            </div>
          )}
            <div
              className="last-message clickable"
              onClick={this.sort('firmwareVersion')}
            >
              Firmware {this.sortIndicator('firmwareVersion')}
            </div>
          {this.props.isSuperUser && (
            <div
              className="last-message clickable"
              onClick={this.sort('modemType')}
            >
              Modem {this.sortIndicator('modemType')}
            </div>
          )}

          {this.props.isSuperUser && (
            <div
              className="sim-number clickable"
              onClick={this.sort('simNumber')}
            >
              SIM Number {this.sortIndicator('simNumber')}
            </div>
          )}
        </div>
        <div className="scrollable-container">
          <table>
            <tbody>
              {devices.map((d) => (
                <tr key={d.id} className="row" style={{height: 30}}>
                  <td className="id" style={{height: 'auto'}}>
                    <a href={`#/devices/${d.id}`}>{d.id}</a>
                  </td>
                  {!this.props.isSuperUser && (
                  <td
                    className="id"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {d.userAssignedID? d.userAssignedID : 'N/A'}
                  </td>
                  )}
                  <td
                    className="name"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    <a href={`#/devices/${d.id}`}>
                      {d.path} : <strong>{d.name}</strong>
                    </a>
                  </td>
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {d.companyName}
                  </td>
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {d.defaultTagHumanReadable}
                  </td>
                  {!this.props.isSuperUser && (
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {d.latestReading ? d.latestReading : 'N/A'}
                  </td>
                  )}
                  {!this.props.isSuperUser && (
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {d.displayUnits ? d.displayUnits : 'default'}
                  </td>
                  )}
                  {!this.props.isSuperUser && (
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    {(d.latitude && d.longitude) ? `${d.latitude}, ${d.longitude}` : 'N/A'}
                  </td>
                  )}
                  <td
                    className="last-message"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    <div className={d.isDelayed ? 'warning' : 'normal'}>
                      {ago(d.lastTimeMessageReceived) ? ago(d.lastTimeMessageReceived) : 'N/A'}
                    </div>
                  </td>
                  <td
                    className="voltage"
                    style={{height: 'auto', minHeight: '1.1em'}}
                  >
                    <div className={d.isLowVoltage ? 'warning' : 'normal'}>
                      {d.batteryLevel ? `${d.batteryLevel.toFixed(2)} V` : 'N/A'}
                    </div>
                  </td>
                  {this.props.isSuperUser && (
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.signalStrength}
                    </td>
                  )}
                  {this.props.isSuperUser && (
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.boardRev}
                    </td>
                  )}
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.firmwareVersion}
                    </td>
                  {this.props.isSuperUser && (
                    <td
                      className="last-message"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.modemType}
                    </td>
                  )}
                  {this.props.isSuperUser && (
                    <td
                      className="sim-number"
                      style={{height: 'auto', minHeight: '1.1em'}}
                    >
                      {d.simNumber}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default List
