import React, {PureComponent} from 'react'
import {dateTime as formatDateTime} from 'utils/format'
import {
  Container,
  YAxis,
  XAxis,
  Graph,
  DataLine,
  FourierDisplay,
  TimeBar,
  Line,
  Dot,
  Comment
} from 'Components/SVGGraph'
import Slider from 'Components/Form/Slider'
import findClosestPoint from 'utils/findClosestPoint'
import isNumber from 'lodash/isNumber'
import colors from 'appColors'
import Menu from 'Components/Menu'
import Modal from 'Components/Modal'
import AddComment from './Modals/AddComment'
import AddUserData from './Modals/AddUserData'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import MediaQuery from 'Components/MediaQuery'
import {UPDATE_ALARMS} from './Alarms'
import {
  GET_TAG,
  DELETE_COMMENT,
  DELETE_DATA,
  DELETE_USER_DATA,
  UPDATE_TEMP_CORRECTION,
  UPDATE_FOURIER_ENABLED,
  UPDATE_ACTIVE_ADDITIONAL
} from './queries'
import Calibration from './Modals/Calibration'
import RadarCalibrationMenu from './Modals/RadarCalibrationMenu'
import TurbineCalibrationMenu from './Modals/TurbineCalibrationMenu'
import {ButtonGroup, ButtonGroupOption} from 'Components/ButtonGroup'
import './Graph.scss'
import Tooltip from 'Components/Tooltip'

const getInitialState = (props) => {
  return {
    currentTime: null,
    expandComments: false,
    modal: false,
    menu: false,
    highWarning: props.Tag.alarms.highWarning,
    lowWarning: props.Tag.alarms.lowWarning,
    criticalHigh: props.Tag.alarms.criticalHigh,
    criticalLow: props.Tag.alarms.criticalLow,
    apiTempCorrectionCoefficient: props.Tag.temperatureCorrectionCoefficient,
    browserTempCorrectionCoefficient:
      props.Tag.temperatureCorrectionCoefficient,
    toggleScale: false,
    activeAdditional: props.Tag.activeAdditional || '',
    additionalTag: props.Tag.activeAdditional
      ? props.additionalTags.filter(
          (dataNode) => dataNode.tag === props.Tag.activeAdditional
        )[0]
      : null,
    fourierEnabled: props.Tag.fourierPlotEnabled
    // xStart: null,
    // xScale: null
  }
}

class TagGraph extends PureComponent {
  state = getInitialState(this.props)

  componentDidUpdate({Tag}) {
    if (
      Tag !== this.props.Tag ||
      Tag.alarms.lowWarning !== this.props.Tag.alarms.lowWarning ||
      Tag.alarms.highWarning !== this.props.Tag.alarms.highWarning ||
      Tag.alarms.criticalHigh !== this.props.Tag.alarms.criticalHigh ||
      Tag.alarms.criticalLow !== this.props.Tag.alarms.criticalLow
    ) {
      this.setState(getInitialState(this.props))
    }
    // if (
    //   Tag.temperatureCorrectionCoefficient !==
    //   this.props.Tag.temperatureCorrectionCoefficient
    // ) {
    //   this.setState(getInitialState(this.props))
    // }
  }

  // reportDomain = (xStart, xScale) => {
  //   this.setState({xStart: xStart, xScale: xScale})
  // }

  setDate = (timeBack) => {
    if (this.graph) {
      if (timeBack) this.graph.setDomain(Date.now() / 1000 - timeBack)
      else if (
        this.props.Tag &&
        this.props.Tag.data &&
        this.props.Tag.data.length
      )
        this.graph.setDomain(this.props.Tag.data[0][0])
    }

    if (this.fourierGraph) {
      if (timeBack) this.fourierGraph.setDomain(Date.now() / 1000 - timeBack)
      else if (
        this.props.Tag &&
        this.props.Tag.data &&
        this.props.Tag.data.length
      )
        this.fourierGraph.setDomain(this.props.Tag.data[0][0])
    }
  }
  mouseMoveHandler = ({time}) => {
    if (time !== this.state.currentTime)
      this.setState({
        currentTime: time
      })
  }

  onPointClick = (e, pointIndex) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.currentTime === null) return
    this.setState({
      menu: {
        x: e.clientX,
        y: e.clientY
      }
    })
  }

  handlePopupMenu = (graphValues) => {
    const {currentTime, x, y} = graphValues

    if (this.state.modal.type) return

    if (this.state.currentTime || currentTime) {
      this.setState({
        currentTime,
        menu: {
          x,
          y
        }
      })
    }
  }

  showWarningTooltip = (e) => {
    let warningTooltip = document.getElementById('warning-tooltip')
    warningTooltip.style.top = e.clientY + 10 + 'px'
    warningTooltip.style.left = e.clientX + 10 + 'px'
    warningTooltip.style.display = 'block'
  }

  hideWarningTooltip = (e) => {
    let warningTooltip = document.getElementById('warning-tooltip')
    warningTooltip.style.display = 'none'
  }

  showHibernationTooltip = (e) => {
    let hibernationTooltip = document.getElementById('hibernation-tooltip')
    hibernationTooltip.style.top = e.clientY + 10 + 'px'
    hibernationTooltip.style.left = e.clientX + 10 + 'px'
    hibernationTooltip.style.display = 'block'
  }

  hideHibernationTooltip = (e) => {
    let hibernationTooltip = document.getElementById('hibernation-tooltip')
    hibernationTooltip.style.display = 'none'
  }

  closeModal = () => this.setState({modal: {type: false}})
  updateAlarms = () =>
    this.props.updateAlarms({
      variables: {
        deviceID: this.props.device.id,
        tag: this.props.Tag.tag,
        low: this.state.lowWarning,
        high: this.state.highWarning,
        lowWarning: this.state.lowWarning,
        highWarning: this.state.highWarning,
        criticalLow: this.state.criticalLow,
        criticalHigh: this.state.criticalHigh
      }
    })

  updateTempCorrection = () => {
    this.props.updateTempCorrection({
      variables: {
        deviceID: this.props.device.id,
        tag: this.props.Tag.tag,
        temperatureCorrectionCoefficient: this.state
          .browserTempCorrectionCoefficient
      }
    })
  }

  render() {
    const {
      Tag,
      Tag2,
      Tag3,
      Tag4,
      Tag5,
      additionalTags,
      sf6Tag,
      device,
      me
    } = this.props

    const fourierData = Tag.fourierData || null
    const positions = [Tag2, Tag3, Tag4, Tag5, this.state.activeAdditional]
    let adjustedData = null
    if (
      this.state.browserTempCorrectionCoefficient !==
      this.state.apiTempCorrectionCoefficient
    ) {
      adjustedData = Tag.data.map((row, i) => {
        const time = row[0]
        let value = row[1]
        const temp = row[2]
        if (
          !!Tag.data[i - 1] &&
          Tag.data[i - 1][2] - temp > Tag.temperatureCorrectionThreshold
        ) {
          return [time, value]
        } else if (
          !!Tag.data[i - 2] &&
          Tag.data[i - 2][2] - temp > Tag.temperatureCorrectionThreshold
        ) {
          return [time, value]
        }
        value =
          value +
          (this.state.browserTempCorrectionCoefficient -
            this.state.apiTempCorrectionCoefficient) *
            (Tag.averageTemperature - temp)
        return [time, value]
      })
    } 

    if (!!this.props.diff) {
      this.props.Tag.ymin = 3
      this.props.Tag.ymax = 4.4

      adjustedData = Tag.data.map((row) => {
        const time = row[0]
        let value = row[1]
        if (
          value <= 4.4
        ) {
          return [time, value]
        }
        value = 4.4
        return [time, value]
      })
    }

    positions.sort(function (a, b) {
      if (a === '' && b === null) {
        return -1
      } else if (a === null && b === '') {
        return 1
      } else {
        return !b ? -1 : 1
      }
    })
    let findPosition = (inputTag) => {
      let result = null
      positions.forEach((tag, i) => {
        if (tag === inputTag) {
          result = i
        }
      })
      return result
    }
    const getAdjustedClosestPoint = (time) => {
      const index = findClosestPoint(time, adjustedData)
      if (isNumber(index)) {
        const [time, value] = adjustedData[index]
        return {time, value, index}
      } else {
        return {time: null, value: null, index: null}
      }
    }

    const {time, value} =
      adjustedData !== null
        ? getAdjustedClosestPoint(this.state.currentTime)
        : Tag.getClosestPoint(this.state.currentTime)
    const currentPoint = [time, value]
    let currentPoint2 = null
    let currentPoint3 = null
    let currentPoint4 = null
    let currentPoint5 = null
    let currentPointSf6 = null
    if (Tag2) {
      const {time, value} = Tag2.getClosestPoint(this.state.currentTime)
      currentPoint2 = [
        time,
        value,
        ((value - Tag2.ymin) * (Tag.ymax - Tag.ymin)) /
          (Tag2.ymax - Tag2.ymin) +
          Tag.ymin
      ]
    }
    if (Tag3) {
      const {time, value} = Tag3.getClosestPoint(this.state.currentTime)
      currentPoint3 = [
        time,
        value,
        ((value - Tag3.ymin) * (Tag.ymax - Tag.ymin)) /
          (Tag3.ymax - Tag3.ymin) +
          Tag.ymin
      ]
    }
    if (Tag4) {
      const {time, value} = Tag4.getClosestPoint(this.state.currentTime)
      currentPoint4 = [
        time,
        value,
        ((value - Tag4.ymin) * (Tag.ymax - Tag.ymin)) /
          (Tag4.ymax - Tag4.ymin) +
          Tag.ymin
      ]
    }
    if (Tag5) {
      const {time, value} = Tag5.getClosestPoint(this.state.currentTime)
      currentPoint5 = [
        time,
        value,
        ((value - Tag5.ymin) * (Tag.ymax - Tag.ymin)) /
          (Tag5.ymax - Tag5.ymin) +
          Tag.ymin
      ]
    }
    let additionalPoint = null
    if (!!this.state.additionalTag) {
      const {time, value} = this.state.additionalTag.getClosestPoint(
        this.state.currentTime
      )
      additionalPoint = [
        time,
        value,
        ((value - this.state.additionalTag.ymin) * (Tag.ymax - Tag.ymin)) /
          (this.state.additionalTag.ymax - this.state.additionalTag.ymin) +
          Tag.ymin
      ]
    }
    if (sf6Tag) {
      const {time, value} = sf6Tag.getClosestPoint(this.state.currentTime)
      currentPointSf6 = [
        time,
        value,
        ((value - sf6Tag.ymin) * (Tag.ymax - Tag.ymin)) /
          (sf6Tag.ymax - sf6Tag.ymin) +
          Tag.ymin
      ]
    }

    const makeCallback = (stateUpdate) => (e) => {
      e.preventDefault()
      e.stopPropagation()
      this.setState(stateUpdate)
    }
    const comments = (device.comments || []).map((c, i) => {
      const {index, value} = this.props.Tag.getClosestPoint(c.time)

      let adjustedValue
      if (adjustedData) {
        const [, value] = adjustedData[index]
        adjustedValue = value
      }

      return (
        <Comment
          point={[c.time, adjustedData ? adjustedValue : value]}
          className="clickable"
          text={this.state.expandComments ? c.comment : 'C'}
          key={'comment' + i}
          onClick={makeCallback({
            modal: {
              type: 'viewComment',
              comment: c
            }
          })}
        />
      )
    })
    const userData = (Tag.userData || []).map((d, i) => (
      <Dot
        key={'userData' + i}
        point={[d.time, d.value]}
        className="clickable"
        color={colors.green}
        radius={5}
        onClick={makeCallback({modal: {type: 'viewUserData', userData: d}})}
      />
    ))
    const calibrations = (Tag.calibrations || []).map((c, i) => {
      const {value} = this.props.Tag.getClosestPoint(c.time)
      return (
        <Dot
          key={'cal' + i}
          point={[c.time, value]}
          className="clickable"
          color={colors.red}
          radius={5}
          onClick={makeCallback({modal: {type: 'calibration', calibration: c}})}
        />
      )
    })

    // const {ymin, ymax} = getYminYmax(Tag)
    return (
      <div className="tag-graph">
        <div className="toolbar">
          <div className="group-left">
            <button
              onClick={() =>
                this.setState({expandComments: !this.state.expandComments})
              }
            >
              {this.state.expandComments
                ? 'Collapse Comments'
                : 'Expand Comments'}
            </button>
            {additionalTags !== undefined && additionalTags.length > 0 && (
              <div className={'additional-data'} style={{zIndex: 1}}>
                <select
                  onChange={async (e) => {
                    const activeAdditional = e.target.value
                    if (activeAdditional !== '') {
                      const additionalTag = additionalTags.filter(
                        (dataNode) => dataNode.tag === activeAdditional
                      )[0]
                      this.setState({
                        activeAdditional: activeAdditional,
                        additionalTag: additionalTag
                      })
                    } else {
                      this.setState({
                        activeAdditional: '',
                        additionalTag: null
                      })
                    }
                    await this.props.updateActiveAdditional({
                      variables: {
                        deviceID: this.props.device.id,
                        tag: Tag.tag,
                        activeAdditional: activeAdditional
                      }
                    })
                  }}
                  value={
                    this.state.activeAdditional
                      ? this.state.activeAdditional
                      : ''
                  }
                >
                  <option value={''} disabled>
                    Additional Sensor Data
                  </option>
                  <option value={''}>None</option>
                  {additionalTags !== undefined &&
                    additionalTags.length > 0 &&
                    additionalTags.map((tag) => (
                      <option value={tag.tag} key={tag.tag}>
                        {tag.display.yAxisLabel}
                      </option>
                    ))}
                </select>
                <Tooltip>
                  {'Plot additional data gathered by the sensor.'}
                </Tooltip>
              </div>
            )}
            {Tag.tag === 'vib' && fourierData.length > 0 && (
              <button
                onClick={async () => {
                  let enabledValue = !this.state.fourierEnabled
                  this.setState({fourierEnabled: enabledValue})
                  this.setDate(14 * 24 * 60 * 60)
                  await this.props.updateFourierEnabled({
                    variables: {
                      deviceID: this.props.device.id,
                      tag: Tag.tag,
                      fourierPlotEnabled: enabledValue
                    }
                  })
                }}
              >
                {this.state.fourierEnabled
                  ? 'Hide Fourier Plot'
                  : 'Show Fourier Plot'}
              </button>
            )}
            {(!!this.props.me.isSuperUser || !!this.props.me.isManager) &&
              Tag.temperatureCorrectionEnabled &&
              (Tag.tag === 'psr' ||
                Tag.tag === 'spsr' ||
                Tag.tag === 'dspsr' ||
                Tag.tag === 'sonr') && (
                <Slider
                  className="temperature-slider"
                  onChange={(value) => {
                    this.setState({
                      browserTempCorrectionCoefficient: parseFloat(value)
                    })
                  }}
                  min={-0.5}
                  max={0.5}
                  step={0.01}
                  value={this.state.browserTempCorrectionCoefficient || 0}
                  defaultValue={Tag.temperatureCorrectionCoefficient || 0}
                  tooltip="Adjust for temperature effects. The text box value may also be
                  set using the arrow keys."
                />
              )}
            {(!!this.props.me.isSuperUser || !!this.props.me.isManager) &&
              Tag.temperatureCorrectionEnabled &&
              (Tag.tag === 'psr' ||
                Tag.tag === 'spsr' ||
                Tag.tag === 'dspsr' ||
                Tag.tag === 'sonr') &&
              this.state.apiTempCorrectionCoefficient !==
                this.state.browserTempCorrectionCoefficient && (
                <div>
                  <Tooltip
                    iconClassName="fa fa-check"
                    left
                    color="green"
                    onClick={this.updateTempCorrection}
                  >
                    Save changes to coefficient value.
                  </Tooltip>
                  <Tooltip
                    iconClassName="fa fa-times"
                    color="red"
                    onClick={() => {
                      this.setState({
                        browserTempCorrectionCoefficient: this.state
                          .apiTempCorrectionCoefficient
                      })
                    }}
                  >
                    Cancel changes to coefficient value.
                  </Tooltip>
                </div>
              )}
          </div>
          {!!currentPoint && !!currentPoint[0] ? (
            <div className="readout">
              <span className="time">{formatDateTime(currentPoint[0])}</span>
              <span className="reading">
                {Tag.display.render(currentPoint[1])}
              </span>
              {currentPointSf6 && (
                <span className="reading sf6">
                  {sf6Tag.display.render(currentPointSf6[1]) + ' (20\u00B0C)'}
                </span>
              )}
              {additionalPoint && this.state.additionalTag && (
                <span className="reading secondary" style={{color: 'orange'}}>
                  {this.state.additionalTag.display.render(additionalPoint[1])}
                </span>
              )}
              {currentPoint5 && (
                <span className="reading fifth">
                  {Tag5.display.render(currentPoint5[1])}
                </span>
              )}
              {currentPoint4 && (
                <span className="reading fourth">
                  {Tag4.display.render(currentPoint4[1])}
                </span>
              )}
              {currentPoint3 && (
                <span className="reading tertiary">
                  {Tag3.display.render(currentPoint3[1])}
                </span>
              )}
              {currentPoint2 && (
                <span className="reading secondary">
                  {Tag2.display.render(currentPoint2[1])}
                </span>
              )}
            </div>
          ) : (
            <div className="readout" />
          )}
          <div className="group-right">
            <ButtonGroup>
              <ButtonGroupOption onClick={() => this.setDate(24 * 60 * 60)}>
                1d
              </ButtonGroupOption>
              <ButtonGroupOption onClick={() => this.setDate(7 * 24 * 60 * 60)}>
                1w
              </ButtonGroupOption>
              <ButtonGroupOption
                onClick={() => this.setDate(14 * 24 * 60 * 60)}
              >
                2w
              </ButtonGroupOption>
              <ButtonGroupOption
                onClick={() => this.setDate(31 * 24 * 60 * 60)}
              >
                1m
              </ButtonGroupOption>
              <ButtonGroupOption
                onClick={() => this.setDate(365 * 24 * 60 * 60)}
              >
                1yr
              </ButtonGroupOption>
              <ButtonGroupOption onClick={() => this.setDate()}>
                all
              </ButtonGroupOption>
            </ButtonGroup>
          </div>
        </div>

        <div className="tag-graph-body">
          {fourierData && fourierData.length > 0 && this.state.fourierEnabled && (
            <Container
              id="fourier-plot"
              fourierPlot={true}
              childRef={(ref) => (this.fourierContainer = ref)}
              connectedLeftOffset={
                this.state.connectedLeftOffset
                  ? this.state.connectedLeftOffset
                  : null
              }
              connectedRightOffset={
                this.state.connectedRightOffset
                  ? this.state.connectedRightOffset
                  : null
              }
              twinContainer={null}
              fourierMax={fourierData[0][12]}
            >
              <YAxis label={'Frequency (Hz)'} numTicks={8} />
              <XAxis label="Time" />
              <Graph
                childRef={(ref) => (this.fourierGraph = ref)}
                timeLine={true}
                currentTime={currentPoint ? currentPoint[0] : null}
                onMouseMove={this.mouseMoveHandler}
                ymin={0}
                ymax={800}
                connectedGraph={this.graph ? this.graph : null}
              >
                <FourierDisplay
                  data={fourierData}
                  maxGap={12 * 60 * 60}
                  breakdownScale={0.02}
                  color={colors.orange}
                  width={2}
                  opacity={0.8}
                />
              </Graph>
            </Container>
          )}
          {fourierData &&
            fourierData.length > 0 &&
            this.state.fourierEnabled && <div style={{height: '5%'}} />}
          <Container
            fourierEnabled={this.state.fourierEnabled}
            childRef={(ref) => (this.mainContainer = ref)}
            twinContainer={this.fourierContainer ? this.fourierContainer : null}
            leftOffsetRef={(ref) => {
              this.connectedLeftOffset = ref
              this.setState({connectedLeftOffset: ref})
            }}
            rightOffsetRef={(ref) => {
              this.connectedRightOffset = ref
              this.setState({connectedRightOffset: ref})
            }}
          >
            <YAxis label={Tag.display.yAxisLabel} />
            {Tag2 && (
              <YAxis
                secondary
                label={Tag2.display.yAxisLabel}
                map={(r) =>
                  ((r - Tag.ymin) * (Tag2.ymax - Tag2.ymin)) /
                    (Tag.ymax - Tag.ymin) +
                  Tag2.ymin
                }
                color={colors.blue}
              />
            )}
            {Tag3 && (
              <YAxis
                secondary={findPosition(Tag3) === 0}
                tertiary={findPosition(Tag3) === 1}
                label={Tag3.display.yAxisLabel}
                map={(r) =>
                  ((r - Tag.ymin) * (Tag3.ymax - Tag3.ymin)) /
                    (Tag.ymax - Tag.ymin) +
                  Tag3.ymin
                }
                color={colors.seaFoam}
              />
            )}
            {Tag4 && (
              <YAxis
                secondary={findPosition(Tag4) === 0}
                tertiary={findPosition(Tag4) === 1}
                fourth={findPosition(Tag4) === 2}
                label={Tag4.display.yAxisLabel}
                map={(r) =>
                  ((r - Tag.ymin) * (Tag4.ymax - Tag4.ymin)) /
                    (Tag.ymax - Tag.ymin) +
                  Tag4.ymin
                }
                color={colors.ochre}
              />
            )}
            {Tag5 && (
              <YAxis
                secondary={findPosition(Tag5) === 0}
                tertiary={findPosition(Tag5) === 1}
                fourth={findPosition(Tag5) === 2}
                fifth={findPosition(Tag5) === 3}
                label={Tag5.display.yAxisLabel}
                map={(r) =>
                  ((r - Tag.ymin) * (Tag5.ymax - Tag5.ymin)) /
                    (Tag.ymax - Tag.ymin) +
                  Tag5.ymin
                }
                color={colors.magenta}
              />
            )}
            {this.state.additionalTag && (
              <YAxis
                secondary={findPosition(this.state.activeAdditional) === 0}
                tertiary={findPosition(this.state.activeAdditional) === 1}
                fourth={findPosition(this.state.activeAdditional) === 2}
                fifth={findPosition(this.state.activeAdditional) === 3}
                sixth={findPosition(this.state.activeAdditional) === 4}
                label={this.state.additionalTag.display.yAxisLabel}
                map={(r) =>
                  ((r - Tag.ymin) *
                    (this.state.additionalTag.ymax -
                      this.state.additionalTag.ymin)) /
                    (Tag.ymax - Tag.ymin) +
                  this.state.additionalTag.ymin
                }
                color={colors.orange}
              />
            )}
            <XAxis label="Time" />

            <Graph
              childRef={(ref) => (this.graph = ref)}
              onMouseMove={this.mouseMoveHandler}
              onClick={this.onPointClick}
              handlePopupMenu={this.handlePopupMenu}
              ymin={Tag.ymin}
              ymax={Tag.ymax}
              connectedGraph={
                this.fourierGraph && this.state.fourierEnabled
                  ? this.fourierGraph
                  : null
              }
            >
              <DataLine
                data={adjustedData !== null ? adjustedData : Tag.data}
                maxGap={12 * 60 * 60}
                breakdownScale={0.02}
                width={2}
              />
              {this.state.additionalTag && (
                <DataLine
                  data={
                    this.state.additionalTag.data &&
                    this.state.additionalTag.data.map((r) => [
                      r[0],
                      r[1] === null
                        ? null
                        : ((r[1] - this.state.additionalTag.ymin) *
                            (Tag.ymax - Tag.ymin)) /
                            (this.state.additionalTag.ymax -
                              this.state.additionalTag.ymin) +
                          Tag.ymin,
                      r[1]
                    ])
                  }
                  maxGap={12 * 60 * 60}
                  breakdownScale={0.02}
                  color={colors.orange}
                  width={2}
                  opacity={0.8}
                />
              )}
              {Tag2 && (
                <DataLine
                  data={
                    Tag2.data &&
                    Tag2.data.map((r) => [
                      r[0],
                      r[1] === null
                        ? null
                        : ((r[1] - Tag2.ymin) * (Tag.ymax - Tag.ymin)) /
                            (Tag2.ymax - Tag2.ymin) +
                          Tag.ymin,
                      r[1]
                    ])
                  }
                  maxGap={12 * 60 * 60}
                  breakdownScale={0.02}
                  color={colors.blue}
                  width={2}
                  opacity={0.8}
                />
              )}
              {Tag3 && (
                <DataLine
                  data={
                    Tag3.data &&
                    Tag3.data.map((r) => [
                      r[0],
                      r[1] === null
                        ? null
                        : ((r[1] - Tag3.ymin) * (Tag.ymax - Tag.ymin)) /
                            (Tag3.ymax - Tag3.ymin) +
                          Tag.ymin,
                      r[1]
                    ])
                  }
                  maxGap={12 * 60 * 60}
                  breakdownScale={0.02}
                  color={colors.seaFoam}
                  width={2}
                  opacity={0.8}
                />
              )}
              {Tag4 && (
                <DataLine
                  data={
                    Tag4.data &&
                    Tag4.data.map((r) => [
                      r[0],
                      r[1] === null
                        ? null
                        : ((r[1] - Tag4.ymin) * (Tag.ymax - Tag.ymin)) /
                            (Tag4.ymax - Tag4.ymin) +
                          Tag.ymin,
                      r[1]
                    ])
                  }
                  maxGap={12 * 60 * 60}
                  breakdownScale={0.02}
                  color={colors.ochre}
                  width={2}
                  opacity={0.8}
                />
              )}
              {Tag5 && (
                <DataLine
                  data={
                    Tag5.data &&
                    Tag5.data.map((r) => [
                      r[0],
                      r[1] === null
                        ? null
                        : ((r[1] - Tag5.ymin) * (Tag.ymax - Tag.ymin)) /
                            (Tag5.ymax - Tag5.ymin) +
                          Tag.ymin,
                      r[1]
                    ])
                  }
                  maxGap={12 * 60 * 60}
                  breakdownScale={0.02}
                  color={colors.magenta}
                  width={2}
                  opacity={0.8}
                />
              )}
              {sf6Tag && (
                <DataLine
                  data={
                    sf6Tag.data &&
                    sf6Tag.data.map((r) => [
                      r[0],
                      r[1] === null
                        ? null
                        : ((r[1] - sf6Tag.ymin) * (Tag.ymax - Tag.ymin)) /
                            (sf6Tag.ymax - sf6Tag.ymin) +
                          Tag.ymin,
                      r[1]
                    ])
                  }
                  maxGap={12 * 60 * 60}
                  breakdownScale={0.02}
                  color={colors.brightGreen}
                  width={2}
                  opacity={0.8}
                />
              )}
              {!!currentPoint && (
                <Dot point={currentPoint} color={colors.yellow} />
              )}
              {!!currentPoint2 && (
                <Dot
                  point={[currentPoint2[0], currentPoint2[2]]}
                  color={colors.blue}
                />
              )}
              {!!currentPoint3 && (
                <Dot
                  point={[currentPoint3[0], currentPoint3[2]]}
                  color={colors.seaFoam}
                />
              )}
              {!!currentPoint4 && (
                <Dot
                  point={[currentPoint4[0], currentPoint4[2]]}
                  color={colors.ochre}
                />
              )}
              {!!currentPoint5 && (
                <Dot
                  point={[currentPoint5[0], currentPoint5[2]]}
                  color={colors.magenta}
                />
              )}
              {!!currentPointSf6 && (
                <Dot
                  point={[currentPointSf6[0], currentPointSf6[2]]}
                  color={colors.brightGreen}
                />
              )}
              {!!additionalPoint && (
                <Dot
                  point={[additionalPoint[0], additionalPoint[2]]}
                  color={colors.orange}
                />
              )}
              {Tag.tag === '_v' && (
                <Line
                  y={device.hibernationVoltage / 1000}
                  color={colors.red}
                  style={{opacity: 0.5}}
                  width={3}
                  onMouseOver={this.showHibernationTooltip}
                  onMouseOut={this.hideHibernationTooltip}
                />
              )}

              {Tag.tag === '_v' && (
                <Line
                  y={device.slowdownVoltage4 / 1000}
                  color={colors.yellow}
                  style={{opacity: 0.5}}
                  width={3}
                  onMouseOver={this.showWarningTooltip}
                  onMouseOut={this.hideWarningTooltip}
                />
              )}
              {this.state.lowWarning !== null && (
                <Line
                  y={this.state.lowWarning}
                  color={colors.yellow}
                  style={{opacity: 0.5}}
                  // dashed={'5'}
                  width={3}
                  onDrag={
                    me.canEdit
                      ? (e) =>
                          this.setState({
                            lowWarning: this.state.lowWarning + e.deltaY
                          })
                      : undefined
                  }
                  onDragEnd={this.updateAlarms}
                  className="draggable"
                />
              )}
              {this.state.highWarning !== null && (
                <Line
                  y={this.state.highWarning}
                  color={colors.yellow}
                  style={{opacity: 0.5}}
                  // dashed={'5'}
                  width={3}
                  onDrag={
                    me.canEdit
                      ? (e) =>
                          this.setState({
                            highWarning: this.state.highWarning + e.deltaY
                          })
                      : undefined
                  }
                  onDragEnd={this.updateAlarms}
                  className="draggable"
                />
              )}
              {this.state.criticalLow !== null && (
                <Line
                  y={this.state.criticalLow}
                  color={colors.red}
                  style={{opacity: 0.5}}
                  width={3}
                  onDrag={
                    me.canEdit
                      ? (e) =>
                          this.setState({
                            criticalLow: this.state.criticalLow + e.deltaY
                          })
                      : undefined
                  }
                  onDragEnd={this.updateAlarms}
                  className="draggable"
                />
              )}
              {this.state.criticalHigh !== null && (
                <Line
                  y={this.state.criticalHigh}
                  color={colors.red}
                  style={{opacity: 0.5}}
                  width={3}
                  onDrag={
                    me.canEdit
                      ? (e) =>
                          this.setState({
                            criticalHigh: this.state.criticalHigh + e.deltaY
                          })
                      : undefined
                  }
                  onDragEnd={this.updateAlarms}
                  className="draggable"
                />
              )}
              {comments}
              {userData}
              {calibrations}
            </Graph>
            <MediaQuery query="(min-width: 1000px)">
              <TimeBar
                data={adjustedData !== null ? adjustedData : Tag.data}
                height={40}
                maxGap={12 * 60 * 60}
                connectedGraph={
                  this.fourierGraph && this.state.fourierEnabled
                    ? this.fourierGraph
                    : null
                }
              />
            </MediaQuery>
          </Container>
          {Tag.tag === '_v' && (
            <div id={'hibernation-tooltip'}>
              When the battery reaches this level, the device will enter a
              hibernation state.
            </div>
          )}
          {Tag.tag === '_v' && (
            <div id={'warning-tooltip'}>
              An alert will be given at this level to notify that the device has
              low battery.
            </div>
          )}
          {!!this.state.menu && (
            <Menu
              x={this.state.menu.x}
              y={this.state.menu.y}
              onClose={() => this.setState({menu: false})}
            >
              <Menu.Item
                onClick={() => this.setState({modal: {type: 'addComment'}})}
              >
                Add Comment
              </Menu.Item>
              <Menu.Item
                onClick={() => this.setState({modal: {type: 'addUserData'}})}
              >
                Add User Data
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  if (this.props.me.isDemo) {
                    this.setState({modal: {type: 'calibrationDemoError'}})
                  } else {
                    this.setState({modal: {type: 'calibration'}})
                  }
                }}
              >
                Add Calibration
              </Menu.Item>
              {this.props.me.isSuperUser && (
                <Menu.Item
                  onClick={async () =>
                    await this.props.deleteData({
                      variables: {
                        deviceID: this.props.device.id,
                        tag: Tag.tag,
                        time: currentPoint[0]
                      },
                      refetchQueries: [
                        {
                          query: GET_TAG,
                          variables: {deviceID: device.id, tag: Tag.tag}
                        }
                      ]
                    })
                  }
                >
                  Delete Data Point
                </Menu.Item>
              )}
            </Menu>
          )}
          {this.state.modal.type === 'addComment' && (
            <AddComment
              Tag={Tag}
              time={currentPoint && currentPoint[0]}
              close={this.closeModal}
              onCancel={this.closeModal}
            />
          )}
          {this.state.modal.type === 'addUserData' && (
            <AddUserData
              Tag={Tag}
              time={currentPoint && currentPoint[0]}
              close={this.closeModal}
            />
          )}
          {this.state.modal.type === 'viewUserData' && (
            <Modal onClose={this.closeModal}>
              <Modal.Title>View User Data</Modal.Title>
              <Modal.Body>
                <p>
                  <strong>Date: </strong>
                  {formatDateTime(this.state.modal.userData.time)}
                </p>
                <p>
                  <strong>Value: </strong>
                  {this.state.modal.userData.value}
                </p>
              </Modal.Body>
              <Modal.Footer>
                {(me.canEdit || me.isDemo) && (
                  <button
                    onClick={() => {
                      this.props.deleteUserData({
                        variables: {
                          deviceID: this.props.Tag.deviceID,
                          tag: this.props.Tag.tag,
                          time: this.state.modal.userData.time
                        }
                      })
                      this.closeModal()
                    }}
                  >
                    Delete User Data Point
                  </button>
                )}
                <button className="primary" onClick={this.closeModal}>
                  Close
                </button>
              </Modal.Footer>
            </Modal>
          )}
          {this.state.modal.type === 'viewComment' && (
            <Modal onClose={this.closeModal}>
              <Modal.Title>View Comment</Modal.Title>
              <Modal.Body>
                <p>
                  <strong>Date: </strong>
                  {formatDateTime(this.state.modal.comment.time)}
                </p>
                <p>
                  <strong>Comment: </strong>
                  {this.state.modal.comment &&
                    this.state.modal.comment.comment}{' '}
                </p>
              </Modal.Body>
              <Modal.Footer>
                {(me.canEdit || me.isDemo) && (
                  <button
                    onClick={() => {
                      this.props.deleteComment({
                        variables: {id: this.state.modal.comment.id}
                      })
                      this.closeModal()
                    }}
                  >
                    Delete Comment
                  </button>
                )}
                <button className="primary" onClick={this.closeModal}>
                  Close
                </button>
              </Modal.Footer>
            </Modal>
          )}
          {this.state.modal.type === 'calibration' && (
            <Calibration
              calibration={this.state.modal.calibration}
              Tag={Tag}
              time={currentPoint && currentPoint[0]}
              close={this.closeModal}
              onCancel={this.closeModal}
            />
          )}
          {this.state.modal.type === 'calibrationDemoError' && (
            <Modal onClose={this.closeModal}>
              <Modal.Title>Action Restricted for Demo Users</Modal.Title>
              <Modal.Body>
                <p>Demo users are unable to calibrate sensors.</p>
              </Modal.Body>
              <Modal.Footer>
                <button className="primary" onClick={this.closeModal}>
                  Close
                </button>
              </Modal.Footer>
            </Modal>
          )}
          {Tag.tag === 'dist' && Tag.calibrations.length === 0 ? (
            <RadarCalibrationMenu Tag={Tag} />
          ) : null}
          {Tag.tag === 'sonr' && Tag.calibrations.length === 0 ? (
            <RadarCalibrationMenu Tag={Tag} />
          ) : null}
          {Tag.tag === 'puls' && Tag.calibrations.length === 0 ? (
            <TurbineCalibrationMenu Tag={Tag} />
          ) : null}
          {Tag.tag === 'pult' && Tag.calibrations.length === 0 ? (
            <TurbineCalibrationMenu Tag={Tag} />
          ) : null}
        </div>
      </div>
    )
  }
}

// const getYminYmax = memoize(({ymin, ymax, data}) => {
//   if (isNumber(ymin) && isNumber(ymax)) {
//     return {ymin, ymax}
//   } else if (data.length) {
//     const {min, max} = data.reduce(
//       ({min, max}, [time, value]) => ({
//         min: value < min ? value : min,
//         max: value > max ? value : max
//       }),
//       {min: data[0][1], max: data[0][1]}
//     )
//     const range = max - min

//     return {
//       ymin: isNumber(ymin) ? ymin : min - 0.5 * range,
//       ymax: isNumber(ymax) ? ymax : max + 0.5 * range
//     }
//   } else {
//     return {ymin: 0, ymax: 10}
//   }
// })

export default compose(
  graphql(DELETE_DATA, {name: 'deleteData'}),
  graphql(DELETE_COMMENT, {name: 'deleteComment'}),
  graphql(DELETE_USER_DATA, {name: 'deleteUserData'}),
  graphql(UPDATE_ALARMS, {name: 'updateAlarms'}),
  graphql(UPDATE_TEMP_CORRECTION, {name: 'updateTempCorrection'}),
  graphql(UPDATE_FOURIER_ENABLED, {name: 'updateFourierEnabled'}),
  graphql(UPDATE_ACTIVE_ADDITIONAL, {name: 'updateActiveAdditional'})
)(TagGraph)
