import React from 'react'
import DeviceCard from 'Components/DeviceCard'
import {Query} from 'react-apollo'
import {GET_DEVICES_NEW} from 'queries'
import Loading from 'Components/Loading'

const NewDevices = () => (
  <Query query={GET_DEVICES_NEW}>
    {({error, loading, data}) => {
      if (error) return 'error'
      if (loading) return <Loading />

      return (
        <div className="device-card-container">
          <div className="device-card-row">
            <div className="title">New Devices</div>
            {(data.devicesNew || [])
              .map(d => (
                <DeviceCard device={d} key={d.device_id} />
              ))}
          </div>
        </div>
      )
    }}
  </Query>
)

export default NewDevices
