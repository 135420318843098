import React from 'react'
import {ReactComponent as NoAlarms} from 'assets/noAlarms.svg'
import DeviceCard from 'Components/DeviceCard'
import isDelayed from 'utils/isDelayed'
import {Query} from 'react-apollo'
import {GET_DEVICES_OFFLINE} from 'queries'
import Loading from 'Components/Loading'

const OfflineDevices = ({allDevices}) => (
  <Query query={GET_DEVICES_OFFLINE}>
    {({error, loading, data}) => {
      if (error) return 'error'
      if (loading) return <Loading />

      const offlineDevices = (data.devicesOffline || [])
        .filter(isDelayed)
      if (offlineDevices.length === 0)
        return (
          <div className="no-alarms">
            <div>
              <NoAlarms />
              <h1>No offline devices</h1>
            </div>
          </div>
        )
      else
        return (
          <div className="device-card-container">
            <div className=" device-card-row">
              <div className="title">Offline Devices</div>
              {offlineDevices.map((d) => (
                <DeviceCard device={d} key={d.id} />
              ))}
            </div>
          </div>
        )
    }}
  </Query>
)

export default OfflineDevices
