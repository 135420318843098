import React from 'react'
import Modal from 'Components/Modal'
import {dateTime as formatDateTime} from 'utils/format'
import {graphql} from 'react-apollo'
import {gql} from 'graphql.macro'

const ADD_COMMENT = gql`
  mutation ADD_COMMENT($deviceID: Int!, $time: Int!, $comment: String!) {
    addComment(deviceID: $deviceID, time: $time, comment: $comment) {
      device {
        id
        comments {
          id
          time
          comment
        }
      }
    }
  }
`

class AddComment extends React.Component {
  constructor() {
    super()
    this.state = {
      message: ''
    }
  }
  render() {
    const footer = (
      <span>
        <span className="button" onClick={this.props.close}>
          Cancel
        </span>
        <span
          className="button primary"
          onClick={() => {
            this.props.addComment({
              variables: {
                deviceID: this.props.Tag.deviceID,
                time: this.props.time,
                comment: this.state.message
              }
            })
            this.props.close()
          }}
        >
          Save
        </span>
      </span>
    )
    return (
      <Modal title="Add Comment" footer={footer} onClose={this.props.close}>
        <div style={{paddingBottom: '1em'}}>
          <strong>Time: </strong>
          {formatDateTime(this.props.time)}
        </div>
        <textarea
          placeholder="Comment text"
          value={this.state.message}
          onChange={e => this.setState({message: e.target.value})}
        />
      </Modal>
    )
  }
}

export default graphql(ADD_COMMENT, {name: 'addComment'})(AddComment)
