import React, {Component} from 'react'
import {Query, Mutation, withApollo} from 'react-apollo'
import {gql} from 'graphql.macro'
import {GET_ME, GET_NOTIFICATION_SETTINGS} from 'queries'
import Loading from 'Components/Loading'
import {name as formatName} from 'utils/format'
import {Input, InputTable} from 'Components/Form'
import Modal from 'Components/Modal'
import LoginAs from './LoginAs'
import * as compose from 'lodash/flowRight'
import {connect} from 'react-redux'
import {logout} from 'store/actions'
import downloadCSV from 'utils/downloadCSV'
import ago from 'utils/ago'
// import useWindowDimensions from 'utils/useWindowDimensions'

import './index.scss'

const GET_API_KEYS = gql`
  query GET_API_KEYS {
    me {
      id
      apiKeys
    }
  }
`
const ADD_API_KEY = gql`
  mutation ADD_API_KEY {
    addAPIKey {
      apiKey
      me {
        id
        apiKeys
      }
    }
  }
`
const DELETE_API_KEY = gql`
  mutation DELETE_API_KEY($key: String!) {
    deleteAPIKey(key: $key) {
      me {
        id
        apiKeys
      }
    }
  }
`

const UPDATE_NOTIFICATIONS = gql`
  mutation UPDATE_NOTIFICATIONS($notifications: String!) {
    updateNotifications(notifications: $notifications) {
      me {
        id
        notificationSettings
      }
    }
  }
`

const UPDATE_ME = gql`
  mutation UPDATE_ME(
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $countryCode: String
    $email: String
    $viewInactiveCompanies: Boolean
  ) {
    updateMe(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      countryCode: $countryCode
      email: $email
      viewInactiveCompanies: $viewInactiveCompanies
    ) {
      me {
        id
        firstName
        lastName
        email
        phoneNumber
        countryCode
        viewInactiveCompanies
      }
    }
  }
`

const GET_DIRECTORY_DEVICES = gql`
  query GET_DIRECTORY_DEVICES {
    directoryDevices {
      id
      userAssignedID
      name
      path
      isActive
      longitude
      latitude
      latestReading
      lastTimeMessageReceived
      batteryLevel
      displayUnits
      defaultTag
      companyID
      firmwareVersion
      company {
        name
      }
    }
    me {
      id
      firstName
    }
  }
`
class Account extends Component {
  state = {
    modal: false,
    showAPIKeys: false,
    receivedAPIKey: null,
    firstName: null,
    lastName: null,
    phoneNumber: null,
    countryCode: null,
    email: null,
    showNotifications: false,
    showAdminOptions: false,
    showCSV: false,
    viewInactiveCompanies: null,
    notifications: {
      startup: null,
      shutdown: null,
      failure: null,
      slowdown: null,
      tardy: null,
      hibernating: null
    }
  }
  closeModal = () => {
    this.setState({modal: false})
  }

  handleDownload = (data) => {
    const rows = data.directoryDevices.map((d) => [
      d.id ? d.id : 'N/A',
      d.userAssignedID ? d.userAssignedID : 'N/A',
      (!!d.path && !!d.name) ? d.path + ' : '+ d.name : (d.path && !d.name) ? d.path + ' : NO NAME': (!d.path && d.name) ? d.name : 'N/A',
      d.defaultTag ? d.defaultTag : 'N/A',
      d.latestReading ? d.latestReading : 'N/A',
      d.displayUnits ? d.displayUnits : 'default',
      d.latitude ? d.latitude + ', ' + d.longitude : 'N/A',
      (!!d.company && !!d.company.name) ? d.company.name : 'N/A',
      ago(d.lastTimeMessageReceived) ? ago(d.lastTimeMessageReceived) : 'N/A',
      d.batteryLevel ? d.batteryLevel + 'V': 'N/A',
      d.firmwareVersion ? d.firmwareVersion : 'N/A',
      d.isActive ? 'Yes' : 'No'
    ])

    downloadCSV(data.me.firstName + '\'s Directory Report', rows, [
      'Device ID',
      'User Assigned ID',
      'Name',
      'Type',
      'Latest Reading',
      'Units',
      'Coordinates',
      'Company',
      'Last Message',
      'Battery Level',
      'Firmware',
      'Active'
    ])
  }

  render = () => (
    <div className="account">
      <Query query={GET_ME}>
        {({loading, error, data}) => {
          if (error) return 'error'
          if (loading) return <Loading />
          const {
            firstName,
            lastName,
            phoneNumber,
            countryCode,
            email,
            company,
            isSuperUser,
            viewInactiveCompanies
          } = data.me
          return (
            <div className="row">
              <div className="left">
                <div>
                  <h1>
                    {formatName({firstName, lastName}, {lastFirst: false})}
                  </h1>
                  <div className="company-name">
                    {company ? company.name : 'N/A'}
                  </div>
                  <div className="divider" />
                </div>
                <div>
                  <button
                    onClick={() => this.setState({modal: 'confirmLogout'})}
                  >
                    Logout
                  </button>{' '}
                  {isSuperUser && (
                    <button onClick={() => this.setState({modal: 'loginAs'})}>
                      Login As
                    </button>
                  )}
                </div>
              </div>
              <div className="right">
                {this.state.editing ? (
                  <div className="buttons">
                    <button onClick={() => this.setState({editing: false})}>
                      Cancel
                    </button>
                    <Mutation mutation={UPDATE_ME}>
                      {(updateMe) => (
                        <button
                          className="primary"
                          onClick={() => {
                            updateMe({
                              variables: {
                                firstName: this.state.firstName,
                                lastName: this.state.lastName,
                                phoneNumber: this.state.phoneNumber,
                                countryCode: this.state.countryCode,
                                email: this.state.email,
                                viewInactiveCompanies:
                                  this.state.viewInactiveCompanies
                              }
                            })
                            this.setState({editing: false})
                          }}
                        >
                          Save
                        </button>
                      )}
                    </Mutation>
                  </div>
                ) : (
                  <div className="buttons">
                    <button
                      onClick={() =>
                        this.setState({
                          editing: true,
                          firstName,
                          lastName,
                          email,
                          phoneNumber,
                          countryCode
                        })
                      }
                    >
                      Edit
                    </button>
                  </div>
                )}
                <InputTable>
                  <Input
                    type="string"
                    label="First Name"
                    onChange={(e) => this.setState({firstName: e.target.value})}
                    value={
                      this.state.editing ? this.state.firstName : firstName
                    }
                    static={!this.state.editing}
                  />
                  <Input
                    type="string"
                    label="Last Name"
                    onChange={(e) => this.setState({lastName: e.target.value})}
                    value={this.state.editing ? this.state.lastName : lastName}
                    static={!this.state.editing}
                  />
                  <Input
                    type="string"
                    label="Email"
                    onChange={(e) => this.setState({email: e.target.value})}
                    value={this.state.editing ? this.state.email : email}
                    static={!this.state.editing}
                  />
                  <Input
                    type="phone"
                    label="Phone Number"
                    onChange={(e) =>
                      this.setState({phoneNumber: e.target.value})
                    }
                    value={
                      this.state.editing ? this.state.phoneNumber : phoneNumber
                    }
                    static={!this.state.editing}
                  />
                  <Input
                    type="select"
                    options={[
                      {label: 'United States (+1)', value: '+1'},
                      {label: 'United Kingdom (+44)', value: '+44'},
                      {label: 'Sweden (+46)', value: '+46'},
                      {label: 'Singapore (+65)', value: '+65'},
                      {label: 'Thailand (+66)', value: '+66'},
                    ]}
                    label="Country Code"
                    static={!this.state.editing}
                    onChange={(e) =>
                      this.setState({
                        countryCode: e.target.value
                      })
                    }
                    value={
                      this.state.editing ? this.state.countryCode : countryCode
                    }
                  />
                  {isSuperUser && (
                    <div style={{marginTop: '2em'}}>
                      Admin Options
                      <div style={{marginTop: '0.5em'}}>
                        <Input
                          type="checkbox"
                          label="View Inactive Companies"
                          value={
                            this.state.viewInactiveCompanies !== null
                              ? this.state.viewInactiveCompanies
                              : viewInactiveCompanies
                          }
                          static={!this.state.editing}
                          onChange={() =>
                            this.setState({
                              viewInactiveCompanies:
                                this.state.viewInactiveCompanies !== null
                                  ? !this.state.viewInactiveCompanies
                                  : !viewInactiveCompanies
                            })
                          }
                        ></Input>
                      </div>
                    </div>
                  )}
                </InputTable>
              </div>
            </div>
          )
        }}
      </Query>
      <div className="api-keys">
        {this.state.showAPIKeys ? (
          <span
            className="toggle clickable"
            onClick={() => this.setState({showAPIKeys: false})}
          >
            <i className="fa fa-caret-down" /> Hide API Keys (Advanced)
          </span>
        ) : (
          <span
            className="toggle clickable"
            onClick={() => this.setState({showAPIKeys: true})}
          >
            <i className="fa fa-caret-right" /> Show API Keys (Advanced)
          </span>
        )}
        {this.state.showAPIKeys && (
          <Query query={GET_API_KEYS}>
            {({loading, error, data}) => {
              if (error) return 'error'
              if (loading) return <Loading />
              const {apiKeys} = data.me
              return (
                <div className="content">
                  <p>
                    The Sensorfield external API is used for accessing data from
                    your devices from an external service. Generate an API key
                    below for accessing data. Your API keys have the same
                    permissions as your user account. Refer to the{' '}
                    <a
                      href="https://api.sensorfield.com/0.4/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      API documetation
                    </a>{' '}
                    on how to access your data.
                  </p>
                  <table>
                    <thead>
                      <tr>
                        <th>Key Prefix</th>
                        <th>
                          <Mutation mutation={ADD_API_KEY}>
                            {(addAPIKey) => (
                              <button
                                onClick={async () => {
                                  const res = await addAPIKey()
                                  this.setState({
                                    receivedAPIKey: res.data.addAPIKey.apiKey,
                                    modal: 'showAPIKey'
                                  })
                                }}
                              >
                                <i className="fa fa-plus" /> Add API Key
                              </button>
                            )}
                          </Mutation>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {apiKeys.map((k, i) => (
                        <tr key={i}>
                          <td>{k}</td>
                          <td>
                            <Mutation mutation={DELETE_API_KEY}>
                              {(deleteAPIKey) => (
                                <button
                                  onClick={() =>
                                    deleteAPIKey({variables: {key: k}})
                                  }
                                >
                                  <i className="fa fa-trash" /> Delete
                                </button>
                              )}
                            </Mutation>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            }}
          </Query>
        )}
      </div>
      <div className="notifications-menu">
        {this.state.showNotifications ? (
          <span
            className="toggle clickable"
            onClick={() => this.setState({showNotifications: false})}
          >
            <i className="fa fa-caret-down" /> Hide Notification Settings
          </span>
        ) : (
          <span
            className="toggle clickable"
            onClick={() => this.setState({showNotifications: true})}
          >
            <i className="fa fa-caret-right" /> Show Notification Settings
          </span>
        )}
        {this.state.showNotifications && (
          <Query query={GET_NOTIFICATION_SETTINGS}>
            {({loading, error, data}) => {
              if (error) return 'error'
              if (loading) return <Loading />
              const notificationSettings = JSON.parse(
                data.me.notificationSettings
              )
              return (
                <div className="content">
                  <p>
                    Devices can send automatic notifications to you based on
                    sensor status. Notifications will be sent for all devices
                    you have access to. Notifications are sent via SMS to the
                    phone number registered with your account.
                  </p>
                  {this.state.editNotifications ? (
                    <div className="buttons">
                      <button
                        onClick={() =>
                          this.setState({
                            editNotifications: false,
                            notifications: {
                              startup: null,
                              shutdown: null,
                              failure: null,
                              slowdown: null,
                              tardy: null,
                              hibernating: null
                            }
                          })
                        }
                      >
                        Cancel
                      </button>
                      <Mutation mutation={UPDATE_NOTIFICATIONS}>
                        {(updateNotifications) => (
                          <button
                            className="primary"
                            onClick={async () => {
                              await updateNotifications({
                                variables: {
                                  notifications: JSON.stringify(
                                    this.state.notifications
                                  )
                                }
                              })
                              this.setState({editNotifications: false})
                            }}
                          >
                            Save
                          </button>
                        )}
                      </Mutation>
                    </div>
                  ) : (
                    <div className="buttons">
                      <button
                        onClick={() =>
                          this.setState({
                            editNotifications: true,
                            notifications: {
                              startup: notificationSettings.startup,
                              shutdown: notificationSettings.shutdown,
                              failure: notificationSettings.failure,
                              slowdown: notificationSettings.slowdown,
                              tardy: notificationSettings.tardy,
                              hibernating: notificationSettings.hibernating
                            }
                          })
                        }
                      >
                        Edit
                      </button>
                    </div>
                  )}
                  <div className="notifications box">
                    <div className="body">
                      <ul>
                        <li
                          key={'startup'}
                          className={
                            'notifications-directory ' +
                            (this.state.notifications.startup !== null
                              ? this.state.notifications.startup
                                ? 'selected'
                                : ''
                              : notificationSettings.startup
                              ? 'selected'
                              : '')
                          }
                        >
                          {!this.state.editNotifications ? (
                            this.state.notifications.startup !== null ? (
                              this.state.notifications.startup ? (
                                <i
                                  className="fa fa-check allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              ) : (
                                <i
                                  className="fa fa-ban not-allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              )
                            ) : notificationSettings.startup ? (
                              <i
                                className="fa fa-check allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            ) : (
                              <i
                                className="fa fa-ban not-allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            )
                          ) : null}
                          <input
                            type="checkbox"
                            checked={
                              this.state.notifications.startup !== null
                                ? this.state.notifications.startup
                                : notificationSettings.startup
                            }
                            style={{
                              display: this.state.editNotifications
                                ? 'block'
                                : 'none'
                            }}
                            disabled
                            onChange={() => {
                              if (this.state.notifications.startup !== null) {
                                this.setState({
                                  notifications: {
                                    startup: !this.state.notifications.startup,
                                    shutdown: this.state.notifications.shutdown,
                                    failure: this.state.notifications.failure,
                                    slowdown: this.state.notifications.slowdown,
                                    tardy: this.state.notifications.tardy,
                                    hibernating:
                                      this.state.notifications.hibernating
                                  }
                                })
                              } else {
                                this.setState({
                                  notifications: {
                                    startup: !notificationSettings.startup,
                                    shutdown: this.state.notifications.shutdown,
                                    failure: this.state.notifications.failure,
                                    slowdown: this.state.notifications.slowdown,
                                    tardy: this.state.notifications.tardy,
                                    hibernating:
                                      this.state.notifications.hibernating
                                  }
                                })
                              }
                            }}
                          />
                          Sensor startup (coming soon)
                        </li>

                        <li
                          key={'shutdown'}
                          className={
                            'notifications-directory ' +
                            (this.state.notifications.shutdown !== null
                              ? this.state.notifications.shutdown
                                ? 'selected'
                                : ''
                              : notificationSettings.shutdown
                              ? 'selected'
                              : '')
                          }
                        >
                          {!this.state.editNotifications ? (
                            this.state.notifications.shutdown !== null ? (
                              this.state.notifications.shutdown ? (
                                <i
                                  className="fa fa-check allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              ) : (
                                <i
                                  className="fa fa-ban not-allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              )
                            ) : notificationSettings.shutdown ? (
                              <i
                                className="fa fa-check allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            ) : (
                              <i
                                className="fa fa-ban not-allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            )
                          ) : null}
                          <input
                            type="checkbox"
                            checked={
                              this.state.notifications.shutdown !== null
                                ? this.state.notifications.shutdown
                                : notificationSettings.shutdown
                            }
                            style={{
                              display: this.state.editNotifications
                                ? 'block'
                                : 'none'
                            }}
                            disabled
                            onChange={() => {
                              if (this.state.notifications.shutdown !== null) {
                                this.setState({
                                  notifications: {
                                    startup: this.state.notifications.startup,
                                    shutdown:
                                      !this.state.notifications.shutdown,
                                    failure: this.state.notifications.failure,
                                    slowdown: this.state.notifications.slowdown,
                                    tardy: this.state.notifications.tardy,
                                    hibernating:
                                      this.state.notifications.hibernating
                                  }
                                })
                              } else {
                                this.setState({
                                  notifications: {
                                    startup: this.state.notifications.startup,
                                    shutdown: !notificationSettings.shutdown,
                                    failure: this.state.notifications.failure,
                                    slowdown: this.state.notifications.slowdown,
                                    tardy: this.state.notifications.tardy,
                                    hibernating:
                                      this.state.notifications.hibernating
                                  }
                                })
                              }
                            }}
                          />
                          Device shutdown (coming soon)
                        </li>
                        <li
                          key={'failure'}
                          className={
                            'notifications-directory ' +
                            (this.state.notifications.failure !== null
                              ? this.state.notifications.failure
                                ? 'selected'
                                : ''
                              : notificationSettings.failure
                              ? 'selected'
                              : '')
                          }
                        >
                          {!this.state.editNotifications ? (
                            this.state.notifications.failure !== null ? (
                              this.state.notifications.failure ? (
                                <i
                                  className="fa fa-check allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              ) : (
                                <i
                                  className="fa fa-ban not-allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              )
                            ) : notificationSettings.failure ? (
                              <i
                                className="fa fa-check allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            ) : (
                              <i
                                className="fa fa-ban not-allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            )
                          ) : null}
                          <input
                            type="checkbox"
                            checked={
                              this.state.notifications.failure !== null
                                ? this.state.notifications.failure
                                : notificationSettings.failure
                            }
                            style={{
                              display: this.state.editNotifications
                                ? 'block'
                                : 'none'
                            }}
                            disabled
                            onChange={() => {
                              if (this.state.notifications.failure !== null) {
                                this.setState({
                                  notifications: {
                                    startup: this.state.notifications.startup,
                                    shutdown: this.state.notifications.shutdown,
                                    failure: !this.state.notifications.failure,
                                    slowdown: this.state.notifications.slowdown,
                                    tardy: this.state.notifications.tardy,
                                    hibernating:
                                      this.state.notifications.hibernating
                                  }
                                })
                              } else {
                                this.setState({
                                  notifications: {
                                    startup: this.state.notifications.startup,
                                    shutdown: this.state.notifications.shutdown,
                                    failure: !notificationSettings.failure,
                                    slowdown: this.state.notifications.slowdown,
                                    tardy: this.state.notifications.tardy,
                                    hibernating:
                                      this.state.notifications.hibernating
                                  }
                                })
                              }
                            }}
                          />
                          Sensor failure (coming soon)
                        </li>
                        <li
                          key={'slowdown'}
                          className={
                            'notifications-directory ' +
                            (this.state.notifications.slowdown !== null
                              ? this.state.notifications.slowdown
                                ? 'selected'
                                : ''
                              : notificationSettings.slowdown
                              ? 'selected'
                              : '')
                          }
                        >
                          {!this.state.editNotifications ? (
                            this.state.notifications.slowdown !== null ? (
                              this.state.notifications.slowdown ? (
                                <i
                                  className="fa fa-check allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              ) : (
                                <i
                                  className="fa fa-ban not-allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              )
                            ) : notificationSettings.slowdown ? (
                              <i
                                className="fa fa-check allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            ) : (
                              <i
                                className="fa fa-ban not-allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            )
                          ) : null}
                          <input
                            type="checkbox"
                            checked={
                              this.state.notifications.slowdown !== null
                                ? this.state.notifications.slowdown
                                : notificationSettings.slowdown
                            }
                            style={{
                              display: this.state.editNotifications
                                ? 'block'
                                : 'none'
                            }}
                            disabled
                            onChange={() => {
                              if (this.state.notifications.slowdown !== null) {
                                this.setState({
                                  notifications: {
                                    startup: this.state.notifications.startup,
                                    shutdown: this.state.notifications.shutdown,
                                    failure: this.state.notifications.failure,
                                    slowdown:
                                      !this.state.notifications.slowdown,
                                    tardy: this.state.notifications.tardy,
                                    hibernating:
                                      this.state.notifications.hibernating
                                  }
                                })
                              } else {
                                this.setState({
                                  notifications: {
                                    startup: this.state.notifications.startup,
                                    shutdown: this.state.notifications.shutdown,
                                    failure: this.state.notifications.failure,
                                    slowdown: !notificationSettings.slowdown,
                                    tardy: this.state.notifications.tardy,
                                    hibernating:
                                      this.state.notifications.hibernating
                                  }
                                })
                              }
                            }}
                          />
                          {window.innerWidth > 500
                            ? 'Device enters slowdown mode* (coming soon)'
                            : 'Device slowdown* (coming soon)'}
                        </li>
                        <li
                          key={'tardy'}
                          className={
                            'notifications-directory ' +
                            (this.state.notifications.tardy !== null
                              ? this.state.notifications.tardy
                                ? 'selected'
                                : ''
                              : notificationSettings.tardy
                              ? 'selected'
                              : '')
                          }
                        >
                          {!this.state.editNotifications ? (
                            this.state.notifications.tardy !== null ? (
                              this.state.notifications.tardy ? (
                                <i
                                  className="fa fa-check allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              ) : (
                                <i
                                  className="fa fa-ban not-allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              )
                            ) : notificationSettings.tardy ? (
                              <i
                                className="fa fa-check allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            ) : (
                              <i
                                className="fa fa-ban not-allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            )
                          ) : null}
                          <input
                            type="checkbox"
                            checked={
                              this.state.notifications.tardy !== null
                                ? this.state.notifications.tardy
                                : notificationSettings.tardy
                            }
                            style={{
                              display: this.state.editNotifications
                                ? 'block'
                                : 'none'
                            }}
                            disabled={!this.state.editNotifications}
                            onChange={() => {
                              if (this.state.notifications.tardy !== null) {
                                this.setState({
                                  notifications: {
                                    startup: this.state.notifications.startup,
                                    shutdown: this.state.notifications.shutdown,
                                    failure: this.state.notifications.failure,
                                    slowdown: this.state.notifications.slowdown,
                                    tardy: !this.state.notifications.tardy,
                                    hibernating:
                                      this.state.notifications.hibernating
                                  }
                                })
                              } else {
                                this.setState({
                                  notifications: {
                                    startup: this.state.notifications.startup,
                                    shutdown: this.state.notifications.shutdown,
                                    failure: this.state.notifications.failure,
                                    slowdown: this.state.notifications.slowdown,
                                    tardy: !notificationSettings.tardy,
                                    hibernating:
                                      this.state.notifications.hibernating
                                  }
                                })
                              }
                            }}
                          />
                          {window.innerWidth > 500
                            ? 'Device is tardy (24 hours passes without sending a health message)*'
                            : 'Tardy sensor*'}
                        </li>
                        <li
                          key={'hibernating'}
                          className={
                            'notifications-directory ' +
                            (this.state.notifications.hibernating !== null
                              ? this.state.notifications.hibernating
                                ? 'selected'
                                : ''
                              : notificationSettings.hibernating
                              ? 'selected'
                              : '')
                          }
                        >
                          {!this.state.editNotifications ? (
                            this.state.notifications.hibernating !== null ? (
                              this.state.notifications.hibernating ? (
                                <i
                                  className="fa fa-check allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              ) : (
                                <i
                                  className="fa fa-ban not-allowed"
                                  style={{
                                    marginRight: '0.6em',
                                    marginLeft: '4px',
                                    width: '15px'
                                  }}
                                />
                              )
                            ) : notificationSettings.hibernating ? (
                              <i
                                className="fa fa-check allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            ) : (
                              <i
                                className="fa fa-ban not-allowed"
                                style={{
                                  marginRight: '0.6em',
                                  marginLeft: '4px',
                                  width: '15px'
                                }}
                              />
                            )
                          ) : null}
                          <input
                            type="checkbox"
                            checked={
                              this.state.notifications.hibernating !== null
                                ? this.state.notifications.hibernating
                                : notificationSettings.hibernating
                            }
                            style={{
                              display: this.state.editNotifications
                                ? 'block'
                                : 'none'
                            }}
                            disabled
                            onChange={() => {
                              if (
                                this.state.notifications.hibernating !== null
                              ) {
                                this.setState({
                                  notifications: {
                                    startup: this.state.notifications.startup,
                                    shutdown: this.state.notifications.shutdown,
                                    failure: this.state.notifications.failure,
                                    slowdown: this.state.notifications.slowdown,
                                    tardy: this.state.notifications.tardy,
                                    hibernating:
                                      !this.state.notifications.hibernating
                                  }
                                })
                              } else {
                                this.setState({
                                  notifications: {
                                    startup: this.state.notifications.startup,
                                    shutdown: this.state.notifications.shutdown,
                                    failure: this.state.notifications.failure,
                                    slowdown: this.state.notifications.slowdown,
                                    tardy: this.state.notifications.tardy,
                                    hibernating:
                                      !notificationSettings.hibernating
                                  }
                                })
                              }
                            }}
                          />
                          {window.innerWidth > 500
                            ? 'Device is hibernating* (coming soon)'
                            : 'Device hibernating* (coming soon)'}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <span className="footer">
                    *will also notify upon resuming normal operation.
                  </span>
                </div>
              )
            }}
          </Query>
        )}
      </div>
      <div className="api-keys">
        {this.state.showCSV ? (
          <span
            className="toggle clickable"
            onClick={() => this.setState({showCSV: false})}
          >
            <i className="fa fa-caret-down" /> Hide Directory CSV
          </span>
        ) : (
          <span
            className="toggle clickable"
            onClick={() => this.setState({showCSV: true})}
          >
            <i className="fa fa-caret-right" /> Show Directory CSV 
          </span>
        )}
        {this.state.showCSV && (
          <div className="content">
            <p>
              For users interested in acquiring a list of all devices (active and inactive) they have access to. It is a CSV
              in the directory list view format. Press the button below to start your download.
            </p>
            <Query query={GET_DIRECTORY_DEVICES}>
            {({ loading, error, data }) => (
              <button onClick={() => this.handleDownload(data)} disabled={loading}>
                {loading ? 'Loading...' : 'Download Devices as CSV'}
              </button>
            )}
          </Query>
          </div>
        )}
      </div>

      {this.state.modal === 'showAPIKey' && (
        <Modal onClose={this.closeModal}>
          <Modal.Title>New API Key</Modal.Title>
          <Modal.Body>
            <p>Your new API key is:</p>
            <pre>{this.state.receivedAPIKey}</pre>
            <p>
              Please save this key. For security, the unencrypted key is not
              saved and you will not be able to view it again.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <span className="button primary" onClick={this.closeModal}>
              Ok
            </span>
          </Modal.Footer>
        </Modal>
      )}
      {this.state.modal === 'loginAs' && <LoginAs onClose={this.closeModal} />}
      {this.state.modal === 'confirmLogout' && (
        <Modal onClose={this.closeModal}>
          <Modal.Title>Confirm Logout</Modal.Title>
          <Modal.Body>Are you sure you want to log out?</Modal.Body>
          <Modal.Footer>
            <span
              className="button"
              onClick={() => {
                this.props.logout()
                this.closeModal()
              }}
            >
              Logout
            </span>
            <span className="button primary" onClick={this.closeModal}>
              Cancel
            </span>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({
  logout: () => dispatch(logout({client: ownProps.client}))
})
export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(Account)
