// import React, {useState} from 'react'
import React from 'react'
// import {ReactComponent as NoAlarms} from 'assets/noAlarms.svg'
// import {Query} from 'react-apollo'
// import Loading from 'Components/Loading'
// import Tag from 'utils/Tag'
// import {gql} from 'graphql.macro'
// import {route} from 'router'
// import {ButtonGroup, ButtonGroupOption} from 'Components/ButtonGroup'

import './ManufacturingChecklist.scss'

// const ManufacturingChecklist = ({allDevices}) => {
const ManufacturingChecklist = () => {
  // const [sortBy, setSortBy] = useState('id')
  // const [sortDirection, setSortDirection] = useState('asc')
  // const [view, setView] = useState('all')

  // const GET_VIA = gql`
  //   query GET_VIA {
  //     me {
  //       id
  //       isSuperUser
  //       company {
  //         id
  //         name
  //       }
  //     }
  //     company(id: 10279) {
  //       id
  //       allDevices {
  //         id
  //         boardRev
  //         firmwareVersion
  //         events {
  //           id
  //           time
  //           flag
  //           data
  //         }
  //         healthMessages {
  //           id
  //           time
  //           voltage
  //           antennaAttempts
  //           antennaSuccesses
  //           csqValue
  //           modemRestarts
  //         }
  //         tags {
  //           tag
  //           time
  //           value
  //         }
  //       }
  //     }
  //   }
  // `
  return ( <div>in testing</div>
    // <Query query={GET_VIA}>
    //   {({error, loading, data}) => {
    //     if (error) {
    //       return 'error'
    //     }
    //     const isSuperUser = data && data.me && data.me.isSuperUser
    //     const isVia =
    //       data && data.me && data.me.company && data.me.company.id === 10279
    //     if (loading) return <Loading />
    //     if (!(!!isSuperUser || !!isVia)) {
    //       route('/alarms')
    //     }

    //     const sort = async (e, key) => {
    //       e.preventDefault()
    //       await setSortBy(key)
    //       await setSortDirection(
    //         sortBy === key ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    //       )
    //     }

    //     const sortFunction = () => {
    //       const reverse = sortDirection === 'asc'
    //       switch (sortBy) {
    //         case 'id':
    //           return (a, b) => (reverse ? a.id - b.id : b.id - a.id)
    //         case 'boardRev':
    //           return (a, b) =>
    //             (reverse ? -1 : 1) *
    //             (a.boardRev || 'zzzzz').localeCompare(b.boardRev || 'zzzzz')
    //         case 'firmware':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) * (a.firmwareVersion - b.firmwareVersion)
    //         case 'batteryLevel':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) * (a.batteryLevel - b.batteryLevel)
    //         case 'timeOnline':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) * (a.timeOnline - b.timeOnline)
    //         case 'startupCount':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) * (a.startupCount - b.startupCount)
    //         case 'modemRestarts':
    //           return (a, b) => {
    //             if (a.modemRestarts === null) return reverse ? -1 : 1
    //             else if (b.modemRestarts === null) return reverse ? -1 : 1
    //             else
    //               return (
    //                 (reverse ? 1 : -1) * (a.modemRestarts - b.modemRestarts)
    //               )
    //           }
    //         case 'csqValue':
    //           return (a, b) => (reverse ? 1 : -1) * (a.csqValue - b.csqValue)
    //         case 'signalRatio':
    //           return (a, b) =>
    //             (reverse ? 1 : -1) * (a.signalRatio - b.signalRatio)
    //         default:
    //           return () => null
    //       }
    //     }

    //     const sortIndicator = (key) =>
    //       sortBy === key ? (
    //         sortDirection === 'asc' ? (
    //           <i className="fa fa-caret-up" />
    //         ) : (
    //           <i className="fa fa-caret-down" />
    //         )
    //       ) : null

    //     const timeString = (s) => {
    //       if (s === null) {
    //         return 'N/A'
    //       }
    //       const d = Math.floor(s / (3600 * 24))

    //       s -= d * 3600 * 24

    //       const h = Math.floor(s / 3600)

    //       s -= h * 3600

    //       const m = Math.floor(s / 60)

    //       s -= m * 60

    //       const tmp = []

    //       d && tmp.push(d + 'd')
    //       ;(d || h) && tmp.push(h + 'h')
    //       ;(d || h || m) && tmp.push(m + 'm')

    //       tmp.push(s + 's')

    //       return tmp.join(' ')
    //     }

    //     let devices = data.company.allDevices
    //       .filter((d) => {
    //         if (view === 'all') {
    //           return true
    //         }

    //         const lastHealthMessage =
    //           d.healthMessages.reduce(
    //             (latest, m) => (m.time > latest.time ? m : latest),
    //             d.healthMessages[0]
    //           ) || null
    //         const latestTag = new Tag(
    //           d.tags.reduce(
    //             (latest, t) => (t.time > latest.time ? t : latest),
    //             d.tags[0]
    //           )
    //         )
    //         const lastStartupTime = d.events
    //           .filter((e) => e.flag === 1)
    //           .reduce(
    //             (latest, t) => (t.time > latest.time ? t : latest),
    //             d.events[0]
    //           )
    //           ? d.events
    //               .filter((e) => e.flag === 1)
    //               .reduce(
    //                 (latest, t) => (t.time > latest.time ? t : latest),
    //                 d.events[0]
    //               ).time
    //           : null

    //         let batteryCheck = true
    //         let csqCheck = true
    //         let modemRestartsCheck = true
    //         d.healthMessages.forEach((m) => {
    //           if (m.voltage < 3.8) {
    //             batteryCheck = false
    //           }
    //           if (m.csqValue < 10) {
    //             csqCheck = false
    //           }
    //           if (m.modemRestarts >= 36) {
    //             modemRestartsCheck = false
    //           }
    //         })
    //         const timeOnlineCheck =
    //           latestTag.time - lastStartupTime >= 48 * 60 * 60
    //         const startupCountCheck =
    //           d.events.filter((e) => e.flag === 1).length >= 3
    //         const signalRatioCheck = !!lastHealthMessage
    //           ? lastHealthMessage.antennaSuccesses !== 0
    //             ? lastHealthMessage.antennaAttempts /
    //                 lastHealthMessage.antennaSuccesses <=
    //               1.5
    //             : false
    //           : false
    //         if (view === 'ready') {
    //           return (
    //             batteryCheck &&
    //             csqCheck &&
    //             modemRestartsCheck &&
    //             timeOnlineCheck &&
    //             startupCountCheck &&
    //             signalRatioCheck
    //           )
    //         } else {
    //           return (
    //             !batteryCheck ||
    //             !csqCheck ||
    //             !modemRestartsCheck ||
    //             !timeOnlineCheck ||
    //             !startupCountCheck ||
    //             !signalRatioCheck
    //           )
    //         }
    //       })
    //       .map((d) => {
    //         const lastStartupTime = d.events
    //           .filter((e) => e.flag === 1)
    //           .reduce(
    //             (latest, t) => (t.time > latest.time ? t : latest),
    //             d.events[0]
    //           )
    //           ? d.events
    //               .filter((e) => e.flag === 1)
    //               .reduce(
    //                 (latest, t) => (t.time > latest.time ? t : latest),
    //                 d.events[0]
    //               ).time
    //           : null
    //         const latestTag = new Tag(
    //           d.tags.reduce(
    //             (latest, t) => (t.time > latest.time ? t : latest),
    //             d.tags[0]
    //           )
    //         )
    //         const lastHealthMessage =
    //           d.healthMessages.reduce(
    //             (latest, m) => (m.time > latest.time ? m : latest),
    //             d.healthMessages[0]
    //           ) || null
    //         let batteryCheck = true
    //         let csqCheck = true
    //         let modemRestartsCheck = true
    //         d.healthMessages.forEach((m) => {
    //           if (m.voltage < 3.8) {
    //             batteryCheck = false
    //           }
    //           if (m.csqValue < 10) {
    //             csqCheck = false
    //           }
    //           if (m.modemRestarts >= 36) {
    //             modemRestartsCheck = false
    //           }
    //         })
    //         const timeOnline =
    //           latestTag.time - lastStartupTime >= 0
    //             ? latestTag.time - lastStartupTime
    //             : null
    //         const timeOnlineString = timeString(timeOnline)

    //         const timeOnlineCheck =
    //           latestTag.time - lastStartupTime >= 48 * 60 * 60
    //         const startupCountCheck =
    //           d.events.filter((e) => e.flag === 1).length >= 3
    //         const signalRatioCheck = !!lastHealthMessage
    //           ? lastHealthMessage.antennaSuccesses !== 0
    //             ? lastHealthMessage.antennaAttempts /
    //                 lastHealthMessage.antennaSuccesses <=
    //               1.5
    //             : false
    //           : null

    //         const ready =
    //           batteryCheck &&
    //           csqCheck &&
    //           modemRestartsCheck &&
    //           timeOnlineCheck &&
    //           startupCountCheck &&
    //           signalRatioCheck

    //         return {
    //           boardRev: d.boardRev,
    //           events: d.events,
    //           firmwareVersion: d.firmwareVersion,
    //           id: d.id,
    //           LatestTag: latestTag,
    //           battery: d.tags.reduce(
    //             (batt, t) => batt || (t.tag === '_v' && t.value),
    //             null
    //           ),
    //           startupCount: d.events.filter((e) => e.flag === 1).length || 0,
    //           lastStartupTime: lastStartupTime,
    //           timeOnline: timeOnline,
    //           timeOnlineString: timeOnlineString,
    //           batteryLevel: lastHealthMessage
    //             ? lastHealthMessage.voltage
    //             : null,
    //           batteryCheck: batteryCheck,
    //           csqValue: lastHealthMessage ? lastHealthMessage.csqValue : null,
    //           csqCheck: csqCheck,
    //           modemRestarts: lastHealthMessage
    //             ? lastHealthMessage.modemRestarts
    //             : null,
    //           modemRestartsCheck: modemRestartsCheck,
    //           healthMessages: d.healthMessages,
    //           signalRatio: lastHealthMessage
    //             ? lastHealthMessage.antennaSuccesses !== 0
    //               ? lastHealthMessage.antennaAttempts /
    //                 lastHealthMessage.antennaSuccesses
    //               : null
    //             : null,
    //           ready: ready
    //         }
    //       })

    //     devices.sort(sortFunction())
    //     return (
    //       <div className=" device-card-container">
    //         <div className=" device-card-row">
    //           <div className="title">
    //             Manufacturing Checklist
    //             <div className="group-right">
    //               <ButtonGroup>
    //                 <ButtonGroupOption
    //                   onClick={() => setView('all')}
    //                   selected={view === 'all'}
    //                 >
    //                   All Devices
    //                 </ButtonGroupOption>
    //                 <ButtonGroupOption
    //                   onClick={() => setView('ready')}
    //                   selected={view === 'ready'}
    //                 >
    //                   Ready Devices
    //                 </ButtonGroupOption>
    //                 <ButtonGroupOption
    //                   onClick={() => setView('nonready')}
    //                   selected={view === 'nonready'}
    //                 >
    //                   Non-Ready Devices
    //                 </ButtonGroupOption>
    //               </ButtonGroup>
    //             </div>
    //           </div>
    //           <div className="list-container device-list">
    //             {devices.length === 0 ? null : (
    //               <div className="manu-row header">
    //                 <div
    //                   className="manu-id clickable"
    //                   onClick={async (e) => await sort(e, 'id')}
    //                 >
    //                   Device ID {sortIndicator('id')}
    //                 </div>
    //                 <div
    //                   className="manu-boardrev clickable"
    //                   onClick={async (e) => await sort(e, 'boardRev')}
    //                 >
    //                   Board Rev {sortIndicator('boardRev')}
    //                 </div>
    //                 <div
    //                   className="manu-firmware clickable"
    //                   onClick={async (e) => await sort(e, 'firmware')}
    //                 >
    //                   Firmware {sortIndicator('firmware')}
    //                 </div>
    //                 <div
    //                   className="manu-time-online clickable"
    //                   onClick={async (e) => await sort(e, 'timeOnline')}
    //                 >
    //                   Time Online {sortIndicator('timeOnline')}
    //                 </div>
    //                 <div
    //                   className="manu-startup-count clickable"
    //                   onClick={async (e) => await sort(e, 'startupCount')}
    //                 >
    //                   Startup Count {sortIndicator('startupCount')}
    //                 </div>
    //                 <div
    //                   className="manu-modem-restarts clickable"
    //                   onClick={async (e) => await sort(e, 'modemRestarts')}
    //                 >
    //                   Modem Restarts {sortIndicator('modemRestarts')}
    //                 </div>
    //                 <div
    //                   className="manu-battery-level clickable"
    //                   onClick={async (e) => await sort(e, 'batteryLevel')}
    //                 >
    //                   Battery Level {sortIndicator('batteryLevel')}
    //                 </div>
    //                 <div
    //                   className="manu-csq-value clickable"
    //                   onClick={async (e) => await sort(e, 'csqValue')}
    //                 >
    //                   CSQ Value {sortIndicator('csqValue')}
    //                 </div>
    //                 <div
    //                   className="manu-signal-ratio clickable"
    //                   onClick={async (e) => await sort(e, 'signalRatio')}
    //                 >
    //                   Signal Ratio {sortIndicator('signalRatio')}
    //                 </div>
    //               </div>
    //             )}

    //             <div className="scrollable-container">
    //               {devices.length === 0 ? (
    //                 <div className="no-alarms" style={{marginTop: '15em'}}>
    //                   <NoAlarms />
    //                   <h1>No Devices Assigned to VIA</h1>
    //                 </div>
    //               ) : (
    //                 <table>
    //                   <tbody>
    //                     {devices.map((d) => (
    //                       <tr
    //                         key={d.id}
    //                         className="manu-row"
    //                         style={{height: '3em'}}
    //                       >
    //                         <td className="manu-id" style={{height: 'auto'}}>
    //                           <a href={`#/devices/${d.id}`}>{d.id}</a>
    //                         </td>
    //                         <td
    //                           className="manu-boardrev"
    //                           style={{height: 'auto'}}
    //                         >
    //                           {d.boardRev ? d.boardRev : <em>None</em>}
    //                         </td>
    //                         <td
    //                           className="manu-firmware"
    //                           style={{height: 'auto'}}
    //                         >
    //                           {d.firmwareVersion ? (
    //                             d.firmwareVersion
    //                           ) : (
    //                             <em>None</em>
    //                           )}
    //                         </td>
    //                         <td
    //                           className="manu-time-online"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div
    //                             className={
    //                               d.timeOnline < 48 * 60 * 60
    //                                 ? 'warning'
    //                                 : 'normal'
    //                             }
    //                           >
    //                             {d.timeOnlineString ? (
    //                               d.timeOnlineString
    //                             ) : (
    //                               <em>N/A</em>
    //                             )}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="manu-startup-count"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div
    //                             className={
    //                               d.startupCount < 3 ? 'warning' : 'normal'
    //                             }
    //                           >
    //                             {d.startupCount ? d.startupCount : <em>N/A</em>}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="manu-modem-restarts"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div
    //                             className={
    //                               d.modemRestartsCheck ? 'normal' : 'warning'
    //                             }
    //                           >
    //                             {d.modemRestarts !== null ? (
    //                               d.modemRestarts
    //                             ) : (
    //                               <em>N/A</em>
    //                             )}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="manu-battery-level"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div
    //                             className={
    //                               d.batteryCheck ? 'normal' : 'warning'
    //                             }
    //                           >
    //                             {d.batteryLevel ? (
    //                               d.batteryLevel.toFixed(2)
    //                             ) : (
    //                               <em>N/A</em>
    //                             )}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="manu-csq-value"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div
    //                             className={d.csqCheck ? 'normal' : 'warning'}
    //                           >
    //                             {d.csqValue ? d.csqValue : <em>N/A</em>}
    //                           </div>
    //                         </td>
    //                         <td
    //                           className="manu-signal-ratio"
    //                           style={{height: 'auto'}}
    //                         >
    //                           <div
    //                             className={
    //                               d.signalRatio >= 1.5 || d.signalRatio === null
    //                                 ? 'warning'
    //                                 : 'normal'
    //                             }
    //                           >
    //                             {d.signalRatio === null ? (
    //                               <em>N/A</em>
    //                             ) : (
    //                               d.signalRatio.toFixed(2)
    //                             )}
    //                           </div>
    //                         </td>
    //                       </tr>
    //                     ))}
    //                   </tbody>
    //                 </table>
    //               )}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     )
    //   }}
    // </Query>
  )
}

export default ManufacturingChecklist
