import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import {gql} from 'graphql.macro'
import {Link} from 'router'
import Modal from 'Components/Modal'
import {Input, InputRow} from 'Components/Form'

import {GET_TAG} from '../queries'

import '../index.scss'

const UPDATE_CALIBRATION = gql`
  mutation UPDATE_CALIBRATION(
    $deviceID: Int!
    $tag: String!
    $time: Int!
    $fluid: String
    $api: Float
    $tds: Float
    $sg: Float
    $target: Float!
    $kFactor: Float!
    $productionOffset: Float!
    $tankHeight: Float
    $tankVolume: Float
    $tankShape: String
  ) {
    updateCalibration(
      deviceID: $deviceID
      tag: $tag
      time: $time
      fluid: $fluid
      api: $api
      tds: $tds
      sg: $sg
      target: $target
      kFactor: $kFactor
      productionOffset: $productionOffset
      tankHeight: $tankHeight
      tankVolume: $tankVolume
      tankShape: $tankShape
    ) {
      success
      device {
        id
      }
    }
  }
`

const calibrationDefaults = {
  time: 0,
  offset: 0,
  fluid: 'water',
  tds: 24,
  api: 32,
  sg: 1,
  barrelsPerInch: 1.67,
  target: 0,
  kFactor: 1,
  productionOffset: 0
}

class TurbineCalibrationMenu extends Component {
  state = {
    introPrompt: true,
    introPromptError: false,
    finalPrompt: false,
    open: true,
    kFactor: null
  }
  close = () => this.setState({open: false})
  showStep = (stateKey) => {
    let stateObj = {
      introPrompt: false,
      introPromptError: false,
      finalPrompt: false
    }
    stateObj[stateKey] = true
    this.setState(stateObj)
  }
  render = () => {
    const Tag = this.props.Tag
    return (
      this.state.open && (
        <Modal>
          {this.state.introPrompt ? (
            <div>
              <Modal.Title>
                Calibration is Needed for Turbine Sensor
              </Modal.Title>
              <Modal.Body>
                <p>
                  Turbine sensors require calibration to display meaningful
                  data. Before continuing, please provide the k-factor (turbine
                  revolutions per gallon of fluid) for your sensor.
                </p>
                <InputRow>
                  <Input
                    label={<span>k-factor:</span>}
                    type={'float'}
                    onChange={(e) => this.setState({kFactor: e.target.value})}
                    value={this.state.kFactor}
                  />
                </InputRow>
                {this.state.introPromptError ? (
                  <p style={{float: 'left', color: 'red'}}>Form Incomplete.</p>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <Link path={''}>Back to Home</Link>
                <button
                  className="primary"
                  onClick={() => {
                    if (this.state.kFactor === null || this.state.kFactor < 0) {
                      this.setState({introPromptError: true})
                    } else {
                      this.showStep('finalPrompt')
                    }
                  }}
                >
                  Continue
                </button>
              </Modal.Footer>
            </div>
          ) : null}
          {this.state.finalPrompt ? (
            <div>
              <Modal.Title>Review Calibration</Modal.Title>
              <Modal.Body>
                <p>
                  Thank you for submitting the k-factor needed for calibration.
                  Please review it below before submitting.
                </p>

                {
                  <div>
                    <InputRow>
                      <Input
                        label={<span>k-factor:</span>}
                        type={'float'}
                        static={true}
                        value={this.state.kFactor}
                      />
                    </InputRow>
                  </div>
                }
                <p>
                  To change the setting of your k-factor later, navigate to the
                  "Settings" tab above the graph.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={() => {
                    this.showStep('introPrompt')
                  }}
                >
                  Back
                </button>
                <button
                  className="primary"
                  onClick={() => {
                    this.props.updateCalibration({
                      variables: {
                        ...calibrationDefaults,
                        kFactor: this.state.kFactor,
                        deviceID: Tag.deviceID,
                        tag: Tag.tag
                      },
                      refetchQueries: [
                        {
                          query: GET_TAG,
                          variables: {
                            deviceID: Tag.deviceID,
                            tag: Tag.tag
                          }
                        }
                      ]
                    })

                    this.close()
                  }}
                >
                  Complete Calibration
                </button>
              </Modal.Footer>
            </div>
          ) : null}
        </Modal>
      )
    )
  }
}

export default compose(
  graphql(UPDATE_CALIBRATION, {name: 'updateCalibration'})
)(TurbineCalibrationMenu)
