import React, {useState} from 'react'
import {Query} from 'react-apollo'
import Loading from 'Components/Loading'
// import {route} from 'router'
import List from './ListAlerts'
import Tooltip from 'Components/Tooltip'
import { route } from 'router'

import './Alerts.scss'
import { gql } from 'graphql.macro'

export const GET_DEVICES_ALERTS = gql`
  query GET_DEVICES_ALERTS($query: String, $previousDays: Int, $recentDays: Int) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesRe(query: $query, previousDays: $previousDays, recentDays: $recentDays) {
      id
      userAssignedID
      name
      path
      isActive
      inService
      longitude
      latitude
      latestReading
      lastTimeMessageReceived
      batteryLevel
      displayUnits
      defaultTag
      companyID
      simNumber
      boardRev
      firmwareVersion
      modemType
      signalStrength
      company {
        name
      }
      tags {
        id
        deviceID
        tag
      }
    }
  }
`

const Alerts = () => {
  // const [clearAlertWarning, setClearAlertWarning] = useState(false)
  const [previousDays, setPreviousDays] = useState(48)
  const [recentDays, setRecentDays] = useState(12)
  const [u_previousDays, setuPreviousDays] = useState(48)
  const [u_recentDays, setuRecentDays] = useState(12)

  function handleChange(e) {
    const input = e.target.value.trim();
    e.preventDefault()
    const name = e.target.name

    if (name === 'before') {
      if (!isNaN(input) && input < 1000000){
        setuPreviousDays(input)
      }
    }

    if (name === 'after') {
      if (!isNaN(input) && input < 1000000){
        setuRecentDays(input)
      }
    }
  }

  function submitForm(e) {
    e.preventDefault()
    setPreviousDays(parseInt(u_previousDays))
    setRecentDays(parseInt(u_recentDays))
  }

  return (
    <Query query={GET_DEVICES_ALERTS} variables={{query: 'alert', recentDays, previousDays}}>
      {({error, loading, data}) => {
        if (error) return 'error'
        if (loading) return <Loading />
        if (!!data && (!data.me.isSuperUser && !data.me.isManager)) {
          route('/alarms')
        } 

        const viewable = data.me.isSuperUser || data.me.isManager

        const alerts = (data.devicesRe || [])
          // .filter((d) => d.lastTimeMessageReceived >= currentTimeUnix - (previousDays * 60 * 60))
          // .filter((d) => d.lastTimeMessageReceived <= currentTimeUnix - (recentDays * 60 * 60))

          // special filter for via engineering devices
          .filter((d) => d.company?.name !== "VIA ENGINEERING")
          .filter((d) => d.path !== "/VIA ENGINEERING TEST")
          .filter((d) => d.path !== "/VIA ENGINEERING COMPLETE")
          .filter((d) => d.path !== "/VIA ENGINEERING TEST/Matt")


          return (
            <>{!!viewable && (<div className='directory-body'>
              <div className='directory-top-bar'>
                <p className='title2344'>TARDY DEVICES / ALERTS VIEW</p>
                <Tooltip>
                *show a list of devices that have a latest reading in the specified timeframe and may indicate problems. [use start time (default 12 hrs) to give devices some leeway - and end time (default 48 hours) to exclude devices which may have long been inactive]
                </Tooltip>
                <form onSubmit={submitForm} className='formst'>
                <label className='setting-input'>Time Range (Hours):</label>
                <input type='number' className='input' name='after' value={u_recentDays} onChange={handleChange}/><span style={{paddingLeft: '10px'}}>-</span>
                <input type='number' className='input' name='before' value={u_previousDays} onChange={handleChange}/>
                <button type='submit' style={{marginLeft: '10px'}}>Submit</button>
                </form>
              </div>
            {alerts.length === 0 ? 
            <div className="no-alarms">
              <h1>No Tardy Devices For This Time Range</h1>
            </div>
          : (<List devices={alerts} isSuperUser={data && data.me && data.me.isSuperUser} />)}
          </div>)}
          </>
          )
      }}
    </Query>
  )
}

export default Alerts
