import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import {gql} from 'graphql.macro'
import {Link} from 'router'
import Modal from 'Components/Modal'
import {Input, InputRow} from 'Components/Form'

import {GET_TAG} from '../queries'

import '../index.scss'

const UPDATE_CALIBRATION = gql`
  mutation UPDATE_CALIBRATION(
    $deviceID: Int!
    $tag: String!
    $time: Int!
    $fluid: String
    $api: Float
    $tds: Float
    $sg: Float
    $target: Float!
    $kFactor: Float!
    $productionOffset: Float!
    $tankHeight: Float
    $tankVolume: Float
    $tankShape: String
  ) {
    updateCalibration(
      deviceID: $deviceID
      tag: $tag
      time: $time
      fluid: $fluid
      api: $api
      tds: $tds
      sg: $sg
      target: $target
      kFactor: $kFactor
      productionOffset: $productionOffset
      tankHeight: $tankHeight
      tankVolume: $tankVolume
      tankShape: $tankShape
    ) {
      success
      device {
        id
      }
    }
  }
`

const calibrationDefaults = {
  time: 0,
  offset: 0,
  fluid: 'water',
  tds: 24,
  api: 32,
  sg: 1,
  barrelsPerInch: 1.67,
  target: 0,
  kFactor: 1,
  productionOffset: 0
}

class RadarCalibrationMenu extends Component {
  state = {
    introPrompt: true,
    volumePrompt: false,
    tankShapePrompt: false,
    tankShapePromptError: false,
    tankHeightPrompt: false,
    tankHeightPromptError: false,
    userCalibratePrompt: false,
    userCalibratePromptError: false,
    finalPrompt: false,
    open: true,
    volumeFormat: false,
    horizontalShape: false,
    tankHeight: null,
    tankVolume: null,
    currentTankLevel: null
  }
  close = () => this.setState({open: false})
  showStep = (stateKey) => {
    let stateObj = {
      introPrompt: false,
      volumePrompt: false,
      tankShapePrompt: false,
      tankShapePromptError: false,
      tankHeightPrompt: false,
      tankHeightPromptError: false,
      userCalibratePrompt: false,
      userCalibratePromptError: false,
      finalPrompt: false
    }
    stateObj[stateKey] = true
    this.setState(stateObj)
  }
  render = () => {
    const Tag = this.props.Tag
    const upperCase = {dist: 'Radar', sonr: 'Sonar'}
    return (
      this.state.open && (
        <Modal>
          {this.state.introPrompt ? (
            <div>
              <Modal.Title>
                Calibration is Needed for {upperCase[Tag.tag]} Sensor
              </Modal.Title>
              <Modal.Body>
                <p>
                  {upperCase[Tag.tag]} sensors require calibration to display
                  meaningful data. Before continuing, please follow the
                  calibration steps below.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Link path={''}>Back to Home</Link>
                <button
                  className="primary"
                  onClick={() => this.showStep('tankHeightPrompt')}
                >
                  Begin Calibration
                </button>
              </Modal.Footer>
            </div>
          ) : null}
          {/* {this.state.volumePrompt ? (
            <div>
              <Modal.Title>Display Inches or Gallons</Modal.Title>
              <Modal.Body>
                <p>I would like my readings to be displayed in:</p>
                {
                  <Input
                    type={'select'}
                    options={[
                      {
                        label: 'Inches',
                        value: false
                      },
                      {
                        label: 'Gallons',
                        value: true
                      }
                    ]}
                    onChange={e =>
                      this.setState({volumeFormat: e.target.value})
                    }
                    value={this.state.volumeFormat}
                  />
                }
              </Modal.Body>
              <Modal.Footer>
                <button onClick={() => this.showStep('introPrompt')}>
                  Back
                </button>
                <button
                  className="primary"
                  onClick={() => {
                    if (this.state.volumeFormat) {
                      this.showStep('tankShapePrompt')
                    } else {
                      this.showStep('tankHeightPrompt')
                    }
                  }}
                >
                  Continue
                </button>
              </Modal.Footer>
            </div>
          ) : null}
          {this.state.tankShapePrompt ? (
            <div>
              <Modal.Title>Tank Attributes</Modal.Title>
              <Modal.Body>
                <p>
                  In order to provide readings in gallons, the sensor must be
                  provided the tank shape, height, and total volume. Please
                  enter them below.
                </p>
                <InputRow>
                  <Input
                    label={<span>Tank Shape:</span>}
                    type={'select'}
                    options={[
                      {
                        label: 'Vertical Cylinder',
                        value: false
                      },
                      {
                        label: 'Horizontal Cylinder',
                        value: true
                      }
                    ]}
                    onChange={e =>
                      this.setState({horizontalShape: e.target.value})
                    }
                    value={this.state.horizontalShape}
                  />
                </InputRow>
                <InputRow>
                  <Input
                    label={<span>Tank Height:</span>}
                    type={'feetinches'}
                    onChange={e =>
                      this.setState({tankHeight: e.target.value * 12})
                    }
                    value={
                      this.state.tankHeight ? this.state.tankHeight / 12 : null
                    }
                  />
                </InputRow>
                <InputRow>
                  <Input
                    label={<span>Tank Volume (gallons):</span>}
                    type={'float'}
                    onChange={e => this.setState({tankVolume: e.target.value})}
                    value={this.state.tankVolume}
                  />
                </InputRow>
                {this.state.tankShapePromptError ? (
                  <p style={{float: 'left', color: 'red'}}>Form Incomplete.</p>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <button onClick={() => this.showStep('volumePrompt')}>
                  Back
                </button>
                <button
                  className="primary"
                  onClick={() => {
                    if (this.state.tankHeight && this.state.tankVolume) {
                      this.showStep('userCalibratePrompt')
                    } else {
                      this.setState({tankShapePromptError: true})
                    }
                  }}
                >
                  Continue
                </button>
              </Modal.Footer>
            </div>
          ) : null} */}
          {this.state.tankHeightPrompt ? (
            <div>
              <Modal.Title>Tank Height</Modal.Title>
              <Modal.Body>
                <p>
                  In order to provide readings in inches, the sensor must be
                  provided the tank height. Please enter it below.
                </p>
                <InputRow>
                  <Input
                    label={<span>Tank Height:</span>}
                    type={'feetinches'}
                    onChange={(e) =>
                      this.setState({tankHeight: e.target.value * 12})
                    }
                    value={
                      this.state.tankHeight ? this.state.tankHeight / 12 : null
                    }
                  />
                </InputRow>
                {this.state.tankHeightPromptError ? (
                  <p style={{float: 'left', color: 'red'}}>Form Incomplete.</p>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <button onClick={() => this.showStep('introPrompt')}>
                  Back
                </button>
                <button
                  className="primary"
                  onClick={() => {
                    if (this.state.tankHeight) {
                      this.showStep('userCalibratePrompt')
                    } else {
                      this.setState({tankHeightPromptError: true})
                    }
                  }}
                >
                  Continue
                </button>
              </Modal.Footer>
            </div>
          ) : null}

          {this.state.userCalibratePrompt ? (
            <div>
              <Modal.Title>Current Tank Level (User Measured)</Modal.Title>
              <Modal.Body>
                <p>
                  Please provide a current, user-measured tank level reading.
                </p>
                <InputRow>
                  <Input
                    label={<span>Current Tank Level:</span>}
                    type={'feetinches'}
                    onChange={(e) =>
                      this.setState({currentTankLevel: e.target.value * 12})
                    }
                    value={
                      this.state.currentTankLevel
                        ? this.state.currentTankLevel / 12
                        : null
                    }
                  />
                </InputRow>
                {this.state.userCalibratePromptError ? (
                  <p style={{float: 'left', color: 'red'}}>Form Incomplete.</p>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={() => {
                    if (this.state.volumeFormat === true) {
                      this.showStep('tankShapePrompt')
                    } else {
                      this.showStep('tankHeightPrompt')
                    }
                  }}
                >
                  Back
                </button>
                <button
                  className="primary"
                  onClick={() => {
                    if (this.state.currentTankLevel) {
                      this.showStep('finalPrompt')
                    } else {
                      this.setState({userCalibratePromptError: true})
                    }
                  }}
                >
                  Continue
                </button>
              </Modal.Footer>
            </div>
          ) : null}
          {this.state.finalPrompt ? (
            <div>
              <Modal.Title>Review Calibration</Modal.Title>
              <Modal.Body>
                <p>
                  Thank you for submitting the information needed for
                  calibration. Please review the info below before submitting
                  it.
                </p>

                {
                  <div>
                    {/* <InputRow>
                      <Input
                        label={<span>Display Units Preference:</span>}
                        static={true}
                        value={this.state.volumeFormat ? 'Gallons' : 'Inches'}
                      />
                    </InputRow> */}
                    {this.state.volumeFormat ? (
                      <div>
                        <InputRow>
                          <Input
                            label={<span>Tank Shape:</span>}
                            static={true}
                            value={
                              this.state.horizontalShape
                                ? 'Horizontal Cylinder'
                                : 'Vertical Cylinder'
                            }
                          />
                        </InputRow>
                        <InputRow>
                          <Input
                            label={<span>Tank Volume (gallons):</span>}
                            static={true}
                            value={this.state.tankVolume}
                          />
                        </InputRow>
                      </div>
                    ) : null}
                    <InputRow>
                      <Input
                        label={<span>Tank Height:</span>}
                        type={'feetinches'}
                        static={true}
                        value={
                          this.state.tankHeight
                            ? this.state.tankHeight / 12
                            : null
                        }
                      />
                    </InputRow>
                    <InputRow>
                      <Input
                        label={<span>Current Tank Level:</span>}
                        type={'feetinches'}
                        static={true}
                        value={
                          this.state.currentTankLevel
                            ? this.state.currentTankLevel / 12
                            : null
                        }
                      />
                    </InputRow>
                  </div>
                }
                <p>
                  To recalibrate later, click a point on the graph at any time
                  and choose the "Add Calibration" option.
                </p>
                <p>
                  To change the setting of your tank height later, navigate to
                  the "Settings" tab above the graph.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <button
                  onClick={() => {
                    this.showStep('userCalibratePrompt')
                  }}
                >
                  Back
                </button>
                <button
                  className="primary"
                  onClick={() => {
                    if (this.state.volumeFormat) {
                      this.props.updateCalibration({
                        variables: {
                          ...calibrationDefaults,
                          tankShape: this.state.horizontalShape
                            ? 'horizontal'
                            : 'vertical',
                          tankHeight: this.state.tankHeight,
                          tankVolume: this.state.tankVolume,
                          target: this.state.currentTankLevel,
                          deviceID: Tag.deviceID,
                          tag: Tag.tag
                        },
                        refetchQueries: [
                          {
                            query: GET_TAG,
                            variables: {
                              deviceID: Tag.deviceID,
                              tag: Tag.tag
                            }
                          }
                        ]
                      })
                    } else {
                      this.props.updateCalibration({
                        variables: {
                          ...calibrationDefaults,
                          tankHeight: this.state.tankHeight,
                          target: this.state.currentTankLevel,
                          deviceID: Tag.deviceID,
                          tag: Tag.tag
                        },
                        refetchQueries: [
                          {
                            query: GET_TAG,
                            variables: {
                              deviceID: Tag.deviceID,
                              tag: Tag.tag
                            }
                          }
                        ]
                      })
                    }
                    this.close()
                  }}
                >
                  Complete Calibration
                </button>
              </Modal.Footer>
            </div>
          ) : null}
        </Modal>
      )
    )
  }
}

export default compose(
  graphql(UPDATE_CALIBRATION, {name: 'updateCalibration'})
)(RadarCalibrationMenu)
