import Alarms from './Directory/Alarms'
import Alerts from './Directory/Alerts'
import Ghosted from 'Directory/Ghosted'
import Battery from 'Directory/BatteryView'
import Cellular from 'Directory/Cellular'
import Tasks from './Directory/Tasks'
import Activity from './Directory/Activity'
import ManufacturingChecklist from './Directory/ManufacturingChecklist'
import AllDevices from './Directory/AllDevices'
import NewDevices from './Directory/NewDevices'
import OfflineDevices from './Directory/OfflineDevices'
import Directory from './Directory'
import Team from './Team'
import AddUser from './Team/AddUser'
import AddGroup from './Team/AddGroup'
import AddDevice from './Directory/AddDevice'
import Devices from './Devices'
import Tags from './Devices/Tags'
import Account from './Account'

import Admin from './Admin'
import NewPO from './Admin/NewPO'
import Fota from './Admin/Fota'
import NewSetting from './Admin/NewSetting'
import Stickers from './Admin/Stickers'
import DeviceIDs from './Admin/DeviceIDs'
import Billing from './Admin/Billing'
import MessageLog from './Admin/MessageLog'
import AddCompany from './Team/AddCompany'

import {Menu as DirectoryMenu} from './Directory'
import {Menu as TeamMenu} from './Team'
import {Menu as AdminMenu} from './Admin'

export const routes = {
  '': {side: DirectoryMenu, content: Alarms, tab: 'directory'},
  '/': {side: DirectoryMenu, content: Directory, tab: 'directory'},
  '/alarms': {side: DirectoryMenu, content: Alarms, tab: 'directory'},
  '/alerts': {side: DirectoryMenu, content: Alerts, tab: 'directory'},
  '/ghost' : {side: DirectoryMenu, content: Ghosted, tab: 'directory'},
  '/battery' : {side: DirectoryMenu, content: Battery, tab: 'directory'},
  '/cellular' : {side: DirectoryMenu, content: Cellular, tab: 'directory'},
  '/tasks': {side: DirectoryMenu, content: Tasks, tab: 'directory'},
  '/activity': {side: DirectoryMenu, content: Activity, tab: 'directory'},
  '/manufacturingChecklist': {
    side: DirectoryMenu,
    content: ManufacturingChecklist,
    tab: 'directory'
  },
  '/allDevices': {
    side: DirectoryMenu,
    content: AllDevices,
    tab: 'directory'
  },
  '/newDevices': {
    side: DirectoryMenu,
    content: NewDevices,
    tab: 'directory'
  },
  '/offline': {
    side: DirectoryMenu,
    content: OfflineDevices,
    tab: 'directory'
  },
  '/directory': {
    side: DirectoryMenu,
    content: Directory,
    tab: 'directory'
  },
  '/addDevice': {
    side: DirectoryMenu,
    content: AddDevice,
    tab: 'directory'
  },
  '/team': {side: TeamMenu, content: Team, tab: 'team'},
  '/addUser': {side: TeamMenu, content: AddUser, tab: 'team'},
  '/addGroup': {side: TeamMenu, content: AddGroup, tab: 'team'},
  '/team/group/:(#)groupIDFromRoute': {
    side: TeamMenu,
    content: Team,
    tab: 'team'
  },
  '/team/user/:(#)userID': {side: TeamMenu, content: Team, tab: 'team'},
  '/team/companyWide/:(#)companyIDFromRoute': {
    side: TeamMenu,
    content: Team,
    tab: 'team'
  },
  '/devices/:(#)deviceID': {
    side: DirectoryMenu,
    content: Devices,
    tab: 'directory'
  },
  '/devices/:(#)deviceID/tags/:tag': {
    side: DirectoryMenu,
    content: Tags,
    tab: 'directory'
  },
  '/account': {
    side: () => null,
    content: Account,
    tab: null
  },
  '/admin/messageLog': {
    side: DirectoryMenu,
    content: MessageLog,
    tab: 'directory'
  },
  '/admin/messageLog/:(#)deviceID': {
    side: DirectoryMenu,
    content: MessageLog,
    tab: 'directory'
  }
}

export const adminRoutes = {
  ...routes,
  '/admin': {side: AdminMenu, content: Admin, tab: 'admin'},
  '/admin/newPO': {side: AdminMenu, content: NewPO, tab: 'admin'},
  '/admin/stickers': {
    side: AdminMenu,
    content: Stickers,
    tab: 'admin'
  },
  '/admin/deviceIDs': {
    side: AdminMenu,
    content: DeviceIDs,
    tab: 'admin'
  },
  '/admin/billing': {side: AdminMenu, content: Billing, tab: 'admin'},
  '/addCompany': {side: TeamMenu, content: AddCompany, tab: 'team'},
  '/admin/fota': {side: AdminMenu, content: Fota, tab: 'admin'},
  '/admin/newsetting': {side: AdminMenu, content: NewSetting, tab: 'admin'}
}
