import React, {Component} from 'react'
import DevicesList from './DevicesList'
import UsersList from './UsersList'
import './ViewGroup.scss'
import {GET_GROUPS_BY_COMPANY} from './queries'
import {route} from 'router'
import {Input, InputTable} from 'Components/Form'
import {gql} from 'graphql.macro'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'

const UPDATE_GROUP = gql`
  mutation UPDATE_GROUP(
    $groupID: Int!
    $name: String
    $users: [Int]
    $devices: [Int]
  ) {
    updateGroup(
      groupID: $groupID
      name: $name
      users: $users
      devices: $devices
    ) {
      group {
        id
        name
        company {
          id
        }
        users {
          id
          firstName
          lastName
          canEdit
          isManager
          isSuperUser
        }
        inclusiveDevices {
          id
          name
          isActive
          inService
        }
      }
    }
  }
`

const DELETE_GROUP = gql`
  mutation DELETE_GROUP($groupID: Int!) {
    deleteGroup(groupID: $groupID) {
      groups {
        id
      }
    }
  }
`

class ViewGroup extends Component {
  constructor(props) {
    super(props)
    this.state = this.getCleanState()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.group !== this.props.group)
      this.setState(this.getCleanState())
  }
  getCleanState = () => {
    const group = this.props.group
    return {
      editing: false,
      confirmDeleteGroup: false,
      name: group.name,
      activeUsers: group.users.map((u) => u.id),
      activeDevices: group.inclusiveDevices.map((d) => d.id),
      companyID: group.company ? group.company.id : null
    }
  }
  toggleUser = (id) => {
    if (this.state.activeUsers.includes(id)) {
      this.setState({
        activeUsers: this.state.activeUsers.filter((uID) => uID !== id)
      })
    } else {
      this.setState({activeUsers: [...this.state.activeUsers, id]})
    }
  }
  toggleDevice = (id) => {
    if (this.state.activeDevices.includes(id)) {
      this.setState({
        activeDevices: this.state.activeDevices.filter((dID) => dID !== id)
      })
    } else {
      this.setState({activeDevices: [...this.state.activeDevices, id]})
    }
  }
  render = () => (
    <div className="view-user">
      <div className="row">
        <div className="left">
          <h1>{this.props.group.name}</h1>
          <div className="divider" />
          {this.state.editing ? (
            <div className="buttons">
              <button onClick={() => this.setState(this.getCleanState())}>
                Cancel
              </button>
              {this.state.confirmDeleteGroup ? (
                <button
                  className="red primary"
                  onClick={async () => {
                    await this.props.deleteGroup({
                      variables: {
                        groupID: this.props.group.id
                      },
                      refetchQueries: [
                        {
                          query: GET_GROUPS_BY_COMPANY,
                          variables: {
                            companyID: this.props.group.companyID
                          }
                        }
                      ]
                    })
                    route('/team')
                    window.location.reload(false)
                  }}
                >
                  Confirm Delete Group
                </button>
              ) : (
                <button
                  className="red"
                  onClick={() => this.setState({confirmDeleteGroup: true})}
                >
                  Delete Group
                </button>
              )}
              <button
                className="primary"
                onClick={async () => {
                  await this.props.updateGroup({
                    variables: {
                      groupID: this.props.group.id,
                      name: this.state.name,
                      users: this.state.activeUsers,
                      devices: this.state.activeDevices
                    },
                    refetchQueries: [
                      {
                        query: GET_GROUPS_BY_COMPANY,
                        variables: {companyID: this.props.group.companyID}
                      }
                    ]
                  })
                }}
              >
                Save Changes
              </button>
            </div>
          ) : (
            <div className="buttons">
              <button onClick={() => this.setState({editing: true})}>
                Edit Group
              </button>
            </div>
          )}
        </div>
        <div className="right">
          {this.state.editing ? (
            <InputTable>
              <Input
                type="string"
                label="Group Name"
                onChange={(e) => this.setState({name: e.target.value})}
                value={this.state.name}
                static={!this.state.editing}
              />
              {/* {this.props.isSuperUser && (
              <Query query={GET_COMPANIES}>
                {({loading, error, data}) => (
                  <Input
                    label="Company"
                    type="select"
                    value={this.state.companyID}
                    onChange={(e) => this.setState({companyID: e.target.value})}
                    options={((data && data.companies) || []).map((c) => ({
                      label: c.name,
                      value: c.id
                    }))}
                    static={!this.state.editing}
                  />
                )}
              </Query>
            )} */}
            </InputTable>
          ) : null}
        </div>
      </div>
      <div className="row permissions">
        <div className="col">
          {!this.state.isDemo && (
            <DevicesList
              activeDevices={this.state.activeDevices}
              toggleDevice={this.toggleDevice}
              allDevices={this.props.allDevices}
              editing={this.state.editing}
            />
          )}
        </div>
        <div className="col">
          {!this.state.isDemo && (
            <UsersList
              activeUsers={this.state.activeUsers}
              toggleUser={this.toggleUser}
              allUsers={this.props.allUsers}
              editing={this.state.editing}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default compose(
  graphql(UPDATE_GROUP, {name: 'updateGroup'}),
  graphql(DELETE_GROUP, {name: 'deleteGroup'})
)(ViewGroup)
