import React from 'react'
import {refreshToken} from 'store/actions'
import {connect} from 'react-redux'
import {ReactComponent as FullLogo} from 'assets/logo-full.svg'
import {Input} from 'Components/Form'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import {gql} from 'graphql.macro'
import safeJSONParse from 'utils/safeJSONParse'

import picture from './picture-min.jpg'
import pictureSmall from './picture-small-min.jpg'

import './index.scss'

const REQUEST_PIN = gql`
  mutation REQUEST_PIN($email: String, $phoneNumber: String) {
    requestPin(email: $email, phoneNumber: $phoneNumber) {
      errorMessage
    }
  }
`

const LOGIN = gql`
  mutation LOGIN($email: String, $phoneNumber: String, $pin: Int!) {
    login(email: $email, phoneNumber: $phoneNumber, pin: $pin) {
      authorization
      errorMessage
    }
  }
`

class Login extends React.Component {
  state = {
    loginMethod: 'phoneNumber',
    requestedPin: false,
    phoneNumber: '',
    email: '',
    pin: '',
    errorMessage: null
  }
  componentDidMount() {
    if (localStorage.getItem('requestedPin')) {
      const identity = safeJSONParse(localStorage.getItem('requestedPin'))
      if (identity.email)
        this.setState({requestedPin: true, email: identity.email})
      if (identity.phoneNumber)
        this.setState({requestedPin: true, phoneNumber: identity.phoneNumber})
      localStorage.removeItem('requestedPin')
    }
  }
  requestPin = async () => {
    const identity =
      this.state.loginMethod === 'phoneNumber'
        ? {
            phoneNumber: this.state.phoneNumber
          }
        : {email: this.state.email}
    const {data} = await this.props.requestPin({variables: identity})

    if (data && data.requestPin && !data.requestPin.errorMessage) {
      this.setState({requestedPin: true, errorMessage: null})
      localStorage.setItem('requestedPin', JSON.stringify(identity))
    } else
      this.setState({
        errorMessage:
          (data && data.requestPin && data.requestPin.errorMessage) ||
          'Something went wrong requesting a pin.'
      })
  }
  login = async () => {
    if ((this.state.pin + '').length !== 4)
      return this.setState({errorMessage: 'Please enter a valid pin.'})
    const {data} = await this.props.login({
      variables:
        this.state.loginMethod === 'phoneNumber'
          ? {
              phoneNumber: this.state.phoneNumber,
              pin: this.state.pin
            }
          : {email: this.state.email, pin: this.state.pin}
    })
    if (data && data.login && !data.login.errorMessage) {
      this.props.setAuthorization(data.login.authorization)
      localStorage.removeItem('requestedPin')
    } else
      this.setState({
        errorMessage:
          (data && data.login && data.login.errorMessage) ||
          'Something went wrong requesting a pin.'
      })
  }
  resetLogin = () => {
    this.setState({requestedPin: false, pin: '', errorMessage: null})
    localStorage.removeItem('requestedPin')
  }
  renderSignInPhone = () => (
    <div className="body">
      <p className="instructions">
        Enter your phone number to receive a one-time passcode to access your
        account.
      </p>
      <Input
        label="Phone Number"
        className="phone-number-input"
        type="phone"
        value={this.state.phoneNumber}
        onChange={e => this.setState({phoneNumber: e.target.value})}
        onKeyUp={e => (e.keyCode === 13 ? this.requestPin() : null)}
      />
      <button
        className={
          this.props.isFetching
            ? 'login-button waiting primary'
            : 'login-button primary'
        }
        disabled={this.props.isFetching}
        onClick={!this.props.isFetching && this.requestPin}
      >
        Text me a passcode
      </button>
      <p className="login-method">
        I don't have my phone. Sign in with my{' '}
        <span
          className="link"
          onClick={() => this.setState({loginMethod: 'email'})}
        >
          email
        </span>
        .
      </p>
    </div>
  )
  renderSignInEmail = () => (
    <div className="body">
      <p className="instructions">
        Enter your email address to receive a one-time passcode to access your
        account.
      </p>
      <div className="input-line">
        <Input
          label="Email"
          type="text"
          value={this.state.email}
          onChange={e => this.setState({email: e.target.value})}
          onKeyUp={e => (e.keyCode === 13 ? this.requestPin() : null)}
        />
      </div>
      <button
        disabled={this.props.isFetching}
        className={
          this.props.isFetching
            ? 'login-button waiting primary'
            : 'login-button primary'
        }
        onClick={!this.props.isFetching && this.requestPin}
      >
        Email me a passcode
      </button>
      <p className="login-method">
        I can't access my email. Sign in with my{' '}
        <span
          className="link"
          onClick={() => this.setState({loginMethod: 'phoneNumber'})}
        >
          phone
        </span>
        .
      </p>
    </div>
  )
  renderPin = () => (
    <div className="body">
      <p className="instructions">
        You will receive a message with a 4 digit PIN number. Please enter it
        below.
      </p>
      <div className="input-line">
        <Input
          label="Pin"
          valid={this.state.pin.toString().length === 4 ? true : undefined}
          type="number"
          value={this.state.pin}
          onChange={e => this.setState({pin: e.target.value})}
          onKeyUp={e => (e.keyCode === 13 ? this.login() : null)}
        />
      </div>
      <button className="submit-pin-button primary" onClick={this.login}>
        Submit Pin
      </button>
      <p className="login-method">
        I didn't receive a pin.{' '}
        <span className="link" onClick={this.resetLogin}>
          Try again
        </span>
        .
      </p>
    </div>
  )
  render() {
    if (this.props.loggedIn) return this.props.children

    return (
      <div className="login">
        <div
          className="login-picture"
          style={{
            background: `url(${picture})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />
        <div className="login-card">
          <FullLogo />

          <div className="header desktop">Sign in to access your dashboard</div>
          <div
            className="header mobile"
            style={{
              background: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ),url(${pictureSmall})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }}
          >
            Sign in to access your dashboard
          </div>
          {this.state.requestedPin
            ? this.renderPin()
            : this.state.loginMethod === 'phoneNumber'
            ? this.renderSignInPhone()
            : this.renderSignInEmail()}

          {!!this.state.errorMessage && (
            <div className="footer error">
              <i className="fa fa-exclamation-triangle" />{' '}
              {this.state.errorMessage}
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loggedIn: !!state.user.authorization
})
const mapDispatchToProps = dispatch => ({
  setAuthorization: authorization => dispatch(refreshToken(authorization))
})

export default compose(
  graphql(LOGIN, {name: 'login'}),
  graphql(REQUEST_PIN, {name: 'requestPin'}),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Login)
