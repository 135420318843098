import Slider from '@material-ui/core/Slider'
import React from 'react'
import Input from '@material-ui/core/Input'
import Tooltip from 'Components/Tooltip'

const SliderComponent = (props) => {
  const handleSliderChange = (e, newValue) => {
    props.onChange(newValue)
  }

  const handleInputChange = (e) => {
    props.onChange(e.target.value === '' ? '' : parseFloat(e.target.value))
  }

  const handleBlur = () => {
    if (props.value < props.min) {
      props.onChange(props.min)
    } else if (props.value > props.max) {
      props.onChange(props.max)
    }
  }

  return (
    <div className={props.className ? props.className : ''}>
      <Slider
        value={typeof props.value === 'number' ? props.value : 0}
        onChange={handleSliderChange}
        min={props.min}
        max={props.max}
        step={props.step}
        marks={props.marks}
        defaultValue={props.defaultValue}
        style={{width: '5em'}}
      />
      <Input
        value={props.value}
        onChange={handleInputChange}
        onBlur={handleBlur}
        inputProps={{
          step: props.step,
          min: props.min,
          max: props.max,
          type: 'number'
        }}
        style={{font: 'revert', marginLeft: '0.5em'}}
      ></Input>
      {props.tooltip ? <Tooltip>{props.tooltip}</Tooltip> : null}
    </div>
  )
}

export default SliderComponent
