import React from 'react'
import calcIsSubpath from 'utils/isSubpath'
import {route, getPath} from 'router'
import {searchNode} from 'utils/mapDevicesToTree'
import TagClass from 'utils/Tag'
import {connect} from 'react-redux'
import {closeMenu} from 'store/actions'

const isAlarm = ({tags}) => (tags || []).some(t => t.alarms.flag)

const _filterVisibleTags = (tags, defaultTag) =>
  tags
    .filter(t => t.tag === defaultTag)

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch => ({
  closeMenu: () => dispatch(closeMenu())
})
const Menu = connect(
  mapStateToProps,
  mapDispatchToProps
)(props => (
  <ul>
    {Object.keys(props.tree.children)
      .sort((a, b) => a.localeCompare(b))
      .map((k, i) => {
        const subpath = (props.rootPath || '') + k
        const isSubpath = calcIsSubpath(subpath, props.directoryPath)
        const node = props.tree.children[k]

        return (
          <MenuFolder
            key={i}
            onClick={e => clickHandler(e, subpath, props)}
            open={isSubpath || props.open}
            alarm={searchNode(node, isAlarm)}
            text={k.substr(1)}
          >
            <Menu {...props} rootPath={subpath} tree={props.tree.children[k]} />
          </MenuFolder>
        )
      })}
    {props.tree.devices
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .reduce(
        (arr, d) =>
          arr.concat(
            _filterVisibleTags(d.tags, d.defaultTag)
              .map(t => new TagClass(t))
              .filter(t => t.tag[0] !== '_')
              .map(t => (
                <MenuItem
                  key={t.deviceID + '-' + t.tag}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.closeMenu()
                    route(`/devices/${d.id}/tags/${t.tag}`)
                  }}
                  alarm={!!t.alarms.flag}
                  warning={false}
                  text={`[${d.id}] : ${d.name || 'New Device'}`}
                />
              ))
          ),
        []
      )}
  </ul>
))

export const MenuItem = ({onClick, alarm, text}) => (
  <li onClick={onClick}>
    <div className="clickable menu-item">
      {alarm && <div className="dot red" />}
      <span className="text ellipsis" style={{color:'rgb(26, 56, 84)'}}>{text}</span>
    </div>
  </li>
)

export const MenuFolder = ({open, alarm, text, onClick, children}) => (
  <li onClick={onClick}>
    <div className={'clickable menu-folder' + (open ? ' active' : '')}>
      {!open && alarm && <div className="dot red" />}
      <span className="text ellipsis">{text}</span>
      <span className="folder-arrow">
        <i className={open ? 'fa fa-angle-up' : 'fa fa-angle-down'} />
      </span>
    </div>
    {open && children}
  </li>
)

const clickHandler = (e, newPath, props) => {
  e.stopPropagation()
  e.preventDefault()

  if (newPath === props.directoryPath && getPath() === '/directory') {
    route('/directory')
    props.setDirectoryPath(newPath.substr(0, newPath.lastIndexOf('/')))
  } else {
    route('/directory')
    props.setDirectoryPath(newPath)
  }
}

export default Menu
