export default {
  1: 'Start Up',
  2: 'User shutdown',
  3: 'Sensor failure shutdown',
  4: 'Hibernate  - Low Battery',
  5: 'Wake from low battery hibernation',
  6: 'Hibernate - Low Temperature',
  7: 'Wake from low temperature hibernation',
  8: 'Sensor error',
  9: 'Internal failure shutdown',
  10: 'User timing change',
  11: 'System timing change',
  12: 'Comm error - consecutive send attempts',
  13: 'Comm error - CEREG',
  14: 'Comm error - server acknowledge',
  15: 'Comm error - QIURC',
  16: "Comm error - 'Data received'",
  17: 'Comm error - consecutive drain queue',
  18: 'Comm error - couldn\'t establish TCP connection',
  19: 'Operator change',
  20: 'Looking for new Operator',
  21: 'EDRX Success',
  22: 'EDRX Failure',
  23: 'PSM time refreshed',
  24: 'Now connected to unknown operator',
  25: 'Now connected to Verizon',
  26: 'Now connected to T-Mobile',
  27: 'Now connected to AT&T',
  30: 'Shutdown forced button',
  31: 'Shutdown I2C fault',
  51: 'Sensor Error - EPROM (1)',
  52: 'Sensor Error - EPROM (2)',
  53: 'Sensor Error - Device (1)',
  54: 'Sensor Error - Device (2)',
  55: 'Sensor Error - Calculation (1)',
  56: 'Sensor Error - Calculation (2)',
  57: 'Modem - Provisioning',
  58: 'Modem - Powerup Failure',
  59: 'Modem - Verizon not found (startup)',
  60: 'Modem - T-Mobile not found (startup)',
  61: 'Modem - New Operator not found',
  62: 'Modem - CSQ failure',
  63: 'Modem - Time Failure (Couldn\'t get time)',
  64: 'Modem - Time Failure (Wrong Time)',
  65: 'Modem - Close socket Failure',
  66: 'Modem - Wait For Response (wrong response)',
  67: 'Modem - Wait For Response (timeout)',
  68: 'Start Up - FOTA',
  69: 'Modem - Set IPR to 19200',
  70: 'Start Up - Reboot',
}
