import React from 'react'
import Modal from '../Components/Modal'
import {connect} from 'react-redux'
import {loginAs} from '../store/actions'
import {gql} from 'graphql.macro'
import {Query, withApollo} from 'react-apollo'
import {name as formatName} from 'utils/format'

const GET_USERS = gql`
  query GET_USERS {
    users {
      id
      firstName
      lastName
    }
  }
`

class LoginAs extends React.Component {
  state = {id: false}
  login = () => {
    this.props.loginAs(this.state.id)
    this.props.onClose()
  }
  render = () => (
    <Query query={GET_USERS}>
      {({loading, error, data}) =>
        loading || error ? null : (
          <Modal onClose={this.props.onClose}>
            <Modal.Title>Login As</Modal.Title>
            <Modal.Body>
              <select onChange={e => this.setState({id: e.target.value})}>
                {this.state.id === false ? <option value={false} /> : null}
                {data.users.map(u => (
                  <option key={u.id} value={u.id}>
                    {formatName(u)}
                  </option>
                ))}
              </select>
            </Modal.Body>
            <Modal.Footer>
              <span className="button" onClick={this.props.onClose}>
                Cancel
              </span>
              <span
                className="button primary"
                disabled={this.state.id === false}
                onClick={this.login}
              >
                Login As
              </span>
            </Modal.Footer>
          </Modal>
        )
      }
    </Query>
  )
}

const mapStateToProps = state => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({
  loginAs: id => dispatch(loginAs({id, client: ownProps.client}))
})

export default withApollo(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginAs)
)
