import React from 'react'
import {name as formatName} from 'utils/format'
import {Link} from 'router'

import './UsersList.scss'

const UsersList = ({allUsers, activeUsers, toggleUser, editing}) => {
  return (
    <div className="permissions-paths box">
      <div className="title">Users: {activeUsers.length}</div>
      <div className="body">
        <UsersMenu
          allUsers={allUsers}
          activeUsers={activeUsers}
          toggleUser={toggleUser}
          editing={editing}
        />
      </div>
    </div>
  )
}

const UsersMenu = ({allUsers, activeUsers, toggleUser, editing}) => {
  if (!allUsers) {
    return null
  }
  const compare = (a, b) => {
    if (a.lastName === null) {
      return 1
    }
    if (b.lastName === null) {
      return -1
    }
    if (a.lastName.toUpperCase() > b.lastName.toUpperCase()) {
      return 1
    } else if (a.lastName.toUpperCase() < b.lastName.toUpperCase()) {
      return -1
    } else {
      if (a.firstName === null) {
        return 1
      }
      if (b.firstName === null) {
        return -1
      }
      if (a.firstName.toUpperCase() > b.firstName.toUpperCase()) {
        return 1
      } else if (a.firstName.toUpperCase() < b.firstName.toUpperCase()) {
        return -1
      } else {
        return 0
      }
    }
  }
  return (
    <ul>
      {allUsers.sort(compare).map((u) => {
        const allowed = activeUsers.includes(u.id)
        return (
          <li
            key={u.id}
            className={'permissions-directory ' + (allowed ? 'allowed' : '')}
          >
            <input
              type="checkbox"
              data-path={u.id}
              checked={allowed}
              disabled={!editing}
              onChange={() => {
                toggleUser(u.id)
              }}
            />
            {!!editing ? (
              formatName(u)
            ) : (
              <Link path={`/team/user/${u.id}`}>
                <div style={{color: '#2c5f8e'}}>{formatName(u)}</div>
              </Link>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default UsersList
