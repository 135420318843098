import React from 'react'

import './Tooltip.scss'

const Tooltip = ({iconClassName, left, children, noIcon, color, onClick}) => (
  <span className="tooltip">
    {!!noIcon ? null : (
      <i
        className={iconClassName || 'fa fa-info-circle'}
        style={{color: color ? color : '#b5c1cd'}}
        onClick={onClick ? onClick : null}
      />
    )}
    <span
      className="tooltip-text"
      style={left ? {right: 30, zIndex: 100} : {left: 15, zIndex: 100}}
    >
      {children}
    </span>
  </span>
)

export default Tooltip
