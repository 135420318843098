import React from 'react'
import './Loading.scss'
import {ReactComponent as Icon} from 'assets/loading.svg'

const DISPLAY_DELAY = 300 // delay before showing the loading indicator in ms

class Loading extends React.Component {
  state = {show: !!this.props.nowait}
  componentDidMount = () =>
    (this.timeout = setTimeout(() => {
      this.setState({show: true})
    }, DISPLAY_DELAY))
  componentWillUnmount = () => clearTimeout(this.timeout)
  render = () =>
    this.state.show && (
      <div className="loading-container">
        <Icon />
      </div>
    )
}

export default Loading
