export default {
  primaryDarkest: 'rgb(30, 45, 61)',
  primaryDark: 'rgb(26, 56, 84)',
  primary: 'rgb(181, 193, 205)',
  primaryLight: 'rgb(222, 228, 232)',
  primaryLightest: '#f6f8f9',
  red: '#d75452',
  solidRed: 'rgb(252, 40, 3)',
  yellow: 'rgb(206, 167, 31)',
  solidYellow: 'rgb(252, 244, 3)',
  green: 'rgb(79, 208, 79)',
  solidGreen: 'rgb(0, 255, 8)',
  greenText: '#32ba32',
  orange: 'rgb(247, 169, 53)',
  purple: 'rgb(132, 61, 197)',
  grey: '#7d848a',
  blue: 'rgb(0, 102, 197)',
  seaFoam: 'rgb(0, 197, 197)',
  ochre: 'rgb(184, 197, 0)',
  magenta: 'rgb(197, 0, 105)',
  brightGreen: 'rgb(51,255,0)'
}
