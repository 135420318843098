import React, {Component} from 'react'
import {dateTime as formatDateTime} from 'utils/format'
import findClosestPoint from 'utils/findClosestPoint'
import downloadCSV from 'utils/downloadCSV'
import {UPDATE_BPI, UPDATE_REPORT_HOUR} from './queries'
import * as compose from 'lodash/flowRight'
import {graphql} from 'react-apollo'

import './ProductionReportLevel.scss'

const getInitialState = (props) => ({
  barrelsPerInch: props.Tag.barrelsPerInch || 1.67,
  monthOffset: 0,
  reportHour: props.Tag.reportHour || 6,
})

class ProductionReportLevel extends Component {

  state = getInitialState(this.props)
  componentDidUpdate({Tag}) {
    if (Tag !== this.props.Tag || Tag.deviceID !== this.props.Tag.deviceID) {
      this.setState(getInitialState(this.props))
    }
  }

  //condition for if the tank is chemical, has tank name, and user has selected gallons as display unit
  switchCase = this.props.Tag.calibration.tankName && this.props.Tag.displayUnits === 'gln'
  isTank = this.props.Tag.tag === 'spsr' || this.props.Tag.tag === 'dspsr'
  
  getRows = () => {
    const now = new Date()
    //start at the 31st of the previous month so we can calculate the daily production on the 1st
    let time = new Date(
      now.getFullYear(),
      now.getMonth() - this.state.monthOffset,
      0,
      this.state.reportHour
    )
    let rows = []
    do {
      const index = findClosestPoint(time.getTime() / 1000, this.props.Tag.data)
      const point = this.props.Tag.data[index]
      
      const inRange =
        point && Math.abs(point[0] - time.getTime() / 1000) < 3 * 60 * 60

      //add comments on the same day
      const comments = this.props.device.comments.filter((i) => formatDateTime(i.time).slice(0, 8).toString() === formatDateTime(time).slice(0, 8).toString())
      let array = []
      comments.forEach((i) => array.push(' ***' + i.comment))

      // Regular tanks (non-chemical) or without a tank selected
      if(!this.switchCase){
        const reading =
          inRange &&
          (this.props.Tag.displayUnits === 'in'
            ? point[1] / 12
            : this.props.Tag.displayUnits === 'ft'
            ? point[1]
            : 0)
        const lastReading =
          inRange && rows.length && rows[rows.length - 1].reading

        rows.push({
          time: formatDateTime(time),
          reading: inRange ? reading.toFixed(2) : false,
          feet: inRange ? Math.floor(reading) : false,
          inches: inRange ? ((reading * 12) % 12).toFixed(1) : false,
          totalBarrels: inRange
            ? (this.state.barrelsPerInch * 12 * reading).toFixed(2)
            : false,
          dailyProduction:
            inRange && lastReading !== false
              ? (
                  this.state.barrelsPerInch *
                  12 *
                  (reading - lastReading)
                ).toFixed(2)
              : false,
          comments: array ? array : false
        })

        // Chemical tanks and must have a tank selected + have gallons selected
      } else {
        const reading =
          inRange &&
          (this.props.Tag.displayUnits === 'gln' ? point[1] : 0)
        const lastReading =
          inRange && rows.length && rows[rows.length - 1].reading

        rows.push({
          time: formatDateTime(time),
          reading: inRange ? reading.toFixed(2) : false,
          gallons: inRange ? reading.toFixed(2) : false,
          dailyProduction:
            inRange && lastReading !== false
              ? (
                  (reading - lastReading)
                ).toFixed(2)
              : false,
          comments: array ? array : false
        })
      }
      time.setDate(time.getDate() + 1)
    } while (time.getDate() !== 1 || rows.length < 5)
    return rows.slice(1) //remove the first row which corresponds to the 31st of the previous month
  }
  updateBarrelsPerInch = (barrelsPerInch) => {
    this.setState({barrelsPerInch})
    if (this.debounce) clearTimeout(this.debounce)
    this.debounce = setTimeout(
      () =>
        this.props.updateBPI({
          variables: {
            deviceID: this.props.Tag.deviceID,
            tag: this.props.Tag.tag,
            barrelsPerInch: barrelsPerInch
          }
        }),
      2000
    )
  }

  updateReportHour = (reportHour) => {
    this.setState({reportHour})
    this.props.updateReportHour({
      variables: {
        deviceID: this.props.Tag.deviceID,
        tag: this.props.Tag.tag,
        reportHour: reportHour
      }
    })
  }
  downloadSpreadsheet = () => {
    // For regular tanks (non-chemical) and those without calibration
    if(!this.switchCase){
      const rows = this.getRows().map((r) => [
        r.time,
        r.feet !== false ? r.feet : '',
        r.inches !== false ? r.inches : '',
        r.totalBarrels !== false ? r.totalBarrels : '',
        r.dailyProduction !== false ? r.dailyProduction : '',
        r.comments !== false ? r.comments : ''
      ])

      downloadCSV(this.props.device.name + ' Production Report', rows, [
        'Date',
        'Feet',
        'Inches',
        'Total Barrels',
        'Daily Production',
        'Comments'
      ])
      // For chemical tanks, calibrated devices which have been given a tank name
    } else {
      const rows = this.getRows().map((r) => [
        r.time,
        r.gallons !== false ? r.gallons : '',
        r.dailyProduction !== false ? r.dailyProduction : '',
        r.comments !== false ? r.comments : ''
      ])

      downloadCSV(this.props.device.name + ' Consumption Report', rows, [
        'Date',
        'Gallons',
        'Daily Consumption',
        'Comments'
      ])
    }
  }
  render() {
    const rows = this.getRows().map((r, i) => (
      <tr key={i}>
        <td className="date">{r.time}</td>
        {!this.switchCase && (<td>{r.feet !== false ? r.feet : '---'}</td>)}
        {!this.switchCase && (<td>{r.inches !== false ? r.inches : '---'}</td>)}
        {!this.switchCase ? <td>{r.totalBarrels !== false ? r.totalBarrels : '---'}</td> : <td>{r.gallons !== false ? r.gallons : '---'}</td>}
        <td>
          <span
            className={
              r.dailyProduction !== false &&
              r.dailyProduction < -12 * this.state.barrelsPerInch
                ? 'highlight'
                : ''
            }
          >
            {r.dailyProduction !== false ? r.dailyProduction : '---'}
          </span>
        </td>
        <td className="comment">
          {r.comments !== false ? r.comments : ''}
        </td>
      </tr>
    ))

    return (
      <div className="production-report">
        <div className="toolbar">
          <div className="options">
            {!this.switchCase && (<div className="label">Barrels/Inch:</div>)}
            {!this.switchCase && (<input
              type="number"
              id="barrels-per-inch-input"
              value={this.state.barrelsPerInch}
              onChange={(e) =>
                this.updateBarrelsPerInch(parseFloat(e.target.value))
              }
            />)}
            <div className="label">Report Hour:</div>
            <select
              id="barrels-per-inch-input"
              value={this.state.reportHour}
              onChange={(e) => {
                this.updateReportHour(parseInt(e.target.value))
              }}
            >
              <option value={0}>00:00</option>
              <option value={1}>01:00</option>
              <option value={2}>02:00</option>
              <option value={3}>03:00</option>
              <option value={4}>04:00</option>
              <option value={5}>05:00</option>
              <option value={6}>06:00</option>
              <option value={7}>07:00</option>
              <option value={8}>08:00</option>
              <option value={9}>09:00</option>
              <option value={10}>10:00</option>
              <option value={11}>11:00</option>
              <option value={12}>12:00</option>
              <option value={13}>13:00</option>
              <option value={14}>14:00</option>
              <option value={15}>15:00</option>
              <option value={16}>16:00</option>
              <option value={17}>17:00</option>
              <option value={18}>18:00</option>
              <option value={19}>19:00</option>
              <option value={20}>20:00</option>
              <option value={21}>21:00</option>
              <option value={22}>22:00</option>
              <option value={23}>23:00</option>
              <option value={24}>24:00</option>
            </select>
            <div className="label">Month:</div>
            <select
              value={this.state.monthOffset}
              onChange={(e) => this.setState({monthOffset: e.target.value})}
            >
              {new Array(12).fill(0).map((e, i) => {
                const date = new Date()
                date.setMonth(date.getMonth() - i, 1)
                return (
                  <option key={i} value={i}>
                    {date.getMonth() + 1} / {date.getFullYear()}
                  </option>
                )
              })}
            </select>
          </div>
          <div>
            <span className="button" onClick={this.downloadSpreadsheet}>
              <span>Download Spreadsheet</span>
              <i className="fa fa-download" />
            </span>
          </div>
        </div>
        <div className="body">
          <table>
            <thead>
              <tr>
                <th className="date">Date</th>
                {!this.switchCase && (<th>Feet</th>)}
                {!this.switchCase && (<th>Inches</th>)}
                {!this.switchCase ? <th>Total Barrels</th> : <th>Gallons</th>}
                {!this.switchCase ? <th>Daily Production</th> : <th>Daily Consumption</th>}
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default compose(
  graphql(UPDATE_BPI, {name: 'updateBPI'}),
  graphql(UPDATE_REPORT_HOUR, {name: 'updateReportHour'})
)(ProductionReportLevel)
