export const toggleMenu = () => ({type: 'TOGGLE_MENU'})
export const openMenu = () => ({type: 'OPEN_MENU'})
export const closeMenu = () => ({type: 'CLOSE_MENU'})

export const setMobile = (value) => ({
  type: 'SET_MOBILE',
  value: value
})

export const refreshToken = (authorization) => {
  try {
    const token = JSON.parse(atob(authorization.split('.')[1]))
    if (window.heap) {
      window.heap.identify(token.id)
      window.heap.addUserProperties(token)
    }
    if (window.Rollbar)
      window.Rollbar.configure({
        payload: {
          user: token
        }
      })

    return {
      type: 'REFRESH_TOKEN',
      token,
      authorization
    }
  } catch (e) {
    console.log('Bad authorization.')
    return {}
  }
}

export const loginAs = ({id, client}) => (dispatch, getState) => {
  dispatch({type: 'LOGIN_AS', userID: id})
  client.resetStore()
}

export const logout = ({client}) => (dispatch, getState) => {
  if (getState().user.loginAsUserID) dispatch({type: 'LOGIN_AS', userID: null})
  else dispatch({type: 'LOGOUT'})

  client.resetStore()
}

export const setDirectoryPath = (path) => ({type: 'SET_DIRECTORY_PATH', path})
export const setDirectoryCompany = (id) => {
  return ({
    type: 'SET_DIRECTORY_COMPANY',
    id
  })
}
export const setDirectoryGroup = (id) => ({
  type: 'SET_DIRECTORY_GROUP',
  id
})
export const toggleIncludeInactive = (bool) => ({
  type: 'TOGGLE_DIRECTORY_INCLUDE_INACTIVE',
  bool
})
export const toggleIncludeOOS = (bool) => ({
  type: 'TOGGLE_DIRECTORY_INCLUDE_OOS',
  bool
})
export const toggleIncludeVIA = (bool) => ({
  type: 'TOGGLE_DIRECTORY_INCLUDE_VIA',
  bool
})
export const setViewType = (viewType) => ({
  type: 'SET_DIRECTORY_VIEW_TYPE',
  viewType
})
export const setRefetchGroupsByCompany = (refetch) => ({
  type: 'SET_REFETCH_GROUPS_BY_COMPANY',
  refetch
})
