import React, {Component} from 'react'
import isSubpath from 'utils/isSubpath'
import {Query} from 'react-apollo'
import mapDevicesToTree from 'utils/mapDevicesToTree'
import { gql } from 'graphql.macro'

export const GET_DIRECTORY_INPUT = gql`
  query GET_DIRECTORY_INPUT($query: String) {
    devicesRe(query: $query) {
      path
    }
  }
`
const DirectoryInputContainer = props => (
  <Query query={GET_DIRECTORY_INPUT} variables={{query: 'path-input'}}>
    {({loading, error, data}) => {
      if (error || !data || !data.devicesRe) return null
      const tree = mapDevicesToTree(
        data.devicesRe.concat({id: null, path: props.value})
      )
      return <DirectoryInput {...props} tree={tree} />
    }}
  </Query>
)

class DirectoryInput extends Component {
  state = {open: false, newFolderName: '', newFolderPath: null}
  componentDidMount() {
    document.addEventListener('mousedown', this.clickOutside)

  
    if (this.props.tree){
      this.findOrCreateNode(this.props.value, this.props.tree);
    }
  }
  findOrCreateNode = (path, node) => {

    const pathCheck = path ? path : ''
    const parts = pathCheck.split('/').filter(Boolean); // Split path into parts and remove empty string
    let currentNode = node;

    // Traverse or create nodes based on path parts
    for (const part of parts){
      if (!currentNode.children[part]){
        currentNode.children[part]={children: {}};
      }
      currentNode = currentNode.children[part];
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutside)
  }
  clickOutside = e => {
    if (this.ref && !this.ref.contains(e.target)) this.setState({open: false})
  }
  onChange = path =>
    this.props.onChange({
      target: {
        dataset: this.props['data-key'] ? {key: this.props['data-key']} : {},
        value: path
      }
    })
  renderNode = (node, path = '') => (
    <ul>
      {Object.keys(node.children)
        .sort((a, b) => a.localeCompare(b))
        .map(f => (
          <li key={f}>
            <div
              onClick={() => this.onChange(path + f)}
              className={
                this.props.value === path + f
                  ? 'clickable item selected'
                  : 'clickable item'
              }
            >
              {f.substr(1)}
            </div>
            {this.props.value &&
              isSubpath(path + f, this.props.value) &&
              this.renderNode(node.children[f], path + f)}
          </li>
        ))}
      {(this.props.value === path || path === '') && (
        <li
          className="clickable"
          onClick={() => {

            this.setState({newFolderPath: path})}}
        >
          {this.state.newFolderPath === path ? (
            this.renderNewFolder(() => {
              node['/' + this.state.newFolderName] = {}
              this.onChange(
                `${this.state.newFolderPath}/${this.state.newFolderName}`
              )
              this.setState({newFolderPath: null, newFolderName: ''})
            })
          ) : (
            <div className="item add-folder">
              <i className="fa fa-plus" />
              New Folder
            </div>
          )}
        </li>
      )}
    </ul>
  )
  renderNewFolder = onAdd => (
    <div className="add-folder-input">
      <input
        value={this.state.newFolderName}
        onChange={e => this.setState({newFolderName: e.target.value})}
        onKeyDown={e => {
          if (e.keyCode === 13 || e.which === 13) onAdd()
        }}
      />
      <span className="button" onClick={onAdd}>
        <i className="fa fa-plus" />
      </span>
    </div>
  )
  render() {
    if (this.props.static)
      return this.props.value && this.props.value.length ? (
        this.props.value
      ) : (
        <em>None</em>
      )
    return (
      <div className="directory-input">
        <div className="link" onClick={() => this.setState({open: true})}>
          {this.props.value && this.props.value.length ? (
            this.props.value
          ) : (
            <em>None</em>
          )}
        </div>
        {this.state.open && (
          <div className="browser" ref={ref => (this.ref = ref)}>
            {this.renderNode(this.props.tree)}
          </div>
        )}
      </div>
    )
  }
}

export default DirectoryInputContainer
