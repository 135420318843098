import React, {useEffect, useState} from 'react'
import axios from '../../../utils/getAxios'

import './index.scss'

const getFotaSignatureValue = (fotaRelocateMapping, relocateSignature) => {
  const currentlySelectedPair = fotaRelocateMapping.find((pair) => pair.relocate_image_crc_id === relocateSignature)

  return currentlySelectedPair ? currentlySelectedPair.fota_image_crc_id : ''
}

export default () => {
  const [fotaRelocateMapping, setFotaRelocateMapping] = useState([])
  const [relocateSignature, setRelocateSignature] = useState('')
  const [fotaSignature, setFotaSignature] = useState('')

  useEffect(() => {
    const fetchMapping = async () => {
      const {data} = await axios.get('/api/fota/signature-mappings')
      const initialRelocateSignature = data && data[0].relocate_image_crc_id
      const initialFotaSignature = getFotaSignatureValue(data, initialRelocateSignature)

      setFotaRelocateMapping(data)
      setRelocateSignature(initialRelocateSignature)
      setFotaSignature(initialFotaSignature)
    }

    fetchMapping()
      .catch(console.error)
  }, [])

  const updateFotaSignatureForMapping = () => axios.put('/api/fota/signature-mappings', {fotaSignature, relocateSignature})

  return (
    <div className='signature-mapping'>
      <h3>Relocate Signature</h3>
      <select
        onChange={(e) => {
          setRelocateSignature(e.target.value)
          setFotaSignature(getFotaSignatureValue(fotaRelocateMapping, e.target.value))
        }}>
        {fotaRelocateMapping.map((pair) => <option key={pair.relocate_image_crc_id} value={pair.relocate_image_crc_id}>{pair.relocate_image_crc_id}</option>)}
      </select>
      <h3>FOTA Signature</h3>
      <input
        maxLength={8}
        type='text'
        value={fotaSignature}
        onChange={(e) => setFotaSignature(e.target.value)}>
      </input>
      <button onClick={updateFotaSignatureForMapping}>Update Mapping</button>
    </div>
  )
}
