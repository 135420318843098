import React from 'react'
import {linkHandler} from 'router'

import './index.scss'

export default () => null

export const Menu = () => (
  <div className="admin-menu">
    <ul>
      <li className="clickable" onClick={linkHandler('/admin/newPO')}>
        New P.O.
      </li>
      <li className="clickable" onClick={linkHandler('/admin/stickers')}>
        Stickers
      </li>
      <li className="clickable" onClick={linkHandler('/admin/deviceIDs')}>
        Device IDs
      </li>
      <li className="clickable" onClick={linkHandler('/admin/billing')}>
        Billing
      </li>
      <li className="clickable" onClick={linkHandler('/admin/messageLog')}>
        Message Log
      </li>
      <li className="clickable" onClick={linkHandler('/admin/fota')}>
        FOTA Management
      </li>
      <li className="clickable" onClick={linkHandler('/admin/newsetting')}>
        New Setting
      </li>
    </ul>
  </div>
)
