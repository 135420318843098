import React, {Component} from 'react'
import {dateTime as formatDateTime} from 'utils/format'
import findClosestPoint from 'utils/findClosestPoint'
import findClosestPointObjectArray from 'utils/findClosestPointObjectArray'
import downloadCSV from 'utils/downloadCSV'
import {UPDATE_REPORT_HOUR} from './queries'
import * as compose from 'lodash/flowRight'
import {graphql} from 'react-apollo'

import './ProductionReportTurbine.scss'

const getInitialState = (props) => ({
  kFactor: props.Tag.calibration.kFactor || 2500,
  monthOffset: 0,
  reportHour: props.Tag.reportHour || 6,
  displayUnits: props.Tag.additionalTagData[0].displayUnits || 'bbls'
})

class ProductionReportTurbine extends Component {
  state = getInitialState(this.props)
  componentDidUpdate({Tag}) {
    if (Tag !== this.props.Tag || Tag.deviceID !== this.props.Tag.deviceID) {
      this.setState(getInitialState(this.props))
    }
  }

  getRows = () => {
    const now = new Date()
    //start at the 31st of the previous month so we can calculate the daily production on the 1st
    let time = new Date(
      now.getFullYear(),
      now.getMonth() - this.state.monthOffset,
      0,
      this.state.reportHour
    )
    let rows = []
    do {
      const index = findClosestPoint(
        time.getTime() / 1000,
        this.props.Tag.data,
        1
      )
      const indexCumulative = findClosestPointObjectArray(
        time.getTime() / 1000,
        this.props.Tag.additionalTagData[0].data,
        1
      )
      const point = this.props.Tag.data[index]
      const pointCumulative =
        this.props.Tag.additionalTagData[0].data[indexCumulative]
      const inRange =
        point &&
        point[0] > time.getTime() / 1000 &&
        point[0] - time.getTime() / 1000 < 24 * 60 * 60

      const reading = inRange && point[1]

      // const lastReading =
      //   inRange && rows.length && rows[rows.length - 1].reading

      const inRangeCumulative =
        pointCumulative &&
        pointCumulative.time - time.getTime() / 1000 < 24 * 60 * 60

      const total = inRangeCumulative && pointCumulative.value.toFixed(2)

      const lastTotal =
        inRangeCumulative && rows.length && rows[rows.length - 1].total

      rows.push({
        time: inRange ? formatDateTime(point[0]) : formatDateTime(time),
        rate: inRange ? reading.toFixed(2) : false,
        daily:
          inRangeCumulative && total && lastTotal && total - lastTotal >= 0
            ? (total - lastTotal).toFixed(2)
            : false,
        total: inRangeCumulative ? total : false
        // totalBarrels: inRange
        //   ? (this.state.barrelsPerInch * 12 * reading).toFixed(2)
        //   : false,
        // dailyProduction:
        //   inRange && lastReading !== false
        //     ? (
        //         this.state.barrelsPerInch *
        //         12 *
        //         (reading - lastReading)
        //       ).toFixed(2)
        //     : false
      })
      if (rows.length > 1) {
        rows[rows.length - 2].daily =
          rows[rows.length - 1].total - rows[rows.length - 2].total >= 0
            ? (
                rows[rows.length - 1].total - rows[rows.length - 2].total
              ).toFixed(2)
            : false
      }
      time.setDate(time.getDate() + 1)
    } while (time.getDate() !== 1 || rows.length < 5)
    return rows.slice(1) //remove the first row which corresponds to the 31st of the previous month
  }

  updateReportHour = (reportHour) => {
    this.setState({reportHour})
    this.props.updateReportHour({
      variables: {
        deviceID: this.props.Tag.deviceID,
        tag: this.props.Tag.tag,
        reportHour: reportHour
      }
    })
  }
  downloadSpreadsheet = () => {
    const rows = this.getRows().map((r) => [
      r.time,
      r.rate !== false ? r.rate : '',
      r.daily !== false ? r.daily : ''
      // r.total !== false ? r.total : ''
    ])
    downloadCSV(this.props.device.name + ' Production Report', rows, [
      'Date',
      'Rate (bbl/day)',
      'Daily Production (bbls)'
      // 'Total Barrels (bbls)'
    ])
  }
  render() {
    const rows = this.getRows().map((r, i) => (
      <tr key={i}>
        <td className="date">{r.time}</td>
        <td>{r.rate !== false ? r.rate : '---'}</td>
        <td>{r.daily !== false ? r.daily : '---'}</td>
        {/* <td>{r.total !== false ? r.total : '---'}</td> */}
      </tr>
    ))

    return (
      <div className="production-report">
        <div className="toolbar">
          <div className="options">
            <div className="label">
              K-Factor (pulses/gallon): {this.state.kFactor}
            </div>
            <div className="label">Report Hour:</div>
            <select
              id="barrels-per-inch-input"
              value={this.state.reportHour}
              onChange={(e) => {
                this.updateReportHour(parseInt(e.target.value))
              }}
            >
              <option value={0} label={'00:00'} />
              <option value={1} label={'01:00'} />
              <option value={2} label={'02:00'} />
              <option value={3} label={'03:00'} />
              <option value={4} label={'04:00'} />
              <option value={5} label={'05:00'} />
              <option value={6} label={'06:00'} />
              <option value={7} label={'07:00'} />
              <option value={8} label={'08:00'} />
              <option value={9} label={'09:00'} />
              <option value={10} label={'10:00'} />
              <option value={11} label={'11:00'} />
              <option value={12} label={'12:00'} />
              <option value={13} label={'13:00'} />
              <option value={14} label={'14:00'} />
              <option value={15} label={'15:00'} />
              <option value={16} label={'16:00'} />
              <option value={17} label={'17:00'} />
              <option value={18} label={'18:00'} />
              <option value={19} label={'19:00'} />
              <option value={20} label={'20:00'} />
              <option value={21} label={'21:00'} />
              <option value={22} label={'22:00'} />
              <option value={23} label={'23:00'} />
              <option value={24} label={'24:00'} />
            </select>
            <div className="label">Month:</div>
            <select
              value={this.state.monthOffset}
              onChange={(e) => this.setState({monthOffset: e.target.value})}
            >
              {new Array(12).fill(0).map((e, i) => {
                const date = new Date()
                date.setMonth(date.getMonth() - i)
                return (
                  <option key={i} value={i}>
                    {date.getMonth() + 1} / {date.getFullYear()}
                  </option>
                )
              })}
            </select>
          </div>
          <div>
            <span className="button" onClick={this.downloadSpreadsheet}>
              <span>Download Spreadsheet</span>
              <i className="fa fa-download" />
            </span>
          </div>
        </div>
        <div className="body">
          <table>
            <thead>
              <tr>
                <th className="date">Date</th>
                <th>Rate (bbls/day)</th>
                <th>Daily Production ({this.state.displayUnits})</th>
                {/* <th>Total Production (bbls)</th> */}
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default compose(graphql(UPDATE_REPORT_HOUR, {name: 'updateReportHour'}))(
  ProductionReportTurbine
)
