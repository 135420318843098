import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import {gql} from 'graphql.macro'
import {dateTime as formatDateTime} from 'utils/format'
import Modal from 'Components/Modal'
// import CustomKeyCode from './CustomKeyCode'
import axios from '../utils/getAxios'

import './Settings.scss'

const ADD_DEVICE_SETTING = gql`
  mutation ADD_DEVICE_SETTING(
    $deviceID: Int!
    $key: String!
    $value: StringFloatOrInt!
  ) {
    addDeviceSetting(deviceID: $deviceID, key: $key, value: $value) {
      device {
        id
        settings {
          key
          reportedValue
          desiredValue
        }
      }
    }
  }
`

const UPDATE_DEVICE_SETTING = gql`
  mutation UPDATE_DEVICE_SETTING(
    $deviceID: Int!
    $key: String!
    $value: StringFloatOrInt!
  ) {
    updateDeviceSetting(deviceID: $deviceID, key: $key, value: $value) {
      device {
        id
        settings {
          key
          reportedValue
          desiredValue
        }
      }
    }
  }
`

const DELETE_DEVICE_SETTING = gql`
  mutation DELETE_DEVICE_SETTING($deviceID: Int!, $key: String!) {
    deleteDeviceSetting(deviceID: $deviceID, key: $key) {
      device {
        id
        settings {
          key
          reportedValue
          desiredValue
        }
      }
    }
  }
`

const DELETE_FOTA_SETTING = gql`
  mutation DELETE_FOTA_SETTING($deviceID: Int!) {
    deleteFOTASetting(deviceID: $deviceID) {
      device {
        id
        settings {
          key
          reportedValue
          desiredValue
        }
      }
    }
  }
`

const is10XVersion = (firmwareVersion) => {
  return (firmwareVersion >= 18500 && firmwareVersion < 20000)
    || (firmwareVersion >= 20400)
}

const getReadingIntervalError = (isSuperUser, firmwareVersion) => {
  if (!is10XVersion(firmwareVersion)) {
    if (isSuperUser) {
      return `Please enter a value between ${INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.superUser.reading.min} and ${INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.superUser.reading.max} minutes (inclusive) for the reading interval.`
    }

    return `Please enter a value between ${INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.user.reading.min} and ${INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.user.reading.max} minutes (inclusive) for the reading interval.`
  }

  if (isSuperUser) {
    return `Please enter a value between ${INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.superUser.reading.min} and ${INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.superUser.reading.max} minutes (inclusive) for the reading interval.`
  }

  return `Please enter a value between ${INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.user.reading.min} and ${INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.user.reading.max} minutes (inclusive) for the reading interval.`
}

const getTransmitIntervalError = (isSuperUser, firmwareVersion) => {
  if (!is10XVersion(firmwareVersion)) {
    if (isSuperUser) {
      return `Please enter a value between ${INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.superUser.transmit.min} and ${INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.superUser.transmit.max} minutes (inclusive) for the transmit interval.`
    }

    return `Please enter a value between ${INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.user.transmit.min} and ${INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.user.transmit.max} minutes (inclusive) for the transmit interval.`
  }

  if (isSuperUser) {
    return `Please enter a value between ${INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.superUser.transmit.min} and ${INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.superUser.transmit.max} minutes (inclusive) for the transmit interval.`
  }

  return `Please enter a value between ${INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.user.transmit.min} and ${INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.user.transmit.max} minutes (inclusive) for the transmit interval.`
}

const changeSensorValueToReadable = (value) => {
  switch(value) {
    case '1':
      return 'tank'
    case '6':
      return 'pressure'
    case '9':
      return 'beam pump'
    case '12':
      return 'vibration'
    default:
      return 'none' 
  }
}

// values are in minutes
const INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE = {
  user: {
    reading: {
      min: 1,
      max: 500
    },
    transmit: {
      min: 15,
      max: 500
    }
  },
  superUser: {
    reading: {
      min: 0.017, // 1 seconds
      max: 500
    },
    transmit: {
      min: 0.17, // 10 seconds
      max: 500
    }
  }
}

// values are in minutes
const INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE = {
  user: {
    reading: {
      min: 1,
      max: 500
    },
    transmit: {
      min: 15,
      max: 5000
    }
  },
  superUser: {
    reading: {
      min: 0.017, // 1 seconds
      max: 500
    },
    transmit: {
      min: 0.17, // 10 seconds
      max: 5000
    }
  }
}

const initialState = {
  formOpen: false,
  readingInterval: null,
  transmitInterval: null,
  applicationImageSignature: null,
  relocateImageSignature: null,
  control2: null,
  sensorApplicationType: null,
  readingIntervalBoundaryError: false,
  transmitIntervalBoundaryError: false,
  applicationFirmwareVersions: [],
  relocateFirmwareVersions: [],
}

class DeviceSettings extends Component {
  isJoeyTrombly = this.props.userId === 11198 // we need a quick hack to give Joey at PG&E superuser-like access but only for device settings
  // we need a quick hack to give Nico at Chevron superuser-like access but only for specific devices and control 2
  isNicoMabilleauAndValidDevice = this.props.userId === 11477 && [5890, 5982].includes(this.props.deviceID)
  state = initialState
  resetState = () => this.setState(Object.assign(initialState, {
    applicationFirmwareVersions: this.state.applicationFirmwareVersions,
    relocateFirmwareVersions: this.state.relocateFirmwareVersions,
  }))

  async componentDidMount() {
    try {
      const {data} = await axios.get('/api/fota/firmware-versions')

      const applicationFirmwareVersions = data.filter(({name}) => name.toLowerCase().includes('application') || name.toLowerCase().includes('generic'))
      const relocateFirmwareVersions = data.filter(({name}) => name.toLowerCase().includes('relocate'))

      this.setState({applicationFirmwareVersions})
      this.setState({relocateFirmwareVersions})
    } catch (err) {
      console.error('Error fetching firmware versions')
    }

  }

  // Ahmed requested feature - function to clear application image and relocate
  clearFota = () => {
    if (
      (!!this.props.isSuperUser)
    ) {
      this.props.deleteFOTASetting({
        variables: {
          deviceID: this.props.deviceID,
        }
      })

        this.setState({formOpen: false})
    }
  }

  transmitShouldUpdate = () => {
    return (
      this.props.settings.find((s) => s.key === 'interval_transmit')
        .desiredValue !== this.state.transmitInterval * 60
    )
  }
  readingShouldUpdate = () => {
    const shouldUpdate = (
      this.props.settings.find((s) => s.key === 'interval_reading')
        .desiredValue !== this.state.readingInterval * 60
    )
    return shouldUpdate
  }
  applicationImageSignatureShouldUpdate = () => {
    return this.props.settings.find((s) => s.key === 'control_1')
      ? this.props.settings.find((s) => s.key === 'control_1').desiredValue !==
          this.state.applicationImageSignature
      : true
  }

  relocateImageSignatureShouldUpdate = () => {
    return this.props.settings.find((s) => s.key === 'relocate_image_signature')
      ? this.props.settings.find((s) => s.key === 'relocate_image_signature').desiredValue !==
          this.state.relocateImageSignature
      : true
  }

  control2ShouldUpdate = () => {
    return this.props.settings.find((s) => s.key === 'control_2')
      ? this.props.settings.find((s) => s.key === 'control_2').desiredValue !==
          this.state.control2
      : true
  }

  sensorApplicationTypeShouldUpdate = () => {
    return this.props.settings.find((s) => s.key === 'sensor_application_type')
      ? this.props.settings.find((s) => s.key === 'sensor_application_type').desiredValue !==
          this.state.sensorApplicationType
      : true
  }

  get isReadingIntervalOutsideBoundaries() {
    if (!is10XVersion(this.props.firmwareVersion)) {
      if (this.props.isSuperUser) {
        return this.state.readingInterval < INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.superUser.reading.min
          || this.state.readingInterval > INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.superUser.reading.max
      }

      return this.state.readingInterval < INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.user.reading.min
        || this.state.readingInterval > INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.user.reading.max
    }

    if (this.props.isSuperUser) {
      return this.state.readingInterval < INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.superUser.reading.min
        || this.state.readingInterval > INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.superUser.reading.max
    }

    return this.state.readingInterval < INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.user.reading.min
      || this.state.readingInterval > INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.user.reading.max
  }

  get isTransmitIntervalOutsideBoundaries() {
    if (!is10XVersion(this.props.firmwareVersion)) {
      if (this.props.isSuperUser) {
        return this.state.transmitInterval < INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.superUser.transmit.min
          || this.state.transmitInterval > INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.superUser.transmit.max
      }

      return this.state.transmitInterval < INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.user.transmit.min
        || this.state.transmitInterval > INTERVAL_MIN_MAX_VALUES_NON_10X_FIRMWARE.user.transmit.max
    }

    if (this.props.isSuperUser) {
      return this.state.transmitInterval < INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.superUser.transmit.min
        || this.state.transmitInterval > INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.superUser.transmit.max
    }

    return this.state.transmitInterval < INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.user.transmit.min
      || this.state.transmitInterval > INTERVAL_MIN_MAX_VALUES_10X_FIRMWARE.user.transmit.max
  }

  validateReadingInterval = () => {
    if (this.isReadingIntervalOutsideBoundaries) {
      this.setState({readingIntervalBoundaryError: true})

      return true
    }

    return false
  }

  validateTransmitInterval = () => {
    if (this.isTransmitIntervalOutsideBoundaries) {
      this.setState({transmitIntervalBoundaryError: true})

      return true
    }

    return false
  }

  submitForm = () => {
    // quick hack-y way of filtering out defaults to get the custom key codes
    const customControlsToUpdate = omit(this.state, [
      'applicationImageSignature',
      'relocateImageSignature',
      'control2',
      'sensorApplicationType',
      'formOpen',
      'readingInterval',
      'transmitInterval',
      'readingIntervalBoundaryError',
      'transmitIntervalBoundaryError',
      'applicationFirmwareVersions',
      'relocateFirmwareVersions',
    ])

    if (
      (!!this.props.isSuperUser || this.isJoeyTrombly || this.isNicoMabilleauAndValidDevice) && Object.keys(customControlsToUpdate).length
    ) {
      for (const [key, value] of Object.entries(customControlsToUpdate)) {
        this.props.updateDeviceSetting({
          variables: {
            deviceID: this.props.deviceID,
            key,
            value
          }
        })
      }
    }

    if (
      (!!this.props.isSuperUser || this.isJoeyTrombly) &&
      this.state.applicationImageSignature !== null &&
      this.state.applicationImageSignature !== 'Select signature' &&
      this.applicationImageSignatureShouldUpdate()
    ) {
      let value = this.state.applicationImageSignature
      this.props.updateDeviceSetting({
        variables: {
          deviceID: this.props.deviceID,
          key: 'control_1',
          value: value
        }
      })
      this.setState({applicationImageSignature: null})
    }
    if (
      (!!this.props.isSuperUser || this.isJoeyTrombly) &&
      this.state.relocateImageSignature !== null &&
      this.state.relocateImageSignature !== 'Select signature' &&
      this.relocateImageSignatureShouldUpdate()
    ) {
      let value = this.state.relocateImageSignature
      this.props.updateDeviceSetting({
        variables: {
          deviceID: this.props.deviceID,
          key: 'relocate_image_signature',
          value: value
        }
      })
      this.setState({relocateImageSignature: null})
    }
    if (
      (!!this.props.isSuperUser || this.isJoeyTrombly || this.isNicoMabilleauAndValidDevice) &&
      this.state.control2 !== null &&
      this.control2ShouldUpdate()
    ) {
      let value = this.state.control2
      this.props.updateDeviceSetting({
        variables: {
          deviceID: this.props.deviceID,
          key: 'control_2',
          value: value
        }
      })
      this.setState({control2: null})
    }
    if (
      this.state.sensorApplicationType !== null &&
      this.state.sensorApplicationType !== '0' &&
      this.sensorApplicationTypeShouldUpdate()
    ) {
      let value = this.state.sensorApplicationType
      this.props.updateDeviceSetting({
        variables: {
          deviceID: this.props.deviceID,
          key: 'sensor_application_type',
          value: value
        }
      })
      this.setState({sensorApplicationType: null})
    }
    if (
      this.state.readingInterval !== null &&
      this.state.transmitInterval !== null
    ) {
      const hasReadingIntervalError = this.validateReadingInterval()
      const hasTransmitIntervalError = this.validateTransmitInterval()

      if (
        !hasReadingIntervalError &&
        !hasTransmitIntervalError &&
        this.readingShouldUpdate() &&
        this.transmitShouldUpdate()
      ) {
        this.props.updateDeviceSetting({
          variables: {
            deviceID: this.props.deviceID,
            key: 'interval_reading',
            value: this.state.readingInterval * 60
          }
        })
        this.props.updateDeviceSetting({
          variables: {
            deviceID: this.props.deviceID,
            key: 'interval_transmit',
            value: this.state.transmitInterval * 60
          }
        })

        this.resetState()
      }

      if (!this.readingShouldUpdate() || !this.transmitShouldUpdate()) {
        this.resetState()
      }
    } else if (this.state.readingInterval !== null) {
      const hasError = this.validateReadingInterval()

      if (!hasError && this.readingShouldUpdate()) {
        this.props.updateDeviceSetting({
          variables: {
            deviceID: this.props.deviceID,
            key: 'interval_reading',
            value: this.state.readingInterval * 60
          }
        })

        this.resetState()
      }

      if (!this.readingShouldUpdate()) {
        this.resetState()
      }
    } else if (this.state.transmitInterval !== null) {
      const hasError = this.validateTransmitInterval()

      if (!hasError && this.transmitShouldUpdate()) {
        this.props.updateDeviceSetting({
          variables: {
            deviceID: this.props.deviceID,
            key: 'interval_transmit',
            value: this.state.transmitInterval * 60
          }
        })

        this.resetState()
      }

      if (!this.transmitShouldUpdate()) {
        this.resetState()
      }
    } else {
      this.setState({formOpen: false})
    }
  }
  renderSettings = (settings) => {
    const filteredSettings = this.getFilteredSettings(settings)
    const defaultSettings = this.getDefaultSettings(filteredSettings)
    const remainingSettings = differenceWith(filteredSettings, defaultSettings, isEqual)

    let [intervalReading, intervalTransmit, applicationImageSignature, relocateImageSignature, control2, sensorApplicationType] = defaultSettings
    return (
      <table className="single-row">
        <thead>
          <tr>
            <th className="key">Setting</th>
            <th className="value">Desired Value</th>
            <th className="last-update">Last Update</th>
            <th className="value">Reported Value</th>
            <th className="last-update">Last Update</th>
          </tr>
        </thead>
        <tbody>
          {intervalReading ? (
            <tr key={intervalReading.key}>
              <td>Reading Interval</td>
              <td className="value">
                {intervalReading.desiredValue
                  ? intervalReading.desiredValue / 60
                  : intervalReading.reportedValue / 60}{' '}
                minutes
              </td>
              <td className="last-update">
                {intervalReading.desiredValue
                  ? formatDateTime(intervalReading.desiredValue)
                  : 'None'}
              </td>
              <td className="value">
                {intervalReading.reportedValue
                  ? `${intervalReading.reportedValue / 60} minutes`
                  : 'None'}
              </td>
              <td className="last-update">
                {intervalReading.reportedTime
                  ? formatDateTime(intervalReading.reportedTime)
                  : 'None'}
              </td>
            </tr>
          ) : (
            <tr key={'interval_reading'}>
              <td>Reading Interval</td>
              <td className="value">None</td>
              <td className="value">None</td>
              <td className="last-update">None</td>
            </tr>
          )}
          {intervalTransmit ? (
            <tr key={intervalTransmit.key}>
              <td>Transmit Interval</td>
              <td className="value">
                {intervalTransmit.desiredValue
                  ? intervalTransmit.desiredValue / 60
                  : intervalTransmit.reportedValue / 60}{' '}
                minutes
              </td>
              <td className="last-update">
                {intervalTransmit.desiredTime
                  ? formatDateTime(intervalTransmit.desiredTime)
                  : 'None'}
              </td>
              <td className="value">
                {intervalTransmit.reportedValue
                  ? `${intervalTransmit.reportedValue / 60} minutes`
                  : 'None'}
              </td>
              <td className="last-update">
                {intervalTransmit.reportedTime
                  ? formatDateTime(intervalTransmit.reportedTime)
                  : 'None'}
              </td>
            </tr>
          ) : (
            <tr key={'interval_transmit'}>
              <td>Transmit Interval</td>
              <td className="value">None</td>
              <td className="value">None</td>
              <td className="last-update">None</td>
            </tr>
          )}
          {!!this.props.isSuperUser &&
            (applicationImageSignature ? (
              <tr key={applicationImageSignature.key}>
                <td>Application Image Signature</td>
                <td className="value">
                  {applicationImageSignature.desiredValue
                    ? applicationImageSignature.desiredValue
                    : 'None'}
                </td>
                <td className="last-update">
                  {applicationImageSignature.desiredTime
                    ? formatDateTime(applicationImageSignature.desiredTime)
                    : 'None'}
                </td>
                <td className="value">
                  {applicationImageSignature.reportedValue
                    ? applicationImageSignature.reportedValue
                    : 'None'}
                </td>
                <td className="last-update">
                  {applicationImageSignature.reportedTime
                    ? formatDateTime(applicationImageSignature.reportedTime)
                    : 'None'}
                </td>
              </tr>
            ) : (
              <tr key={'control_1'}>
                <td>Application Image Signature</td>
                <td className="value">None</td>
                <td className="value">None</td>
                <td className="last-update">None</td>
              </tr>
            ))}
          {(!!this.props.isSuperUser) &&
            (relocateImageSignature ? (
              <tr key={relocateImageSignature.key}>
                <td>Relocate Image Signature</td>
                <td className="value">
                  {relocateImageSignature.desiredValue
                    ? relocateImageSignature.desiredValue
                    : 'None'}
                </td>
                <td className="last-update">
                  {relocateImageSignature.desiredTime
                    ? formatDateTime(relocateImageSignature.desiredTime)
                    : 'None'}
                </td>
                <td className="value">
                  {relocateImageSignature.reportedValue
                    ? relocateImageSignature.reportedValue
                    : 'None'}
                </td>
                <td className="last-update">
                  {relocateImageSignature.reportedTime
                    ? formatDateTime(relocateImageSignature.reportedTime)
                    : 'None'}
                </td>
              </tr>
            ) : (
              <tr key={'relocate_image_signature'}>
                <td>Relocate Image Signature</td>
                <td className="value">Nones</td>
                <td className="value">Nones</td>
                <td className="last-update">Nones</td>
              </tr>
            ))}
          {(!!this.props.isSuperUser || this.isNicoMabilleauAndValidDevice) &&
            (control2 ? (
              <tr key={control2.key}>
                <td>Control 2</td>
                <td className="value">
                  {control2.desiredValue
                    ? control2.desiredValue
                    : control2.reportedValue}
                </td>
                <td className="last-update">
                  {control2.desiredTime
                    ? formatDateTime(control2.desiredTime)
                    : 'None'}
                </td>
                <td className="value">
                  {control2.reportedValue ? control2.reportedValue : 'None'}
                </td>
                <td className="last-update">
                  {control2.reportedTime
                    ? formatDateTime(control2.reportedTime)
                    : 'None'}
                </td>
              </tr>
            ) : (
              <tr key={'control_2_'}>
                <td>Control 2</td>
                <td className="value">None</td>
                <td className="value">None</td>
                <td className="last-update">None</td>
              </tr>
            ))}
            {(!!this.props.isSuperUser || !!this.props.isManager) && Object.keys(sensorApplicationType).length > 1 ? (
              <tr key={sensorApplicationType.key}>
                <td>Sensor Application Type</td>
                <td className="value">
                  {sensorApplicationType.desiredValue
                    ? changeSensorValueToReadable(sensorApplicationType.desiredValue)
                    : changeSensorValueToReadable(sensorApplicationType.reportedValue)}
                </td>
                <td className="last-update">
                  {sensorApplicationType.desiredTime
                    ? formatDateTime(sensorApplicationType.desiredTime)
                    : 'None'}
                </td>
                <td className="value">
                  {sensorApplicationType.reportedValue ? changeSensorValueToReadable(sensorApplicationType.reportedValue) : 'None'}
                </td>
                <td className="last-update">
                  {sensorApplicationType.reportedTime
                    ? formatDateTime(sensorApplicationType.reportedTime)
                    : 'None'}
                </td>
              </tr>
            ) : (<></>
            )}
          {(!!this.props.isSuperUser || this.isJoeyTrombly) && (
            remainingSettings ? remainingSettings.map(setting =>{
              return (
                <tr key={setting.key}>
                <td>{setting.key}</td>
                <td className="value">
                  {setting.desiredValue
                    ? setting.desiredValue
                    : setting.reportedValue}
                </td>
                <td className="last-update">
                  {setting.desiredTime
                    ? formatDateTime(setting.desiredTime)
                    : 'None'}
                </td>
                <td className="value">
                  {setting.reportedValue ? setting.reportedValue : 'None'}
                </td>
                <td className="last-update">
                  {setting.reportedTime
                    ? formatDateTime(setting.reportedTime)
                    : 'None'}
                </td>
              </tr>
              )
            }) : null
          )}
        </tbody>
      </table>
    )
  }
  renderForm = (settings) => {
    const filteredSettings = this.getFilteredSettings(settings)
    const defaultSettings = this.getDefaultSettings(filteredSettings)
    const remainingSettings = differenceWith(filteredSettings, defaultSettings, isEqual)
    const sensorType = this.props.defaultTag

    let [intervalReading, intervalTransmit, applicationImageSignature, relocateImageSignature, control2, sensorApplicationType] = defaultSettings
    return (
      <table className="single-row">
        <thead>
          <tr>
            <th className="key">Setting</th>
            <th className="value">Value</th>
            <th className="last-update"></th>
          </tr>
        </thead>
        <tbody>
          {intervalReading ? (
            <tr key={intervalReading.key}>
              <td>Reading Interval</td>
              <td className="value">
                <input
                  value={
                    this.state.readingInterval !== null
                      ? this.state.readingInterval
                      : intervalReading.desiredValue / 60
                  }
                  onChange={(e) => {
                    this.setState({readingInterval: e.target.value})
                  }}
                />{' '}
                minutes
              </td>
              <td className="last-update">
                {this.state.readingIntervalBoundaryError ? (
                  <Modal onClose={this.resetState}>
                    <Modal.Title>
                      Invalid Value Assigned{' '}
                      <i className="fa fa-exclamation-circle error" />
                    </Modal.Title>
                    <Modal.Body>
                      <p>{getReadingIntervalError(this.props.isSuperUser, this.props.firmwareVersion)}</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="primary" onClick={this.resetState}>
                        Ok
                      </button>
                    </Modal.Footer>
                  </Modal>
                ) : null}
              </td>
            </tr>
          ) : (
            <tr key={'interval_reading'}>
              <td>Reading Interval</td>
              <td className="value">
                <input
                  value={this.state.readingInterval || ''}
                  onChange={(e) => {
                    this.setState({readingInterval: e.target.value})
                  }}
                />{' '}
                minutes
              </td>
              <td className="last-update"></td>
            </tr>
          )}
          {intervalTransmit ? (
            <tr key={intervalTransmit.key}>
              <td>Transmit Interval</td>
              <td className="value">
                <input
                  value={
                    this.state.transmitInterval !== null
                      ? this.state.transmitInterval
                      : intervalTransmit.desiredValue / 60
                  }
                  onChange={(e) => {
                    this.setState({transmitInterval: e.target.value})
                  }}
                />{' '}
                minutes
              </td>
              <td className="last-update">
                {this.state.transmitIntervalBoundaryError ? (
                  <Modal onClose={this.resetState}>
                    <Modal.Title>
                      Invalid Value Assigned{' '}
                      <i className="fa fa-exclamation-circle error" />
                    </Modal.Title>
                    <Modal.Body>
                      <p>{getTransmitIntervalError(this.props.isSuperUser, this.props.firmwareVersion)}</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="primary" onClick={this.resetState}>
                        Ok
                      </button>
                    </Modal.Footer>
                  </Modal>
                ) : null}
              </td>
            </tr>
          ) : (
            <tr key={'interval_transmit'}>
              <td>Transmit Interval</td>
              <td className="value">
                <input
                  value={this.state.transmitInterval || ''}
                  onChange={(e) => {
                    this.setState({transmitInterval: e.target.value})
                  }}
                />{' '}
                minutes
              </td>
              <td className="last-update"></td>
            </tr>
          )}
          {(!!this.props.isSuperUser) &&
            (applicationImageSignature  && applicationImageSignature.desiredValue ? (
              <tr key={applicationImageSignature.key}>
                <td>Application Image Signature</td>
                <td className="value">
                  <select
                    value={
                      this.state.applicationImageSignature !== null
                        ? this.state.applicationImageSignature
                        : applicationImageSignature.desiredValue
                    }
                    onChange={(e) => {
                      this.setState({applicationImageSignature: e.target.value})
                    }}
                  >
                    <option key='1'>Select signature</option>
                    {this.state.applicationFirmwareVersions.map(({crc_id}) => <option key={crc_id} value={crc_id}>{crc_id}</option>)}
                  </select>
                </td>
                <td className="last-update"></td>
              </tr>
            ) : (
              <tr key={'control_1'}>
                <td>Application Image Signature</td>
                <td className="value">
                  <select
                    value={this.state.applicationImageSignature || ''}
                    onChange={(e) => {
                      this.setState({applicationImageSignature: e.target.value})
                    }}
                  >
                    <option key='1'>Select signature</option>
                    {this.state.applicationFirmwareVersions.map(({crc_id}) => <option key={crc_id} value={crc_id}>{crc_id}</option>)}
                  </select>
                </td>
                <td className="last-update"></td>
              </tr>
            ))}
          {(!!this.props.isSuperUser) &&
            (relocateImageSignature && relocateImageSignature.desiredValue ? (
              <tr key={relocateImageSignature.key}>
                <td>Relocate Image Signature</td>
                <td className="value">
                  <select
                    value={
                      this.state.relocateImageSignature !== null
                        ? this.state.relocateImageSignature
                        : relocateImageSignature.desiredValue
                    }
                    onChange={(e) => {
                      this.setState({relocateImageSignature: e.target.value})
                    }}
                  >
                    <option key='1'>Select signature</option>
                    {this.state.relocateFirmwareVersions.map(({crc_id}) => <option key={crc_id} value={crc_id}>{crc_id}</option>)}
                  </select>
                </td>
                <td className="last-update"></td>
              </tr>
            ) : (
              <tr key={'relocate_image_signature'}>
                <td>Relocate Image Signature</td>
                <td className="value">
                  <select
                    value={this.state.relocateImageSignature || ''}
                    onChange={(e) => {
                      this.setState({relocateImageSignature: e.target.value})
                    }}
                  >
                    <option key='1'>Select signature</option>
                    {this.state.relocateFirmwareVersions.map(({crc_id}) => <option key={crc_id} value={crc_id}>{crc_id}</option>)}
                  </select>
                </td>
                <td className="last-update"></td>
              </tr>
            ))}
          {(!!this.props.isSuperUser || this.isJoeyTrombly || this.isNicoMabilleauAndValidDevice) &&
            (control2 ? (
              <tr key={control2.key}>
                <td>Control 2</td>
                <td className="value">
                  <input
                    value={
                      this.state.control2 !== null
                        ? this.state.control2
                        : control2.desiredValue
                    }
                    onChange={(e) => {
                      this.setState({control2: e.target.value})
                    }}
                  />
                </td>
                <td className="last-update"></td>
              </tr>
            ) : (
              <tr key={'control_2'}>
                <td>Control 2</td>
                <td className="value">
                  <input
                    value={this.state.control2 || ''}
                    onChange={(e) => {
                      this.setState({control2: e.target.value})
                    }}
                  />
                </td>
                <td className="last-update"></td>
              </tr>
            ))}
            {(!!this.props.isSuperUser || !!this.props.isManager) && Object.keys(sensorApplicationType).length > 1 ? (
              <tr key={sensorApplicationType.key}>
                <td>Sensor Application Type</td>
                <td className="value">
                  <select
                    value={
                      this.state.sensorApplicationType !== null
                        ? this.state.sensorApplicationType
                        : sensorApplicationType.desiredValue
                    }
                    onChange={(e) => {
                      this.setState({sensorApplicationType: e.target.value})
                    }}
                  >
                    <option value='0'>Select Sensor Type</option>
                    {(sensorType === 'psr' || sensorType === 'spsr') && <option value='1'>Tank</option>}
                    {(sensorType === 'psr' || sensorType === 'spsr') && <option value='6'>Pressure</option>}
                    {(sensorType === 'vib' || sensorType === 'rot') && <option value='9'>Beam Pump</option>}
                    {(sensorType === 'vib' || sensorType === 'rot') && <option value='12'>Vibration</option>}

                  </select>
                </td>
                <td className="last-update"></td>
              </tr>
            ) : (<></>
            )}
          {(!!this.props.isSuperUser || this.isJoeyTrombly) &&
            (remainingSettings ? (
              <>
                {remainingSettings.map((setting) => {
                  return (
                    <tr key={setting.key}>
                    <td>{setting.key}</td>
                    <td className="value">
                      <input
                        value={
                          this.state[setting.key] !== null && this.state[setting.key] !== undefined
                            ? this.state[setting.key]
                            : setting.desiredValue
                        }
                        onChange={(e) => {
                          this.setState({[setting.key]: e.target.value})
                        }}
                      />
                    </td>
                    <td className="last-update"></td>
                  </tr>
                  )
                })}
              </>
            ) : null
          )}
        </tbody>
      </table>
    )
  }

  getFilteredSettings = (settings) => {
    return settings.filter((setting) => {
      const {key} = setting
      const hasControlSettings = !!this.props.isSuperUser ? key.startsWith('control_') : null
      return (!key.startsWith('alarm') && !key.startsWith('error')) || hasControlSettings || key === 'relocate_image_signature'
    })
  }

  getDefaultSettings = (filteredSettings) => {
    const intervalReading = filteredSettings.find((s) => s.key === 'interval_reading')
    const intervalTransmit = filteredSettings.find((s) => s.key === 'interval_transmit')
    const applicationImageSignature = filteredSettings.find((s) => s.key === 'control_1')
    const control2 = filteredSettings.find((s) => s.key === 'control_2')
    const relocateImageSignature = filteredSettings.find((s) => s.key === 'relocate_image_signature')
      // without this below it was causing a weird bug, because this value would be undefined for devices without
      // this setting in the device_settings table
      || {key: 'relocate_image_signature'}
      const sensorApplicationType = filteredSettings.find((s) => s.key === 'sensor_application_type')
      || {key: 'sensor_application_type'}

    return [intervalReading, intervalTransmit, applicationImageSignature, relocateImageSignature, control2, sensorApplicationType]
  }

  render() {
    const settings = this.getFilteredSettings(this.props.settings)
    return (
      <div className="box device-settings">
        <div className="title">
          <span className="left">Settings</span>
          <span className="right">
            {this.state.formOpen ? (
              <div>
                {(this.props.userId === 11240 || this.props.userId === 11808) && (<button onClick={this.clearFota}>Clear Fota</button>)}
                <button onClick={this.resetState}>Cancel</button>
                <button className="primary" onClick={this.submitForm}>
                  Save Changes
                </button>
              </div>
            ) : (
              <button onClick={() => this.setState({formOpen: true})}>
                Edit Settings
              </button>
            )}
          </span>
        </div>
        {this.state.formOpen
          ? this.renderForm(settings)
          : this.renderSettings(settings)}
        {/* {!!this.props.isSuperUser && this.props.deviceID < 1000 && <CustomKeyCode deviceID={this.props.deviceID} />} */}
      </div>
    )
  }
}

export default compose(
  graphql(ADD_DEVICE_SETTING, {name: 'addDeviceSetting'}),
  graphql(UPDATE_DEVICE_SETTING, {name: 'updateDeviceSetting'}),
  graphql(DELETE_DEVICE_SETTING, {name: 'deleteDeviceSetting'}),
  graphql(DELETE_FOTA_SETTING, {name: 'deleteFOTASetting'}),
)(DeviceSettings)
