import isNumber from 'lodash/isNumber'
import findClosestPoint from 'utils/findClosestPoint'
import {feet as formatFeet} from 'utils/format'
import memoize from 'lodash/memoize'

export default memoize((tag) => new AdditionalTag(tag))

class AdditionalTag {
  constructor(tagData) {
    if (!tagData) return null
    this.tag = tagData.tag
    this.ymin = tagData.ymin || this.getYMin(tagData)
    this.ymax = tagData.ymax || this.getYMax(tagData)
    this.displayUnits = tagData.displayUnits
    // tagData.data.forEach(dataNode => {
    //   if (dataNode.value < this.ymin) {
    //     let len = Math.round(dataNode.value).toString().length
    //     let fac = Math.pow(10, len - 1)
    //     this.ymin = Math.floor(dataNode.value / fac) * fac
    //   }
    //   if (dataNode.value > this.ymax) {
    //     let len = Math.round(dataNode.value).toString().length
    //     let fac = Math.pow(10, len - 1)
    //     this.ymax = Math.ceil(dataNode.value / fac) * fac
    //   }
    // })
    this.label = labels[this.tag] || labels.default
    this.data = tagData.data.map((dataNode) => [dataNode.time, dataNode.value])
    this.render = this.display.render
  }

  getYMin = (tagData) => {
    return 0
  }
  getYMax = (tagData) => {
    switch (tagData.tag) {
      case 'temp':
      case 'htemp':
      case 'ttemp':
        if (tagData.displayUnits === 'f') {
          return 140
        } else if (tagData.displayUnits === 'c') {
          return 60
        } else {
          return 140
        }
      case 'pdiff':
        return 1
      case 'hpres':
        return 20
      case 'tpres':
        return 20
      case 'puls':
        return 3000
      case 'pult':
        return 3000
      default:
        return 30
    }
  }

  get display() {
    const options =
      this.displayOptions.find((o) => o.value === this.displayUnits) ||
      this.displayOptions[0]
    return {...options, render: (v) => (v === null ? null : options.render(v))}
  }
  get displayOptions() {
    switch (this.tag) {
      case 'psr':
        return [
          {
            label: 'PSI',
            value: 'psi',
            render: (v) => `${v.toFixed(3)} psi`,
            yAxisLabel: 'Pressure (psi)'
          },
          {
            label: 'in Hg',
            value: 'inhg',
            render: (v) => `${v.toFixed(1)} inHg`,
            yAxisLabel: 'Pressure (in Hg)',
            decimals: 1
          },
          {
            label: 'Bar',
            value: 'bar',
            yAxisLabel: 'Pressure (bar)',
            decimals: 1,
            render: (v) => `${v.toFixed(1)} bar`
          }
        ]

      case 'spsr':
        return [
          {
            label: 'Feet',
            value: 'ft',
            render: formatFeet,
            yAxisLabel: 'Level (ft)',
            input: 'feetinches',
            ymin: 0,
            ymax: 20
          },
          {
            label: 'Inches',
            value: 'in',
            render: (v) => `${v.toFixed(1)} in`,
            yAxisLabel: 'Level (in)',
            ymin: 0,
            ymax: 240
          },
          {
            label: 'PSI',
            value: 'psi',
            render: (v) => `${v.toFixed(1)} psi`,
            yAxisLabel: 'Pressure (psi)',
            ymin: 0,
            ymax: 10
          },
          {
            label: 'PSI (Full)',
            value: 'psi (full)',
            render: (v) => `${v.toFixed(3)} psi`,
            yAxisLabel: 'Pressure (psi)',
            ymin: 0,
            ymax: 10
          },
          {
            label: 'Gallons (REQUIRES TANK STRAP CALIBRATION)',
            value: 'gln',
            render: (v) => `${v.toFixed(1)} gln`,
            yAxisLabel: 'Level (gln)',
            ymin: 0,
            ymax: 5000
          }
        ]
      case 'dspsr':
        return [
          {
            label: 'Feet',
            value: 'ft',
            render: formatFeet,
            yAxisLabel: 'Level (ft)',
            input: 'feetinches'
          },
          {
            label: 'Inches',
            value: 'in',
            render: (v) => `${v.toFixed(1)} in`,
            yAxisLabel: 'Level (in)'
          },
          {
            label: 'PSI',
            value: 'psi',
            render: (v) => `${v.toFixed(1)} psi`,
            yAxisLabel: 'Pressure (psi)'
          }
        ]
      case 'rot':
        return [
          {
            label: 'Strokes Per Minute',
            value: 'spm',
            render: (v) => `${v.toFixed(3)} strokes/min`,
            yAxisLabel: 'Rotation Speed (strokes/min)'
          },
          {
            label: 'Seconds per stroke',
            value: 'sps',
            render: (v) => `${v.toFixed(3)} sec/stroke`,
            yAxisLabel: 'Rotation Speed (sec/stroke)'
          }
        ]
      case 'vib':
        return [
          {
            label: 'Unitless',
            value: 'unitless',
            render: (v) => `${v.toFixed(1)}`,
            yAxisLabel: 'Vibration Amplitude'
          }
        ]
      case 'puls':
        return [
          {
            label: 'Flow (bbls/day)',
            value: 'flow_barrels_per_day',
            yAxisLabel: 'Flow (bbls/day)',
            render: (v) => `${v.toFixed(1)} bbls/day`
          }
          // {
          //   label: 'Flow (gal/min)',
          //   value: 'flow_gal',
          //   yAxisLabel: 'Flow (gal/min)',
          //   render: (v) => `${v.toFixed(2)} gal/min`
          // },
          // {
          //   label: 'Flow (bbl/min)',
          //   value: 'flow_bbl',
          //   yAxisLabel: 'Flow (bbl/min)',
          //   render: (v) => `${v.toFixed(2)} bbl/min`
          // },
          // {
          //   label: 'Volume (gal)',
          //   value: 'total_gal',
          //   yAxisLabel: 'Volume (gal)',
          //   render: (v) => `${v.toFixed(1)} gal`
          // },
          // {
          //   label: 'Volume (bbl)',
          //   value: 'total_bbl',
          //   yAxisLabel: 'Volume (bbl)',
          //   render: (v) => `${v.toFixed(1)} bbl`
          // },
          // {
          //   label: 'Pulses',
          //   value: 'pulse',
          //   yAxisLabel: 'Pulse Count',
          //   render: (v) => `${v}`
          // }
        ]
      case 'pult':
        return [
          {
            label: 'Flow (bbls/day)',
            value: 'flow_barrels_per_day',
            yAxisLabel: 'Flow (bbls/day)',
            render: (v) => `${v.toFixed(1)} bbls/day`
          }
        ]
      case 'dist':
      case 'sonr':
        return [
          {
            label: 'Inches',
            value: 'in',
            yAxisLabel: 'Level (in)',
            render: (v) => {
              return `${v.toFixed(1)} in`
            }
          }
        ]
      case 'hum':
        return [
          {
            label: 'Percentage',
            value: '%',
            yAxisLabel: 'Percent',
            render: (v) => `${v.toFixed(1)}%`
          }
        ]
      case '_v':
        return [
          // {
          //   label: 'Percentage',
          //   value: '%',
          //   render: v => `${Math.round(v)}%`,
          //   yAxisLabel: 'Percent'
          // },
          {
            label: 'Voltage',
            value: 'v',
            render: (v) => `${v.toFixed(2)} V`,
            yAxisLabel: 'Voltage (V)'
          }
        ]
      case '_t':
      case 'temp':
        return [
          {
            label: 'Farenheit',
            value: 'f',
            render: (v) => `${v.toFixed(1)}°F`,
            yAxisLabel: 'Sensor Temp (°F)'
          },
          {
            label: 'Celsius',
            value: 'c',
            render: (v) => `${v.toFixed(1)}°C`,
            yAxisLabel: 'Sensor Temp (°C)'
          }
        ]
      case 'pdiff':
        return [
          {
            label: 'Differential Pressure',
            value: 'psi',
            render: (v) => `${v.toFixed(3)} psi`,
            yAxisLabel: 'Differential Pressure (psi)'
          }
        ]
      case 'hpres':
        return [
          {
            label: 'Vapor Pressure',
            value: 'psi',
            render: (v) => `${v.toFixed(3)} psi`,
            yAxisLabel: 'Vapor Pressure (psi)'
          }
        ]
      case 'tpres':
        return [
          {
            label: 'Barometric Pressure',
            value: 'psi',
            render: (v) => `${v.toFixed(3)} psi`,
            yAxisLabel: 'Barometric Pressure (psi)'
          }
        ]
      case 'htemp':
        return [
          {
            label: 'Vapor Temp',
            value: 'f',
            render: (v) => `${v.toFixed(1)}°F`,
            yAxisLabel: 'Vapor Temp (°F)'
          }
        ]
      case 'ttemp':
        return [
          {
            label: 'Transmitter Temp',
            value: 'f',
            render: (v) => `${v.toFixed(1)}°F`,
            yAxisLabel: 'Transmitter Temp (°F)'
          }
        ]
      case 'cumulative_pulses':
        return [
          {
            label: 'Cumulative Production (Bbls)',
            value: 'bbls',
            render: (v) => `${v.toFixed(2)} Bbls`,
            yAxisLabel: 'Cumulative Production (Bbls)'
          },
          {
            label: 'Cumulative Production (MBbls)',
            value: 'mbbls',
            render: (v) => `${v.toFixed(2)} MBbls`,
            yAxisLabel: 'Cumulative Production (MBbls)'
          }
        ]
      case 'uv_pulses': // mv pulses now. leaving since this is a quick hack
        return [
          {
            label: 'Pulse amplitude (mV)',
            value: 'P',
            render: (uV) => `${uV.toFixed(3)} P`, // mv pulses now. leaving since this is a quick hack
            yAxisLabel: 'Pulse amplitude (mV)'
          }
        ]
      default:
        return [
          {
            label: 'Default',
            value: null,
            render: (v) => (isNumber(v) ? v.toFixed(2) : null),
            yAxisLabel: 'Reading'
          }
        ]
    }
  }
  getClosestPoint(time) {
    const index = findClosestPoint(time, this.data)
    if (isNumber(index)) {
      const [time, value] = this.data[index]
      return {time, value, index}
    } else {
      return {time: null, value: null, index: null}
    }
  }
}

export const labels = {
  psr: 'Pressure',
  spsr: 'Fluid Level',
  dspsr: 'Fluid Level',
  rot: 'Rotation',
  vib: 'Vibration',
  dist: 'Radar Fluid Level',
  sonr: 'Sonar Fluid Level',
  hum: 'Humidity',
  _v: 'Internal Battery Level',
  _t: 'Internal Device Temperature',
  puls: 'Flow',
  pult: 'Flow Test',
  default: 'Sensor'
}
