import React from 'react'
const DirectoryIcon = props =>
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 144 144" xmlSpace="preserve">
    <g>
      <path d="M51,5.5H4.8C1.9,5.5,0,7.9,0,10.3v46.2c0,2.9,2.4,4.8,4.8,4.8H51c2.9,0,4.8-2.4,4.8-4.8V10.3C55.8,7.9,53.9,5.5,51,5.5z M46.2,51.7H9.6V15.1h36.6V51.7z"/>
      <path d="M51,136.8c2.9,0,4.8-2.4,4.8-4.8V85.8c0-2.9-2.4-4.8-4.8-4.8H4.8C1.9,81,0,83.4,0,85.8V132c0,2.9,2.4,4.8,4.8,4.8H51z M9.6,91.1h36.6v36.6H9.6V91.1z"/>
      <path d="M68.8,20.4c0,2.9,2.4,4.8,4.8,4.8h65.5c2.9,0,4.8-2.4,4.8-4.8s-2.4-4.8-4.8-4.8H73.6C70.7,15.1,68.8,17.5,68.8,20.4z"/>
      <path d="M139.1,42H73.6c-2.9,0-4.8,2.4-4.8,4.8c0,2.9,2.4,4.8,4.8,4.8h65.5c2.9,0,4.8-2.4,4.8-4.8C143.9,44.4,142,42,139.1,42z"/>
      <path d="M139.1,91.1H73.6c-2.9,0-4.8,2.4-4.8,4.8c0,2.9,2.4,4.8,4.8,4.8h65.5c2.9,0,4.8-2.4,4.8-4.8C143.9,93,142,91.1,139.1,91.1z"/>
      <path d="M143.9,122.4c0-2.9-2.4-4.8-4.8-4.8H73.6c-2.9,0-4.8,2.4-4.8,4.8c0,2.9,2.4,4.8,4.8,4.8h65.5C142,127.2,143.9,124.8,143.9,122.4z"/>
    </g>
  </svg>
export default DirectoryIcon