import React, {Component} from 'react'
import {connect} from 'react-redux'
import {changeOffset} from './store/actions'

const getFormattedNumber = (value) => {
  // if value is NOT a float ("safe integer"), then return the integer, else return up to 1 decimal point (float)
  return Number.isSafeInteger(value) ? value.toFixed(0) : value.toFixed(1)
}

class YAxis extends Component {
  componentDidMount() {
    this.props.addOffsets()
  }
  componentWillUnmount() {
    this.props.removeOffsets()
  }
  render() {
    const {
      yStart,
      yEnd,
      height,
      scale,
      secondary,
      tertiary,
      fourth,
      fifth,
      sixth,
      map,
      color,
      y,
      label,
      containerWidth,
      onClick,
      xStart,
      xEnd,
      numTicks
    } = this.props

    if (height <= 0) return null

    //get a nice, even tick spacing thats breaks the range into 50px increments
    const tickAmount = !!numTicks
      ? (yEnd - yStart) / numTicks
      : getSmoothTickSpacing((yEnd - yStart) * (50 / height))

    let ticks = [],
      labels = []
    const start = yStart - (yStart % tickAmount)
    for (let y = start; y < yEnd + tickAmount; y += tickAmount) {
      const scaledY = height - (y - yStart) * scale
      ticks.push(
        <Tick key={scaledY} scaledY={scaledY} xStart={xStart} xEnd={xEnd} />
      )
      labels.push(
        <Label
          key={y}
          scaledY={scaledY}
          value={map ? map(y) : y}
          secondary={secondary}
          tertiary={tertiary}
          fourth={fourth}
          fifth={fifth}
          sixth={sixth}
          color={color}
        />
      )
    }

    const getX = () => {
      if (!!secondary) {
        return containerWidth - 65
      } else if (!!tertiary) {
        return containerWidth - 130
      } else if (!!fourth) {
        return containerWidth - 195
      } else if (!!fifth) {
        return containerWidth - 260
      } else if (!!sixth) {
        return containerWidth - 325
      } else {
        return 0
      }
    }
    const getID = () => {
      if (!!secondary) {
        return 'y-axis-secondary'
      } else if (!!tertiary) {
        return 'y-axis-tertiary'
      } else if (!!fourth) {
        return 'y-axis-fourth'
      } else if (!!fifth) {
        return 'y-axis-fifth'
      } else if (!!sixth) {
        return 'y-axis-sixth'
      } else {
        return 'y-axis'
      }
    }
    const getTransform = () => {
      if (!!secondary || !!tertiary || !!fourth || !!fifth || !!sixth) {
        return `translate(30, ${0.5 * height}) rotate(-90)`
      } else {
        return `translate(0, ${0.5 * height}) rotate(-90)`
      }
    }

    return (
      <svg x={getX()} y={0} id={getID()}>
        <g transform={`translate(0, ${y})`}>
          {!secondary &&
            !tertiary &&
            !fourth &&
            !fifth &&
            !sixth &&
            this.props.ticks !== false &&
            ticks}
          <g>{labels}</g>
          <text
            x="0"
            y="10"
            transform={getTransform()}
            style={{fontWeight: 800, fill: color || '#000'}}
            dominantBaseline="central"
            textAnchor="middle"
            onClick={onClick}
            className={onClick ? 'clickable' : ''}
          >
            {label || ''}
          </text>
        </g>
      </svg>
    )
  }
}

const Tick = ({scaledY, xStart, xEnd}) => (
  <line
    key={scaledY}
    x1={xStart}
    x2={xEnd}
    y1={scaledY}
    y2={scaledY}
    shapeRendering="crispEdges"
    style={{stroke: '#ccc', strokeWidth: 1}}
  />
)
const Label = ({
  scaledY,
  offset,
  value,
  secondary,
  tertiary,
  fourth,
  fifth,
  sixth,
  color
}) => (
  <text
    key={scaledY}
    x={secondary || tertiary || fourth || fifth || sixth ? 0 : 55}
    y={scaledY}
    alignmentBaseline="middle"
    textAnchor={
      secondary || tertiary || fourth || fifth || sixth ? 'start' : 'end'
    }
    style={{fill: color}}
  >
    {getFormattedNumber(value)}
  </text>
)

//takes a rough spacing interval and gives the next largest spacing interval
//that's on an even grid
//e.g.
//1,2,3,4,5
//.1, .2, .3, .4, .5
//0 2 4 6 8
//0 5 10 15 20
//1500 2000 2500
export function getSmoothTickSpacing(rough) {
  const pow = Math.log10(rough)
  const mag = Math.floor(pow)
  const partial = pow - mag
  if (partial < Math.log10(2)) return 2 * Math.pow(10, mag)
  else if (partial < Math.log10(5)) return 5 * Math.pow(10, mag)
  else return 10 * Math.pow(10, mag)
}

const mapStateToProps = (state) => ({
  yStart: state.view.y.start,
  yEnd: state.view.y.end,
  scale: state.view.y.scale,
  height:
    state.container.height -
    state.container.topOffset -
    state.container.bottomOffset,
  y: state.container.topOffset,
  containerWidth: state.container.width,
  xStart: state.container.leftOffset,
  xEnd: state.container.width - state.container.rightOffset
})
const mapDispatchToProps = (
  dispatch,
  {secondary, tertiary, fourth, fifth, sixth}
) => ({
  addOffsets: () =>
    secondary
      ? dispatch(changeOffset({rightOffset: 75}))
      : tertiary
      ? dispatch(changeOffset({rightOffset: 65}))
      : fourth
      ? dispatch(changeOffset({rightOffset: 65}))
      : fifth
      ? dispatch(changeOffset({rightOffset: 65}))
      : sixth
      ? dispatch(changeOffset({rightOffset: 65}))
      : dispatch(changeOffset({leftOffset: 75, topOffset: 10})),
  removeOffsets: () =>
    secondary
      ? dispatch(changeOffset({rightOffset: -75}))
      : tertiary
      ? dispatch(changeOffset({rightOffset: -65}))
      : fourth
      ? dispatch(changeOffset({rightOffset: -65}))
      : fifth
      ? dispatch(changeOffset({rightOffset: -65}))
      : sixth
      ? dispatch(changeOffset({rightOffset: -65}))
      : dispatch(changeOffset({leftOffset: -75, topOffset: -10}))
})
export default connect(mapStateToProps, mapDispatchToProps)(YAxis)
