import React, {Component, PureComponent} from 'react'
import {connect} from 'react-redux'
import downsample from './downsample'

// for speed, x2 mutliplication is done with a bitshift << 1

class DataLine extends Component {
  componentDidMount() {
    this.UNSAFE_componentWillReceiveProps(this.props)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.data) return (this.data = null)
    let changed = [
      'xScale',
      'yStart',
      'yScale',
      'data',
      'graphHeight',
      'graphWidth'
    ].reduce((obj, k) => ({...obj, [k]: nextProps[k] !== this.props[k]}), {})
    if (!this.data) changed.data = true
    if (changed.data) this.data = new Float32Array(nextProps.data.length * 2)

    if (changed.yStart || changed.yScale || changed.graphHeight || changed.data)
      for (let i = 0; i < nextProps.data.length; i++)
        this.data[i * 2 + 1] =
          nextProps.graphHeight -
          nextProps.yScale * (nextProps.data[i][1] - nextProps.yStart)

    const outOfPrerenderedRange =
      !this.xStart ||
      !this.xEnd ||
      !this.xStartPoint ||
      nextProps.xStart < this.xStart ||
      nextProps.xEnd > this.xEnd

    if (Object.keys(changed).some((k) => changed[k]) || outOfPrerenderedRange) {
      const domain = nextProps.xEnd - nextProps.xStart
      this.xStart = nextProps.xStart - domain / 3
      this.xEnd = nextProps.xEnd + domain / 3
      this.xStartPoint = nextProps.xStart
      const inds = downsample(
        nextProps.data,
        nextProps.graphWidth * (1 + 2 / 3),
        this.xStart,
        this.xEnd
      )
      if (!inds || !inds.length || inds.some((i) => i === null)) return
      for (let i = 0; i < inds.length; i++)
        this.data[inds[i] << 1] =
          nextProps.xScale * (nextProps.data[inds[i]][0] - nextProps.xStart)
      this.makePath(inds, nextProps)
    }
  }
  makePath = (inds, props) => {
    const maxGap = Math.max(props.maxGap, 5 / props.xScale)
    this.paths = []

    inds.forEach((ind, i) => {
      if (props.data[ind][1] === null) return ''
      if (
        i === 0 ||
        ind === 0 ||
        props.data[ind - 1][1] === null ||
        props.data[ind][0] - props.data[inds[i - 1]][0] > maxGap ||
        this.pointsAreSeparated(props.data, inds[i - 1], inds[i])
      )
        this.paths.push(
          `M${this.data[ind << 1] >> 0} ${this.data[(ind << 1) + 1] >> 0} l0 0`
        )
      else
        this.paths[this.paths.length - 1] += `L${this.data[ind << 1] >> 0} ${
          this.data[(ind << 1) + 1] >> 0
        }`
    })

    this.setState({}) //if did mount, force rerender
  }
  pointsAreSeparated = (data, i1, i2) => {
    for (let i = i1 + 1; i < i2; i++) if (data[i][1] === null) return true
    return false
  }
  renderDots = () =>
    this.props.data
      .filter(
        (d) =>
          d[0] > this.props.xStart && d[0] < this.props.xEnd && d[1] !== null
      )
      .map((d) => (
        <circle
          key={d[0]}
          r={3}
          cx={(d[0] - this.props.xStart) * this.props.xScale}
          cy={
            this.props.graphHeight -
            (d[1] - this.props.yStart) * this.props.yScale
          }
          style={{
            fill: this.props.color || '#666',
            strokeWidth: 1,
            opacity: this.props.opacity || 1,
            ...this.props.style
          }}
        />
      ))
  render() {
    if (!this.data || !this.data.length) return null
    if (
      this.props.breakdownScale &&
      this.props.xScale > this.props.breakdownScale
    )
      return <g>{this.renderDots()}</g>
    else
      return (
        <g
          transform={`translate(${
            (this.xStartPoint - this.props.xStart) * this.props.xScale
          }, 0)`}
        >
          {this.paths.map((p, i) => (
            <Line
              key={i}
              path={p}
              color={this.props.color}
              style={this.props.style}
              width={this.props.width}
              opacity={this.props.opacity}
            />
          ))}
        </g>
      )
  }
}

class Line extends PureComponent {
  render() {
    return (
      <path
        d={this.props.path}
        style={{
          fill: 'none',
          stroke: this.props.color || '#777',
          strokeWidth: this.props.width || 1,
          strokeOpacity: this.props.opacity || 1,
          ...this.props.style
        }}
        shapeRendering="geometricPrecision"
        strokeLinecap="round"
      />
    )
  }
}

const mapStateToProps = (state) => ({
  xStart: state.view.x.start,
  xEnd: state.view.x.end,
  xScale: state.view.x.scale,
  yStart: state.view.y.start,
  yScale: state.view.y.scale,
  graphHeight:
    state.container.height -
    state.container.topOffset -
    state.container.bottomOffset,
  graphWidth:
    state.container.width -
    state.container.leftOffset -
    state.container.rightOffset
})

export default connect(mapStateToProps)(DataLine)
