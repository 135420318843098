import {gql} from 'graphql.macro'

export const GET_DEVICE = gql`
  query GET_DEVICE($id: Int!) {
    me {
      id
      isSuperUser
      isManager
      canEdit
      isDemo
    }
    device(id: $id) {
      id
      name
      path
      deviceType
      slowdownVoltage4
      hibernationVoltage
      company {
        id
        name
      }
      tags {
        id
        deviceID
        tag
        ymin
        ymax
        time
        value
        displayUnits
        calibration {
          fluid
        }
      }
      voltage: tag(tag: "_v") {
        id
        deviceID
        tag
        ymin
        ymax
        data
        displayUnits
      }
      isActive
      inService
      latestReading
      timeOffset
      accountingCode
      apiNumber
      userAssignedID
      latitude
      longitude
      replacementID
      po
      manufacturerCode
      model
      pin
      simNumber
      description
      firmwareVersion
      defaultTag
      sensorSubtype1
      sensorSubtype2
      modemType
      boardRev
      events {
        time
        flag
      }
      settings {
        key
        reportedValue
        desiredValue
        reportedTime
        desiredTime
      }
    }
    deviceGps(id: $id) {
      latitude
      longitude
    }
  }
`

export const GET_DEVICE_RE = gql`
  query GET_DEVICE_RE($id: Int!) {
    me {
      id
      isSuperUser
      isManager
      canEdit
      isDemo
    }
    deviceRe(id: $id) {
      id
      name
      path
      deviceType
      slowdownVoltage4
      hibernationVoltage
      company {
        id
        name
      }
      tags {
        id
        deviceID
        tag
        ymin
        ymax
        time
        value
        displayUnits
        calibration {
          fluid
        }
      }
      voltage: tag(tag: "_v") {
        id
        deviceID
        tag
        ymin
        ymax
        data
        displayUnits
      }
      isActive
      inService
      iconFluid
      latestReading
      timeOffset
      accountingCode
      apiNumber
      userAssignedID
      latitude
      longitude
      replacementID
      po
      manufacturerCode
      model
      pin
      simNumber
      description
      firmwareVersion
      defaultTag
      sensorSubtype1
      sensorSubtype2
      modemType
      boardRev
      events {
        time
        flag
      }
      settings {
        key
        reportedValue
        desiredValue
        reportedTime
        desiredTime
      }
    }
    deviceGps(id: $id) {
      latitude
      longitude
    }
  }
`

export const UPDATE_DEVICE = gql`
  mutation UPDATE_DEVICE($id: Int!, $update: UpdateDeviceInput) {
    updateDevice(id: $id, update: $update) {
      device {
        id
        name
        path
        deviceType
        isActive
        inService
        timeOffset
        accountingCode
        apiNumber
        userAssignedID
        latitude
        longitude
        replacementID
        companyID
      }
    }
  }
`

export const ADD_STATUS = gql`
  mutation ADD_STATUS($deviceID: Int!, $status: Int!) {
    addStatus(deviceID: $deviceID, status: $status) {
      device {
        id
        latest {
          status
        }
        log {
          time
          status
        }
      }
    }
  }
`

export const ADD_LOG_COMMENT = gql`
  mutation ADD_LOG_COMMENT($deviceID: Int!, $comment: String!) {
    addLogComment(deviceID: $deviceID, comment: $comment) {
      device {
        id
        logComments {
          time
          comment
        }
      }
    }
  }
`

export const REPLACE_DEVICE = gql`
  mutation REPLACE_DEVICE($oldDeviceID: Int!, $newDeviceID: Int!) {
    replaceDevice(oldDeviceID: $oldDeviceID, newDeviceID: $newDeviceID) {
      oldDevice {
        id
        isActive
        inService
        replacementID
      }
      newDevice {
        id
        isActive
        inService
        name
        path
        accountingCode
        apiNumber
        userAssignedID
        deviceType
        timeOffset
        latitude
        longitude
      }
    }
  }
`

export const CLEAR_DEVICE_DATA = gql`
  mutation CLEAR_DEVICE_DATA($deviceID: Int!) {
    clearDeviceData(deviceID: $deviceID) {
      success
    }
  }
`
