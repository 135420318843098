import React, {useEffect, useState} from 'react'
import axios from '../../../utils/getAxios'

const CONSTANTS = {
  applicationDefault: 'application_image',
  relocateDefault: 'relocate_image'
}

const getDefaultImageFromResults = (defaultImages, _key) => {
  return defaultImages.find(({key}) => key === _key).value
}

export default () => {
  const [currentApplicationDefault, setCurrentApplicationDefault] = useState('')
  const [currentRelocateDefault, setCurrentRelocateDefault] = useState('')
  const [availableApplicationImages, setAvailableApplicationImages] = useState([])
  const [availableRelocateImages, setAvailableRelocateImages] = useState([])
  const [selectedApplicationDefault, setSelectedApplicationDefault] = useState('')
  const [selectedRelocateDefault, setSelectedRelocateDefault] = useState('')

  useEffect(() => {
    const fetchCurrentDefaultImages = async () => {
      const {data} = await axios.get('/api/fota/default-images')
      const applicationDefaultImage = getDefaultImageFromResults(data, CONSTANTS.applicationDefault)
      const relocateDefaultImage = getDefaultImageFromResults(data, CONSTANTS.relocateDefault)

      setCurrentApplicationDefault(applicationDefaultImage)
      setCurrentRelocateDefault(relocateDefaultImage)
    }

    const fetchAvailableImages = async () => {
      const {data} = await axios.get('/api/fota/firmware-versions')

      const applicationFirmwareVersions = data.filter(({name}) => name.toLowerCase().includes('application') || name.toLowerCase().includes('generic'))
      const relocateFirmwareVersions = data.filter(({name}) => name.toLowerCase().includes('relocate'))

      setAvailableApplicationImages(applicationFirmwareVersions)
      setAvailableRelocateImages(relocateFirmwareVersions)
    }

    fetchCurrentDefaultImages()
      .catch(console.error)

    fetchAvailableImages()
      .catch(console.error)
  }, [])

  const updateApplicationDefault = () => axios.put('/api/fota/default-images/application-image', {applicationSignature: selectedApplicationDefault})
  const updateRelocateDefault = () => axios.put('/api/fota/default-images/relocate-image', {relocateSignature: selectedRelocateDefault})

  return (
    <div className='default-images'>
      <p>Current Application Default: {currentApplicationDefault}</p>
      <select onChange={(e) => {
        setSelectedApplicationDefault(e.target.value)
      }}>
        {availableApplicationImages.map((applicationImage) => <option key={applicationImage.crc_id} value={applicationImage.crc_id}>{applicationImage.crc_id}</option>)}
      </select>
      <button onClick={updateApplicationDefault}>Update Application Default</button>
      <p>Current Relocate Default: {currentRelocateDefault}</p>
      <select onChange={(e) => {
        setSelectedRelocateDefault(e.target.value)
      }}>
        {availableRelocateImages.map((relocateImage) => <option key={relocateImage.crc_id} value={relocateImage.crc_id}>{relocateImage.crc_id}</option>)}
      </select>
      <button onClick={updateRelocateDefault}>Update Relocate Default</button>
    </div>
  )
}

// current application default text
// current relocate default text
// application dropdown - default option is --select--
// relocate dropdown - default option is --select--