import React from 'react'
import DirectoryMenu from './Menu'
import Toolbar from './Toolbar'
import {getPath, route} from 'router'
import {Query, graphql} from 'react-apollo'
import { gql } from 'graphql.macro'
import * as compose from 'lodash/flowRight'
import mapDevicesToTree from 'utils/mapDevicesToTree'
import {GET_ME} from 'queries'
import isSubpath from 'utils/isSubpath'
import Loading from 'Components/Loading'
// import isDelayed from 'utils/isDelayed'
// import isNewDevice from 'utils/isNewDevice'
import {connect} from 'react-redux'
import {
  setDirectoryPath,
  setDirectoryCompany,
  toggleIncludeInactive,
  toggleIncludeOOS,
  setViewType
} from 'store/actions'

import Icons from './Icons'
import List from './List'
import Loadable from 'react-loadable'
import AddButton from 'Components/AddButton'
import {linkHandler} from 'router'

import './index.scss'

export const GET_ME_AND_ALARM = gql`
  query GET_ME_AND_ALARM {
    me {
      id
      firstName
      lastName
      email
      phoneNumber
      countryCode
      isSuperUser
      isManager
      isDemo
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    alarmCount {
      alarm_count
    }
  }
`
export const GET_DIRECTORY_COMPANIES = gql`
  query GET_DEVICES_DIRECTORY_COMPANIES {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    companies {
      id
      name
      isActive
    }
  }
`

export const GET_DEVICES_DIRECTORY_LIST = gql`
  query GET_DEVICES_DIRECTORY_LIST($includeInactive: Boolean, $includeOOS: Boolean, $query: String, $selectedCompanyID: Int) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesRe(includeInactive: $includeInactive, includeOOS: $includeOOS, query: $query, selectedCompanyID: $selectedCompanyID) {
      id
      name
      path
      defaultTag
      companyID
      tags {
        tag
        alarms {
          flag
        }
      }
    }
  }
`

export const GET_DEVICES_SCRATCH_RE = gql`
  query GET_DEVICES_SCRATCH_RE($includeInactive: Boolean, $includeOOS: Boolean, $query: String, $selectedCompanyID: Int, $limit: Int, $offset: Int) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesRe(includeInactive: $includeInactive, includeOOS: $includeOOS, query: $query, selectedCompanyID: $selectedCompanyID, limit: $limit, offset: $offset) {
      id
      userAssignedID
      name
      path
      batteryLevel
      displayUnits
      longitude
      latitude
      sensorSubtype1
      sensorSubtype2
      firmwareVersion
      modemType
      simNumber
      boardRev
      signalStrength
      defaultTag
      companyID
      company {
        name
      }
      iconFluid
      latestReading
      lastTimeMessageReceived
      tags {
        tag
        ymax
        ymin
        alarms {
          flag
        }
      }
    }
  }
`

const MapComp = new Loadable({
  loader: () => import('./Map'),
  loading: Loading
})

const Directory = ({viewType, includeInactive, includeOOS, companyID, path}) => (
  <Query query={GET_DEVICES_SCRATCH_RE} variables={{includeInactive, includeOOS, query: 'directory-details', selectedCompanyID: companyID}}>
    {({error, loading, data}) => {
      if (error) return 'error'
      if (loading) return <Loading />

      const isSuperUser = data && data.me && data.me.isSuperUser
      const isSpecificUser = data && data.me && data.me.id === 12034

      if (data.loading) return <Loading />

      const allDevices = (data.devicesRe || []).filter((d) =>
        (!!isSuperUser && !!isSpecificUser) && companyID === null
          ? true
          : (!isSuperUser && !isSpecificUser) || (d.companyID === companyID) || (d.company && d.company.id === companyID)
      )

      const devices = allDevices.filter(
        (d) => d.path && d.path.length && isSubpath(path, d.path)
      )

      // If there is no selected company to view then route to original page
      if (isSuperUser && companyID === null) {route("/alerts")}
      else if (isSpecificUser && companyID === null) {route("/alarms")}

        return (
          <div className="directory-body">
            <Toolbar
              isSuperUser={data && data.me && data.me.isSuperUser}
              isManager={data && data.me && data.me.isManager}
            />
            {(allDevices.length !== 0 && viewType) === "icons" ? (
              <Icons devices={devices} />
            ) : (allDevices.length !== 0 && viewType) === "list" ? (
              <List devices={devices} isSuperUser={data && data.me && data.me.isSuperUser}/>
            ) : (allDevices.length !== 0 && viewType) === "map" ? (
              <MapComp devices={devices} />
            ) : null}

            {allDevices.length === 0 && (
              <>
                <i className="missing-icon fa fa-question-circle fa-5x" aria-hidden="true"></i>
                <h1 className="empty-text">No Devices Found</h1>
                <p className="empty-desc">Your device may not be set as active.<br />If you believe you should see devices here<br />contact your company lead or Sensorfield</p>
              </>
              )}
          </div>
        )
    }}
  </Query>

)

export const withToolbar = (Comp) => (props) =>
  (
    <Query query={GET_ME}>
      {({data}) => (
        <div className="directory-body">
          <Toolbar
            isSuperUser={data && data.me && data.me.isSuperUser}
            isManager={data && data.me && data.me.isManager}
          />
          <Comp {...props} />
        </div>
      )}
    </Query>
  )

const mapStateToProps = (state) => ({
  path: state.directory.path,
  companyID: state.directory.companyID,
  includeInactive: state.directory.includeInactive,
  includeOOS: state.directory.includeOOS,
  viewType: state.directory.viewType
})
const mapDispatchToProps = (dispatch) => ({
  setDirectoryPath: (path) => dispatch(setDirectoryPath(path)),
  setDirectoryCompany: (id) => {
    dispatch(setDirectoryPath(''))
    dispatch(setDirectoryCompany(id))
  },
  toggleIncludeInactive: () => dispatch(toggleIncludeInactive()),
  toggleIncludeOOS: () => dispatch(toggleIncludeOOS()),
  setViewType: (type) => dispatch(setViewType(type))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Directory)

const MenuInner = ({
  data,
  path,
  companyID,
  viewType,
  includeInactive, 
  includeOOS,
  setDirectoryPath,
  // closeMenu,
  setDirectoryCompany,
}) => {
  if (data.error) return <div>error</div>
  const isSuperUser = data?.me?.isSuperUser
  const isManager = data?.me?.isManager
  const isSpecificUser = data?.me?.id === 12034
  const alarmCount = data?.alarmCount?.alarm_count
  // const isVia =
  //   data && data.me && data.me.company && data.me.company.id === 10279

  const viewInactiveCompanies = data && data.me && data.me.viewInactiveCompanies

  return (
    <div className="directory-sidebar">
      <AddButton onClick={linkHandler('/addDevice')} />
      {!data.loading && (
        <ul className="directory-groups">
          {/* {!!isSuperUser && (
            <li
              className='clickable alarms-link active'
              onClick={() => route('/tasks')}
            >
              Tasks
            </li>
          )} */}
          {/* {(!!isSuperUser || !!isVia) && (
            <li
              className={'clickable alarms-link'}
              onClick={() => route('/manufacturingChecklist')}
            >
              Manufacturing
            </li>
          )} */}
          {!isSuperUser && (
            <li
            className={
                 'clickable alarms-link active'
            }
              onClick={() => route('/alarms')}
            ><i className="fa fa-bell"/>
              <span>Alarms ({alarmCount})</span>
            </li>
          )}
          {!!(isSuperUser || isManager) && (
            <li
            className='clickable alarms-link active'
              onClick={() => route('/alerts')}
            ><i className="fa fa-clock"/>
             <span>Alerts / Tardy Devices</span>
            </li>
            )}
          {!!isSuperUser && (
            <li
              className='clickable ghost-link'
              onClick={() => route('/ghost')}
            ><i className="fa fa-clock-rotate-left"/>
              <span>Modem IPR Reset</span>
            </li>
          )}
          {!!isSuperUser && (
            <li
              className='clickable battery-link'
              onClick={() => route('/battery')}
            ><i className="fa fa-battery"/>
              <span>Battery Insights</span>
            </li>
          )}
          {!!isSuperUser && (
            <li
              className='clickable cellular-link'
              onClick={() => route('/cellular')}
            ><i className="fa fa-phone"/>
              <span>Cellular Network</span>
            </li>
          )}
          {!!isSuperUser && (
            <li
              className='clickable activity-link'
              onClick={() => route('/activity')}
            ><i className="fa fa-calendar"/>
              <span>User Activity</span>
            </li>
          )}
            <li
              className="clickable all-devices-link"
              onClick={() => route('/allDevices')}
            ><i className="fa fa-star"/>
              <span>All Devices</span>
            </li>
            <li
              className="clickable new-devices-link"
              onClick={() => route('/newDevices')}
            ><i className="fa-regular fa-star"/>
              <span>New Devices</span>
            </li>
            <li
              className="clickable offline-devices-link"
              onClick={() => route('/offline')}
            ><i className="fa-regular fa-star-half"/>
              <span>Offline Devices</span>
            </li>
        </ul>
      )}
      <hr />
      <div className="tree">
        {(isSuperUser || isSpecificUser) ? (
          <Query query={GET_DIRECTORY_COMPANIES}>
          {({data}) => (
          <ul>
            {((data && data.companies) || [])
              .filter((c) => {
                // if (isSpecificUser) {
                //   return [10344, 10303, 10359, 10337, 10345, 10379, 10292, 10381, 10365].includes(c.id)
                // }
                if (!!viewInactiveCompanies) {
                  return true
                } else {
                  return !!c.isActive
                }
              })
              .map((c) => (
                <li key={c.id}>
                  <div
                    className={
                      companyID === c.id ? 'clickable active' : 'clickable'
                    }
                    onClick={() => {
                      if (companyID === c.id) {
                        if (path === '' && getPath() === '/directory') {
                          setDirectoryCompany(null)
                          setDirectoryPath('')
                          route('/directory')
                        } else {
                          setDirectoryPath('')
                          route('/directory')
                        }
                      } else {
                        setDirectoryCompany(c.id)
                        route('/directory')
                        setDirectoryPath('')
                      }
                    }}
                  >
                    {c.name}
                  </div>

                  {companyID === c.id && (
                    <Query query={GET_DEVICES_DIRECTORY_LIST} variables={{includeInactive, includeOOS, query: 'directory-side', selectedCompanyID: companyID}} fetchPolicy= {'no-cache'}>
                    {({error, loading, data}) => {
                      if (error) return 'error'
                      if (loading) return <Loading />
                      const allDevices = (data.devicesRe || []).filter((d) => {
                        // d.companyID is from `recent_data` table. leaving the last condition just in case / backwards compatibility
                        return (!isSuperUser && !isSpecificUser) || (d.companyID === companyID) || (d.company && d.company.id === companyID)
                      })
                    
                      const tree = mapDevicesToTree(allDevices)
                      const alwaysOpenMenu = allDevices.length < 15
                                
                        return (
                          <DirectoryMenu
                            tree={tree}
                            directoryPath={path}
                            setDirectoryPath={setDirectoryPath}
                            viewType={viewType}
                            open={alwaysOpenMenu}
                          />)
                          }}
                    </Query>
                  )}
                </li>
              ))}
            </ul>
          )}
          </Query>
        ) : (
          <Query query={GET_DEVICES_DIRECTORY_LIST} variables={{includeInactive, includeOOS, query: 'directory-side-user'}} fetchPolicy= {'no-cache'}>
          {({error, loading, data}) => {
            if (error) return 'error'
            if (loading) return <Loading />
            const allDevices = (data.devicesRe || []).filter((d) => {
            // d.companyID is from `recent_data` table. leaving the last condition just in case / backwards compatibility
            return (!isSuperUser && !isSpecificUser) || (d.companyID === companyID) || (d.company && d.company.id === companyID)
            })
          
            const tree = mapDevicesToTree(allDevices)
            const alwaysOpenMenu = allDevices.length < 15

            return (
              <DirectoryMenu
                tree={tree}
                directoryPath={path}
                setDirectoryPath={setDirectoryPath}
                viewType={viewType}
                open={alwaysOpenMenu}
              />)
          }}
          </Query>
        )}
      </div>
    </div>
  )
}

export const Menu = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GET_ME_AND_ALARM)
)(MenuInner)
