import React from 'react'
import './Modal.scss'

class Modal extends React.Component {
  escapeHandler = e => {
    if (e.keyCode === 27 && this.props.onClose) this.props.onClose()
  }
  clickAway = e => {
    e.preventDefault()
    e.stopPropagation()
    if (this.props.onClose) this.props.onClose()
  }
  componentDidMount() {
    document.addEventListener('keydown', this.escapeHandler, false)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.escapeHandler, false)
  }
  render() {
    return (
      <div
        className={
          this.props.className
            ? 'modal-blackout ' + this.props.className
            : 'modal-blackout'
        }
        onClick={this.clickAway}
      >
        <div className="modal" onClick={e => e.stopPropagation()}>
          {!!this.props.title && (
            <div className="modal-title">{this.props.title}</div>
          )}
          <div className="modal-body">{this.props.children}</div>
          {!!this.props.footer && (
            <div className="modal-footer">{this.props.footer}</div>
          )}
        </div>
      </div>
    )
  }
}
Modal.Title = props => <div className="modal-title">{props.children}</div>
Modal.Body = props => <div className="modal-body">{props.children}</div>
Modal.Footer = props => <div className="modal-footer">{props.children}</div>

export default Modal
