import React from 'react'
import {ReactComponent as NoAlarms} from 'assets/noAlarms.svg'
import DeviceCard from 'Components/DeviceCard'
import {Query} from 'react-apollo'
import { gql } from 'graphql.macro'
import {route} from 'router'
import Loading from 'Components/Loading'

import './Alarms.scss'

export const GET_DEVICES_ALARMS = gql`
  query GET_DEVICES_ALARMS($query: String) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesRe(query: $query) {
      id
      name
      path
      isActive
      inService
      latestReading
      lastTimeMessageReceived
      displayUnits
      defaultTag
      iconFluid
      tags {
        id
        deviceID
        tag
        alarms {
          criticalLow
          criticalHigh
          lowWarning
          highWarning
          flag
        }
      }
    }
  }
`

const Alarms = () => (
  <Query query={GET_DEVICES_ALARMS} variables={{query: 'alarm'}}>
    {({error, loading, data}) => {
      if (error) return 'error'
      if (loading) return <Loading />
      if (!!data && data.me.isSuperUser) {
        route('/alerts')
      } 
      // tag !== '_v' && tag !== '_t' && 
      const alarms = (data.devicesRe || [])

      if (alarms.length === 0)
        return (
          <div className="no-alarms">
            <NoAlarms />
            <h1>No alarms</h1>
          </div>
        )
      else
        return (
        <>{!data.me.isSuperUser && (
          <div className=" device-card-container">
            <div className=" device-card-row">
              <div className="title">Alarms</div>
              {alarms.map((d) => (
                <DeviceCard device={d} key={d.id} />
              ))}
            </div>
          </div>)}
          </>
        )
    }}
  </Query>
)

export default Alarms
