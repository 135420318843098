import React, {Component} from 'react'
import virtualize from 'react-virtual-list'

export default class List extends Component {
  VirtualList = () => null
  componentDidUpdate(prevProps) {
    if (prevProps.renderItem !== this.props.renderItem)
      this.ref(this.containerRef)
  }
  ref = ref => {
    this.containerRef = ref
    this.VirtualList = virtualize({container: ref})(this.ListSkeleton)
    this.setState({})
  }
  ListSkeleton = ({virtual, itemHeight}) => (
    <div style={virtual.style}>{virtual.items.map(this.props.renderItem)}</div>
  )
  render() {
    return (
      <div
        ref={this.ref}
        style={{...this.props.style, flex: '1 1 auto', overflowY: 'scroll'}}
        className={this.props.className}
      >
        {React.createElement(this.VirtualList, {
          itemHeight: this.props.itemHeight,
          items: this.props.items
        })}
      </div>
    )
  }
}
