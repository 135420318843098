import React from 'react'
import isMobile from 'utils/isMobile'
import './Menu.scss'

class Menu extends React.Component {
  escapeHandler = e => {
    if (e.keyCode === 27 && this.props.onClose) {
      e.stopPropagation()
      this.props.onClose()
    }
  }
  componentDidMount() {
    document.addEventListener('keydown', this.escapeHandler, false)
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.escapeHandler, false)
  }
  render() {
    const onClick = e => {
      e.preventDefault()
      e.stopPropagation()
      if (this.props.onClose) this.props.onClose()
    }
    const menuStyle = isMobile()
      ? {left: '35%', top: '50%', width: '200px', fontSize: '1.75em'}
      : {left: this.props.x, top: this.props.y}

    return (
      <div
        className={'menu-backdrop ' + this.props.className}
        onClick={onClick}
      >
        <div className="menu" style={menuStyle}>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Menu.Item = props => (
  <div className={'menu-item ' + props.className} onClick={props.onClick}>
    {props.children}
  </div>
)

export default Menu
