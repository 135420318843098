import React, {Component} from 'react'
import {Input} from 'Components/Form'
import {graphql} from 'react-apollo'
import {gql} from 'graphql.macro'
import Loading from 'Components/Loading'
import {GET_DEVICES} from 'queries'
import {timer as formatTimer} from 'utils/format'
import {ReactComponent as SensorLabel} from 'assets/sensor-label.svg'
import {GET_ME} from 'queries'
import * as compose from 'lodash/flowRight'

import './AddDevice.scss'

const ADD_DEVICE = gql`
  mutation ADD_DEVICE(
    $deviceID: Int!
    $pin: Int!
    $name: String
    $path: String
    $latitude: Float
    $longitude: Float
    $companyID: Int
  ) {
    addDevice(
      deviceID: $deviceID
      pin: $pin
      name: $name
      path: $path
      latitude: $latitude
      longitude: $longitude
      companyID: $companyID
    ) {
      device {
        id
        name
        path
        deviceType
        isOnline
        companyID
      }
    }
  }
`

const initialState = {
  deviceID: '',
  pin: '',
  name: '',
  path: '',
  step: 0,
  isOnline: false,
  didTimeout: false,
  error: false,
  pending: false,
  didSaveInfo: false
}

class AddDevice extends Component {
  state = initialState
  reset = () => {
    this.setState(initialState)
    this.stopPolling()
  }
  componentWillUnmount = () => this.stopPolling()
  startPolling = () => (this.interval = setInterval(this.pollDevice, 5000))
  stopPolling = () => clearInterval(this.interval)
  pollDevice = () => {
    this.props
      .addDevice({
        variables: {deviceID: this.state.deviceID * 1, pin: this.state.pin * 1},
        refetchQueries: {query: GET_DEVICES}
      })
      .then((res) => {
        const isOnline = res.data.addDevice.device.isOnline
        this.setState({step: 1, error: false, pending: false, isOnline})
        if (isOnline) this.stopPolling()
      })
      .catch(() =>
        this.setState({step: 0, error: true, pending: false, isOnline: false})
      )
  }

  renderIcon = () => {
    if (this.state.step === 0) return <SensorLabel />
    else if (this.state.isOnline === true)
      return (
        <div className="is-online">
          <i className="fa fa-check-circle" />
          <div className="title">Device is Online</div>
          <p>
            <span className="link" onClick={this.reset}>
              Go back
            </span>{' '}
            to add another device.
          </p>
        </div>
      )
    else if (this.state.didTimeout === true)
      return (
        <div className="did-timeout">
          <i className="fa fa-warning" />
          <div className="title">Timed Out</div>
          <p>
            The server has not yet received a message from the device. Make sure
            the device correctly turns on and connects. If you are still having
            trouble, contact us at <a href="tel:281-631-5926">(281) 631-5926</a>
            .
          </p>
          <p>
            <span className="link" onClick={this.reset}>
              Go back
            </span>{' '}
            to start over.
          </p>
        </div>
      )
    else
      return (
        <WaitingToConnect
          onTimeout={() => this.setState({didTimeout: true})}
          onReset={this.reset}
        />
      )
  }
  render() {
    return (
      <div
        className={`add-device step-${this.state.step} ${
          this.state.error ? 'error' : ''
        }`}
      >
        <div className="container">
          <div className="col box-icon">{this.renderIcon()}</div>
          <div className="col inputs">
            <div className="flex-row device-id">
              <div className="label">Device ID: </div>
              <div className="input">
                <input
                  type="number"
                  value={this.state.deviceID}
                  onChange={(e) => this.setState({deviceID: e.target.value})}
                  disabled={this.state.step !== 0}
                />
              </div>
            </div>
            <div className="flex-row pin">
              <div className="label">Pin: </div>
              <div className="input">
                <input
                  type="number"
                  value={this.state.pin}
                  onChange={(e) => this.setState({pin: e.target.value})}
                  disabled={this.state.step !== 0}
                />
              </div>
            </div>
            {this.props.data.me && !this.props.data.me.isDemo && (
              <div className="flex-row name">
                <div className="label">Name: </div>
                <div className="input">
                  <input
                    value={this.state.name}
                    onChange={(e) => this.setState({name: e.target.value})}
                  />
                </div>
              </div>
            )}
            {this.props.data.me && !this.props.data.me.isDemo && (
              <div className="flex-row directory">
                <div className="label">Directory: </div>
                <div className="input">
                  <Input
                    type="directory"
                    value={this.state.path}
                    onChange={(e) => this.setState({path: e.target.value})}
                    disabled={this.state.didSaveInfo}
                  />
                </div>
              </div>
            )}
            {this.state.step === 0 ? (
              <div className="row buttons">
                <button
                  className="add-button primary"
                  onClick={() => {
                    this.setState({pending: true})
                    this.pollDevice()
                  }}
                  disabled={this.state.pending || this.state.didSaveInfo}
                >
                  {this.state.pending ? <Loading nowait /> : 'Add Device'}
                </button>
              </div>
            ) : (
              <div className="row buttons">
                {this.state.pending ? (
                  <Loading nowait />
                ) : this.state.didSaveInfo ? (
                  <button disabled>Saved</button>
                ) : (
                  <button
                    className="add-button primary"
                    onClick={() => {
                      this.setState({pending: true})
                      this.props
                        .addDevice({
                          variables: {
                            deviceID: this.state.deviceID * 1,
                            pin: this.state.pin * 1,
                            name: this.state.name,
                            path: this.state.path
                          },
                          refetchQueries: {query: GET_DEVICES}
                        })
                        .then(() =>
                          this.setState({
                            pending: false,
                            didSaveInfo: true
                          })
                        )
                    }}
                  >
                    Save Info
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

class WaitingToConnect extends Component {
  state = {endTime: Date.now() + 60 * 1000 + 500}
  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({})
      if (Date.now() > this.state.endTime) this.props.onTimeout()
    }, 1000)
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  render() {
    return (
      <div className="waiting-to-connect">
        <Loading nowait />
        <div className="title">
          {formatTimer((this.state.endTime - Date.now()) / 1000)}
        </div>
        <p>Listening for message from device...</p>
        <p>
          <span className="link" onClick={this.props.onReset}>
            Go back
          </span>{' '}
          to start over.
        </p>
      </div>
    )
  }
}

// export default graphql(ADD_DEVICE, {name: 'addDevice'})(AddDevice)

export default compose(
  graphql(ADD_DEVICE, {name: 'addDevice'}),
  graphql(GET_ME)
)(AddDevice)
