import React from 'react'
import Select from './Select'
import {GET_GROUPS} from 'queries'
import {Query} from 'react-apollo'

export default (props) => (
  <Query query={GET_GROUPS}>
    {({loading, error, data}) => (
      <Select
        {...props}
        options={((data && data.groups) || [])
          .filter((g) => !props.filter || props.filter(g))
          .map((g) => ({
            label: g.name,
            value: g.id
          }))}
      />
    )}
  </Query>
)
