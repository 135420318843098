import React from 'react'
import {uniqBy} from 'lodash'
import Select from './Select'
import {GET_GROUPS} from 'queries'
import {Query} from 'react-apollo'
import {name as formatName} from 'utils/format'

const getUserListFromGroup = (data, groupIds) => {
  const filteredGroups = ((data && data.groups) || [])
    .filter(group => groupIds.includes(group.id))
  
  return uniqBy(
    filteredGroups
      .map(({users}) => {
        return users.map(u => ({
          label: formatName(u),
          value: u.id
        }))
      })
      .flat()
      .sort((a, b) => a.label.localeCompare(b.label)),
    'value'
  )
}

export default props => (
  <Query query={GET_GROUPS}>
    {({loading, error, data}) => (
      <Select
        {...props}
        options={getUserListFromGroup(data, props.groupIds)}
      />
    )}
  </Query>
)
