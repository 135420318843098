import React, {Component} from 'react'
import CompanyDeviceList from './CompanyDeviceList'
import CompanyUserList from './CompanyUserList'
import './ViewCompanyWide.scss'
import {linkHandler} from 'router'

class ViewCompanyWide extends Component {
  constructor(props) {
    super(props)
    this.state = this.getCleanState()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.group !== this.props.group)
      this.setState(this.getCleanState())
  }
  getCleanState = () => {
    const company = this.props.company
    return {
      editing: false,
      company: company,
      name: company.name,
      users: company.users,
      devices: company.inclusiveDevices,
      companyID: company ? company.id : null
    }
  }
  render = () => (
    <div className="view-user">
      <div className="row">
        <div className="left">
          <h1>{this.state.company && this.state.company.name}</h1>
          <div className="divider" />

          <div className="buttons">
            <button onClick={linkHandler('/addUser')}>
              <i className="fa fa-plus" /> Add User
            </button>
            <button onClick={linkHandler('/addGroup')}>
              <i className="fa fa-plus" /> Add Group
            </button>
          </div>
        </div>
        <div className="right"></div>
      </div>
      <div className="row permissions">
        <div className="col">
          {<CompanyDeviceList devices={this.state.devices} />}
        </div>
        <div className="col">
          {<CompanyUserList users={this.state.users} />}
        </div>
      </div>
    </div>
  )
}

export default ViewCompanyWide
