const initialUserState = {
  loginAsUserID: null,
  token: false,
  authorization: false
}

export default function user(state = initialUserState, action) {
  switch (action.type) {
    case 'REFRESH_TOKEN':
      return {
        ...state,
        token: action.token,
        authorization: action.authorization
      }
    case 'LOGIN_AS':
      return {...state, loginAsUserID: action.userID}
    case 'LOGOUT':
      return initialUserState
    default:
      return state
  }
}
