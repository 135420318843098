import update from 'immutability-helper'

const MIN_X_SCALE = 0.000007297562125554499

const initialState = {
  container: {
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    leftOffset: 0,
    rightOffset: 0,
    topOffset: 0,
    bottomOffset: 0
  },
  view: {
    x: {
      start: 0,
      end: 0,
      scale: 0
    },
    y: {
      start: 0,
      end: 0,
      scale: 0
    }
  }
}

const updateScale = (state) =>
  update(state, {
    view: {
      x: {
        scale: {
          $set:
            (state.container.width -
              state.container.leftOffset -
              state.container.rightOffset) /
            (state.view.x.end - state.view.x.start)
        }
      },
      y: {
        scale: {
          $set:
            (state.container.height -
              state.container.topOffset -
              state.container.bottomOffset) /
            (state.view.y.end - state.view.y.start)
        }
      }
    }
  })
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CONTAINER_CHANGE_OFFSET':
      const s = state.container,
        p = action.payload
      return updateScale(
        update(state, {
          container: {
            $merge: {
              leftOffset: s.leftOffset + (p.leftOffset || 0),
              rightOffset: s.rightOffset + (p.rightOffset || 0),
              topOffset: s.topOffset + (p.topOffset || 0),
              bottomOffset: s.bottomOffset + (p.bottomOffset || 0)
            }
          }
        })
      )
    case 'CONTAINER_UPDATE_OFFSET':
      return updateScale(
        update(state, {
          container: {
            $merge: {
              leftOffset:
                action.payload.leftOffset !== null
                  ? action.payload.leftOffset
                  : state.container.leftOffset,
              rightOffset:
                action.payload.rightOffset !== null
                  ? action.payload.rightOffset
                  : state.container.rightOffset
            }
          }
        })
      )
    case 'CONTAINER_UPDATE':
      return updateScale(update(state, {container: {$merge: action.payload}}))
    case 'VIEW_SET':
      return updateScale(
        update(state, {
          view: {
            x: {
              $set: {
                start: action.payload.x.start,
                end: action.payload.x.end
              }
            },
            y: {
              $set: {
                start: action.payload.y.start,
                end: action.payload.y.end
              }
            }
          }
        })
      )
    case 'VIEW_MOVE':
      const xShift = action.payload.x / state.view.x.scale
      const yShift = action.payload.y / state.view.y.scale
      return update(state, {
        view: {
          x: {
            start: {$set: state.view.x.start - (xShift || 0)},
            end: {$set: state.view.x.end - (xShift || 0)}
          },
          y: {
            start: {$set: state.view.y.start + (yShift || 0)},
            end: {$set: state.view.y.end + (yShift || 0)}
          }
        }
      })
    case 'VIEW_UPDATE':
      return updateScale(
        update(state, {
          view: {
            x: {$merge: action.payload.x || {}},
            y: {$merge: action.payload.y || {}}
          }
        })
      )
    case 'VIEW_ZOOM':
      const factor =
        state.view.x.scale * action.payload.factor < MIN_X_SCALE
          ? MIN_X_SCALE / state.view.x.scale
          : action.payload.factor

      const dataCenter =
        (action.payload.center.x - state.container.left) / state.view.x.scale +
        state.view.x.start
      return update(state, {
        view: {
          x: {
            $set: {
              scale: state.view.x.scale * factor,
              start:
                dataCenter - (1 / factor) * (dataCenter - state.view.x.start),
              end: dataCenter - (1 / factor) * (dataCenter - state.view.x.end)
            }
          }
        }
      })
    default:
      return state
  }
}

export default reducer
