import {saveAs} from 'file-saver'

const downloadCSV = (title, rows, header) => {
  const string = rows.reduce(
    (str, r) => `${str}\r\n${r.map(escape).join(',')}`,
    header.map(escape).join(',')
  )
  const blob = new Blob([string], {type: 'data:attachment/csv'})
  saveAs(blob, `${title}.csv`)
}

const escape = cell => `"${(cell + '').replace(/"/g, `""`)}"`

export default downloadCSV
