import React from 'react'

const InputNumber = props =>
  props.static ? (
    props.value || <em>None</em>
  ) : (
    <input
      type="number"
      onChange={e =>
        props.onChange({
          ...e,
          target: {
            dataset: e.target.dataset,
            value: parseInt(e.target.value, 10)
          }
        })
      }
      value={props.value === null ? '' : props.value}
      data-key={props['data-key']}
      data-nullable={props['data-nullable']}
      className={
        props.valid === true ? 'valid' : props.valid === false ? 'invalid' : ''
      }
      placeholder={props.placeholder}
      onKeyUp={props.onKeyUp}
      pattern="[0-9]*"
    />
  )

export default InputNumber
