import React from 'react'

const Select = props => {
  if (props.static)
    return (
      props.options.find(o => o.value === props.value) || {label: <em>None</em>}
    ).label
  const options =
    props.value === null
      ? [{label: props.emptyLabel || '', value: null}].concat(props.options)
      : props.options
  const onChange = e => {
    const dataset = e.target.dataset
    const index = parseFloat(e.target.value)
    //const valueOut =
    props.onChange({
      target: {
        dataset: dataset,
        value: isNaN(index)
          ? null
          : typeof options[index] === 'object'
          ? options[index].value
          : options[index]
      }
    })
  }
  const selectedOptionIndex = options.findIndex(
    o => props.value === o || props.value === o.value
  )
  const defaultOptionIndex = options.findIndex(o => o.default)
  const value =
    selectedOptionIndex !== -1
      ? selectedOptionIndex + ''
      : defaultOptionIndex !== -1
      ? defaultOptionIndex + ''
      : '0'

  return (
    <select
      value={value}
      data-key={props['data-key']}
      onChange={onChange}
      disabled={props.disabled}
    >
      {options.map((o, i) =>
        typeof o === 'object' ? (
          <option key={i} value={i + ''} disabled={o.disabled === true}>
            {o.label}
          </option>
        ) : (
          <option key={i} value={i + ''}>
            {o}
          </option>
        )
      )}
    </select>
  )
}

export default Select
