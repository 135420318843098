import React from 'react'
import {ButtonGroup, ButtonGroupOption} from 'Components/ButtonGroup'

const ButtonGroupInput = ({value, options, onChange, ...props}) => {
  if (props.static)
    return (options.find(o => o.value === value) || {label: ''}).label

  return (
    <ButtonGroup value={value} data-key={props['data-key']}>
      {options.map((o, i) => (
        <ButtonGroupOption
          key={i}
          selected={'value' in o ? o.value === value : o === value}
          onClick={() =>
            onChange({
              target: {
                dataset: props.dataset,
                value: 'value' in o ? o.value : o
              }
            })
          }
        >
          {'label' in o ? o.label : o}
        </ButtonGroupOption>
      ))}
    </ButtonGroup>
  )
}

export default ButtonGroupInput
