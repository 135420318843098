import React from 'react'
import {connect} from 'react-redux'
import {date as formatDate, dateTime as formatDateTime} from 'utils/format'
import {changeOffset} from './store/actions'

//possible spacing intervals in [seconds, minutes, hours, days, months, years]
const intervals = [
  [1, 0, 0, 0, 0, 0],
  [5, 0, 0, 0, 0, 0],
  [15, 0, 0, 0, 0, 0],
  [0, 1, 0, 0, 0, 0],
  [0, 5, 0, 0, 0, 0],
  [0, 15, 0, 0, 0, 0],
  [0, 0, 1, 0, 0, 0],
  [0, 0, 3, 0, 0, 0],
  [0, 0, 12, 0, 0, 0],
  [0, 0, 0, 1, 0, 0],
  [0, 0, 0, 3, 0, 0],
  [0, 0, 0, 7, 0, 0],
  [0, 0, 0, 14, 0, 0],
  [0, 0, 0, 0, 1, 0],
  [0, 0, 0, 0, 3, 0],
  [0, 0, 0, 0, 6, 0],
  [0, 0, 0, 0, 0, 1],
  [0, 0, 0, 0, 0, 5]
]

const createIncrementTimeByInterval = (interval) => {
  if (interval[0])
    return (time, interval) => time.setTime(time.getTime() + 1000 * interval[0])
  if (interval[1])
    return (time, interval) =>
      time.setTime(time.getTime() + 1000 * 60 * interval[1])
  if (interval[2])
    return (time, interval) =>
      time.setTime(time.getTime() + 1000 * 60 * 60 * interval[2])
  if (interval[3])
    return (time, interval) => time.setDate(time.getDate() + interval[3])
  if (interval[4])
    return (time, interval) => time.setMonth(time.getMonth() + interval[4])
  if (interval[5])
    return (time, interval) =>
      time.setFullYear(time.getFullYear() + interval[5])
}

class XAxis extends React.Component {
  componentDidMount() {
    this.props.addOffset()
  }
  componentWillUnmount() {
    this.props.removeOffset()
  }
  render() {
    const {start, end, scale, x, y, yLabels, graphHeight} = this.props
    if (yLabels <= 0 || scale === 0) return null
    const roughSpacing = 120 / scale

    //find first clean interval that contains the rough interval
    const interval =
      intervals.find(
        (i) =>
          roughSpacing <
          i[0] +
            i[1] * 60 +
            i[2] * 60 * 60 +
            i[3] * 60 * 60 * 24 +
            i[4] * 60 * 60 * 24 * 30 +
            i[5] * 60 * 60 * 24 * 365
      ) || intervals[0]
    const incrementTimeByInterval = createIncrementTimeByInterval(interval)

    //time to start the grid lines
    let time = new Date(start * 1000)

    //for the various intervals, make sure the starting time starts on a clean interval
    //e.g. if the interval is 1 month, make the first line at March 1 at midnight
    if (interval[0]) {
      time.setSeconds(time.getSeconds() - (time.getSeconds() % interval[0]))
    }
    if (interval[1]) {
      time.setSeconds(0)
      time.setMinutes(time.getMinutes() - (time.getMinutes() % interval[1]))
    }
    if (interval[2]) {
      time.setSeconds(0)
      time.setMinutes(0)
      time.setHours(time.getHours() - (time.getHours() % interval[2]))
    }
    if (interval[3]) {
      time.setSeconds(0)
      time.setMinutes(0)
      time.setHours(0)
      time.setDate(time.getDate() - ((time.getDate() - 1) % interval[3]))
    }
    if (interval[4]) {
      time.setSeconds(0)
      time.setMinutes(0)
      time.setHours(0)
      time.setDate(1)
      time.setMonth(time.getMonth() - (time.getMonth() % interval[4]))
    }
    if (interval[5]) {
      time.setSeconds(0)
      time.setMinutes(0)
      time.setHours(0)
      time.setDate(1)
      time.setMonth(0)
      time.setFullYear(time.getFullYear() - (time.getFullYear() % interval[5]))
    }

    let ticks = []
    let labels = []
    let i = 0 //simple counter to make sure the loop finishes even with bad inputs

    while (time.getTime() / 1000 < end && i < 100) {
      if (time.getTime() / 1000 > start) {
        const scaledX = (time.getTime() / 1000 - start) * scale
        ticks.push(
          <line
            key={time.getTime()}
            x1={scaledX}
            x2={scaledX}
            y1={0}
            y2={graphHeight}
            shapeRendering="crispEdges"
            style={{stroke: '#ccc', strokeWidth: 1}}
          />
        )
        labels.push(
          <text
            key={time.getTime()}
            x={scaledX}
            y={yLabels + 20}
            textAnchor="middle"
          >
            {time.getSeconds() === 0 &&
            time.getMinutes() === 0 &&
            time.getHours() === 0
              ? formatDate(time)
              : formatDateTime(time)}
          </text>
        )
      }
      incrementTimeByInterval(time, interval)
      i++
    }

    return (
      <svg x={0} y={y} id="x-axis">
        <g transform={`translate(${x}, 0)`}>
          {this.props.ticks !== false && <g>{ticks}</g>}
          <g>{labels}</g>
        </g>
      </svg>
    )
  }
}

const mapStateToProps = (state) => ({
  start: state.view.x.start,
  end: state.view.x.end,
  scale: state.view.x.scale,
  y: state.container.topOffset,
  yLabels: state.container.height - state.container.bottomOffset,
  x: state.container.leftOffset,
  graphHeight:
    state.container.height -
    state.container.topOffset -
    state.container.bottomOffset
})
const mapDispatchToProps = (dispatch) => ({
  addOffset: () => dispatch(changeOffset({bottomOffset: 30})),
  removeOffset: () => dispatch(changeOffset({bottomOffset: -30}))
})

export default connect(mapStateToProps, mapDispatchToProps)(XAxis)
