import {Component} from 'react'

export default class MediaQuery extends Component {
  constructor(props) {
    super(props)
    this.listen()
  }
  listen = () => {
    this.mql = window.matchMedia(this.props.query)
    this.mql.addListener(this.rerender)
  }
  unlisten = () => this.mql.removeListener(this.rerender)
  rerender = () => this.setState({})
  componentWillUnmount() {
    this.unlisten()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query) {
      this.unlisten()
      this.listen()
    }
  }
  render() {
    if (typeof this.props.children === 'function')
      return this.props.children(this.mql.matches)
    else if (this.mql.matches) return this.props.children
    else return null
  }
}
