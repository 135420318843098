import React, {Component} from 'react'
import DeviceCard from 'Components/DeviceCard'

import './Icons.scss'

const Icons = ({devices, me}) => (
  <div className="device-card-container">
    {devices
      .map(d => d.path)
      .filter((path, i, paths) => paths.indexOf(path) === i)
      .sort((a, b) => a.localeCompare(b))
      .map(p => (
        <Folder
          path={p}
          devices={devices.filter(d => d.path === p)}
          me={me}
          key={p}
        />
      ))}
  </div>
)

// const hasData = d => d.tags.some(t => t.tag[0] !== '_')
const hasData = d => !!d.latestReading

class Folder extends Component {
  state = {open: false}
  render = () => {
    const devices = this.props.devices.sort((a, b) =>
      (a.name || '').localeCompare(b.name || '')
    )
    const emptyDevices = devices.filter(d => !hasData(d))
    const notEmptyDevices = devices.filter(d => hasData(d))
    return (
      <div className="device-card-row">
        <div className="title">
          <ul className="path">
            {(this.props.path || '/Unassigned')
              .split('/')
              .slice(1)
              .map((p, i) => (
                <li key={i}>{p}</li>
              ))}
          </ul>
        </div>
        {notEmptyDevices.map(d => {
          return (
          <DeviceCard
            device={d}
            key={d.id}
            isSuperUser={this.props.me && this.props.me.isSuperUser}
          />)
          })}
        {this.state.open ? (
          emptyDevices.map(d => (
            <DeviceCard
              device={d}
              key={d.id}
              isSuperUser={this.props.me && this.props.me.isSuperUser}
            />
          ))
        ) : emptyDevices.length ? (
          <div className="device-card show-dataless-devices-toggle">
            <div className="box" onClick={() => this.setState({open: true})}>
              View All
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default Icons
