import React from 'react'
import Tooltip from 'Components/Tooltip'

import Checkbox from './Checkbox'
import DirectoryInput from './DirectoryInput'
import FeetInches from './FeetInches'
import InputFloat from './InputFloat'
import InputNumber from './InputNumber'
import Phone from './Phone'
import InputString from './InputString'
import Radio from './Radio'
import Select from './Select'
import ButtonGroupInput from './ButtonGroup'
import User from './User'
import Group from './Group'
import UsersInGroup from './UsersInGroup'
import Address from './Address'

import './index.scss'

const Components = {
  checkbox: Checkbox,
  radio: Radio,
  select: Select,
  buttongroup: ButtonGroupInput,
  feetinches: FeetInches,
  number: InputNumber,
  phone: Phone,
  float: InputFloat,
  user: User,
  group: Group,
  groupUsers: UsersInGroup,
  address: Address,
  directory: DirectoryInput,
  string: InputString
}
export const Input = (props) => {
  const Component = Components[props.type] || Components['string']

  if (props.fieldOnly) return <Component {...props} />
  else
    return (
      <InputWrapper {...props}>
        <Component {...props} />
      </InputWrapper>
    )
}

export const InputRow = ({className, children, ...props}) => {
  return (
    <div className={'input-row ' + (className || '')} {...props}>
      {children}
    </div>
  )
}

export const InputTable = ({className, children, ...props}) => {
  return (
    <div className={'input-table ' + (className || '')} {...props}>
      {children}
    </div>
  )
}

export const InputWrapper = (props) => (
  <div
    id={props.id}
    className={
      props.className ? 'input-group ' + props.className : 'input-group'
    }
  >
    <div className="input-label">
      {props.label}
      {!props.tooltip ? null : <Tooltip>{props.tooltip}</Tooltip>}
    </div>
    {props.children}
  </div>
)

export {
  Checkbox,
  DirectoryInput,
  FeetInches,
  InputFloat,
  InputNumber,
  InputString,
  Radio,
  Select
}
