import React from 'react'
import './Menu2.scss'

export class Menu extends React.Component {
  state = {opened: false}
  escapeHandler = e => (e.keyCode === 27 || e.which === 27) && this.close(e)
  open = e => {
    e.preventDefault()
    e.stopPropagation()
    document.addEventListener('keydown', this.escapeHandler, false)
    document.addEventListener('click', this.close, false)
    this.setState({opened: true})
  }
  close = e => {
    e.preventDefault()
    e.stopPropagation()
    document.removeEventListener('keydown', this.escapeHandler, false)
    document.removeEventListener('click', this.close, false)
    this.setState({opened: false})
  }
  render() {
    const button = this.props.button && this.props.button(this.open)
    return (
      <div className={'menu-component ' + (this.props.className || '')}>
        {this.props.text && (
          <div
            className="menu-text"
            onClick={this.open}
            style={{width: this.props.width || 150}}
          >
            <i className="fa fa-caret-down" />
            <span>{this.props.text}</span>
          </div>
        )}
        {button}
        {this.state.opened && (
          <div
            className="menu-items"
            style={{
              width: this.props.width || 150,
              [this.props.left ? 'right' : 'left']: 0
            }}
          >
            {this.props.children}
          </div>
        )}
      </div>
    )
  }
}

export const MenuItem = props => (
  <div
    className={'menu-item ' + (props.disabled ? 'disabled' : '')}
    id={props.id}
    onClick={!props.disabled && props.onClick}
  >
    {props.children}
  </div>
)

export const MenuSeparator = props => <div className="menu-separator" />
