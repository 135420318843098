import React, {Component} from 'react'
import Search from './Search'
import {linkHandler} from 'router'
import MediaQuery from 'Components/MediaQuery'
import {connect} from 'react-redux'
import {openMenu, closeMenu, toggleMenu} from 'store/actions'
import {ReactComponent as FullLogo} from 'assets/logo-full.svg'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'
import {gql} from 'graphql.macro'
import {name as formatName} from 'utils/format'
import * as Sentry from '@sentry/browser'


import './index.scss'

const GET_ME_AND_LOGIN_FROM = gql`
  query GET_ME_AND_LOGIN_FROM($loginFromUserID: Int!) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
    }
    loginFromUser: user(id: $loginFromUserID) {
      id
      firstName
      lastName
      isManager
    }
    notice {
      notice_type
      notice_content
    }
  }
`

export const GET_ME_AND_NOTICE = gql`
  query GET_ME_AND_NOTICE {
    me {
      id
      firstName
      lastName
      email
      phoneNumber
      countryCode
      isSuperUser
      isManager
      isDemo
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    notice {
      notice_type
      notice_content
    }
  }
`

class Layout extends Component {
  state = {
    showPopup: true
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.swipeStart)
    window.addEventListener('touchend', this.swipeEnd)

    const popupToggle = localStorage.getItem('popupToggle')

    if (popupToggle === 'false') {
      this.setState({
        showPopup: false
      })
    }

  }
  componentDidUpdate(prevProps) {
    const prevID = prevProps.data.me && prevProps.data.me.id
    const currentID = this.props.data.me && this.props.data.me.id
    if (prevID !== currentID && Sentry)
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: currentID,
          name: this.props.data.me
            ? `${this.props.data.me.firstName} ${this.props.data.me.lastName}`
            : null
        })
      })
  }
  componentWillUnmount() {
    window.removeEventListener('touchstart', this.swipeStart)
    window.removeEventListener('touchend', this.swipeEnd)
    // window.onload(this.loadPopup)
  }
  swipeStart = (e) => {
    const {clientX} = e.touches[0]
    this.swipeInfo = {clientX, ms: Date.now()}
  }
  swipeEnd = (e) => {
    const {clientX} = e.changedTouches[0]
    if (!this.swipeInfo) return
    if (
      this.props.isOpen &&
      clientX - this.swipeInfo.clientX < -100 &&
      Date.now() - this.swipeInfo.ms < 250
    )
      this.props.closeMenu()
    if (
      !this.props.isOpen &&
      clientX - this.swipeInfo.clientX > 100 &&
      Date.now() - this.swipeInfo.ms < 250 &&
      this.swipeInfo.clientX < 150
    )
      this.props.openMenu()
  }
  
  onTogglePopup = () => {
    localStorage.setItem('popupToggle', !this.state.showPopup)

    this.setState((prevState) => ({
      showPopup: !prevState.showPopup,
    }))
  }
  
  render() {
    const {side, content, menuIsOpen, closeMenu, data} = this.props
    const me = (data && data.me) || {
      firstName: '',
      lastName: '',
      isSuperUser: false,
      isManager: false
    }
    const notice = (data && data.notice) || null
    const {isSuperUser, isManager} = me
    const loginFromUser = data.loginFromUser

    // A simple method to make sure the next notification will by default appear if users have toggled off the current one
    // basically reset localStorage once we removed the current website notification
    if((notice && notice.notice_type === '0')) {
      if(localStorage.getItem('popupToggle') === 'false'){
        localStorage.removeItem('popupToggle')
      }
    }

    return (
      <MediaQuery query="(max-width: 1000px)">
        {(isMobile) => (
          <div className={isMobile ? 'layout mobile' : 'layout'}>
            <Search />
            <div className="layout-topbar">
              <div className="menu-toggle" onClick={this.props.toggleMenu}>
                <i className="fa fa-bars" /> Menu
              </div>
              <div
                className="logo clickable"
                onClick={() => {
                  window.location.hash = ''
                  window.location.reload()
                }}
              >
                <FullLogo />
              </div>
              <div className="spacer" />
              {notice?.notice_type === '1' &&
              <div className ="notice" onClick={this.onTogglePopup}>
                <i className='fa fa-bell fa-xl' />
                <span className={`popuptext ${this.state.showPopup ? 'show' : ''}`}>{notice.notice_content}</span>
              </div>
              }
              <div
                onClick={() => {
                  window.open(
                    'https://www.notion.so/Sensorfield-Dashboard-Walk-through-c8851b75cbc24f8782b6c20b2f1c3ccc',
                    '_blank'
                  )
                }}
                className="guide"
              >
                User Guide
              </div>
              <div
                onClick={() => {
                  let e = new Event('keydown')
                  e.keyCode = 27
                  e.which = 27
                  document.dispatchEvent(e)
                }}
                className="search"
              >
                <MediaQuery query="(max-width: 1000px)">
                    {(isMobile) => (isMobile ? <i className="fa fa-search" /> : 'Search')}
                </MediaQuery>
              </div>
              <div className="user" onClick={linkHandler('/account')}>
                <span className="name">
                  {!!loginFromUser &&
                    `${formatName(loginFromUser, {lastFirst: false})} as `}
                  {formatName(me, {lastFirst: false})}
                </span>
                <span className="fa-stack">
                  <i className="fa fa-circle fa-stack-2x"></i>
                  <i className="fa fa-user fa-stack-1x fa-inverse"></i>
                </span>
              </div>
            </div>
            <div className="layout-inner">
              <div
                className={
                  menuIsOpen ? 'layout-sidebar' : 'layout-sidebar closed'
                }
              >
                <div className="tabs clickable">
                  <Tab
                    currentTab={this.props.tab}
                    tabName="directory"
                    path="/directory"
                  >
                    Directory
                  </Tab>
                  {(isManager || isSuperUser) && (
                    <Tab
                      currentTab={this.props.tab}
                      tabName="team"
                      path="/team"
                    >
                      Team
                    </Tab>
                  )}
                  {isSuperUser && (
                    <Tab
                      currentTab={this.props.tab}
                      tabName="admin"
                      path="/admin"
                      small
                    >
                      <i className="fa fa-gear" />
                    </Tab>
                  )}
                </div>
                {side}
              </div>
              <div
                className={`layout-sidebar-backdrop  ${
                  menuIsOpen ? '' : 'closed'
                }`}
                onClick={closeMenu}
              />
              <div className="layout-content">{content}</div>
            </div>
          </div>
        )}
      </MediaQuery>
    )
  }
}

const Tab = ({currentTab, tabName, path, children, small}) => (
  <div
    className={`tab ${currentTab === tabName ? 'active ' : ''} ${
      small ? 'small' : ''
    }`}
    onClick={linkHandler(path)}
  >
    {children}
  </div>
)

const mapStateToProps = (state) => ({
  menuIsOpen: state.menu.isOpen,
  loginFromUserID: state.user.token.id,
  loginAsUserID: state.user.loginAsUserID
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  openMenu: () => dispatch(openMenu()),
  closeMenu: () => dispatch(closeMenu()),
  toggleMenu: () => dispatch(toggleMenu())
})
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GET_ME_AND_LOGIN_FROM, {
    options: (props) => ({variables: {loginFromUserID: props.loginFromUserID}}),
    skip: (props) => !props.loginAsUserID
  }),
  graphql(GET_ME_AND_NOTICE, {
    skip: (props) => !!props.loginAsUserID
  })
)(Layout)
