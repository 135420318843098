import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {updateView, changeOffset} from '../store/actions'
import Preview from './Preview'
import SelectionBox from './SelectionBox'

class TimeBar extends PureComponent {
  state = {dragging: false}
  now = Date.now() / 1000
  componentDidMount() {
    this.props.changeOffset(50)
  }
  componentWillUnmount() {
    this.props.changeOffset(-50)
  }
  getTimeFromClickEvent = (e) => {
    if (!this.ref) return
    const {width, left} = this.ref.getBoundingClientRect()

    const scalePixelsToTime =
      (Date.now() / 1000 - this.props.data[0][0]) / width
    return scalePixelsToTime * (e.clientX - left) + this.props.data[0][0]
  }
  startDrag = (e, handle) => {
    e.preventDefault()
    e.stopPropagation()
    const savedEvent = {...e}
    window.addEventListener('mousemove', this.mouseMoveHandler)
    window.addEventListener('mouseup', this.mouseUpHandler)
    window.addEventListener('mouseleave', this.mouseUpHandler)
    this.setState({dragging: handle}, () => this.mouseMoveHandler(savedEvent))
  }
  mouseMoveHandler = (e) => {
    const time = this.getTimeFromClickEvent(e)
    switch (this.state.dragging) {
      case 'start':
        if (time < this.props.end) {
          if (this.props.connectedGraph !== null) {
            this.props.connectedGraph.remoteUpdate({x: {start: time}})
          }
          return this.props.updateView({x: {start: time}})
        } else {
          if (this.props.connectedGraph !== null) {
            this.props.connectedGraph.remoteUpdate({
              x: {start: this.props.end, end: time}
            })
          }
          return this.setState({dragging: 'end'}, () =>
            this.props.updateView({x: {start: this.props.end, end: time}})
          )
        }
      case 'end':
        if (time > this.props.start) {
          if (this.props.connectedGraph !== null) {
            this.props.connectedGraph.remoteUpdate({x: {end: time}})
          }
          return this.props.updateView({x: {end: time}})
        } else {
          if (this.props.connectedGraph !== null) {
            this.props.connectedGraph.remoteUpdate({
              x: {start: time, end: this.props.start}
            })
          }
          return this.setState({dragging: 'start'}, () =>
            this.props.updateView({
              x: {start: time, end: this.props.start}
            })
          )
        }
      default:
      case 'middle':
        const selectedDomain = this.props.end - this.props.start

        if (this.props.connectedGraph !== null) {
          this.props.connectedGraph.remoteUpdate({
            x: {
              start: time - selectedDomain / 2,
              end: time + selectedDomain / 2
            }
          })
        }
        return this.props.updateView({
          x: {start: time - selectedDomain / 2, end: time + selectedDomain / 2}
        })
    }
  }
  mouseUpHandler = (e) => {
    this.setState({dragging: false})
    window.removeEventListener('mousemove', this.mouseMoveHandler)
    window.removeEventListener('mouseup', this.mouseUpHandler)
    window.removeEventListener('mouseleave', this.mouseUpHandler)
  }
  render() {
    const width = this.props.width || this.props.graphWidth
    if (width <= 0 || !this.props.data.length) return null

    return (
      <svg
        x={this.props.x}
        y={this.props.y}
        onMouseDown={(e) => this.startDrag(e, 'middle')}
        width={width}
        height={this.props.height || 40}
        ref={(ref) => (this.ref = ref)}
        style={{cursor: this.state.dragging ? 'grabbing' : 'pointer'}}
      >
        <Preview
          maxGap={this.props.maxGap}
          height={this.props.height || 40}
          data={this.props.data}
          width={width}
          min={this.props.data[0][0]}
          max={this.now}
        />
        <SelectionBox
          height={40}
          startDrag={this.startDrag}
          dragging={this.state.dragging}
          min={this.props.data[0][0]}
          max={Date.now() / 1000}
          width={width}
        />
      </svg>
    )
  }
}

const mapStateToProps = (state) => ({
  x: state.container.leftOffset,
  y: state.container.height - 40,
  graphWidth:
    state.container.width -
    state.container.leftOffset -
    state.container.rightOffset,
  left: state.container.left + state.container.leftOffset,
  start: state.view.x.start,
  end: state.view.x.end
})

const mapDispatchToProps = (dispatch) => ({
  updateView: (params) => dispatch(updateView(params)),
  changeOffset: (value) => dispatch(changeOffset({bottomOffset: value}))
})

export default connect(mapStateToProps, mapDispatchToProps)(TimeBar)
