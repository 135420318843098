import React, {Component} from 'react'
import {feet as formatFeet} from 'utils/format'
import isNumber from 'lodash/isNumber'

class FeetInches extends Component {
  state = {value: null, feet: '', inches: ''}
  componentDidMount() {
    this.updateValue(this.props.value)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.value !== this.props.value &&
      nextProps.value !== this.state.value
    )
      this.updateValue(nextProps.value)
  }
  onChange = () =>
    this.props.onChange({
      target: {
        value: this.state.value,
        dataset: {
          key: this.props['data-key']
        }
      }
    })

  updateValue = (value) => {
    if (isNumber(value)) {
      value = value.toFixed(3)
      if (value >= 0) {
        this.setState({
          value: value,
          feet: Math.floor(value),
          inches: parseFloat(((value - Math.floor(value)) * 12).toFixed(1))
        })
      } else {
        this.setState({
          value: value,
          feet: Math.ceil(value),
          inches: parseFloat(((value - Math.ceil(value)) * 12).toFixed(1))
        })
      }
    } else this.setState({value: null, feet: '', inches: ''})
  }
  updateInputs = (change) => {
    const nextState = {...this.state, ...change}
    if (
      isNaN(parseFloat(nextState.feet)) &&
      isNaN(parseFloat(nextState.inches))
    )
      this.setState({value: null, feet: '', inches: ''}, this.onChange)
    else if (
      (parseFloat(nextState.feet) >= 0 || isNaN(parseFloat(nextState.feet))) &&
      parseFloat(nextState.inches) >= 0
    ) {
      this.setState(
        {
          ...nextState,
          value:
            (parseFloat(nextState.feet) || 0) +
            (parseFloat(nextState.inches) || 0) / 12
        },
        this.onChange
      )
    } else {
      this.setState(
        {
          ...nextState,
          value:
            (parseFloat(nextState.feet) || 0) -
            Math.abs((parseFloat(nextState.inches) || 0) / 12)
        },
        this.onChange
      )
    }
  }
  render() {
    if (this.props.static)
      if (isNumber(this.props.value)) return formatFeet(this.props.value)
      else return <em>None</em>

    return (
      <div className="feetinches">
        <div className="icon-group">
          <input
            type="number"
            step=".01"
            onChange={(e) => this.updateInputs({feet: e.target.value})}
            value={this.state.feet}
            disabled={this.props.disabled}
          />
          <div className="input-icon">ft</div>
        </div>
        <div className="icon-group">
          <input
            type="number"
            step=".01"
            onChange={(e) => this.updateInputs({inches: e.target.value})}
            value={this.state.inches}
            disabled={this.props.disabled}
          />
          <div className="input-icon">in</div>
        </div>
      </div>
    )
  }
}

export default FeetInches
