import React from 'react'
import {connect} from 'react-redux'

const Dot = ({
  radius = 5,
  point,
  xStart,
  yStart,
  xScale,
  yScale,
  height,
  width,
  color = '#00f',
  style = {},
  className,
  onClick
}) => {
  if (!point) return null
  const x = (point[0] - xStart) * xScale
  const y = (point[1] - yStart) * yScale
  if (x < -radius || x > width + radius || y < -radius || y > height + radius)
    return null
  return (
    <circle
      cx={x}
      cy={height - y}
      r={radius || 5}
      style={{fill: color, ...style}}
      className={className}
      onClick={onClick}
    />
  )
}

const mapStateToProps = state => ({
  xStart: state.view.x.start,
  yStart: state.view.y.start,
  xScale: state.view.x.scale,
  yScale: state.view.y.scale,
  height:
    state.container.height -
    state.container.topOffset -
    state.container.bottomOffset,
  width:
    state.container.width -
    state.container.leftOffset -
    state.container.rightOffset
})

export default connect(mapStateToProps)(Dot)
