import React from 'react'
import {useQuery} from 'react-apollo'
import {gql} from 'graphql.macro'
import Loading from 'Components/Loading'
import {route} from 'router'
import List from './ListCellular'

import './Alerts.scss'

const GET_DEVICES_CELLULAR = gql`
  query GET_DEVICES_CELLULAR($query: String, $limit: Int, $offset: Int) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
      company {
        id
        name
      }
    }
    devicesRe(query: $query, limit: $limit, offset: $offset) {
      id
      name
      path
      defaultTag
      companyID
      modemType
      firmwareVersion
      signalStrength
      lastTimeMessageReceived
      latitude
      longitude
      tmobileOp
      verizonOp
      attOp
      unknownOp
      company {
        name
      }
    }
  }
`

const Cellular = () => {
  // const [limit] = useState(30)
  // const [offset, setOffset] = useState(0)
  // // const [devices, setDevices] = useState([])

  // const onScroll = event => {
  //   var element = event.target;
  //   if (element.scrollHeight - element.scrollTop === element.clientHeight) {
  //     fetchMore({
  //       variables: { offset: offset + limit },
  //       updateQuery: (prev, { fetchMoreResult }) => {
  //         if (!fetchMoreResult) return prev
  //         return {
  //           me: prev.me,
  //           devicesRe: [...prev.devicesRe, ...fetchMoreResult.devicesRe]
  //         }
  //       }
  //     })
  //     setOffset(offset + limit)
  //   }
  // }

  const {error, loading, data} = useQuery(GET_DEVICES_CELLULAR, {variables: {query: 'cellular'}})
  if (error) return 'error'
  if (loading) return <Loading />
  if (!!data && !data.me.isSuperUser) {
    route('/alarms')
  } 

    return (
      <>{!!data.me.isSuperUser && (
      <div className='directory-body'>
        <div className='directory-top-bar'>
          <p className='title2344'>CELLULAR VIEW</p>
        </div>
      <List devices={data.devicesRe} isSuperUser={data && data.me && data.me.isSuperUser} />
      </div>)}
    </>
    )
  

}

export default Cellular
