import React from 'react'

const InputString = props =>
  props.static ? (
    props.value || <em>None</em>
  ) : (
    <input
      onChange={e =>
        props.length && e.target.value.length > props.length
          ? null
          : props.onChange(e)
      }
      value={props.value === null ? '' : props.value}
      data-key={props['data-key']}
      data-nullable={props['data-nullable']}
      className={
        props.valid === true ? 'valid' : props.valid === false ? 'invalid' : ''
      }
      placeholder={props.placeholder}
      onKeyUp={props.onKeyUp}
    />
  )

export default InputString
