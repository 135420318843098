import React from 'react'

import './PermissionsList.scss'

export default ({canEdit, isManager, isSuperUser}) => (
  <div className="permissions-options-list">
    {/* [ description, can limited member, can full member, can manager ] */}
    {[
      ['Rename and Move Devices', 1, 1, 1],
      ['View Data', 1, 1, 1],
      ['Add Comments', 1, 1, 1],
      ['Add User Data', 1, 1, 1],
      ['Edit Alarms', 0, 1, 1],
      ['Calibrate sensors', 0, 1, 1],
      ['Delete Comments', 0, 1, 1],
      ['Delete User Data', 0, 1, 1],
      ['Manage the Team', 0, 0, 1]
    ].map((p, i) => (
      <Permission
        allowed={
          p[1] || (p[2] && canEdit) || (p[3] && isManager) || isSuperUser
        }
        text={p[0]}
        key={i}
      />
    ))}
    <div
      style={{
        margin: '10px',
        padding: '5px',
        fontSize: '12px',
        fontStyle: 'italic',
        backgroundColor: '#fff',
        border: '1px solid #dae0e6',
        borderRadius: '3px'
      }}
    >
      {isManager
        ? '* For all company devices - Admin users have access to every device.'
        : '* For devices belonging to groups that the user has access to.  Admin users create and set group privileges.'}
    </div>
  </div>
)

const Permission = ({allowed, text}) => (
  <div className="permissions-options-list-item">
    {allowed ? (
      <i className="fa fa-check allowed" />
    ) : (
      <i className="fa fa-ban not-allowed" />
    )}
    {text + (text === 'Manage the Team' ? '' : ' *')}
  </div>
)
