import {gql} from 'graphql.macro'

export const GET_DEVICES = gql`
  query GET_DEVICES($includeInactive: Boolean, $includeOOS: Boolean) {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      viewInactiveCompanies
    }
    devices(includeInactive: $includeInactive, includeOOS: $includeOOS) {
      id
      name
      path
      isActive
      inService
      longitude
      latitude
      tags {
        id
        deviceID
        tag
        ymin
        ymax
        time
        value
        displayUnits
        calibration {
          fluid
        }
        alarms {
          lowWarning
          highWarning
          criticalHigh
          criticalLow
          flag
        }
      }
      company {
        id
        name
        isActive
      }
    }
  }
`

export const GET_ME_AND_USERS = gql`
  query GET_ME_AND_USERS {
    me {
      id
      firstName
      lastName
      email
      phoneNumber
      countryCode
      isSuperUser
      isManager
      isDemo
      viewInactiveCompanies
      company {
        id
        name
        users {
          id
          firstName
          lastName
          phoneNumber
          countryCode
          email
          isManager
          isSuperUser
          isDemo
          canEdit
          permissions
          latestActivity
        }
      }
    }
  }
`

export const GET_ME = gql`
  query GET_ME {
    me {
      id
      firstName
      lastName
      isSuperUser
      isManager
      isDemo
      viewInactiveCompanies
      company {
        id
        name
      }
    }
  }
`

export const GET_USERS = gql`
  query GET_USERS {
    me {
      id
      firstName
      lastName
      isManager
      isSuperUser
      viewInactiveCompanies
    }
    users {
      id
      firstName
      lastName
      phoneNumber
      countryCode
      email
      isManager
      isSuperUser
      isDemo
      canEdit
      company {
        id
        name
      }
      permissions
      latestActivity
    }
  }
`

export const GET_GROUP_BY_ID = gql`
  query GET_GROUP_BY_ID($id: Int!) {
    group(id: $id) {
      id
      name
      users {
        id
        firstName
        lastName
        isManager
        isSuperUser
        isDemo
        canEdit
      }
      inclusiveDevices {
        id
        name
      }
      company {
        id
        name
        users {
          id
          firstName
          lastName
          isManager
          isSuperUser
          isDemo
          canEdit
        }
        inclusiveDevices {
          id
          name
        }
      }
    }
  }
`

export const GET_COMPANY_BY_ID = gql`
  query GET_COMPANY_BY_ID($id: Int!) {
    company(id: $id) {
      id
      name
      users {
        id
        firstName
        lastName
      }
      inclusiveDevices {
        id
        name
      }
    }
  }
`

export const GET_USER_BY_ID = gql`
  query GET_USER_BY_ID($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      phoneNumber
      countryCode
      email
      isManager
      isSuperUser
      isDemo
      canEdit
      groups {
        id
      }
      company {
        id
        name
        groups {
          id
          name
        }
      }
      permissions
      latestActivity
    }
  }
`

export const GET_GROUP_AND_USER_IDS = gql`
  query GET_GROUP_AND_USER_IDS {
    me {
      id
      firstName
      lastName
      isManager
      isSuperUser
      company {
        id
        name
      }
    }
    groups {
      id
    }
    users {
      id
    }
  }
`

export const GET_GROUPS_BY_COMPANY = gql`
  query GET_GROUP_IDS_BY_COMPANY($companyID: Int) {
    groups(companyID: $companyID) {
      id
      name
      users {
        id
        firstName
        lastName
        isManager
        isSuperUser
        isDemo
        canEdit
      }
    }
  }
`

export const GET_COMPANIES = gql`
  query GET_COMPANIES {
    companies {
      id
      name
      isActive
      users {
        id
        firstName
        lastName
        isManager
        isSuperUser
        isDemo
        canEdit
      }
    }
  }
`

export const DELETE_COMPANY = gql`
  mutation DELETE_COMPANY($id: Int!) {
    deleteCompany(id: $id) {
      company {
        id
        name
      }
    }
  }
`
