import axios from 'axios'

let authToken = ''

// the authorization token is stored in Local Storage, I think by GraphQL,
// so we have to do some hacky parsing to get the value of the token out of storage
try {
  const localStorageData = window.localStorage.getItem('persist:root')
  const user = JSON.parse(localStorageData).user
  authToken = JSON.parse(user).authorization
} catch(err) {
  console.error(err)
}

axios.defaults.headers.common['authorization'] = `Bearer ${authToken}`

export default axios
