import {gql} from 'graphql.macro'

export const GET_TAG = gql`
  query GET_TAG($deviceID: Int!, $tag: String!, $startTime: Float, $endTime: Float, $downsampled: Boolean) {
    device(id: $deviceID) {
      id
      name
      path
      defaultTag
      defaultTagScale
      firmwareVersion
      sensorAck
      sensorAck2
      groups {
        id
        name
      }
      settings {
        key
        reportedValue
        reportedTime
      }
      tag(tag: $tag) {
        id
        deviceID
        tag
        ymin
        ymax
        displayUnits
        barrelsPerInch
        reportHour
        filterEnabled
        filterThreshold
        filterMinimumValue
        sensorTempFilterEnabled
        sensorTempFilterThreshold
        temperatureCorrectionEnabled
        temperatureCorrectionCoefficient
        temperatureCorrectionThreshold
        sf6NormalizationEnabled
        fourierPlotEnabled
        maximumFourierAmplitude
        activeAdditional
        averageTempData
        sf6NormalizedData
        additionalTagData(startTime: $startTime, endTime: $endTime, downsampled: $downsampled) {
          tag
          displayUnits
          ymin
          ymax
          data {
            time
            value
          }
        }
        calibration {
          offset
          fluid
          api
          tds
          time
          target
          tankHeight
          kFactor
          productionOffset
          tankName
        }
        calibrations {
          offset
          fluid
          api
          tds
          time
          target
          kFactor
          productionOffset
          tankName
        }
        secondaryGraph(startTime: $startTime, endTime: $endTime) {
          deviceID
          tag
          ymin
          ymax
          displayUnits
          data
          filterEnabled
          filterThreshold
          filterMinimumValue
        }
        thirdGraph(startTime: $startTime, endTime: $endTime) {
          deviceID
          tag
          ymin
          ymax
          displayUnits
          data
          filterEnabled
          filterThreshold
          filterMinimumValue
        }
        fourthGraph(startTime: $startTime, endTime: $endTime) {
          deviceID
          tag
          ymin
          ymax
          displayUnits
          data
          filterEnabled
          filterThreshold
          filterMinimumValue
        }
        fifthGraph(startTime: $startTime, endTime: $endTime) {
          deviceID
          tag
          ymin
          ymax
          displayUnits
          data
          filterEnabled
          filterThreshold
          filterMinimumValue
        }
        data(startTime: $startTime, endTime: $endTime, downsampled: $downsampled)
        fourierData
        userData {
          time
          value
        }
        alarms {
          criticalHigh
          criticalLow
          highWarning
          lowWarning
          unconvertedLowWarning
          unconvertedHighWarning
          unconvertedCriticalLow
          unconvertedCriticalHigh
          flag
          actions {
            delay
            user {
              id
              firstName
              lastName
            }
            action
          }
          log {
            time
            user {
              id
              firstName
              lastName
            }
            action
          }
        }
      }
      comments {
        id
        time
        comment
      }
    }
    me {
      firstName
      lastName
      id
      isSuperUser
      isManager
      canEdit
      company {
        id
      }
      isDemo
    }
  }
`

export const DELETE_DATA = gql`
  mutation DELETE_DATA($deviceID: Int!, $tag: String!, $time: Int!) {
    deleteData(deviceID: $deviceID, tag: $tag, time: $time) {
      success
    }
  }
`

export const DELETE_COMMENT = gql`
  mutation DELETE_COMMENT($id: Int!) {
    deleteComment(id: $id) {
      device {
        id
        comments {
          id
          time
          comment
        }
      }
    }
  }
`

export const ADD_USER_DATA = gql`
  mutation ADD_USER_DATA(
    $deviceID: Int!
    $time: Int!
    $index: Int!
    $value: Float!
  ) {
    addUserData(
      deviceID: $deviceID
      index: $index
      time: $time
      value: $value
    ) {
      device {
        id
        userData {
          time
          value
        }
      }
    }
  }
`

export const DELETE_USER_DATA = gql`
  mutation DELETE_USER_DATA($deviceID: Int!, $tag: String!, $time: Int!) {
    deleteUserData(deviceID: $deviceID, tag: $tag, time: $time) {
      device {
        id
        tag(tag: $tag) {
          id
          userData {
            time
            value
          }
        }
      }
    }
  }
`

export const UPDATE_ALARMS = gql`
  mutation UPDATE_ALARMS($deviceID: Int!, $alarms: UpdateAlarmsInput!) {
    updateAlarms(deviceID: $deviceID, alarms: $alarms) {
      device {
        id
        alarms {
          lowWarning
          highWarning
          criticalLow
          criticalHigh
          stepsOnTrigger {
            wait
            action
            userID
          }
          stepsOnNormalize {
            wait
            action
            userID
          }
        }
      }
    }
  }
`

export const UPDATE_TEMP_CORRECTION = gql`
  mutation UPDATE_TEMP_CORRECTION(
    $deviceID: Int!
    $tag: String!
    $temperatureCorrectionCoefficient: Float
  ) {
    updateTempCorrection(
      deviceID: $deviceID
      tag: $tag
      temperatureCorrectionCoefficient: $temperatureCorrectionCoefficient
    ) {
      device {
        id
        tag(tag: $tag) {
          id
          tag
          temperatureCorrectionCoefficient
          data
        }
      }
    }
  }
`

export const UPDATE_TAG = gql`
  mutation UPDATE_TAG(
    $deviceID: Int!
    $tag: String!
    $ymin: Float
    $ymax: Float
    $displayUnits: String
    $secondaryGraphDeviceID: Int
    $secondaryGraphTag: String
    $thirdGraphDeviceID: Int
    $thirdGraphTag: String
    $fourthGraphDeviceID: Int
    $fourthGraphTag: String
    $fifthGraphDeviceID: Int
    $fifthGraphTag: String
    $additionalTags: [aTagsType]
    $barrelsPerInch: Float
    $filterEnabled: Boolean
    $filterThreshold: Float
    $filterMinimumValue: Float
    $sensorTempFilterEnabled: Boolean
    $sensorTempFilterThreshold: Int
    $temperatureCorrectionEnabled: Boolean
    $temperatureCorrectionThreshold: Float
    $sf6NormalizationEnabled: Boolean
    $fourierPlotEnabled: Boolean
    $maximumFourierAmplitude: Int
  ) {
    updateTag(
      deviceID: $deviceID
      tag: $tag
      ymin: $ymin
      ymax: $ymax
      displayUnits: $displayUnits
      secondaryGraphDeviceID: $secondaryGraphDeviceID
      secondaryGraphTag: $secondaryGraphTag
      thirdGraphDeviceID: $thirdGraphDeviceID
      thirdGraphTag: $thirdGraphTag
      fourthGraphDeviceID: $fourthGraphDeviceID
      fourthGraphTag: $fourthGraphTag
      fifthGraphDeviceID: $fifthGraphDeviceID
      fifthGraphTag: $fifthGraphTag
      additionalTags: $additionalTags
      barrelsPerInch: $barrelsPerInch
      filterEnabled: $filterEnabled
      filterThreshold: $filterThreshold
      filterMinimumValue: $filterMinimumValue
      sensorTempFilterEnabled: $sensorTempFilterEnabled
      sensorTempFilterThreshold: $sensorTempFilterThreshold
      temperatureCorrectionEnabled: $temperatureCorrectionEnabled
      temperatureCorrectionThreshold: $temperatureCorrectionThreshold
      sf6NormalizationEnabled: $sf6NormalizationEnabled
      fourierPlotEnabled: $fourierPlotEnabled
      maximumFourierAmplitude: $maximumFourierAmplitude
    ) {
      device {
        id
        tag(tag: $tag) {
          id
          tag
          ymin
          ymax
          barrelsPerInch
          filterEnabled
          filterThreshold
          filterMinimumValue
          temperatureCorrectionEnabled
          temperatureCorrectionThreshold
          sf6NormalizationEnabled
          fourierPlotEnabled
          maximumFourierAmplitude
          alarms {
            lowWarning
            highWarning
            criticalLow
            criticalHigh
          }
          displayUnits
          data
          additionalTagData {
            tag
            displayUnits
            ymin
            ymax
            data {
              time
              value
            }
          }
          secondaryGraph {
            deviceID
            tag
          }
          thirdGraph {
            deviceID
            tag
          }
          fourthGraph {
            deviceID
            tag
          }
          fifthGraph {
            deviceID
            tag
          }
        }
      }
    }
  }
`
export const UPDATE_BPI = gql`
  mutation UPDATE_BPI($deviceID: Int!, $tag: String!, $barrelsPerInch: Float!) {
    updateBPI(deviceID: $deviceID, tag: $tag, barrelsPerInch: $barrelsPerInch) {
      device {
        id
        tag(tag: $tag) {
          id
          tag
          barrelsPerInch
        }
      }
    }
  }
`

export const UPDATE_REPORT_HOUR = gql`
  mutation UPDATE_REPORT_HOUR(
    $deviceID: Int!
    $tag: String!
    $reportHour: Int!
  ) {
    updateReportHour(deviceID: $deviceID, tag: $tag, reportHour: $reportHour) {
      device {
        id
        tag(tag: $tag) {
          id
          tag
          reportHour
        }
      }
    }
  }
`

export const UPDATE_FOURIER_ENABLED = gql`
  mutation UPDATE_FOURIER_ENABLED(
    $deviceID: Int!
    $tag: String!
    $fourierPlotEnabled: Boolean!
  ) {
    updateFourierEnabled(
      deviceID: $deviceID
      tag: $tag
      fourierPlotEnabled: $fourierPlotEnabled
    ) {
      device {
        id
        tag(tag: $tag) {
          id
          tag
          fourierPlotEnabled
        }
      }
    }
  }
`

export const UPDATE_ACTIVE_ADDITIONAL = gql`
  mutation UPDATE_ACTIVE_ADDITIONAL(
    $deviceID: Int!
    $tag: String!
    $activeAdditional: String!
  ) {
    updateActiveAdditional(
      deviceID: $deviceID
      tag: $tag
      activeAdditional: $activeAdditional
    ) {
      device {
        id
        tag(tag: $tag) {
          id
          tag
          activeAdditional
        }
      }
    }
  }
`
