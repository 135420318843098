import React from 'react'
import {name as formatName} from 'utils/format'
import {Link} from 'router'

import './CompanyUserList.scss'

const CompanyUserList = ({users}) => {
  if (!users) {
    return null
  }
  return (
    <div className="permissions-paths box">
      <div className="title">Users: {users.length}</div>
      <div className="body">
        <UsersMenu users={users} />
      </div>
    </div>
  )
}

const UsersMenu = ({users}) => {
  if (!users) {
    return null
  }
  const compare = (a, b) => {
    if (a.lastName === null) {
      return 1
    }
    if (b.lastName === null) {
      return -1
    }
    if (a.lastName.toUpperCase() > b.lastName.toUpperCase()) {
      return 1
    } else if (a.lastName.toUpperCase() < b.lastName.toUpperCase()) {
      return -1
    } else {
      if (a.firstName === null) {
        return 1
      }
      if (b.firstName === null) {
        return -1
      }
      if (a.firstName.toUpperCase() > b.firstName.toUpperCase()) {
        return 1
      } else if (a.firstName.toUpperCase() < b.firstName.toUpperCase()) {
        return -1
      } else {
        return 0
      }
    }
  }
  return (
    <ul>
      {users.sort(compare).map((u) => {
        return (
          <li
            key={u.id}
            className={'permissions-directory'}
            style={{backgroundColor: 'cornsilk'}}
          >
            <Link path={`/team/user/${u.id}`}>
              <div style={{color: '#2c5f8e'}}>{formatName(u)}</div>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default CompanyUserList
