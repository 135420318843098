const mapping = [
  {str: 'second', val: 1000},
  {str: 'minute', val: 60 * 1000},
  {str: 'hour', val: 60 * 60 * 1000},
  {str: 'day', val: 24 * 60 * 60 * 1000},
  {str: 'week', val: 7 * 24 * 60 * 60 * 1000},
  {str: 'month', val: 30 * 24 * 60 * 60 * 1000},
  {str: 'year', val: 365 * 24 * 60 * 60 * 1000}
]

function ago(date) {
  if (!date) return null
  const diff = Date.now() - (date.getTime ? date.getTime() : date * 1000)
  for (var i = mapping.length - 1; i >= 0; i--)
    if (diff > mapping[i].val)
      return `${Math.round(diff / mapping[i].val)} ${mapping[i].str}${
        diff / mapping[i].val >= 1.5 ? 's' : ''
      } ago`
  return `Just now`
}

export default ago
