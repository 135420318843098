import React from 'react'
import {Link} from 'router'

import './DevicesList.scss'

const DevicesList = ({allDevices, activeDevices, toggleDevice, editing}) => {
  // const [includeInactive, setIncludeInactive] = React.useState(false)
  return (
    <div className="permissions-paths box">
      <div className="title">
        Devices:{' '}
        {/* {devices.filter((d) => (includeInactive ? true : d.isActive)).length} */}
        {activeDevices.length}
        {/* <div className="show-inactive">
          Show Inactive{' '}
          <input
            className="include-inactive-checkbox"
            type="checkbox"
            checked={includeInactive}
            onChange={() => {
              setIncludeInactive(!includeInactive)
            }}
          />
        </div> */}
      </div>
      <div className="body">
        <DevicesMenu
          allDevices={allDevices}
          activeDevices={activeDevices}
          toggleDevice={toggleDevice}
          editing={editing}
        />
      </div>
    </div>
  )
}

const DevicesMenu = ({allDevices, activeDevices, toggleDevice, editing}) => {
  if (!allDevices) {
    return null
  }
  const compare = (a, b) => {
    if (a.name === null) {
      return 1
    }
    if (b.name === null) {
      return -1
    }
    if (a.name.toUpperCase() > b.name.toUpperCase()) {
      return 1
    } else if (a.name.toUpperCase() < b.name.toUpperCase()) {
      return -1
    } else {
      return 0
    }
  }
  return (
    <ul>
      {allDevices.sort(compare).map((d) => {
        const allowed = activeDevices.includes(d.id)
        return (
          <li
            key={d.id}
            className={'permissions-directory ' + (allowed ? 'allowed' : '')}
          >
            <input
              type="checkbox"
              data-path={d.id}
              checked={allowed}
              disabled={!editing}
              onChange={() => {
                toggleDevice(d.id)
              }}
            />
            {!!editing ? (
              `${d.id} - ${d.name}`
            ) : (
              <Link path={`/devices/${d.id}`}>
                <div style={{color: '#2c5f8e'}}>
                  {d.id} - {d.name}
                </div>
              </Link>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default DevicesList
