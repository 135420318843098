import React, {Component} from 'react'
import './ViewCompany.scss'

import {Input, InputTable} from 'Components/Form'
import Modal from 'Components/Modal'
import {gql} from 'graphql.macro'
import {graphql} from 'react-apollo'
import * as compose from 'lodash/flowRight'

import {DELETE_COMPANY} from './queries'

const GET_COMPANY_USERS = gql`
  query GET_COMPANY_USERS($companyID: Int!) {
    company(id: $companyID) {
      id
      name
      isBilled
      isTaxed
      isActive
      billingAddress {
        attn
        street1
        street2
        city
        state
        zip
      }
    }
  }
`

const UPDATE_COMPANY = gql`
  mutation UPDATE_COMPANY(
    $id: Int!
    $name: String
    $billingAddress: AddressInput
    $isBilled: Boolean
    $isTaxed: Boolean
    $isActive: Boolean
  ) {
    updateCompany(
      id: $id
      name: $name
      billingAddress: $billingAddress
      isBilled: $isBilled
      isTaxed: $isTaxed
      isActive: $isActive
    ) {
      company {
        id
        name
        isTaxed
        isBilled
        isActive
        billingAddress {
          attn
          street1
          street2
          city
          state
          zip
        }
      }
    }
  }
`

class ViewCompany extends Component {
  constructor(props) {
    super(props)
    this.state = this.getCleanState()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data.company !== this.props.data.company)
      this.setState(this.getCleanState())
  }
  getCleanState = () => ({
    billingAddress: {},
    ...this.props.data.company,
    editing: false,
    showDeleteCompanyModal: false,
  })

  deleteCompany = () => {
    this.props.deleteCompany({
      variables: {
        id: this.state.id
      }
    })

    window.location.reload()
  }

  handleModal = (show = false) => {
    this.setState({showDeleteCompanyModal: show})
  }

  render = () => (
    <div className="view-company-container">
      <div className="view-company">
        <div className="header">
          <span className="name">{this.state.name}</span>
          <span className="spacer" />
          {this.state.editing && (
            <button onClick={() => this.setState(this.getCleanState())}>
              Cancel
            </button>
          )}
          {this.state.editing && (
            <button
              className="primary"
              onClick={() => {
                const {
                  __typename,
                  ...billingAddress
                } = this.state.billingAddress
                this.props.updateCompany({
                  variables: {
                    id: this.state.id,
                    name: this.state.name,
                    isTaxed: this.state.isTaxed,
                    isBilled: this.state.isBilled,
                    isActive: this.state.isActive,
                    billingAddress: billingAddress
                  }
                })
                this.setState(this.getCleanState())
              }}
            >
              Save Changes
            </button>
          )}
          {this.props.isSuperUser && (
            <button className="view-company-delete" onClick={() => this.handleModal(true)}>
              Delete Company
            </button>
          )}
          {this.state.showDeleteCompanyModal && (
            <Modal onClose={() => this.handleModal(false)}>
            <Modal.Title>Delete Company</Modal.Title>
            <Modal.Body>
              Are you sure you want to delete <b>{this.state.name}</b>?
            </Modal.Body>
            <Modal.Footer>
              <span className="button" onClick={() => this.handleModal(false)}>
                Cancel
              </span>
              <span className="button primary" onClick={this.deleteCompany}>
                Yes
              </span>
            </Modal.Footer>
          </Modal>
          )}
          {!this.state.editing && (
            <button onClick={() => this.setState({editing: true})}>
              Edit Company
            </button>
          )}
        </div>
        <InputTable>
          <Input
            type="string"
            label="Company Name"
            onChange={(e) => this.setState({name: e.target.value})}
            value={this.state.name}
            static={!this.state.editing}
          />
          <Input
            type="checkbox"
            label="Include in Billing"
            onChange={(e) => this.setState({isBilled: !this.state.isBilled})}
            value={this.state.isBilled}
            static={!this.state.editing}
          />
          <Input
            type="checkbox"
            label="Should be Taxed"
            onChange={(e) => this.setState({isTaxed: !this.state.isTaxed})}
            value={this.state.isTaxed}
            static={!this.state.editing}
          />
          <Input
            type="checkbox"
            label="Is Active"
            onChange={(e) => this.setState({isActive: !this.state.isActive})}
            value={this.state.isActive}
            static={!this.state.editing}
          />
          <hr />
          <strong>Billing</strong>
          <Input
            type="string"
            label="Attention"
            onChange={(e) =>
              this.setState({
                billingAddress: {
                  ...this.state.billingAddress,
                  attn: e.target.value
                }
              })
            }
            value={this.state.billingAddress.attn}
            static={!this.state.editing}
          />
          <Input
            type="string"
            label="Street 1"
            onChange={(e) =>
              this.setState({
                billingAddress: {
                  ...this.state.billingAddress,
                  street1: e.target.value
                }
              })
            }
            value={this.state.billingAddress.street1}
            static={!this.state.editing}
          />
          <Input
            type="string"
            label="Street 2"
            onChange={(e) =>
              this.setState({
                billingAddress: {
                  ...this.state.billingAddress,
                  street2: e.target.value
                }
              })
            }
            value={this.state.billingAddress.street2}
            static={!this.state.editing}
          />
          <Input
            type="string"
            label="City"
            onChange={(e) =>
              this.setState({
                billingAddress: {
                  ...this.state.billingAddress,
                  city: e.target.value
                }
              })
            }
            value={this.state.billingAddress.city}
            static={!this.state.editing}
          />
          <Input
            type="string"
            label="State"
            onChange={(e) =>
              this.setState({
                billingAddress: {
                  ...this.state.billingAddress,
                  state: e.target.value
                }
              })
            }
            value={this.state.billingAddress.state}
            static={!this.state.editing}
          />
          <Input
            type="string"
            label="ZIP"
            onChange={(e) =>
              this.setState({
                billingAddress: {
                  ...this.state.billingAddress,
                  zip: e.target.value
                }
              })
            }
            value={this.state.billingAddress.zip}
            static={!this.state.editing}
          />
          <Input
            type="string"
            label="Billing Email"
            onChange={(e) => this.setState({billingEmail: e.target.value})}
            value={this.state.billingEmail}
            static={!this.state.editing}
          />
        </InputTable>
      </div>
    </div>
  )
}

export default compose(
  graphql(GET_COMPANY_USERS),
  graphql(UPDATE_COMPANY, {name: 'updateCompany'}),
  graphql(DELETE_COMPANY, {name: 'deleteCompany'})
)(ViewCompany)
