import React, {PureComponent} from 'react'
import {createStore} from 'redux'
import {Provider} from 'react-redux'
import reducer from './store/reducer'
import {resizeContainer, updateOffset, changeOffset} from './store/actions'
import {connect} from 'react-redux'
import colors from 'appColors'
import './Container.scss'

class Container extends PureComponent {
  state = {
    colorPalette: null,
    darkMode: null
  }
  componentDidMount() {
    this.props.childRef && this.props.childRef(this)
    this.props.leftOffsetRef && this.props.leftOffsetRef(this.props.leftOffset)
    this.props.rightOffsetRef &&
      this.props.rightOffsetRef(this.props.rightOffset)
    if (this.props.connectedRightOffset !== this.props.rightOffset) {
      let difference = this.props.connectedRightOffset - this.props.rightOffset
      this.props.changeOffset({rightOffset: difference})
    }
    if (this.props.connectedLeftOffset !== this.props.leftOffset) {
      let difference = this.props.connectedLeftOffset - this.props.leftOffset
      this.props.changeOffset({leftOffset: difference})
    }
    window.addEventListener('resize', this.onResize)
    this.initializeState()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.leftOffset !== this.props.leftOffset) {
      this.props.leftOffsetRef &&
        this.props.leftOffsetRef(this.props.leftOffset)
    }
    if (prevProps.rightOffset !== this.props.rightOffset) {
      this.props.rightOffsetRef &&
        this.props.rightOffsetRef(this.props.rightOffset)
    }
    if (this.props.connectedRightOffset !== this.props.rightOffset) {
      let difference = this.props.connectedRightOffset - this.props.rightOffset
      this.props.changeOffset({rightOffset: difference})
    }
    if (this.props.connectedLeftOffset !== this.props.leftOffset) {
      let difference = this.props.connectedLeftOffset - this.props.leftOffset
      this.props.changeOffset({leftOffset: difference})
    }
    // let update = {leftOffset: null, rightOffset: null}
    // if (prevProps.containerLeftOffset !== this.props.containerLeftOffset)
    //   update.leftOffset = this.props.containerLeftOffset
    // if (prevProps.containerRightOffset !== this.props.containerRightOffset)
    //   update.rightOffset = this.props.containerRightOffset
    // if (update.leftOffset !== null || update.rightOffset !== null) {
    //   this.connectedUpdateOffset(update)
    //   this.props.leftOffsetRef &&
    //     this.props.leftOffsetRef(this.props.containerLeftOffset)
    //   this.props.rightOffsetRef &&
    //     this.props.rightOffsetRef(this.props.containerRightOffset)
    // }
    if (prevProps.fourierEnabled !== this.props.fourierEnabled) {
      this.onResize()
    }
    if (prevProps.fourierMax !== this.props.fourierMax) {
      this.initializeState()
    }
    // // if (this.props.fourierEnabled) {
    // //   this.connectedUpdateOffset({
    // //     leftOffset: this.props.containerLeftOffset,
    // //     rightOffset: this.props.containerRightOffset
    // //   })
    // // }
  }

  initializeState = () => {
    if (this.props.fourierMax) {
      const prefersDarkScheme = window.matchMedia(
        '(prefers-color-scheme: dark)'
      )
      if (prefersDarkScheme.matches) {
        this.setState({
          colorPalette: ['blue', 'purple', 'red'],
          darkMode: true
        })
      } else {
        this.setState({
          colorPalette: ['green', 'yellow', 'red'],
          darkMode: false
        })
      }
    }
  }

  isMobile = () => {
    // credit to Timothy Huang for this regex test:
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true
    } else {
      return false
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }
  // connectedUpdateOffset = (update, skip = false) => {
  //   if (this.props.twinContainer !== null && skip === false) {
  //     this.props.twinContainer.connectedUpdateOffset(update, true)
  //   }
  //   this.props.updateOffset(update)
  // }

  getRef = (ref) => {
    this.ref = ref
    setTimeout(this.onResize, 0)
  }
  onResize = () => {
    this.ref && this.props.resizeContainer(this.ref.getBoundingClientRect())
  }
  render() {
    return (
      <svg
        className="svg-graph graph-container"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        style={{
          flex: !!this.props.fourierPlot
            ? '1 1.2 auto'
            : !!this.props.fourierEnabled
            ? '1.2 1 auto'
            : '1 1 auto',
          height: '100%',
          width: '100%',
          userSelect: 'none'
        }}
        ref={this.getRef}
      >
        {/* empty rect to catch pointer events*/}
        <rect x="0%" y="0%" height="100%" width="100%" style={{fill: 'none'}} />
        {this.props.children}
        {this.props.fourierMax && (
          // <g>
          //   <defs>
          //     <linearGradient
          //       id={'example-gradient'}
          //       x1="0"
          //       x2="0"
          //       y1="1"
          //       y2="0"
          //     >
          //       <stop
          //         offset={'0%'}
          //         stopColor={
          //           this.state.colorPalette && this.state.colorPalette[0]
          //         }
          //         stopOpacity={0.5}
          //       />
          //       <stop
          //         offset={'50%'}
          //         stopColor={
          //           this.state.colorPalette && this.state.colorPalette[1]
          //         }
          //         stopOpacity={0.5}
          //       />
          //       <stop
          //         offset={'100%'}
          //         stopColor={
          //           this.state.colorPalette && this.state.colorPalette[2]
          //         }
          //         stopOpacity={0.5}
          //       />
          //     </linearGradient>
          //   </defs>
          //   <rect
          //     height={this.props.graphHeight > 0 ? this.props.graphHeight : 0}
          //     width="35px"
          //     style={{
          //       fill: 'white',
          //       strokeWidth: '1px',
          //       stroke: colors.primary
          //     }}
          //     x={
          //       this.props.rightOffset > 35
          //         ? `${this.props.graphWidth + this.props.leftOffset}px`
          //         : `${this.props.graphWidth + this.props.leftOffset - 35}px`
          //     }
          //     y={this.props.graphY}
          //   />
          //   <rect
          //     id="fourier-amplitude-bar"
          //     x={
          //       this.props.rightOffset > 35
          //         ? `${this.props.graphWidth + this.props.leftOffset}px`
          //         : `${this.props.graphWidth + this.props.leftOffset - 35}px`
          //     }
          //     y={this.props.graphY}
          //     height={this.props.graphHeight > 0 ? this.props.graphHeight : 0}
          //     width="15px"
          //     style={{
          //       fill: 'url(#example-gradient)',
          //       strokeWidth: '1px',
          //       stroke: this.state.darkMode
          //         ? colors.primaryDark
          //         : colors.primary
          //     }}
          //   />
          //   <text
          //     fontSize={this.isMobile() ? '11px' : '13px'}
          //     style={{
          //       transform: `translate(${
          //         this.props.rightOffset > 35
          //           ? `${this.props.graphWidth + this.props.leftOffset + 30}px`
          //           : `${this.props.graphWidth + this.props.leftOffset - 5}px`
          //       },${
          //         this.props.graphY + this.props.graphHeight - 10
          //       }px) rotate(-90deg)`
          //     }}
          //   >
          //     0
          //   </text>
          //   <text
          //     textAnchor="middle"
          //     fontSize={this.isMobile() ? '11px' : '13px'}
          //     style={{
          //       transform: `translateX(${
          //         this.props.rightOffset > 35
          //           ? `${this.props.graphWidth + this.props.leftOffset + 30}px`
          //           : `${this.props.graphWidth + this.props.leftOffset - 5}px`
          //       }) translateY(${
          //         this.props.graphY + this.props.graphHeight / 2
          //       }px) rotate(-90deg)`,
          //       WebkitTransform: `translateX(${
          //         this.props.rightOffset > 35
          //           ? `${this.props.graphWidth + this.props.leftOffset + 30}px`
          //           : `${this.props.graphWidth + this.props.leftOffset - 5}px`
          //       }) translateY(${
          //         this.props.graphY + this.props.graphHeight / 2
          //       }px) rotate(-90deg)`
          //     }}
          //   >
          //     Amplitude (Hz)
          //   </text>
          //   <text
          //     textAnchor="end"
          //     fontSize={this.isMobile() ? '11px' : '13px'}
          //     style={{
          //       transform: `translate(${
          //         this.props.rightOffset > 35
          //           ? `${this.props.graphWidth + this.props.leftOffset + 30}px`
          //           : `${this.props.graphWidth + this.props.leftOffset - 5}px`
          //       },${this.props.graphY + 10}px) rotate(-90deg)`
          //     }}
          //   >
          //     {this.props.fourierMax}
          //   </text>
          // </g>
          <g
            style={{
              transform: `translateX(${
                this.props.rightOffset > 35
                  ? `${this.props.graphWidth + this.props.leftOffset}px`
                  : `${this.props.graphWidth + this.props.leftOffset - 36}px`
              }) translateY(${
                this.props.graphY + this.props.graphHeight
              }px) rotate(-90deg)`,
              WebkitTransform: `translateX(${
                this.props.rightOffset > 35
                  ? `${this.props.graphWidth + this.props.leftOffset}px`
                  : `${this.props.graphWidth + this.props.leftOffset - 36}px`
              }) translateY(${
                this.props.graphY + this.props.graphHeight
              }px) rotate(-90deg)`
            }}
          >
            <defs>
              <linearGradient
                id={'example-gradient'}
                x1="0"
                x2="1"
                y1="0"
                y2="0"
              >
                <stop
                  offset={'0%'}
                  stopColor={
                    this.state.colorPalette && this.state.colorPalette[0]
                  }
                  stopOpacity={0.5}
                />
                <stop
                  offset={'50%'}
                  stopColor={
                    this.state.colorPalette && this.state.colorPalette[1]
                  }
                  stopOpacity={0.5}
                />
                <stop
                  offset={'100%'}
                  stopColor={
                    this.state.colorPalette && this.state.colorPalette[2]
                  }
                  stopOpacity={0.5}
                />
              </linearGradient>
            </defs>
            <rect
              width={this.props.graphHeight > 0 ? this.props.graphHeight : 0}
              height="35px"
              style={{
                fill: 'white',
                strokeWidth: '1px',
                stroke: colors.primary
              }}
            />
            <rect
              id="fourier-amplitude-bar"
              width={this.props.graphHeight > 0 ? this.props.graphHeight : 0}
              height="15px"
              style={{
                fill: 'url(#example-gradient)',
                strokeWidth: '1px',
                stroke: this.state.darkMode
                  ? colors.primaryDark
                  : colors.primary
              }}
            />
            <text fontSize={this.isMobile() ? '9.5px' : '13px'} x={10} y={30}>
              0
            </text>
            <text
              textAnchor="middle"
              fontSize={this.isMobile() ? '9.5px' : '13px'}
              x={this.props.graphHeight / 2}
              y={30}
            >
              Amplitude
            </text>
            <text
              textAnchor="end"
              fontSize={this.isMobile() ? '9.5px' : '13px'}
              x={this.props.graphHeight - 10}
              y={30}
            >
              {this.props.fourierMax.toFixed(2)}
            </text>
          </g>
        )}
      </svg>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    leftOffset: state.container.leftOffset,
    rightOffset: state.container.rightOffset,
    graphWidth:
      state.container.width -
      state.container.leftOffset -
      state.container.rightOffset,
    graphHeight:
      state.container.height -
      state.container.topOffset -
      state.container.bottomOffset,
    graphX: state.container.leftOffset,
    graphY: state.container.topOffset
  }
}
const mapDispatchToProps = (dispatch) => ({
  resizeContainer: (params) => {
    dispatch(resizeContainer(params))
  },
  updateOffset: (params) => {
    dispatch(updateOffset(params))
  },
  changeOffset: (params) => {
    dispatch(changeOffset(params))
  }
})

const ConnectedContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container)

class Combined extends React.Component {
  store = createStore(
    reducer
    //applyMiddleware(require('redux-logger').createLogger())
  )
  render() {
    return (
      <Provider store={this.store}>
        <ConnectedContainer
          childRef={this.props.childRef}
          fourierEnabled={!!this.props.fourierEnabled}
          fourierPlot={!!this.props.fourierPlot}
          twinContainer={this.props.twinContainer}
          leftOffsetRef={this.props.leftOffsetRef}
          rightOffsetRef={this.props.rightOffsetRef}
          connectedLeftOffset={this.props.connectedLeftOffset}
          connectedRightOffset={this.props.connectedRightOffset}
          fourierMax={this.props.fourierMax}
        >
          {this.props.children}
        </ConnectedContainer>
      </Provider>
    )
  }
}

export default Combined
